import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";

export const initialize = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.INITIALIZE_REQUEST,
    });
    // The rest is in middleware
  };
};
