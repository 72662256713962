import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./App";
import "./index.common";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(<App />, document.getElementById("root") as HTMLElement);

const qs = new URLSearchParams(window.location.search);
const clearDataFromQuery = qs.get("cd");
if (clearDataFromQuery) {
  localStorage.clear();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.REACT_APP_REPORT_WEB_VITALS) {
  reportWebVitals(console.log);
}
