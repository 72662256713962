import { ApiError, Page } from "@blacknut/javascript-sdk/dist";
import { Reducer } from "redux";
import { Action as AppAction } from "../actions/App";
import { ListAction, ListActionTypes } from "../actions/List";
import { PageAction, PageActionTypes } from "../actions/Page";
import { UserAction, UserActionTypes } from "../actions/User";
export interface State {
  pages: {
    [id: string]: {
      loading: boolean;
      page?: Page;
      error?: ApiError;
      paginating: boolean;
    };
  };
}
const initialState: State = {
  pages: {},
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: PageAction | AppAction | UserAction | ListAction,
) => {
  switch (action.type) {
    case PageActionTypes.PAGE_FETCH_REQUEST: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = {
        loading: true,
        paginating: false,
      };
      return { ...state, pages };
    }
    case PageActionTypes.PAGE_FETCH_SUCCESS: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = {
        loading: false,
        page: action.page,
        paginating: false,
      };

      return { ...state, pages };
    }
    case UserActionTypes.USER_UPDATE: {
      return { ...state, pages: {} };
    }
    case PageActionTypes.PAGE_FETCH_ERROR: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = {
        loading: false,
        error: action.error,
        paginating: false,
      };
      return { ...state, pages };
    }
    case PageActionTypes.PAGE_FETCH_CATEGORY_REQUEST: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = {
        loading: true,
        paginating: false,
      };
      return { ...state, pages };
    }
    case PageActionTypes.PAGE_FETCH_CATEGORY_SUCCESS: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = {
        loading: false,
        page: action.page,
        paginating: false,
      };

      return { ...state, pages };
    }
    case PageActionTypes.PAGE_FETCH_CATEGORY_ERROR: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = {
        loading: false,
        error: action.error,
        paginating: false,
      };
      return { ...state, pages };
    }
    case PageActionTypes.REMOVE_PAGE: {
      if (!(action.id in state.pages)) {
        return state;
      }
      const pages = Object.assign(state.pages, {});
      delete pages[action.id];
      return { ...state, pages };
    }

    case PageActionTypes.PAGE_PAGINATE_SUCCESS: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = { ...pages[action.id], paginating: false, page: action.page };
      return { ...state, pages };
    }
    case PageActionTypes.PAGE_PAGINATE_REQUEST: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = { ...pages[action.id], paginating: true, error: undefined };
      return { ...state, pages };
    }
    case PageActionTypes.PAGE_PAGINATE_ERROR: {
      const pages = Object.assign(state.pages, {});
      pages[action.id] = { ...pages[action.id], paginating: false, error: action.error };
      return { ...state, pages };
    }
    case UserActionTypes.SET_USER_TOKEN: {
      if (!action.fromRenew) {
        return { ...state, pages: {} };
      }
      return state;
    }
    case ListActionTypes.LIST_PAGINATION_SUCCESS: {
      // On List pagination success, update all corresponding lists
      for (const key in state.pages) {
        // Home is editorialized, so exclude it.
        if (state.pages.hasOwnProperty(key)) {
          const page = state.pages[key].page;
          if (page && page.lists) {
            const idx = page.lists.findIndex((l2) => l2.uuid === action.list.uuid);
            if (idx !== -1) {
              const newList = {
                ...page.lists[idx],
                tiles: action.list.tiles,
              };

              const newLists = page.lists.slice(0);
              newLists[idx] = newList;

              const newPage: Page = {
                ...page,
                lists: newLists,
              };

              const pages = { ...state.pages };
              pages[key] = { ...pages[key], page: newPage };
              return { ...state, pages };
            }
          }
        }
      }
      return state;
    }

    default:
      return state;
  }
};
