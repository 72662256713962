import { CreateProfileFormData, UserProfile } from "@blacknut/javascript-sdk/dist";
import { createFamilyProfile as apiCreateFamilyProfile } from "@blacknut/javascript-sdk/dist/api/Family/FamilyProfile/createFamilyProfile";
import { Dispatch } from "redux";
import { ProfilesAction, ProfilesActionTypes } from "./Profiles";

export const dismissCreateProfileError = () => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({ type: ProfilesActionTypes.CREATE_PROFILE_ERROR });
  };
};
export const createProfile = (data: CreateProfileFormData) => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    return new Promise<UserProfile>((resolve, reject) => {
      dispatch({ type: ProfilesActionTypes.CREATE_PROFILE_REQUEST });
      apiCreateFamilyProfile(data).subscribe({
        next: (res) => {
          dispatch({ type: ProfilesActionTypes.CREATE_PROFILE_SUCCESS, profile: res });
          resolve(res);
        },
        error: (error) => {
          dispatch({ type: ProfilesActionTypes.CREATE_PROFILE_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
