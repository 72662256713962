import { ApiError } from "@blacknut/javascript-sdk/dist";
import { Reducer } from "redux";
import { M1Action, M1ActionTypes } from "../actions/M1";
export interface State {
  generateToken: {
    inProgress: boolean;
    error?: ApiError;
  };
}
const initialState: State = {
  generateToken: { inProgress: false },
};

export const reducer: Reducer<State> = (state = initialState, action: M1Action) => {
  switch (action.type) {
    case M1ActionTypes.M1_GENERATE_TOKEN_REQUEST: {
      return { ...state, generateToken: { inProgress: true } };
    }
    case M1ActionTypes.M1_GENERATE_TOKEN_SUCCESS: {
      return {
        ...state,
        generateToken: { inProgress: false },
      };
    }
    case M1ActionTypes.M1_GENERATE_TOKEN_ERROR: {
      return { ...state, generateToken: { inProgress: false, error: action.error } };
    }

    default:
      return state;
  }
};
