import { reducer as gamesState, State as GameState } from "./Game";
import { reducer as globalState, State as GlobalState, AppLayout } from "./Global";
import { reducer as listsState, State as ListsState } from "./List";
import { reducer as pagesState, State as PageState } from "./Page";
import { reducer as profilesState, State as ProfilesState } from "./Profiles";
import { reducer as searchState, State as SearchState } from "./Search";
import { reducer as leMagState, State as LeMagState } from "./LeMag";
import { reducer as dreiState, State as DreiState } from "./Drei";
import { reducer as m1State, State as M1State } from "./M1";

export default {
  globalState,
  pagesState,
  gamesState,
  listsState,
  profilesState,
  searchState,
  leMagState,
  dreiState,
  m1State,
};

export interface State {
  globalState: GlobalState;
  gamesState: GameState;
  pagesState: PageState;
  listsState: ListsState;
  profilesState: ProfilesState;
  searchState: SearchState;
  leMagState: LeMagState;
  dreiState: DreiState;
  m1State: M1State;
}

export { AppLayout };
