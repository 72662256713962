import { List, Page } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import {
  FocusableSection,
  FocusableSectionProps,
  LeaveFor,
} from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { useInView } from "react-intersection-observer";
import { LOGGING_TAG } from "../../../../utils/Utils";
import BannerTile from "../../../TileBanner/BannerTile";
import { useProfiler } from "../../../../hooks/useProfiler";
import clsx from "clsx";
import styles from "./styles.module.scss";
interface BannerViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  leaveFor?: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  page?: Page;
}

const BannerView = ({ list, leaveFor, onFocus, page }: BannerViewProps) => {
  const t = list.tiles[0];
  logD(LOGGING_TAG, "Render banner");
  const [bannerRef, inView] = useInView({
    threshold: 0,
  });
  const Root = useProfiler("bannerList", { uuid: list.uuid });
  const hasTitle = list.title;
  return (
    <Root>
      <FocusableSection
        focusKey={list.uuid}
        key={list.uuid}
        leaveFor={leaveFor}
        onFocus={onFocus}
      >
        <div ref={bannerRef}>
          {hasTitle && (
            <div className={clsx(styles.title)}>
              {list.title}
              {process.env.NODE_ENV === "development" && `(${list.display})`}
            </div>
          )}

          <BannerTile
            item={t}
            list={list}
            key={list.uuid}
            inView={inView || process.env.NODE_ENV === "test"}
            page={page}
          />
        </div>
      </FocusableSection>
    </Root>
  );
};

export default BannerView;
