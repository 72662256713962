import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissPageError,
  fetchPageByCategory,
  paginatePageGamesByCategory,
} from "../../redux/actions/Page";
import { State } from "../../redux/reducers";

const TAG = "Category";

export const usePageGamesByCategory = (props: { category: string; id: string }) => {
  const { category, id } = props;
  const dispatch = useDispatch();
  const { loading, page, error, paginating } = useSelector(
    (state: State) => state.pagesState.pages[id],
  ) || {
    loading: false,
    paginating: false,
  };

  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (!page && !mounted.current) {
      mounted.current = true;
      const init = async () => {
        try {
          await fetchPageByCategory(category, id)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting page %o %o", `${category}-${id}`, e);
        }
      };
      init();
    }
  }, [page, loading, error, id, dispatch, category]);

  return {
    loading,
    page,
    loadingError: error,
    paginating: paginating,
    dismissLoadingError: React.useCallback(() => {
      dismissPageError(id)(dispatch);
    }, [dispatch, id]),
    paginate: React.useCallback(async () => {
      if (page) {
        if (page.total > page.lists.length && !paginating) {
          try {
            await paginatePageGamesByCategory(category, id, page)(dispatch);
          } catch (e) {
            logE(TAG, "Caught eror on page pagination %o: %o", page.uuid, e);
          }
        }
      }
    }, [category, dispatch, id, page, paginating]),
  };
};
