import {
  ApiError,
  ApiErrorCode,
  apiService,
  createUserAgentString,
  GENERIC_ERROR,
  RemoteNotification,
  TIMEOUT_ERROR,
  Token,
  UserAgent,
} from "@blacknut/javascript-sdk/dist";
import { Observable, Subject } from "rxjs";
import { timeout } from "rxjs/operators";
import { logE, logW, logD } from "@blacknut/logging/dist";
const TAG = "Notifications";

export enum NotificationMessageKind {
  REQ_HELLO = "helloReq",
  RESP_HELLO = "helloResp",
  NOTIFICATION_RESP = "notificationResp",
}

export enum HasNativeNotif {
  DENIED = "denied",
  LATER = "later",
  BLOCKED = "blocked",
  GRANTED = "granted",
}

export type NotificationMessage =
  | {
      kind: NotificationMessageKind.REQ_HELLO;
      payload: {
        lang: string;
        userAgent: string;
        token: Token;
      };
    }
  | {
      kind: NotificationMessageKind.RESP_HELLO;
      payload: unknown;
      status: number;
      error?: ApiError;
    }
  | {
      kind: NotificationMessageKind.NOTIFICATION_RESP;
      payload: {
        notification: RemoteNotification;
      };
      status: number;
    };

export class NotificationsService {
  // tslint:disable-next-line:variable-name
  private _url = "";

  // tslint:disable-next-line:variable-name
  private _lang: string;

  // tslint:disable-next-line:variable-name
  private _userAgentString: string;

  private retry = false;

  private retryTimeout?: NodeJS.Timeout;

  // tslint:disable-next-line:variable-name
  private _onMessageSubject = new Subject<NotificationMessage>();

  // tslint:disable-next-line:variable-name
  private _onReadySubject = new Subject<void>();

  // tslint:disable-next-line:variable-name
  private _token: Token | undefined;

  private ws: WebSocket | null = null;

  // tslint:disable-next-line:variable-name
  private _intervalHeartbeat?: NodeJS.Timeout;

  private errorCount = 0;

  public set url(v: string) {
    logD(TAG, "Using URL %s", v);
    this._url = v;
  }

  public get url(): string {
    return this._url;
  }

  public set lang(v: string) {
    this._lang = v;
  }

  public onMessage(): Observable<NotificationMessage> {
    return this._onMessageSubject.asObservable();
  }

  public onReady(): Observable<void> {
    return this._onReadySubject.asObservable();
  }

  public set token(v: Token | undefined) {
    logD(TAG, `init %o`, v);
    this._token = v;
    this.retry = true;
  }

  public get token(): Token | undefined {
    return this._token;
  }

  public start() {
    if (!this._url) {
      logW(TAG, "No URL set");
      return;
    }
    if (process.env.NODE_ENV === "test") {
      return;
    }
    if (this.ws && this.ws.readyState === WebSocket.CONNECTING) {
      logD(TAG, "Already connecting");
      return;
    }
    this.connect(this._url);
  }

  public isAvailable() {
    return this.ws && this.ws.readyState === WebSocket.OPEN;
  }

  public userAgent(params: UserAgent) {
    this._userAgentString = createUserAgentString(params);
  }

  public stop() {
    this.token = undefined;
    logD(TAG, "stop");
    this.retry = false;
    this.errorCount = 0;
    if (this.isAvailable()) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.ws!.close(1000, "completed");

      /* Sometimes close callback is not called or called with on error code, so force it */
      this._onClose({ code: 1000 });
    }
  }

  public send(kind: NotificationMessageKind, payload?: unknown) {
    if (this.isAvailable()) {
      logD(TAG, "Sending to ws %o", { kind, payload });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.ws!.send(JSON.stringify({ kind, payload }));
    } else {
      throw new Error("Socked closed");
    }
  }

  public sendSync(
    kind: NotificationMessageKind.REQ_HELLO,
    payload: unknown,
    responseKind: NotificationMessageKind.RESP_HELLO,
  ) {
    if (this.isAvailable()) {
      const res = new Subject<unknown>();
      const subscription = this.onMessage()
        .pipe(timeout(2000))
        .subscribe(
          (m) => {
            if (m.kind === responseKind) {
              subscription.unsubscribe();
              if (m.status === 200) {
                res.next(m.payload);
              } else {
                res.error(m.error);
              }
            }
          },
          (err) => {
            logE(TAG, "Caught err", err);
            subscription.unsubscribe();
            res.error({
              status: TIMEOUT_ERROR,
              code: TIMEOUT_ERROR,
              title: TIMEOUT_ERROR,
            });
          },
        );

      this.send(kind, payload);

      return res.asObservable();
    } else {
      throw new Error("Socked closed");
    }
  }

  private connect(url: string) {
    this.create(url);
  }

  private create(url: string) {
    logD(TAG, "Connect %s", url);
    try {
      this.ws = new WebSocket(url);
      this.ws.onopen = this._onOpen.bind(this);
      this.ws.onmessage = this._onMessage.bind(this);
      this.ws.onerror = this._onError.bind(this);
      this.ws.onclose = this._onClose.bind(this);
    } catch (e) {
      logE(TAG, "Error creating socket", e);
    }
  }

  private _onMessage(msg: MessageEvent<string>) {
    logD(TAG, "onMessage %o", msg);
    const requestData: NotificationMessage = JSON.parse(msg.data);
    this._onMessageSubject.next(requestData);
  }

  private _onOpen(event: MessageEvent<string>) {
    this.errorCount = 0;
    logD(TAG, "onOpen %o", event);
    this.sendSync(
      NotificationMessageKind.REQ_HELLO,
      {
        lang: this._lang,
        userAgent: this._userAgentString,
        accessToken: this._token?.accessToken,
      },
      NotificationMessageKind.RESP_HELLO,
    ).subscribe(
      () => {
        logD(TAG, "Hello OK");
        this._onReadySubject.next();
        this._onReadySubject.complete();

        // this._intervalHeartbeat = setInterval(() => {
        //   if (this.isAvailable()) {
        //     this.send(MessageKind.PING);
        //   }
        // }, 30000);
      },
      (err) => {
        logE(TAG, ">Hello NOK", err);
        this.stop();

        if ("code" in err) {
          if (err.code === ApiErrorCode.ACCESS_TOKEN_EXPIRED) {
            logD(TAG, "Refresh token");
            apiService.refreshToken("user").catch((err) => {
              this._onReadySubject.error(err);
            });
          }
        }
      },
    );
  }

  private _onError(event: MessageEvent<string>) {
    logD(TAG, "onError %o", event);
    this.errorCount++;
    this.retry = this.retry && this.errorCount < 10;
    if (this.retry) {
      this.retryTimeout = setTimeout(() => {
        logD(TAG, "Retry");
        this.connect(this._url);
      }, 1000);
    } else {
      logD(TAG, "Giving up !");
      this._onReadySubject.error({
        status: GENERIC_ERROR,
        code: GENERIC_ERROR,
        title: GENERIC_ERROR,
      });
    }
  }

  private _onClose(event?: { code: number }) {
    if (this.ws) {
      this.ws.onopen = null;
      this.ws.onclose = null;
      this.ws.onmessage = null;
      this.ws.onerror = null;
    }
    this.ws = null;
    if (this._intervalHeartbeat !== undefined) {
      clearInterval(this._intervalHeartbeat);
      this._intervalHeartbeat = undefined;
    }

    if (this.retryTimeout !== undefined) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = undefined;
    }

    if (event && event.code !== 1000) {
      logD(TAG, "onCloseAbnormaly %o", event);

      // Retry
      if (this.retry) {
        this.retryTimeout = setTimeout(() => {
          logD(TAG, "Retry");
          this.connect(this._url);
        }, 1000);
      }
    } else {
      logD(TAG, "onCloseNormaly %o", event);
      this.retry = false;
      this._onReadySubject = new Subject<void>();
      this._onMessageSubject = new Subject<NotificationMessage>();
    }
  }
}

export default new NotificationsService();
