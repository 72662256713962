import {
  List,
  ListDisplayType,
  NotificationType,
  Page,
} from "@blacknut/javascript-sdk/dist";
import { logD, logW } from "@blacknut/logging/dist";
import { Button, CircularProgress } from "@blacknut/react-sdk/dist";
import { getSectionIdFromPath } from "@blacknut/spatialnav-sdk/dist";
import { AppLayout, State, StorageKey, addNotification, useLayout } from "core";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import NotificationInstallComponent from "src/components/NotificationsInstall";
import { ViewOrNot } from "src/components/NotificationsInstall/ViewOrNot";
import { detectDevice } from "src/services/detectDevice";
import { ReactComponent as BlacknutLogo } from "../../assets/dist/logo.svg";
import { ReactComponent as FavoritePlaceholder } from "../../assets/dist/placeholder_list_empty.svg";
import { useProfiler } from "../../hooks/useProfiler";
import { useOrientation } from "../../utils/OrientationContext";
import { LOGGING_TAG, isInStandaloneMode } from "../../utils/Utils";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import {
  BonusGamesRenderer,
  FeaturedRenderer,
  HeroRenderer,
  LeMagRenderer,
  ListRendererFn,
} from "./renderer";
import BannerRenderer from "./renderer/BannerRenderer";
import { BrandedRenderer } from "./renderer/BrandedRenderer";
import FlatFeaturedRenderer from "./renderer/FlatFeaturedRenderer";
import { FullRenderer } from "./renderer/FullRenderer";
import { MarketingRenderer } from "./renderer/MarketingRenderer";
import RandomRenderer from "./renderer/RandomRenderer";
import styles from "./styles.module.scss";
import FlatRenderer from "./renderer/FlatRenderer";

// Each list is responsible of filtering input data depending on layout/orientation
const filter = (page: Page) => {
  const data = page.lists || [];
  return data.filter((l) => l.display === ListDisplayType.BONUS_GAMES || l.total > 0);
};

interface CatalogProps {
  page: Page;
  onPaginate: () => void;
  paginating: boolean;
  scrollable?: boolean;
}

const getLeaveFor = ({
  data,
  page,
  index,
}: {
  page: Page;
  data: List[];
  index: number;
}) => {
  const down =
    index < data.length - 1
      ? "@" + getSectionIdFromPath(`/${page.uuid}/${data[index + 1].uuid}`)
      : undefined;
  const up =
    index > 0
      ? "@" + getSectionIdFromPath(`/${page.uuid}/${data[index - 1].uuid}`)
      : undefined;

  return { up, down };
};

const Renderers: { [key in ListDisplayType]?: ListRendererFn } = {
  [ListDisplayType.BONUS_GAMES]: BonusGamesRenderer,
  [ListDisplayType.HERO]: HeroRenderer,
  [ListDisplayType.FEATURED]: FeaturedRenderer,
  [ListDisplayType.LEMAG]: LeMagRenderer,
  [ListDisplayType.MARKETING]: MarketingRenderer,
  [ListDisplayType.BRANDED]: BrandedRenderer,
  [ListDisplayType.FLAT_FEATURED]: FlatFeaturedRenderer,
  [ListDisplayType.BANNER]: BannerRenderer,
  [ListDisplayType.RANDOM]: RandomRenderer,
  [ListDisplayType.FULL]: FullRenderer,
  [ListDisplayType.FLAT]: FlatRenderer,
};

const Catalog = ({ page, onPaginate, paginating, scrollable }: CatalogProps) => {
  const { layout } = useSelector((state: State) => state.globalState);
  const { orientation } = useOrientation();
  const dispatch = useDispatch();
  const { active: previewActive } = usePreview();
  const { config } = useSelector((state: State) => state.globalState);

  if (scrollable === undefined) {
    scrollable = layout === AppLayout.PHONE || layout === AppLayout.TABLET;
  }
  const background =
    page.imgBackground && Array.isArray(page.imgBackground)
      ? page.imgBackground[0]
      : page.imgBackground;
  const data = useMemo(() => filter(page), [page]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [installNotif, setInstallNotif] = useState(
    localStorage.getItem(StorageKey.INSTALL_NOTIF),
  );
  const deviceInfos = detectDevice(navigator.userAgent);

  useEffect(() => {
    if (previewActive) return;
    window.addEventListener("storage", () => {
      setInstallNotif(localStorage.getItem(StorageKey.INSTALL_NOTIF));
    });
    return () => {
      window.removeEventListener("storage", () => {
        setInstallNotif(ViewOrNot(deviceInfos));
      });
    };
  }, [deviceInfos, previewActive]);
  const [paginationThresholdRef, inView] = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (inView && page.lists.length > 0) {
      logD(LOGGING_TAG, "Reached bottom");
      if (page.total > page.lists.length && !paginating) {
        logD(LOGGING_TAG, "Start pagination");
        onPaginate();
      } else {
        // case of list pagination
        const l = page.lists[page.lists.length - 1];
        if (l.total > l.tiles.length && !paginating) {
          onPaginate();
        }
      }
    }
  }, [inView, onPaginate, page, paginating]);

  const usingLayout = useLayout();
  const isOnTv = usingLayout === AppLayout.TV;

  const onFocusCenterContent = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      if (layout !== AppLayout.TV) {
        return;
      }
      e.currentTarget.scrollIntoView({ block: "center" });
    },
    [layout],
  );

  useEffect(() => {
    if (localStorage.getItem(StorageKey.WELCOME_NOTIF) == "true") {
      addNotification({
        type: NotificationType.SUCCESS,
        time: new Date().getTime(),
        message: "notifications.welcomeNewUser.success",
      })(dispatch);
      localStorage.removeItem(StorageKey.WELCOME_NOTIF);
    }
  }, [dispatch]);

  const Root = useProfiler("catalog");
  return (
    <Root>
      {background && page.gamePassUnlocked && (
        <FadeInImage className={styles.image} image={background} />
      )}
      <div className={styles.container} ref={containerRef}>
        {installNotif === "true" &&
          !isInStandaloneMode() &&
          !isOnTv &&
          !previewActive && <NotificationInstallComponent deviceInfos={deviceInfos} />}
        {isOnTv && <BlacknutLogo className={styles.logo} />}
        {data.length === 0 && (
          <div className={styles.emptyContainer}>
            <FavoritePlaceholder />
            <span className={styles.text}>{t("list.empty")}</span>
            {layout === AppLayout.TV && (
              <Button variant="primary" onClick={history.goBack}>
                {t("buttons.back")}
              </Button>
            )}
          </div>
        )}
        {data.map((e, index) => {
          const Renderer = Renderers[e.display];
          if (!Renderer) {
            logW(LOGGING_TAG, "Unhandled: ", e.display);
            return null;
          }
          const props = {
            list: e,
            leaveFor: getLeaveFor({ data, page, index }),
            onFocus: onFocusCenterContent,
            scrollable,
            layout,
            config,
            orientation,
            page,
          };
          return <Renderer key={e.uuid} {...props}></Renderer>;
        })}
        <div className={styles.paginationThreshold} ref={paginationThresholdRef} />
        {paginating && (
          <CircularProgress className={styles.paginationLoader} size={36} />
        )}
      </div>
    </Root>
  );
};

export default Catalog;
