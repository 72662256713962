import { StorageKey } from "core";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
const languagedetector = new LanguageDetector();
const SUPPORTED = [
  "de",
  "en",
  "fr",
  "ja",
  "es",
  "pl",
  "pt",
  "th",
  "zh", //https://stackoverflow.com/questions/4892372/language-codes-for-simplified-chinese-and-traditional-chinese
  "zh-HANT",
];
languagedetector.addDetector({
  name: "i18nLangDetector",
  lookup: () => {
    const s = localStorage.getItem(StorageKey.LOCALE);
    if (s) {
      const res = JSON.parse(s);
      if (res && SUPPORTED.indexOf(res) === -1) {
        const langNoCountry = res.slice(0, 2);
        return langNoCountry;
      }
      return res;
    }
    return undefined;
  },
  cacheUserLanguage: () => {
    console.error("NOP");
  },
});

const instance = i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .use(languagedetector)
  .init(
    {
      supportedLngs: SUPPORTED,
      nonExplicitSupportedLngs: true,
      fallbackLng: "en",
      ns: ["app"],
      defaultNS: "app",
      debug: true,
      backend: {
        backends: [HttpBackend],
        backendOptions: [
          {
            customHeaders: {
              "X-Blk-User-Agent": "Blacknut/1.0.0 app/1.0.0",
            },
            loadPath:
              "https://www.blacknut.com/api/studio/dictionary-app?locale={{lng}}&type=app",
          },
        ],
      },
      detection: {
        // order and from where user language should be detected
        order: ["cookie", "i18nLangDetector", "navigator"],

        // keys or params to lookup language from
        lookupCookie: "lang",
        lookupLocalStorage: undefined,
      },

      interpolation: {
        escapeValue: false, // not needed for react!!
      },
    }, // We must provide a function as second parameter, otherwise i18next errors
    (err) => {
      if (err) {
        return console.log(err);
      }
    },
  );

export default instance;
