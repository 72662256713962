import { Dispatch } from "redux";
import { PageAction, PageActionTypes } from "./Page";
import { ApiError, Page } from "@blacknut/javascript-sdk/dist";
import { getPage as apiGetPage } from "@blacknut/javascript-sdk/dist/api/Catalog/getPage";
import { getPassPage as apiGetPassPage } from "@blacknut/javascript-sdk/dist/api/BlacknutPlus/getPassPage";

const paginatePageRequest = (id: string): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_REQUEST,
  id,
});
const paginatePageSuccess = (id: string, page: Page): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_SUCCESS,
  id,
  page,
});

const paginatePageError = (id: string, error?: ApiError): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_ERROR,
  id,
  error,
});

export const paginatePage = (
  id: string,
  page: Page,
  count?: number,
  tilesLimit?: number,
  pass = false,
) => {
  return (dispatch: Dispatch<PageAction>) => {
    return new Promise<Page>((resolve, reject) => {
      dispatch(paginatePageRequest(id));

      const offset = page.lists!.length;
      const limit = count || 5;

      const observable = pass ? apiGetPassPage : apiGetPage;
      observable(id, offset, limit, tilesLimit).subscribe({
        next: (newPage) => {
          let newList = page.lists || [];
          newList = (page.lists || []).concat(newPage.lists!);
          dispatch(
            paginatePageSuccess(id, {
              ...page,
              lists: newList,
            }),
          );

          resolve(page);
        },
        error: (error) => {
          dispatch(paginatePageError(id, error));
          reject(error);
        },
      });
    });
  };
};
