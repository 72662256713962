import { ApiError } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";
import { ContactMessageFormData } from "@blacknut/javascript-sdk/dist";
import { sendContactMessage as JsSendContactMessage } from "@blacknut/javascript-sdk/dist/api/User/Action/sendContactMessage";
const sendContactMessageRequest = (): Action => ({
  type: ActionTypes.SEND_CONTACT_MESSAGE_REQUEST,
});

const sendContactMessageSuccess = (): Action => ({
  type: ActionTypes.SEND_CONTACT_MESSAGE_SUCCESS,
});

const sendContactMessageError = (error: ApiError): Action => ({
  type: ActionTypes.SEND_CONTACT_MESSAGE_ERROR,
  error,
});

export const dismissContactSendError = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SEND_CONTACT_MESSAGE_ERROR,
      error: undefined,
    });
  };
};

export const sendContactMessage = (params: ContactMessageFormData) => {
  return (dispatch: Dispatch<Action>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(sendContactMessageRequest());
      return JsSendContactMessage(params).subscribe({
        next: () => {
          dispatch(sendContactMessageSuccess());
          resolve();
        },
        error: (error) => {
          dispatch(sendContactMessageError(error));
          reject(error);
        },
      });
    });
  };
};
