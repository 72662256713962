import { Reducer } from "redux";
import { Action as AppAction } from "../actions/App";
import { SearchAction, SearchActionTypes } from "../actions/Search";
import { UserAction, UserActionTypes } from "../actions/User";
import { SearchFormData } from "@blacknut/javascript-sdk/dist";
import { ApiError, List } from "@blacknut/javascript-sdk/dist";

export interface State {
  inProgress: boolean;
  error?: ApiError;
  results?: List;
  paginating: boolean;
  query?: SearchFormData;
}
const initialState: State = {
  inProgress: false,
  paginating: false,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: SearchAction | AppAction | UserAction,
) => {
  switch (action.type) {
    case SearchActionTypes.SEARCH_REQUEST: {
      return { ...state, inProgress: true, error: undefined, query: action.query };
    }
    case SearchActionTypes.SEARCH_SUCCESS: {
      return { ...state, inProgress: false, results: action.results };
    }
    case SearchActionTypes.SEARCH_ERROR: {
      return { ...state, inProgress: false, error: action.error };
    }
    case SearchActionTypes.SEARCH_PAGINATION_REQUEST: {
      return { ...state, paginating: true, error: undefined };
    }
    case SearchActionTypes.SEARCH_PAGINATION_SUCCESS: {
      return { ...state, paginating: false, results: action.results };
    }
    case SearchActionTypes.SEARCH_PAGINATION_ERROR: {
      return { ...state, paginating: false, error: action.error };
    }

    case SearchActionTypes.CLEAR: {
      return {
        inProgress: false,
        paginating: false,
        results: undefined,
        query: undefined,
      };
    }
    case UserActionTypes.SET_USER_TOKEN: {
      if (!action.fromRenew) {
        return {
          inProgress: false,
          paginating: false,
        } as State;
      }
      return state;
    }
    default:
      return state;
  }
};
