import { ImageType, Plan, Image, getPassPlanInfo } from "@blacknut/javascript-sdk/dist";
import { Button, CircularProgress } from "@blacknut/react-sdk";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useProfiler } from "src/hooks/useProfiler";
import useGamePassUnlock from "src/utils/useGamePassUnlock";
import { ReactComponent as IcPass } from "../../assets/dist/ic_pass.svg";
import FadeInImage from "../FadeInImage/FadeInImage";
import { HeroTileProps } from "./HeroTile";
import styles from "./pass.module.scss";
import React, { useEffect, useState } from "react";
import { firstValueFrom } from "rxjs";
import { logE } from "@blacknut/logging/dist";
import { LOGGING_TAG } from "@blacknut/speedtest/dist/Config";
import { AppLayout, State } from "core";
import { useSelector } from "react-redux";
import { useOrientation, OrientationType } from "src/utils/OrientationContext";
const scrollTop = () => {
  document.documentElement.scroll && document.documentElement.scroll({ top: 0 });
};

const getBackground = (
  device: string,
  orientation: OrientationType,
  images?: Image[],
) => {
  let background = undefined;

  if (images) {
    if (device === AppLayout.PHONE && orientation === "LANDSCAPE") {
      background = images.find((img) => img.kind === ImageType.HERO_LANDSCAPE);
    } else {
      background = images.find((img) => img.kind === ImageType.HERO);
    }
  }
  return background;
};
const HeroTilePass = ({ item, list }: HeroTileProps) => {
  const [loading, setLoading] = useState(false);
  const [plan, setPlan] = useState<Plan>();
  const { layout } = useSelector((state: State) => state.globalState);
  const { orientation } = useOrientation();
  useEffect(() => {
    const init = async () => {
      if (list.tiles[0].gamePass?.uuid) {
        setLoading(true);
        try {
          setPlan(await firstValueFrom(getPassPlanInfo(list.tiles[0].gamePass?.uuid)));
        } catch (e) {
          logE(LOGGING_TAG, "Error BN+ get pass plan", e);
        }
        setLoading(false);
      }
    };
    init();
  }, [list.tiles]);

  const Root = useProfiler("hero", {
    id: item.gamePass?.uuid || "",
  });
  const background = getBackground(layout, orientation, list.tiles[0].gamePass?.images);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const [ref, inView] = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();
  const { unlockPass } = useGamePassUnlock(item.gamePass?.uuid || "");
  const logo = list.imgLogo && list.imgLogo.length > 0 && list.imgLogo[0];

  const { profile } = useSelector((state: State) => state.profilesState);
  const isMaster = profile?.isMaster;
  const onMobile = layout === AppLayout.PHONE;
  return (
    <Root>
      <div
        className={clsx(
          styles.container,
          styles.gamepass,
          spatialNavigationActive && styles.focusableBtn,
        )}
      >
        {loading && <CircularProgress className={styles.loading} size={50} />}
        <>
          {background && (inView || process.env.NODE_ENV === "test") && (
            <FadeInImage className={styles.image} image={background} />
          )}

          <div className={clsx(styles.content)} ref={ref}>
            {logo && (
              <div className={styles.passLogoContainer}>
                <img
                  className={onMobile ? styles.passLogoMobile : styles.passLogo}
                  src={logo.url}
                />
              </div>
            )}
            <div className={clsx(styles.title, onMobile && styles.titleOnMobile)}>
              {list.tiles[0].gamePass?.title}
            </div>
            <div className={clsx(styles.description, onMobile && styles.descOnMobile)}>
              {list.tiles[0].gamePass?.description}
            </div>
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.seeMoreBtn}
                variant={"primary"}
                testID="unlockPass"
                onClick={unlockPass}
                onFocus={scrollTop}
              >
                <IcPass /> {plan?.formattedPrice} /
                {t(`bnPlus.passPrice.${plan?.billingPeriod}`)}
              </Button>
            </div>
            <div className={styles.secondaryAccountDisclaimer}>
              {!isMaster && t("profile.accessFromMain")}
            </div>
          </div>
        </>
      </div>
    </Root>
  );
};
export default HeroTilePass;
