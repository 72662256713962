import { GameAction, GameActionTypes, favoriteError } from "./Game";
import { Game } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { addToFavorite as apiAddToFavorite } from "@blacknut/javascript-sdk/dist/api/addToFavorite";
const addToFavoriteRequest = (id: string): GameAction => ({
  type: GameActionTypes.ADD_TO_FAVORITE_REQUEST,
  id,
});

export const addToFavoriteSuccess = (id: string): GameAction => ({
  type: GameActionTypes.ADD_TO_FAVORITE_SUCCESS,
  id,
});

export const addToFavorite = (game: Game) => {
  return (dispatch: Dispatch<GameAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(addToFavoriteRequest(game.id));
      apiAddToFavorite(game.id).subscribe({
        next: () => {
          dispatch(addToFavoriteSuccess(game.id));
          resolve();
        },
        error: (error) => {
          dispatch(favoriteError(game.id, error));
          reject(error);
        },
      });
    });
  };
};
