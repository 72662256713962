import { Dispatch } from "redux";
import { PageAction } from "./Page";
import {
  getPageGameGenre as apiGetPageGameGenre,
  Page,
} from "@blacknut/javascript-sdk/dist";
import { fetchPageError, fetchPageRequest, fetchPageSuccess } from "./fetchPage";

export const fetchPageGenre = (id: string, limit?: number, tilesLimit?: number) => {
  return (dispatch: Dispatch<PageAction>) => {
    return new Promise<Page>((resolve, reject) => {
      dispatch(fetchPageRequest(id));
      apiGetPageGameGenre(id, 0, limit, tilesLimit).subscribe({
        next: (page) => {
          dispatch(fetchPageSuccess(id, page));
          resolve(page);
        },
        error: (error) => {
          dispatch(fetchPageError(id, error));
          reject(error);
        },
      });
    });
  };
};
