import { List, SearchFormData } from "@blacknut/javascript-sdk/dist";
import { shallowEqual } from "react-redux";
import { Dispatch } from "redux";
import { map } from "rxjs";
import { State } from "../../reducers";
import { SearchAction, SearchActionTypes, searchResults2List } from "./Search";
import { searchGames as apiSearchGames } from "@blacknut/javascript-sdk/dist/api/searchGames";

export const searchSuccess = (results: List): SearchAction => {
  return { type: SearchActionTypes.SEARCH_SUCCESS, results };
};

export const search = (query: SearchFormData, limit?: number) => {
  return (dispatch: Dispatch<SearchAction>, getState: () => State) => {
    return new Promise<List>((resolve, reject) => {
      dispatch({ type: SearchActionTypes.SEARCH_REQUEST, query });
      apiSearchGames(query, 0, limit)
        .pipe(map((res) => searchResults2List(res)))
        .subscribe({
          next: (list) => {
            if (shallowEqual(query, getState().searchState.query)) {
              dispatch(searchSuccess(list));
              resolve(list);
            } else {
              reject("canceled");
            }
          },
          error: (error) => {
            dispatch({ type: SearchActionTypes.SEARCH_ERROR, error });
            reject(error);
          },
        });
    });
  };
};
