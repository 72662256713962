import {
  ApiError,
  apiService,
  BillingInfo,
  Config,
  FamilyStreams,
  isTV,
  Menu,
  PlayerLogLevel,
  RemoteNotification,
  Token,
  User,
  Game,
  GameGenre,
  GameStreamProtocol,
  Organization,
  metricsService,
  LinkedAccount,
  OnboardingFlowV3,
  OnboardingFlowV4,
  analyticsService,
} from "@blacknut/javascript-sdk/dist";
import Notification from "@blacknut/javascript-sdk/dist/model/Notification";
import { Reducer } from "redux";
import { v1 as uuidv4 } from "uuid";
import { envSet } from "../../EnvSet";
import { Plan } from "@blacknut/javascript-sdk/dist";
declare type OnboardingFlow = OnboardingFlowV3 | OnboardingFlowV4;
import mediationServerService from "../../services/MediationServerService";
import notificationsService from "../../services/NotificationsService";
import { IncubatingFeatures } from "../../utils/Constants";
import { Action as AppAction, ActionTypes as AppActionType } from "../actions/App";
import { DreiAction, DreiActionTypes as DreiActionType } from "../actions/Drei";
import { GameAction } from "../actions/Game";
import { M1Action, M1ActionTypes as M1ActionType } from "../actions/M1";
import { PageAction } from "../actions/Page";
import {
  ProfilesAction,
  ProfilesActionTypes as ProfilesActionType,
} from "../actions/Profiles";
import { UserAction, UserActionTypes as UserActionType } from "../actions/User";

const isTVLayout = isTV();

export enum AppLayout {
  TV = "tv",
  DESKTOP = "desktop",
  PHONE = "phone",
  TABLET = "tablet",
}
let layout: AppLayout = AppLayout.DESKTOP;
if (isTVLayout) {
  layout = AppLayout.TV;
}

export interface State {
  authentication: {
    inProgress: boolean;
    error?: ApiError;
  };

  signingUp: {
    inProgress: boolean;
    error?: ApiError;
  };

  layout: AppLayout;

  sessionId: string;

  resetPassword: {
    inProgress: boolean;
    error?: ApiError;
  };
  resetEmail: {
    inProgress: boolean;
    error?: ApiError;
  };
  updatePaymentInfo: {
    inProgress: boolean;
    error?: ApiError | unknown;
  };

  config?: Config;

  app: {
    initializing: boolean;
    initialized: boolean;
  };

  onboardingFlow: {
    loading: boolean;
    loadingError?: ApiError;
    flow?: OnboardingFlow;
  };
  contact: {
    inProgress: boolean;
    error?: ApiError;
  };

  menu: {
    inProgress: boolean;
    error?: ApiError;
    menu?: Menu;
  };
  user?: User;

  userUpdate: {
    inProgress: boolean;
    error?: ApiError;
  };

  emailUpdate: {
    inProgress: boolean;
    error?: ApiError;
  };

  passwordUpdate: {
    inProgress: boolean;
    error?: ApiError;
  };

  playedGame?: Game;

  notifications: Notification[];
  platform?: string;
  edito?: string;
  startUrl?: string;
  wsPlatform?: string;
  metricsUrl?: string;
  loginWebSocketUrl?: string;

  googleSignin?: string;
  googlePayAvailable?: boolean;
  stripeKey?: string;

  stats4Nerds: boolean;
  showFPS: boolean;
  protocole: GameStreamProtocol;

  familyToken?: Token;
  userToken?: Token;

  remoteNotifications: RemoteNotification[];

  apiVersion: string;

  billingInfo: BillingInfo;

  newsletter: {
    updating: boolean;
    error?: ApiError;
  };

  orientation: string;

  subscription: {
    loading: boolean;
    inProgress: boolean;
    error?: ApiError;
  };

  linkedAccounts: {
    inProgress: boolean;
    accounts: LinkedAccount[];
    error?: ApiError;
  };

  /* State for user subscribe/unsubscribe */
  unsubscribe: {
    inProgress: boolean;
    error?: ApiError;
  };

  plans: {
    loading: boolean;
    error?: ApiError;
    basicPlan?: Plan;

    // Request plan on subscrition
    planId?: string;
    plans?: Plan[];

    /* State for plan update */
    updating: boolean;
    updateError?: ApiError;
  };

  registrationCode: {
    inProgress: boolean;
    error?: ApiError;
    shortCode?: string;
    url?: string;
  };

  /* Almost Unique Device IDentifier */
  audid?: string;

  deviceId?: string;

  referrer?: string;

  preshippingId?: string;

  muted: boolean;

  frontUrl?: string;
  accountUrl?: string;

  amazonLoginURL?: string;
  googleLoginURL?: string;

  spatialNavigation: boolean;

  genres: {
    loading: boolean;
    genres?: GameGenre[];
    error?: ApiError;
  };

  doUpdateSubscribe: boolean;

  incubatingFeatures?: IncubatingFeatures;

  streams: {
    streams?: FamilyStreams;
    loading: boolean;
    error?: ApiError;
  };

  supportedProtocols: GameStreamProtocol[];
  deeplink?: string;
  locale?: string;
  updateAvailable: boolean;
  organization?: Organization;
}
const initialState: () => State = () => {
  const allProtocols: GameStreamProtocol[] = [];
  for (const key of Object.keys(GameStreamProtocol)) {
    allProtocols.push((GameStreamProtocol as Record<string, GameStreamProtocol>)[key]);
  }
  return {
    authentication: {
      inProgress: false,
    },

    signingUp: { inProgress: false },

    resetPassword: {
      inProgress: false,
    },
    resetEmail: {
      inProgress: false,
    },

    updatePaymentInfo: {
      inProgress: false,
    },
    addCoupon: {
      inProgress: false,
    },
    app: {
      initialized: false,
      initializing: false,
    },
    userUpdate: {
      inProgress: false,
    },
    emailUpdate: { inProgress: false },
    passwordUpdate: {
      inProgress: false,
    },
    contact: {
      inProgress: false,
    },
    layout,
    notifications: [],
    stats4Nerds: false,
    playerDebugLevel: PlayerLogLevel.INFO,
    protocole: GameStreamProtocol.AUTO,
    menu: { inProgress: false },
    remoteNotifications: [],
    onboardingFlow: {
      loading: false,
    },
    apiVersion: "5.0.0",
    newsletter: {
      updating: false,
    },
    orientation: "PORTRAIT",
    menuIsOpen: false,
    billingInfo: {},
    subscription: {
      loading: false,
      inProgress: false,
    },
    plans: {
      loading: false,
      updating: false,
    },
    registrationCode: {
      inProgress: false,
    },
    unsubscribe: {
      inProgress: false,
    },
    muted: true,

    spatialNavigation: isTV(),

    genres: {
      loading: false,
    },
    doUpdateSubscribe: true,

    streams: {
      loading: false,
    },
    supportedProtocols: allProtocols,

    showFPS: false,
    updateAvailable: false,
    sessionId: uuidv4(),

    linkedAccounts: {
      inProgress: false,
      accounts: [],
    },
  };
};

export const reducer: Reducer<State> = (
  state = initialState(),
  action:
    | UserAction
    | AppAction
    | GameAction
    | PageAction
    | ProfilesAction
    | DreiAction
    | M1Action,
) => {
  switch (action.type) {
    case UserActionType.AUTO_LOGIN_REQUEST:
    case DreiActionType.DREI_LOGIN_REQUEST:
    case M1ActionType.M1_GENERATE_TOKEN_REQUEST:
    case ProfilesActionType.SELECT_PROFILE_REQUEST: {
      return {
        ...state,
        authentication: { inProgress: true },
        menu: { inProgress: false },
      };
    }
    case AppActionType.SET_CONFIG: {
      return {
        ...state,
        config: action.config,
        organization: action.config?.organization,
      };
    }
    case UserActionType.USER_LOGIN_SUCCESS:
    case DreiActionType.DREI_LOGIN_SUCCESS:
    case M1ActionType.M1_GENERATE_TOKEN_SUCCESS:
    case UserActionType.AUTO_LOGIN_SUCCESS: {
      return {
        ...state,
        authentication: { inProgress: false },
        user: action.user,
      };
    }
    case ProfilesActionType.SELECT_PROFILE_SUCCESS: {
      return {
        ...state,
        authentication: action.fromProfileSelection
          ? { inProgress: false }
          : state.authentication,
        user: action.user,
        genres: { loading: false },
        remoteNotifications: [],
        notifications: [],
      };
    }
    case UserActionType.USER_LOGIN_ERROR:
    case UserActionType.AUTO_LOGIN_ERROR:
    case DreiActionType.DREI_LOGIN_ERROR:
    case M1ActionType.M1_GENERATE_TOKEN_ERROR:
    case ProfilesActionType.SELECT_PROFILE_ERROR: {
      return { ...state, authentication: { inProgress: false, error: action.error } };
    }
    case UserActionType.USER_CREATE_REQUEST: {
      return { ...state, signingUp: { inProgress: true } };
    }
    case UserActionType.USER_CREATE_SUCCESS: {
      return {
        ...state,
        signingUp: { inProgress: false },
        user: action.user,
      };
    }
    case UserActionType.USER_CREATE_ERROR: {
      return { ...state, signingUp: { inProgress: false, error: action.error } };
    }

    case UserActionType.USER_AMAZON_LOGIN_REQUEST: {
      return {
        ...state,
        authentication: {
          inProgress: true,
        },
      };
    }
    case UserActionType.USER_AMAZON_LOGIN_SUCCESS: {
      return {
        ...state,
        authentication: {
          inProgress: false,
        },
      };
    }
    case UserActionType.USER_AMAZON_LOGIN_ERROR: {
      return {
        ...state,
        authentication: {
          inProgress: false,
          error: action.error,
        },
      };
    }
    case UserActionType.USER_AMAZON_CREATE_REQUEST: {
      return {
        ...state,
        signingUp: {
          inProgress: true,
        },
      };
    }
    case UserActionType.USER_AMAZON_CREATE_SUCCESS: {
      return {
        ...state,
        signingUp: {
          inProgress: false,
        },
      };
    }
    case UserActionType.USER_AMAZON_CREATE_ERROR: {
      return {
        ...state,
        signingUp: {
          inProgress: false,
          error: action.error,
        },
      };
    }
    case UserActionType.USER_AMAZON_SUBSCRIBE_REQUEST:
    case UserActionType.USER_AMAZON_UPDATE_REQUEST: {
      return { ...state, updatePaymentInfo: { inProgress: true, error: undefined } };
    }
    case UserActionType.USER_AMAZON_SUBSCRIBE_SUCCESS:
    case UserActionType.USER_AMAZON_UPDATE_SUCCESS: {
      return {
        ...state,
        updatePaymentInfo: { inProgress: false, error: undefined },
        billingInfo: action.billingInfo,
      };
    }
    case UserActionType.USER_AMAZON_SUBSCRIBE_ERROR:
    case UserActionType.USER_AMAZON_UPDATE_ERROR: {
      return { ...state, updatePaymentInfo: { inProgress: false, error: action.error } };
    }
    case UserActionType.USER_UPDATE: {
      return { ...state, userUpdate: { inProgress: false }, user: action.user };
    }

    case UserActionType.SUBSCRIPTION_FETCH_SUCCESS: {
      return {
        ...state,
        billingInfo: { ...action.billingInfo },
      };
    }

    case AppActionType.INITIALIZE_REQUEST: {
      return { ...state, app: { initializing: true, initialized: false } };
    }
    case AppActionType.INITIALIZE_SUCCESS: {
      return {
        ...state,
        app: { initializing: false, initialized: true },
        config: action.config,
        user: action.user,
      };
    }
    case UserActionType.SET_REMOTE_NOTIFICATIONS: {
      return {
        ...state,
        remoteNotifications: action.notifications,
      };
    }
    case UserActionType.ADD_REMOTE_NOTIFICATIONS: {
      // Merge new notifications with old ones
      const newOnes = [];
      for (const n of action.notifications) {
        if (
          state.remoteNotifications!.findIndex(
            (x) => x.id === n.id || (x.description === n.description && n.description),
          ) === -1
        ) {
          newOnes.push(n);
        }
      }
      const merged: RemoteNotification[] = newOnes.concat(state.remoteNotifications!);
      return {
        ...state,
        remoteNotifications: merged,
      };
    }
    case UserActionType.DELETE_REMOTE_NOTIFICATION: {
      return {
        ...state,
        remoteNotifications: state.remoteNotifications
          .slice(0)
          .filter((n) => n.id !== action.notification.id),
      };
    }
    case AppActionType.INITIALIZE_ERROR: {
      return {
        ...state,
        app: { initializing: false, initialized: true, error: action.error },
      };
    }
    case UserActionType.SET_USER_TOKEN: {
      apiService.userToken = action.token;
      if (action.fromRenew) {
        return {
          ...state,
          userToken: action.token,
        };
      }

      return {
        ...state,
        userToken: action.token,
      };
    }
    case UserActionType.SET_FAMILY_TOKEN: {
      apiService.familyToken = action.token;
      if (action.fromRenew) {
        return { ...state, familyToken: action.token };
      }

      return {
        ...state,
        user: undefined,
        subscription: { inProgress: false, loading: false },
        userToken: undefined,
        billingInfo: {},
        menu: { inProgress: false },
        remoteNotifications: [],
        notifications: [],
        plans: {
          loading: false,
          updating: false,
        },

        familyToken: action.token,
        genres: {
          loading: false,
        },
        sessionId: uuidv4(),
        linkedAccounts: {
          inProgress: false,
          accounts: [],
        },
      };
    }
    case AppActionType.SEND_CONTACT_MESSAGE_REQUEST: {
      return {
        ...state,
        contact: { inProgress: true, error: undefined },
      };
    }
    case AppActionType.SEND_CONTACT_MESSAGE_SUCCESS: {
      return {
        ...state,
        contact: { inProgress: false },
      };
    }
    case AppActionType.SEND_CONTACT_MESSAGE_ERROR: {
      return {
        ...state,
        contact: { inProgress: false, error: action.error },
      };
    }

    case AppActionType.SET_SUPPORTED_PROTOCOLS: {
      return {
        ...state,
        supportedProtocols: action.protocols,
      };
    }
    case AppActionType.ADD_NOTIFICATION: {
      const notifications = state.notifications.slice(0);
      notifications.push(action.notification);
      return {
        ...state,
        notifications,
      };
    }
    case AppActionType.ACKNOWLEDGE_NOTIFICATION: {
      return {
        ...state,
        notifications: state.notifications
          .slice(0)
          .filter((n) => n !== action.notification),
      };
    }
    case AppActionType.CLEAR_NOTIFICATION: {
      return {
        ...state,
        notifications: [],
      };
    }

    case AppActionType.SET_PLATFORM: {
      apiService.endpoint = action.platform;
      let env: typeof envSet.dev | undefined;
      if (action.platform.indexOf("blacknut.com") !== -1) {
        env = envSet.prod;
      } else if (action.platform.indexOf("dev") !== -1) {
        // dev
        env = envSet.dev;
      } else if (action.platform.indexOf("integration") !== -1) {
        // integration
        env = envSet.integration;
      } else if (action.platform.indexOf("staging") !== -1) {
        // staging or demo
        env = envSet.staging;
      } else {
      }

      if (env?.url.metrics) {
        metricsService.endpoint = env.url.metrics;
      }
      if (env?.url.mediationServer) {
        mediationServerService.url = env.url.mediationServer;
      }

      if (env?.url.notifications) {
        notificationsService.url = env.url.notifications;
      }
      if (env?.url.analytics) {
        analyticsService.endpoint = env.url.analytics;
      }
      return {
        ...state,
        platform: action.platform,
        wsPlatform: env?.url.mediationServer,
        metricsUrl: env?.url.metrics,
        googleSignin: env?.key.google_signin,
        googlePayAvailable: env?.availabe.google_pay,
        paypalKey: env?.key.paypal,
        stripeKey: env?.key.stripe,
        onboardingFlow: { loading: false },
        frontUrl: env?.url.front,
        amazonLoginURL: env?.url.amazonLogin,
        googleLoginURL: env?.url.googleLogin,
        loginWebSocketUrl: env?.url.loginWebSocket,
        accountUrl: env?.url.account,
        edito: env?.url.edito,
      };
    }
    case AppActionType.SET_STATS_4_NERDS: {
      return {
        ...state,
        stats4Nerds: action.active,
      };
    }
    case AppActionType.SET_SHOW_FPS: {
      return {
        ...state,
        showFPS: action.showFPS,
      };
    }

    case AppActionType.FETCH_MENU_REQUEST: {
      return {
        ...state,
        menu: { inProgress: true, error: undefined, menu: undefined },
      };
    }
    case AppActionType.FETCH_MENU_SUCCESS: {
      return {
        ...state,
        menu: { inProgress: false, menu: action.menu },
      };
    }
    case AppActionType.FETCH_MENU_ERROR: {
      return {
        ...state,
        menu: { inProgress: false, error: action.error },
      };
    }

    case AppActionType.GET_ONBOARDING_FLOW_REQUEST: {
      return {
        ...state,
        onboardingFlow: { loading: true },
      };
    }
    case AppActionType.GET_ONBOARDING_FLOW_SUCCESS: {
      return {
        ...state,
        onboardingFlow: {
          flow: action.flow,
          loading: false,
          loadingError: undefined,
        },
      };
    }
    case AppActionType.GET_ONBOARDING_FLOW_ERROR: {
      return {
        ...state,
        onboardingFlow: {
          ...state.onboardingFlow,
          loading: false,
          loadingError: action.error,
        },
      };
    }

    case UserActionType.TOGGLE_NEWSLETTER_REQUEST: {
      return {
        ...state,
        user: { ...state.user!, newsletter: !state.user!.newsletter },
        newsletter: {
          updating: true,
        },
      };
    }
    case UserActionType.TOGGLE_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        newsletter: {
          updating: false,
          error: undefined,
        },
      };
    }
    case UserActionType.TOGGLE_NEWSLETTER_ERROR: {
      return {
        ...state,
        user: { ...state.user!, newsletter: !state.user!.newsletter },
        newsletter: {
          updating: false,
          error: action.error,
        },
      };
    }
    case UserActionType.FETCH_SUBSCRIPTION_REQUEST: {
      return {
        ...state,
        subscription: {
          loading: true,
          inProgress: false,
        },
      };
    }
    case UserActionType.FETCH_SUBSCRIPTION_SUCCESS: {
      return {
        ...state,
        subscription: {
          loading: false,
          inProgress: false,
          error: undefined,
        },
        billingInfo: { ...action.billingInfo },
      };
    }
    case UserActionType.FETCH_SUBSCRIPTION_ERROR: {
      return {
        ...state,
        subscription: {
          loading: false,
          inProgress: false,
          error: action.error,
        },
      };
    }

    case AppActionType.SET_APP_LAYOUT: {
      return {
        ...state,
        layout: action.appLayout,
        spatialNavigation: action.appLayout === AppLayout.TV || state.spatialNavigation,
      };
    }

    case AppActionType.GET_REGISTRATION_CODE_REQUEST: {
      return {
        ...state,
        registrationCode: {
          inProgress: true,
          error: undefined,
        },
      };
    }
    case AppActionType.GET_REGISTRATION_CODE_SUCCESS: {
      return {
        ...state,
        registrationCode: {
          inProgress: false,
          shortCode: action.code,
          url: action.url,
        },
      };
    }
    case AppActionType.GET_REGISTRATION_CODE_ERROR: {
      if (action.error) {
        return {
          ...state,
          registrationCode: {
            inProgress: false,
            error: action.error,
          },
        };
      }
      return {
        ...state,
        registrationCode: {
          ...state.registrationCode,
          inProgress: false,
          error: undefined,
        },
      };
    }
    case AppActionType.SET_AUDID: {
      return {
        ...state,
        audid: action.audid,
      };
    }
    case AppActionType.SET_DEVICE_ID: {
      return {
        ...state,
        deviceId: action.deviceId,
      };
    }
    case AppActionType.SET_PRESHIPPING_ID: {
      return {
        ...state,
        preshippingId: action.preshippingId,
      };
    }
    case AppActionType.TOGGLE_MUTE: {
      return {
        ...state,
        muted: !state.muted,
      };
    }
    case AppActionType.SET_MUTED: {
      return {
        ...state,
        muted: action.muted,
      };
    }
    case AppActionType.TOGGLE_SPATIAL_NAVIGATION: {
      return {
        ...state,
        spatialNavigation: !state.spatialNavigation,
      };
    }

    case AppActionType.SET_INCUBATING_FEATURES: {
      return {
        ...state,
        incubatingFeatures: action.incubating,
      };
    }
    case AppActionType.GET_GENRES_REQUEST: {
      return {
        ...state,
        genres: {
          loading: true,
          error: undefined,
        },
      };
    }
    case AppActionType.GET_GENRES_SUCCESS: {
      return {
        ...state,
        genres: {
          loading: false,
          genres: action.genres,
        },
      };
    }
    case AppActionType.GET_GENRES_ERROR: {
      return {
        ...state,
        genres: {
          loading: false,
          error: action.error,
        },
      };
    }
    case AppActionType.GET_PAGE_GENRES_REQUEST: {
      return {
        ...state,
        page: {
          loading: true,
          error: undefined,
        },
      };
    }
    case AppActionType.GET_PAGE_GENRES_SUCCESS: {
      return {
        ...state,
        page: {
          loading: false,
          page: action.page,
        },
      };
    }
    case AppActionType.GET_PAGE_GENRES_ERROR: {
      return {
        ...state,
        page: {
          loading: false,
          error: action.error,
        },
      };
    }
    case AppActionType.GET_CURRENT_STREAM_REQUEST: {
      return {
        ...state,
        streams: {
          loading: true,
        },
      };
    }
    case UserActionType.SET_USER_CONFIG: {
      return {
        ...state,
        config: {
          ...state.config!,
          userConf: action.userConf,
        },
      };
    }

    case AppActionType.GET_CURRENT_STREAM_SUCCESS: {
      // Filters the ones with non supported protocol
      // let myProtocol =
      //   state.config && state.config.userConf && state.config.userConf.protocol
      //     ? state.config.userConf.protocol
      //     : state.protocole;
      // if (state.supportedProtocols.indexOf(myProtocol) === -1) {
      //   myProtocol = GameStreamProtocole.AUTO;
      // }

      // if (action.streams.user) {
      //   action.streams.user = action.streams.user.filter(
      //     str => str.stream!.protocol === myProtocol,
      //   );
      // }

      return {
        ...state,
        streams: {
          streams: action.streams,
          loading: false,
        },
      };
    }
    case AppActionType.GET_CURRENT_STREAM_ERROR: {
      return {
        ...state,
        streams: {
          loading: false,
          error: action.error,
        },
      };
    }
    case AppActionType.SET_DEEPLINK: {
      return {
        ...state,
        deeplink: action.deeplink,
      };
    }
    case AppActionType.SET_LOCALE: {
      return {
        ...state,
        locale: action.locale,
      };
    }
    case AppActionType.SET_START_URL: {
      return {
        ...state,
        starturl: action.startUrl,
      };
    }
    case AppActionType.SET_UPDATE_AVAILABLE: {
      return {
        ...state,
        updateAvailable: action.available,
      };
    }
    case AppActionType.SET_ORGANIZATION: {
      return {
        ...state,
        organization: action.organization,
      };
    }
    case UserActionType.GET_LINKED_ACCOUNTS_REQUEST: {
      return {
        ...state,
        linkedAccounts: {
          inProgress: true,
          accounts: [],
        },
      };
    }
    case UserActionType.GET_LINKED_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        linkedAccounts: {
          inProgress: false,
          accounts: action.linkedAccounts,
        },
      };
    }
    case UserActionType.GET_LINKED_ACCOUNTS_ERROR: {
      return {
        ...state,
        linkedAccounts: {
          inProgress: false,
          error: action.error,
          accounts: [],
        },
      };
    }
    default:
      return state;
  }
};
