import { UserProfile } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { ProfilesAction, ProfilesActionTypes } from "./Profiles";
import { deleteFamilyProfile } from "@blacknut/javascript-sdk/dist/api/Family/FamilyProfile/deleteFamilyProfile";

export const dismissDeleteProfileError = () => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({ type: ProfilesActionTypes.DELETE_PROFILE_ERROR });
  };
};
export const deleteProfile = (id: string) => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    return new Promise<UserProfile>((resolve, reject) => {
      dispatch({ type: ProfilesActionTypes.DELETE_PROFILE_REQUEST });
      deleteFamilyProfile(id).subscribe({
        next: (res) => {
          dispatch({ type: ProfilesActionTypes.DELETE_PROFILE_SUCCESS, id });
          resolve(res);
        },
        error: (error) => {
          dispatch({ type: ProfilesActionTypes.DELETE_PROFILE_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
