import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";

export const toggleMute = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.TOGGLE_MUTE });
  };
};

export const setMuted = (muted: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.SET_MUTED, muted });
  };
};
