import { Dispatch } from "redux";
import { LeMagActionTypes, LeMagAction } from "./LeMag";
import {
  ArticleDetailsResponse,
  getArticleDetails as apiGetArticlesDetails,
} from "@blacknut/javascript-sdk/dist";

export const getArticleDetails = (slug = "") => {
  return (dispatch: Dispatch<LeMagAction>) => {
    dispatch({ type: LeMagActionTypes.GET_ARTICLEDETAILS_REQUEST });
    return new Promise<ArticleDetailsResponse>((resolve, reject) => {
      return apiGetArticlesDetails(slug).subscribe({
        next: (response) => {
          dispatch({ type: LeMagActionTypes.GET_ARTICLEDETAILS_SUCCESS, response });
          resolve(response);
        },
        error: (error) => {
          dispatch({ type: LeMagActionTypes.GET_ARTICLEDETAILS_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
