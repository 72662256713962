import {
  UpdateFamilyProfileData,
  NotificationType,
} from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../redux/actions/App";
import {
  deleteProfile,
  dismissDeleteProfileError,
  dismissSaveProfileError,
  saveProfile,
} from "../../redux/actions/Profiles";
import { State } from "../../redux/reducers";
const TAG = "EditProfile";

export const useEditProfile = (props: {
  id: string;
  onSaveSuccess?: () => void;
  onDeleteSuccess?: () => void;
}) => {
  const dispatch = useDispatch();
  const { id, onSaveSuccess, onDeleteSuccess } = props;
  const {
    saveProfile: saveState,
    deleteProfile: deleteState,
    familyProfiles,
  } = useSelector((state: State) => state.profilesState);
  const profiles = familyProfiles.profiles || [];
  const profile = profiles.find((p) => p.id === id);

  return {
    profiles,
    profile,
    saveInProgress: saveState.inProgress,
    saveError: saveState.error,
    saveProfile: React.useCallback(
      async (data: UpdateFamilyProfileData) => {
        try {
          await saveProfile(data)(dispatch);
          addNotification({
            type: NotificationType.SUCCESS,
            message: "notifications.profileUpdate.success",
            time: new Date().getTime(),
          })(dispatch);
          if (onSaveSuccess) {
            onSaveSuccess();
          }
        } catch (e) {
          logE(TAG, "Caught error saving profile", e);
          throw e;
        }
      },
      [dispatch, onSaveSuccess],
    ),

    dismissSaveError: React.useCallback(() => {
      dismissSaveProfileError()(dispatch);
    }, [dispatch]),

    deleteInProgress: deleteState.inProgress,
    deleteError: deleteState.error,
    deleteProfile: React.useCallback(async () => {
      try {
        await deleteProfile(id)(dispatch);
        addNotification({
          type: NotificationType.SUCCESS,
          message: "notifications.profileDelete.success",
          time: new Date().getTime(),
        })(dispatch);
        if (onDeleteSuccess) {
          onDeleteSuccess();
        }
      } catch (e) {
        logE(TAG, "Caught error deleting profile", e);
        throw e;
      }
    }, [dispatch, id, onDeleteSuccess]),
    dismissDeleteError: React.useCallback(
      () => dismissDeleteProfileError()(dispatch),
      [dispatch],
    ),
  };
};
