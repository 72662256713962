import { Button, MessageModal, Modal, ModalProps } from "@blacknut/react-sdk";
import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import {
  AppLayout,
  NotificationMessage,
  NotificationMessageKind,
  State,
  notificationService,
  useLayout,
} from "core";
import { useSelector } from "react-redux";
import { QRCodeSVG } from "qrcode.react";
import { useWarioURL } from "src/utils/useWarioURL";
import { RemoteNotificationTrigger } from "@blacknut/javascript-sdk/dist";
import { useTheme } from "src/theme/ThemeProvider";

export interface NotSubscribedProps extends ModalProps {
  isProfileMaster: boolean;
  onClose: () => void;
  onSubscribe: () => void;
  onPlanSubscribed: () => void;
}

const TVModal = (props: NotSubscribedProps) => {
  const { t } = useTranslation();
  const { isProfileMaster, onClose } = props;
  const { accountUrl, organization } = useSelector((state: State) => state.globalState);
  const { create } = useWarioURL();
  const { theme } = useTheme();
  const [URL, setURL] = useState("");

  useEffect(() => {
    const getWarioURL = async () => {
      const warioUrl = await create(
        organization?.subscriptionUrl || `${accountUrl}/checkout?device=tv`,
        undefined,
        { tokens: isProfileMaster },
      );
      setURL(warioUrl.toString());
    };
    getWarioURL();
  }, [accountUrl, create, isProfileMaster, organization?.subscriptionUrl]);

  return (
    <Modal
      className={styles.TVmodal}
      {...props}
      title={t("dialogs.subscriptionNow")}
      onClose={onClose}
      buttons={[]}
    >
      <div className={styles.message}>
        <div>{t("dialogs.error.notSubscribed.message")}</div>
        <Button
          variant="primary"
          key="cancel"
          className={styles.notnow}
          onClick={onClose}
        >
          {t("dialogs.error.notSubscribed.no")}
        </Button>
        {isProfileMaster && (
          <div className={styles.right}>
            <QRCodeSVG value={URL} size={256} includeMargin={theme.name === "dark"} />
            <p className={styles.calltoaction}>
              {t("dialogs.error.notSubscribed.subscribeQRCode")}
            </p>
          </div>
        )}
      </div>
    </Modal>
  );
};

const NotSubscribedModal = (props: NotSubscribedProps) => {
  const { t } = useTranslation();
  const { isProfileMaster, onSubscribe, onClose, onPlanSubscribed } = props;
  const isTV = useLayout() === AppLayout.TV;

  useEffect(() => {
    const sub = notificationService.onMessage().subscribe((n: NotificationMessage) => {
      if (n.kind !== NotificationMessageKind.NOTIFICATION_RESP) return;
      const { notification } = n.payload;

      if (notification.trigger === RemoteNotificationTrigger.PLAN_SUBSCRIBED) {
        onPlanSubscribed();
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [onPlanSubscribed]);

  if (isTV) {
    return <TVModal {...props} />;
  }

  return (
    <MessageModal
      {...props}
      title={t("dialogs.error.notSubscribed.title")}
      message={t("dialogs.error.notSubscribed.message")}
      onClose={onClose}
      buttons={
        isProfileMaster && [
          <Button variant="secondary" key="cancel" onClick={onClose}>
            {t("dialogs.error.notSubscribed.no")}
          </Button>,
          <Button variant="primary" key="subscribe" onClick={onSubscribe}>
            {t("buttons.subscribe")}
          </Button>,
        ]
      }
    />
  );
};

export default NotSubscribedModal;
