import { Dispatch } from "redux";
import { ProfilesAction, ProfilesActionTypes } from "./Profiles";
import { Subscription, UserProfile } from "@blacknut/javascript-sdk/dist";
import { getFamilyInfo as apiGetFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";

export const loadFamilyInfoSuccess = (
  profiles?: UserProfile[],
  subscription?: Subscription,
): ProfilesAction => {
  return { type: ProfilesActionTypes.LOAD_FAMILY_INFO_SUCCESS, profiles, subscription };
};

export const dismissFamilyInfoLoadError = () => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({ type: ProfilesActionTypes.LOAD_FAMILY_INFO_ERROR, error: undefined });
  };
};
export const loadFamilyInfo = () => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    return new Promise<{ profiles: UserProfile[]; subscription?: Subscription }>(
      (resolve, reject) => {
        dispatch({ type: ProfilesActionTypes.LOAD_FAMILY_INFO_REQUEST });
        apiGetFamilyInfo().subscribe({
          next: (res) => {
            dispatch(loadFamilyInfoSuccess(res.profiles, res.subscription));
            resolve(res);
          },
          error: (error) => {
            dispatch({ type: ProfilesActionTypes.LOAD_FAMILY_INFO_ERROR, error });
            reject(error);
          },
        });
      },
    );
  };
};
