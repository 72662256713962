import { CreateProfileFormData, NotificationType } from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addNotification } from "../../redux/actions/App";
import {
  createProfile,
  dismissCreateProfileError,
  dismissFamilyInfoLoadError,
  loadFamilyInfo,
} from "../../redux/actions/Profiles";
import { State } from "../../redux/reducers";

const TAG = "CreateProfile";

export const useCreateProfile = () => {
  const dispatch = useDispatch();
  const { createProfile: createProfileState, familyProfiles } = useSelector(
    (state: State) => state.profilesState,
  );

  // Load profiles on startup
  React.useEffect(() => {
    const init = async () => {
      if (!familyProfiles.profiles && !familyProfiles.inProgress) {
        try {
          await loadFamilyInfo()(dispatch);
        } catch (e) {
          logE(TAG, "Caught error loading profiles");
        }
      }
    };
    init();
  }, [dispatch, familyProfiles.inProgress, familyProfiles.profiles]);

  return {
    inProgress: createProfileState.inProgress,
    createError: createProfileState.error,
    profiles: familyProfiles.profiles,
    profilesLoadInProgress: familyProfiles.inProgress,
    profilesLoadError: familyProfiles.error,
    dismissLoadError: React.useCallback(() => {
      dismissFamilyInfoLoadError()(dispatch);
    }, [dispatch]),
    onSubmit: React.useCallback(
      async (data: CreateProfileFormData) => {
        try {
          await createProfile(data)(dispatch);
          addNotification({
            type: NotificationType.SUCCESS,
            message: "notifications.profileCreate.success",
            time: new Date().getTime(),
          })(dispatch);
        } catch (e) {
          logE(TAG, "Error creating profile", e);
          // let's ui handle the error
          throw e;
        }
      },
      [dispatch],
    ),
    dismissCreateError: React.useCallback(() => {
      dismissCreateProfileError()(dispatch);
    }, [dispatch]),
  };
};
