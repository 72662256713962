import { ListRendererFn } from "./ListRenderer";
import styles from "../styles.module.scss";
import React from "react";
import clsx from "clsx";
import BrandedView from "../componentView/BrandedView";

/*********
 * Branded/Saga
 * Desktop: 1 row of 5 tiles
 * Phone:
 *  Portrait :
 *      Scrollable : 1 row of n tiles and 2 partially visible tiles
 *      Not scrollable : N/A
 *  Landscape :
 *      Scrollable: 1 row of n tiles and 4 partially visible tiles
 *      Not scrollable: N/A
 * Tablet: Similar to desktop
 *
 */
const BrandedRenderer: ListRendererFn = ({
  list,
  leaveFor,
  scrollable,
  onFocus,
  page,
}) => {
  return (
    <BrandedView
      list={list}
      key={list.uuid}
      leaveFor={leaveFor}
      onFocus={onFocus}
      scrollable={scrollable}
      className={clsx(
        styles.branded,
        scrollable && styles.scrollable,
        list.moreLink && styles.moreLink,
      )}
      page={page}
    />
  );
};
export { BrandedRenderer };
