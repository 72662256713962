import AvatarImage from "../AvatarSelectionModal/AvatarImage";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import React from "react";
const ProfileIcon = ({
  profile,
  className,
}: {
  profile: { avatarID?: string; avatarId?: string };
  className?: string;
}) => {
  const avatarId = profile.avatarID || profile.avatarId;
  return (
    <>
      {avatarId && <AvatarImage avatarId={avatarId} className={className} />}
      {!avatarId && <AvatarDefault className={className} />}
    </>
  );
};

export default ProfileIcon;
