import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToFavorite,
  dismissFavoriteError,
  dismissBonusGameError,
  dismissGameError,
  fetchGame,
  removeFromFavorite,
  addToBonusGame,
} from "../../redux/actions/Game";
import { acknowledgeGamepadOnly } from "../../redux/actions/Profiles";
import { State } from "../../redux/reducers";
import { NotificationType } from "@blacknut/javascript-sdk/dist";
import { addNotification } from "../../redux/actions/App/notifications";

const TAG = "Game";

export const useGame = (props: { gameId: string }) => {
  const { gameId } = props;
  const { profile: currentProfile, profilePrefs } = useSelector(
    (state: State) => state.profilesState,
  );
  const { config } = useSelector((state: State) => state.globalState);
  const gameState = useSelector((state: State) => state.gamesState.games[gameId]) || {
    favorite: false,
    favoritePending: false,
    loading: false,
  };
  const {
    game,
    favoritePending,
    favoriteError,
    bonusGameError,
    favorite,
    loading,
    loadingError,
  } = gameState;

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (!game) {
      const init = async () => {
        try {
          await fetchGame(gameId)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting game", e);
        }
      };
      init();
    }
  }, [dispatch, game, gameId]);

  const myPrefs = currentProfile && profilePrefs && profilePrefs[currentProfile.id];

  const acknowledgedGamepadOnly: boolean =
    !!myPrefs &&
    !!myPrefs.acknowledgedGamepadGames &&
    myPrefs.acknowledgedGamepadGames.has(gameId);

  return {
    game,
    favorite,
    favoritePending,
    loading,
    loadingError,
    favoriteError,
    bonusGameError,
    acknowledgedGamepadOnly,
    config,
    dismissLoadingError: React.useCallback(() => {
      dismissGameError(gameId)(dispatch);
    }, [dispatch, gameId]),
    toggleFavorite: React.useCallback(async () => {
      if (favoritePending || !game) {
        return;
      }
      if (favorite) {
        try {
          await removeFromFavorite(game)(dispatch);
          addNotification({
            time: new Date().getTime(),
            type: NotificationType.SUCCESS,
            message: "notifications.favorite.success",
          })(dispatch);
        } catch (e) {
          logE(TAG, "Caught error toggling favorite", e);
        }
      } else {
        try {
          await addToFavorite(game)(dispatch);
          addNotification({
            time: new Date().getTime(),
            type: NotificationType.SUCCESS,
            message: "notifications.favorite.success",
          })(dispatch);
        } catch (e) {
          logE(TAG, "Caught error toggling favorite", e);
        }
      }
    }, [favoritePending, game, favorite, dispatch]),
    addToBonusGame: React.useCallback(async () => {
      if (gameId) {
        try {
          await addToBonusGame(gameId)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error adding to bonus games", e);
        }
      }
    }, [gameId, dispatch]),
    acknowledgeGamepadOnly: React.useCallback(() => {
      if (currentProfile && game) {
        acknowledgeGamepadOnly(game, currentProfile)(dispatch);
      }
    }, [currentProfile, game, dispatch]),
    dismissFavoriteError: React.useCallback(() => {
      dismissFavoriteError(gameId)(dispatch);
    }, [dispatch, gameId]),
    dismissBonusGameError: React.useCallback(() => {
      dismissBonusGameError(gameId)(dispatch);
    }, [dispatch, gameId]),
  };
};
