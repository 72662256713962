import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissRemoveGamesFromFavoritesError,
  dismissRemoveGamesFromLastPlayedError,
  removeGamesFromFavorites,
  removeGamesFromLastPlayed,
} from "../../redux/actions/Game";
import {
  dismissListError,
  FAVORITES_LIST_ID,
  fetchFavorites,
  fetchLastPlayed,
  LAST_PLAYED_LIST_ID,
  paginateFavorites,
  paginateLastPlayed,
  removeList,
} from "../../redux/actions/List";
import { State } from "../../redux/reducers";
import { List, ListSortMethod } from "@blacknut/javascript-sdk/dist";
const TAG = "MyList";
export const useMyList = (props: { tilesLimit?: number }) => {
  const { tilesLimit } = props;
  const dispatch = useDispatch();

  const state = useSelector((state: State) => state);
  const { listsState, gamesState } = state;
  const favoritesList =
    FAVORITES_LIST_ID in listsState.lists
      ? listsState.lists[FAVORITES_LIST_ID]
      : undefined;

  const lastPlayedList =
    LAST_PLAYED_LIST_ID in listsState.lists
      ? listsState.lists[LAST_PLAYED_LIST_ID]
      : undefined;
  return {
    favoritesList: favoritesList ? favoritesList.list : undefined,
    favoritesListError: favoritesList ? favoritesList.error : undefined,
    favoritesListLoading: favoritesList ? favoritesList.loading : false,
    favoritesListPaginating: favoritesList ? favoritesList.paginating : false,
    lastPlayedList: lastPlayedList ? lastPlayedList.list : undefined,
    lastPlayedListError: lastPlayedList ? lastPlayedList.error : undefined,
    lastPlayedListLoading: lastPlayedList ? lastPlayedList.loading : false,
    lastPlayedListPaginating: lastPlayedList ? lastPlayedList.paginating : false,
    favoritesUpdateInProgress: gamesState.bulkFavoritesUpdateInProgress,
    favoritesUpdateError: gamesState.bulkFavoritesUpdateError,
    lastPlayedUpdateInProgress: gamesState.bulkLastPlayedUpdateInProgress,
    lastPlayedUpdateError: gamesState.bulkLastPlayedUpdateError,
    lastPlayedSort: lastPlayedList?.sort,
    favoritesListSort: favoritesList?.sort,
    getLastPlayedList: React.useCallback(
      async (sortBy: ListSortMethod) => {
        dispatch(removeList(LAST_PLAYED_LIST_ID));
        try {
          await fetchLastPlayed(sortBy, tilesLimit)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting last played", e);
        }
      },
      [dispatch, tilesLimit],
    ),

    getFavoritesList: React.useCallback(
      async (sortBy: ListSortMethod) => {
        dispatch(removeList(FAVORITES_LIST_ID));
        try {
          await fetchFavorites(sortBy, tilesLimit)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting favorites", e);
        }
      },
      [dispatch, tilesLimit],
    ),

    paginateLastPlayedList: React.useCallback(async () => {
      if (lastPlayedList?.list) {
        try {
          await paginateLastPlayed(lastPlayedList?.list, tilesLimit)(
            dispatch,
            () => state,
          );
        } catch (e) {
          logE(TAG, "Caught error getting last played", e);
        }
      }
    }, [dispatch, lastPlayedList?.list, state, tilesLimit]),
    paginateFavoritesList: React.useCallback(async () => {
      if (favoritesList?.list) {
        try {
          await paginateFavorites(favoritesList?.list, tilesLimit)(
            dispatch,
            () => state,
          );
        } catch (e) {
          logE(TAG, "Caught error getting favorites", e);
        }
      }
    }, [dispatch, favoritesList?.list, state, tilesLimit]),
    dismissLastPlayedLoadError: React.useCallback(() => {
      dismissListError(LAST_PLAYED_LIST_ID)(dispatch);
    }, [dispatch]),
    dismissFavoritesLoadError: React.useCallback(() => {
      dismissListError(FAVORITES_LIST_ID)(dispatch);
    }, [dispatch]),

    removeGamesFromFavorites: React.useCallback(
      async (ids: Set<string>) => {
        if (favoritesList?.list) {
          try {
            await removeGamesFromFavorites(
              favoritesList as { list: List; sort: ListSortMethod },
              ids,
            )(dispatch);
          } catch (e) {
            logE(TAG, "Caught error while removing from favorites", e);
          }
        }
      },
      [dispatch, favoritesList],
    ),

    removeGamesFromLastPlayed: React.useCallback(
      async (ids: Set<string>) => {
        if (lastPlayedList?.list) {
          try {
            await removeGamesFromLastPlayed(
              lastPlayedList as { list: List; sort: ListSortMethod },
              ids,
            )(dispatch);
          } catch (e) {
            logE(TAG, "Caught error while removing from last played", e);
          }
        }
      },
      [dispatch, lastPlayedList],
    ),

    dismissLastPlayedUpdateError: React.useCallback(
      () => dismissRemoveGamesFromLastPlayedError()(dispatch),
      [dispatch],
    ),
    dismissFavoritesUpdateError: React.useCallback(
      () => dismissRemoveGamesFromFavoritesError()(dispatch),
      [dispatch],
    ),
  };
};
