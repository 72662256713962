import { Dispatch } from "redux";
import { UserActionTypes, UserAction } from "./User";
import { BillingInfo } from "@blacknut/javascript-sdk/dist";
import { getCurrentSubscription } from "@blacknut/javascript-sdk/dist/api/Subscription/Current/getCurrentSubscription";
export const getSubscription = () => {
  return (dispatch: Dispatch<UserAction>) => {
    return new Promise<BillingInfo>((resolve, reject) => {
      dispatch({
        type: UserActionTypes.FETCH_SUBSCRIPTION_REQUEST,
      });
      getCurrentSubscription().subscribe({
        next: (billingInfo) => {
          dispatch({
            type: UserActionTypes.FETCH_SUBSCRIPTION_SUCCESS,
            billingInfo,
          });
          resolve(billingInfo);
        },
        error: (error) => {
          dispatch({
            type: UserActionTypes.FETCH_SUBSCRIPTION_ERROR,
            error,
          });
          reject(error);
        },
      });
    });
  };
};
