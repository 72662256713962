import {
  ArticleDetailsResponse,
  LeMagFeaturedArticle,
} from "@blacknut/javascript-sdk/dist";
import { Reducer } from "redux";
import { LeMagAction, LeMagActionTypes } from "../actions/LeMag";
import { UserAction, UserActionTypes } from "../actions/User";

export interface State {
  articles: LeMagFeaturedArticle[];
  totalArticles: number;
  loading: boolean;
  articleDetails?: ArticleDetailsResponse;
  error?: any;
  paginating: boolean;
}
const initialState: State = {
  articles: [],
  totalArticles: 0,
  loading: false,
  articleDetails: undefined,
  paginating: false,
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: LeMagAction | UserAction,
) => {
  switch (action.type) {
    case LeMagActionTypes.ARTICLES_FETCH_REQUEST: {
      return { ...state, loading: true };
    }
    case LeMagActionTypes.ARTICLES_FETCH_SUCCESS: {
      return {
        ...state,
        articles: action.response.articles,
        totalArticles: action.response.infos.total_items,
        loading: false,
      };
    }
    case LeMagActionTypes.ARTICLES_FETCH_ERROR: {
      return { ...state, error: action.error, loading: false };
    }
    case LeMagActionTypes.ARTICLES_PAGINATE_REQUEST: {
      return { ...state, paginating: true };
    }
    case LeMagActionTypes.ARTICLES_PAGINATE_SUCCESS: {
      return {
        ...state,
        paginating: false,
        articles: state.articles.slice(0).concat(action.response.articles),
      };
    }
    case LeMagActionTypes.ARTICLES_PAGINATE_ERROR: {
      return { ...state, paginating: false };
    }
    case LeMagActionTypes.GET_ARTICLEDETAILS_REQUEST: {
      return { ...state, paginating: true };
    }
    case LeMagActionTypes.GET_ARTICLEDETAILS_SUCCESS: {
      return {
        ...state,
        articleDetails: action.response,
      };
    }
    case LeMagActionTypes.GET_ARTICLEDETAILS_ERROR: {
      return { ...state, paginating: false };
    }
    case UserActionTypes.SET_USER_TOKEN: {
      if (!action.fromRenew) {
        return { ...state, articles: [], totalArticles: 0 };
      }
      return state;
    }
    default:
      return state;
  }
};
