import { ApiError, BillingInfo } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { fetchSubscriptionSuccess, UserAction, UserActionTypes } from "../User";
import { concatMap } from "rxjs";
import { amazonSubscribe as apiAmazonSubscribe } from "@blacknut/javascript-sdk/dist/api/User/Action/amazonSubscribe";
import { getCurrentSubscription } from "@blacknut/javascript-sdk/dist/api/Subscription/Current/getCurrentSubscription";
const userAmazonSubscribeRequest = (): UserAction => ({
  type: UserActionTypes.USER_AMAZON_SUBSCRIBE_REQUEST,
});
const userAmazonSubscribeSuccess = (billingInfo: BillingInfo): UserAction => ({
  type: UserActionTypes.USER_AMAZON_SUBSCRIBE_SUCCESS,
  billingInfo,
});
const userAmazonSubscribeError = (error?: ApiError): UserAction => ({
  type: UserActionTypes.USER_AMAZON_SUBSCRIBE_ERROR,
  error,
});
export const dismissAmazonSubscribeError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(userAmazonSubscribeError());
  };
};

export const amazonSubscribe = (purchaseResponseJson: unknown) => {
  return (dispatch: Dispatch<UserAction>) => {
    return new Promise<BillingInfo>((resolve, reject) => {
      dispatch(userAmazonSubscribeRequest());
      apiAmazonSubscribe(purchaseResponseJson)
        .pipe(concatMap(() => getCurrentSubscription()))
        .subscribe({
          next: (res) => {
            dispatch(userAmazonSubscribeSuccess(res));
            dispatch(fetchSubscriptionSuccess(res));
            resolve(res);
          },
          error: (error) => {
            dispatch(userAmazonSubscribeError(error));
            reject(error);
          },
        });
    });
  };
};
