import { NotificationType } from "@blacknut/javascript-sdk/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  addNotification,
  setLocale,
  setPlatform,
  setStartUrl,
  setStats4Nerds,
} from "../../redux/actions/App";
import { State } from "../../redux/reducers";

const usePowerUserSettings = () => {
  const history = useHistory();
  const { platform, edito, startUrl, protocole, locale, stats4Nerds } = useSelector(
    (state: State) => state.globalState,
  );
  const dispatch = useDispatch();
  return {
    platform,
    startUrl,
    edito,
    stats4Nerds,
    protocol: protocole,
    locale,
    onSelectLocale: React.useCallback(
      (opt?: string) => {
        addNotification({
          time: new Date().getTime(),
          type: NotificationType.SUCCESS,
          localizedMessage: `Locale set: ${opt}`,
        })(dispatch);
        setLocale(opt)(dispatch);
      },
      [dispatch],
    ),

    onSelectPlaform: React.useCallback(
      (env: string) => {
        setPlatform(env)(dispatch);

        addNotification({
          time: new Date().getTime(),
          type: NotificationType.SUCCESS,
          message: {
            key: "notifications.platformUpdate.success",
            opts: { env },
          },
        })(dispatch);

        setTimeout(() => {
          history.push("/");
        }, 1000);
      },
      [dispatch, history],
    ),
    onSelectStartUrl: React.useCallback(
      (startUrl: string) => {
        setStartUrl(startUrl)(dispatch);
      },
      [dispatch],
    ),

    onSelectStats4Nerds: React.useCallback(
      (active: boolean) => {
        setStats4Nerds(active)(dispatch);
        addNotification({
          time: new Date().getTime(),
          type: NotificationType.SUCCESS,
          localizedMessage: `Stats4Nerds set: ${active}`,
        })(dispatch);
      },
      [dispatch],
    ),
  };
};

export { usePowerUserSettings };
