import {
  speedtest,
  SpeedTest,
  SpeedTestPostResult,
  SpeedTestError,
  SpeedTestErrorLevel,
  apiService,
} from "@blacknut/javascript-sdk/dist";
import { logE, logD } from "@blacknut/logging/dist";

import { Observable, Subject } from "rxjs";
import { IStorageService, StorageKey } from "./IStorageService";

const TAG = "Speedtest";

export enum SPEEDTEST_EVENTS {
  START = "start",
  END = "end",
  FAIL = "fail",
  SUCCESS = "success",
}

export enum SPEEDTEST_STATUS {
  NOT_SET,
  RUNNING,
  SUCCESS,
  ERROR,
  FAILED,
}

export class SpeedTestService {
  // tslint:disable-next-line:variable-name
  private _status: SPEEDTEST_STATUS = SPEEDTEST_STATUS.NOT_SET;

  private _localStorageService: IStorageService;
  public get localStorageService(): IStorageService {
    return this._localStorageService;
  }
  public set localStorageService(v: IStorageService) {
    this._localStorageService = v;
  }

  private statusSubject = new Subject<{
    status: SPEEDTEST_STATUS;
    result?: SpeedTestPostResult;
    cause?: SpeedTestError;
  }>();

  public onStatusUpdate(): Observable<{
    status: SPEEDTEST_STATUS;
    result?: SpeedTestPostResult;
    cause?: SpeedTestError;
  }> {
    return this.statusSubject.asObservable();
  }

  public speedTestWillStart(observable: Observable<SpeedTest[]>) {
    logD(TAG, "onStart");
    this.status = SPEEDTEST_STATUS.RUNNING;

    observable.subscribe({
      next: this.onReceiveSpeedtest.bind(this),
      error: this.onError.bind(this),
    });
  }

  public get status(): SPEEDTEST_STATUS {
    return this._status;
  }

  public set status(v: SPEEDTEST_STATUS) {
    this._status = v;
  }

  public reset() {
    this.status = SPEEDTEST_STATUS.NOT_SET;
    this.statusSubject.next({ status: this.status });
  }

  public forceStop() {
    logD(TAG, "forceStop");
    this.status = SPEEDTEST_STATUS.NOT_SET;
    this.statusSubject.next({ status: this.status });
  }

  public static resultAnalysis(
    speedtestsResults: SpeedTest[],
  ): Observable<SpeedTestPostResult> {
    return speedtest(speedtestsResults);
  }

  private onError(speedTestError: SpeedTestError) {
    logD(TAG, "onError");
    this.status = SPEEDTEST_STATUS.ERROR;
    this.statusSubject.next({ status: this.status, cause: speedTestError });
  }

  private onSuccess(result: SpeedTestPostResult) {
    logD(TAG, "onSpeedtestSuccess");
    this.status = SPEEDTEST_STATUS.SUCCESS;
    this.statusSubject.next({ status: this.status, result });
  }

  private onFailed(speedTestError?: SpeedTestError) {
    logD(TAG, "onSpeedtestFailed");
    this.status = SPEEDTEST_STATUS.FAILED;
    this.statusSubject.next({ status: this.status, cause: speedTestError });
  }

  private onReceiveSpeedtest(speedtestsResults: SpeedTest[]) {
    logD(TAG, "onReceiveSpeedtest %o", speedtestsResults);

    if (!apiService.userToken) return;

    speedtest(speedtestsResults.filter((x) => !!x.provider)).subscribe({
      next: (speedTestResult: SpeedTestPostResult) => {
        logD(TAG, "RESPONSE speedtest", speedTestResult);
        if (speedTestResult.results && this.localStorageService) {
          this.localStorageService
            .setItem(StorageKey.SPEED_TEST_RESULT, speedTestResult.results)
            .then(() => {
              logD(TAG, "Speedtest save OK");
            })
            .catch((e) => {
              logD(TAG, "Speedtest save KO", e);
            });
        }
        if (speedTestResult.error) {
          if (speedTestResult.error.level === SpeedTestErrorLevel.WARNING) {
            this.onFailed(speedTestResult.error);
          } else {
            this.onSuccess(speedTestResult);
          }
        } else {
          this.onSuccess(speedTestResult);
        }
      },
      error: (err) => {
        logE(TAG, "Error from err sending speed test result", err);
        this.onError(err);
      },
    });
  }
}

export default new SpeedTestService();
