import { interval, Subject, Subscription } from "rxjs";
import { sample } from "rxjs/operators";

const PROCESS_NETWORK_STATUS_INTERVAL = 1000;

export enum NETWORK_EVENTS {
  ONLINE = "online",
  OFFLINE = "offline",
}

export class NetworkService {
  public isOnline = false;
  public events: Subject<NETWORK_EVENTS> = new Subject();
  public inboundEvents: Subject<boolean> = new Subject<boolean>();
  private subscriptions: Subscription[] = [];

  constructor() {
    this.subscribeNetworkEvents();
  }

  public emitOnline() {
    this.inboundEvents.next(true);
  }

  public emitOffline() {
    this.inboundEvents.next(false);
  }

  public onNetworkEvent() {
    return this.events.asObservable();
  }

  public destroy() {
    this.unSubscribeInboundEvents();
  }

  private subscribeNetworkEvents() {
    // Process emited network status every <PROCESS_NETWORK_STATUS_INTERVAL> milliseconds
    this.subscriptions.push(
      this.inboundEvents
        .asObservable()
        .pipe(sample(interval(PROCESS_NETWORK_STATUS_INTERVAL)))
        .subscribe(
          (isOnline) => {
            this.isOnline = isOnline;
            if (isOnline) {
              this.events.next(NETWORK_EVENTS.ONLINE);
            } else {
              this.events.next(NETWORK_EVENTS.OFFLINE);
            }
          },
          (error) => {
            // console.error('Got err', error);
          },
        ),
    );
  }

  private unSubscribeInboundEvents() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions.slice(0);
  }
}

export default new NetworkService();
