import BonusGameView from "../componentView/BonusGameView";
import { ListRendererFn } from "./ListRenderer";
import React from "react";
import styles from "../styles.module.scss";
import clsx from "clsx";

/******
 * Bonus game list
 * Desktop: List of 5 tiles
 * Phone:
 *  Portrait: Scrollable List of n tiles with 2 fully visible and 1 partialy visible
 *  Landscape: Scrollable List of n tiles  with 2 fully visible and 1 partialy visible
 * Tablet:
 *  Portrait: Scrollable List of n tiles with 5 fully visible and 1 partialy visible
 *  Landscape: Scrollable List of n tiles  with 3 fully visible and 1 partialy visible
 */
const BonusGamesRenderer: ListRendererFn = ({
  list,
  leaveFor,
  onFocus,
  scrollable,
  page,
}) => {
  return (
    <BonusGameView
      list={list}
      key={list.uuid}
      leaveFor={leaveFor}
      onFocus={onFocus}
      scrollable={scrollable}
      className={clsx(styles.bonus, scrollable && styles.scrollable)}
      page={page}
    />
  );
};
export { BonusGamesRenderer };
