import { SubscriptionKind } from "@blacknut/javascript-sdk/dist";
import { State } from "core";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import electronService from "../../services/ElectronService";
import { HeaderStyle } from "../../theme/Theme";
import { useTheme } from "../../theme/ThemeProvider";

import clsx from "clsx";
import styles from "./styles.module.scss";
import React from "react";
interface HeaderProps {
  title?: string;
  headerRight?: JSX.Element;
  headerRightContainer?: JSX.Element;
  headerLeft?: JSX.Element;
  headerStyle?: Partial<HeaderStyle>;
  className?: string;
  hidden?: boolean;
}

const WebHeader = ({
  title,
  headerRight,
  headerLeft,
  headerStyle,
  className,
  hidden = false,
  headerRightContainer,
}: HeaderProps) => {
  const { theme } = useTheme();
  const history = useHistory();
  const { familySubscription } = useSelector((state: State) => state.profilesState);
  const isVirginSub =
    SubscriptionKind.VIRGIN_MOBILE &&
    familySubscription?.subscription?.kind === SubscriptionKind.VIRGIN_MOBILE;

  const goToSlash = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <FocusableSection
      focusKey="header"
      component="header"
      className={clsx([
        styles.container,
        hidden && styles.hidden,
        electronService.getPlatform() === "darwin" && styles.darwin,
        className,
      ])}
      style={headerStyle}
    >
      {headerLeft && <div className={clsx(styles.leftContainer)}>{headerLeft}</div>}
      {!headerLeft && headerRight && <div />}

      {
        //FIXME check if still used
      }
      {title && !headerLeft && <div className={styles.title}>{title}</div>}

      {/* LOGO */}
      {title === undefined && (
        <div className={styles.logoContainer} onClick={goToSlash} data-testid="logo">
          {isVirginSub
            ? theme.images.LogoAIS && (
                <span>
                  <theme.images.LogoAIS
                    style={{
                      width: `${(theme as any).logoAISSize.width}rem`,
                      height: `${(theme as any).logoAISSize.height}rem`,
                    }}
                  />
                </span>
              )
            : theme.images.Logo && (
                <theme.images.Logo
                  style={{
                    width: `${theme.logoSize.width}rem`,
                    height: `${theme.logoSize.height}rem`,
                  }}
                />
              )}
        </div>
      )}

      {headerRight && !headerRightContainer && (
        <div className={styles.rightContainer}>{headerRight}</div>
      )}
      {headerRightContainer}
    </FocusableSection>
  );
};

export default WebHeader;
