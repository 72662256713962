import { User } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { UserAction, UserActionTypes } from "./User";
import { toggleNewsletter as apiToggleNewsletter } from "@blacknut/javascript-sdk/dist/api/User/Preference/toggleNewsletter";
export const dismissNewsletterError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    return dispatch({ type: UserActionTypes.TOGGLE_NEWSLETTER_ERROR });
  };
};

export const toggleNewsletter = (user: User) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({ type: UserActionTypes.TOGGLE_NEWSLETTER_REQUEST });
    return new Promise<User>((resolve, reject) => {
      apiToggleNewsletter().subscribe({
        next: (u) => {
          dispatch({ type: UserActionTypes.TOGGLE_NEWSLETTER_SUCCESS, user });
          resolve(u);
        },
        error: (error) => {
          dispatch({ type: UserActionTypes.TOGGLE_NEWSLETTER_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
