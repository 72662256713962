import { Config, getConfig as apiGetConfig } from "@blacknut/javascript-sdk/dist";
import { TAG } from "@blacknut/javascript-sdk/dist/api/ApiService";
import { logE } from "@blacknut/logging/dist";
import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";

export const setConfig = (config?: Config): Action => {
  return {
    type: ActionTypes.SET_CONFIG,
    config,
  };
};

export const getConfig = () => {
  return (dispatch: Dispatch<Action>) => {
    return new Promise<Config>((resolve, reject) => {
      apiGetConfig().subscribe({
        next: (config) => {
          dispatch(setConfig(config));
          resolve(config);
        },
        error: (error) => {
          logE(TAG, "Error getting config", error);
          reject(error);
        },
      });
    });
  };
};
