import { logE } from "@blacknut/logging/dist";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dismissError,
  fetchArticles,
  getArticleDetails,
  paginateArticles,
} from "../../redux/actions/LeMag";
import { State } from "../../redux/reducers";
const TAG = "LeMag";

const useLeMagFeaturedArticles = (props?: { limit?: number }) => {
  const limit = props?.limit ?? 10;
  const dispatch = useDispatch();
  const { loading, error, articles, totalArticles, paginating, articleDetails } =
    useSelector((state: State) => state.leMagState);
  const { profile } = useSelector((state: State) => state.profilesState);
  const _fetchArticles = useCallback(async () => {
    if (articles.length === 0) {
      try {
        await fetchArticles(1, limit, profile?.userProfile)(dispatch);
      } catch (e) {
        logE(TAG, "Caught error getting articles", e);
      }
    }
  }, [articles, dispatch, limit, profile?.userProfile]);

  const _getArticleDetails = useCallback(
    async (slug) => {
      try {
        await getArticleDetails(slug)(dispatch);
      } catch (e) {
        logE(TAG, "Caught error getting article details", e);
      }
    },
    [dispatch],
  );

  useEffect(() => {
    if (articles.length === 0) {
      _fetchArticles();
    }
  }, [articles, _fetchArticles]);
  return {
    loading,
    loadError: error,
    articles,
    articleDetails,
    totalArticles,
    paginating,
    dismissLoadError: useCallback(() => {
      dismissError()(dispatch);
    }, [dispatch]),
    fetchArticles: _fetchArticles,
    getArticleDetails: _getArticleDetails,
    paginateNext: useCallback(async () => {
      try {
        await paginateArticles(
          1 + Math.ceil(articles.length / limit),
          limit,
          profile?.userProfile,
        )(dispatch);
      } catch (e) {
        logE(TAG, "Caught error on pagination", e);
      }
    }, [articles.length, limit, profile?.userProfile, dispatch]),
  };
};
export { useLeMagFeaturedArticles };
