import { Status } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import { State } from "core";
import { ModalSubscription, useModal } from "@blacknut/react-sdk";
import React, { useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import NotSubscribedModal from "src/components/Modals/NotSubscribedModal";
import { useSubscriptionRedirect } from "src/components/SubscriptionRedirect/SubscriptionRedirect";
import { LOGGING_TAG } from "src/utils/Utils";

const useSubscribed = () => {
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const { redirectToSubscription } = useSubscriptionRedirect();
  const { billingInfo } = useSelector((state: State) => state.globalState);
  const { profile } = useSelector((state: State) => state.profilesState);

  const _redirectToSubscription = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;

    //Le's defer redirect, otherwise, redirect will go the / but modal close will go back
    //Stream will be relaunched
    setTimeout(redirectToSubscription, 500);
  }, [redirectToSubscription]);

  const closeModal = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
  }, []);

  const checkForValidSubscription = () => {
    const isSubscriptionValid =
      billingInfo.subscription?.status === Status.ACTIVE ||
      billingInfo.subscription?.status === Status.TRIALING ||
      billingInfo.subscription?.status === Status.CANCELING;

    if (!isSubscriptionValid) {
      logD(LOGGING_TAG, "Current subscription is not valid");
      modalSubscription.current = modalPush((props) => (
        <NotSubscribedModal
          {...props}
          isProfileMaster={profile?.isMaster || false}
          onSubscribe={_redirectToSubscription}
          onClose={closeModal}
          onPlanSubscribed={closeModal}
        />
      ));
    }

    return isSubscriptionValid;
  };

  return { checkForValidSubscription };
};

export default useSubscribed;
