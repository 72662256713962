import { List } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { ListAction, ListActionTypes, LAST_PLAYED_LIST_ID } from "./List";
import { getLastPlayed as apiGetLastPlayed } from "@blacknut/javascript-sdk/dist/api/Catalog/getLastPlayed";

export const paginateLastPlayed = (list: List, limit = 6) => {
  return (dispatch: Dispatch<ListAction>, getState: () => State) => {
    return new Promise<List>((resolve, reject) => {
      dispatch({ type: ListActionTypes.LIST_PAGINATION_REQUEST, id: list.uuid });
      apiGetLastPlayed(
        getState().listsState.lists[LAST_PLAYED_LIST_ID].sort,
        1 + Math.floor(list.tiles.length / limit),
        limit,
      ).subscribe({
        next: (res) => {
          const newList = {
            ...list,
            tiles: list.tiles.concat(
              res.games.map((game) => {
                return {
                  game,
                };
              }),
            ),
          };
          dispatch({
            type: ListActionTypes.LIST_PAGINATION_SUCCESS,
            id: list.uuid,
            list: newList,
          });
          resolve(newList);
        },
        error: (error) => {
          dispatch({
            type: ListActionTypes.LIST_PAGINATION_ERROR,
            error,
            id: list.uuid,
          });
          reject(error);
        },
      });
    });
  };
};
