import { AppLayout, useAnalytics, useHomePage, useLayout } from "core";
import { FocusableSection, NavigationFailedEvent } from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useEffect, useRef } from "react";
import Catalog from "../../components/Catalog/Catalog";
import { CircularProgress } from "@blacknut/react-sdk/dist";
import { useHeader } from "../../components/Header/HeaderProvider";
import { useProfiler } from "../../hooks/useProfiler";
import { useSpatialNavigationEnabler } from "../../utils/Navigation";
import { useErrorHandling } from "../../utils/V2ErrorHandler";
import { AccountHeaderRight } from "./AccountHeaderRight";
import BannerNotification from "./BannerNotification";
import styles from "./styles.module.scss";
import { RemoteNotificationFormat } from "@blacknut/javascript-sdk/dist";
const HomePage = () => {
  const {
    page,
    loading,
    dismissLoadingError,
    loadingError,
    paginating,
    paginate,
    remoteNotifications,
    markRemoteNotificationAsRead,
  } = useHomePage({
    limit: 10,
    tilesLimit: 20,
  });
  const layout = useLayout();

  const notification =
    remoteNotifications &&
    remoteNotifications.find(
      (n) => n.format === RemoteNotificationFormat.BANNER && n.isNew,
    );

  const markAsRead = useCallback(() => {
    if (notification) {
      markRemoteNotificationAsRead(notification);
    }
  }, [markRemoteNotificationAsRead, notification]);

  const reload = useCallback(() => {
    window.location.reload();
  }, []);

  useErrorHandling({
    error: loadingError,
    clearError: dismissLoadingError,
    callback: reload,
  });

  const { setTitle: setHeaderTitle, setHeaderLeft, setHeaderRight } = useHeader();

  React.useEffect(() => {
    setHeaderLeft(undefined);
    setHeaderRight(<AccountHeaderRight />);
    setHeaderTitle(undefined);
  }, [setHeaderLeft, setHeaderRight, setHeaderTitle]);

  const { trackPageView } = useAnalytics();
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      trackPageView("home");
    }
  }, [trackPageView]);

  const shouldEnableNavigation = useCallback(
    () => !!(page && page.lists.length),
    [page],
  );
  useSpatialNavigationEnabler(
    shouldEnableNavigation,
    `/${page?.uuid}/${page?.lists[0].uuid}`,
  );

  const onNavigationFailed = useCallback((e: NavigationFailedEvent) => {
    if (e.detail.direction === "up") {
      document.documentElement.scroll({ top: 0 });
    } else if (e.detail.direction === "down") {
      document.documentElement.scroll({ top: document.body.scrollHeight });
    }
  }, []);

  const Root = useProfiler("home", {}, { memory: true });

  return (
    <Root>
      <FocusableSection
        className={styles.container}
        focusKey={page?.uuid || "xx"}
        onNavigationFailed={onNavigationFailed}
      >
        {layout !== AppLayout.TV &&
          notification &&
          notification.format === RemoteNotificationFormat.BANNER && (
            <BannerNotification notification={notification} onClose={markAsRead} />
          )}
        {loading && <CircularProgress className={styles.loading} size={50} />}
        {page && <Catalog page={page} paginating={paginating} onPaginate={paginate} />}
      </FocusableSection>
    </Root>
  );
};
export default HomePage;
