import {
  RemoteNotification,
  RemoteNotificationImpl,
  User,
} from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import { Dispatch } from "redux";
import { forkJoin, map } from "rxjs";
import { UserAction, UserActionTypes } from "./User";
const TAG = "Notifications";
import { deleteNotifications } from "@blacknut/javascript-sdk/dist/api/User/Action/deleteNotifications";
import { getNotifications } from "@blacknut/javascript-sdk/dist/api/User/Action/getNotifications";

export const setRemoteNotifications = (
  user: User,
  notifications: RemoteNotification[],
) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.SET_REMOTE_NOTIFICATIONS,
      notifications,
      user,
    });
  };
};

export const markRemoteNotificationAsRead = (
  user: User,
  notification: RemoteNotification,
) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.DELETE_REMOTE_NOTIFICATION,
      notification,
      user,
    });
  };
};

export const markAllRemoteNotificationsAsRead = (u: User) => {
  return (dispatch: Dispatch<UserAction>) => {
    setRemoteNotifications(u, [])(dispatch);
  };
};

export const fetchRemoteNotifications = (user: User) => {
  return (dispatch: Dispatch<UserAction>) => {
    return new Promise<RemoteNotification[]>((resolve) => {
      getNotifications()
        .pipe(
          map((res) =>
            res.map((n) => {
              const n2 = new RemoteNotificationImpl(n);
              n2.isNew = true;
              return n2;
            }),
          ),
        )
        .subscribe({
          next: (notifications) => {
            dispatch({
              type: UserActionTypes.ADD_REMOTE_NOTIFICATIONS,
              notifications,
              user,
            });

            if (notifications.length > 0) {
              // Mark remote notifications as read

              forkJoin(notifications.map((n) => deleteNotifications(n.id))).subscribe({
                next: () => {
                  logD(TAG, "Successfully deleted");
                },
                error: (err) => {
                  logE(TAG, "Error on delete", err);
                },
              });
            }

            resolve(notifications);
          },
          error: (error) => {
            logE(TAG, "Error getting remote notifications", error);
          },
        });
    });
  };
};
