import { Dispatch } from "redux";
import { PageAction, PageActionTypes } from "./Page";
import { ApiError, Page } from "@blacknut/javascript-sdk/dist";

import { getPassPage as apiGetPassPage } from "@blacknut/javascript-sdk/dist/api/BlacknutPlus/getPassPage";
import { getPage as apiGetPage } from "@blacknut/javascript-sdk/dist/api/Catalog/getPage";

export const fetchPageRequest = (id: string): PageAction => ({
  type: PageActionTypes.PAGE_FETCH_REQUEST,
  id,
});

export const fetchPageSuccess = (id: string, page: Page): PageAction => ({
  type: PageActionTypes.PAGE_FETCH_SUCCESS,
  id,
  page,
});

export const fetchPageError = (id: string, error?: ApiError): PageAction => ({
  type: PageActionTypes.PAGE_FETCH_ERROR,
  id,
  error,
});

export const dismissPageError = (id: string) => {
  return (dispatch: Dispatch<PageAction>) => {
    dispatch(fetchPageError(id));
  };
};

export const dismissHomePageError = () => {
  return (dispatch: Dispatch<PageAction>) => {
    dispatch(fetchPageError("home"));
  };
};

export const fetchPage = (
  id: string,
  limit?: number,
  tilesLimit?: number,
  pass = false,
) => {
  return (dispatch: Dispatch<PageAction>) => {
    return new Promise<Page>((resolve, reject) => {
      dispatch(fetchPageRequest(id));
      const observable = pass ? apiGetPassPage : apiGetPage;
      observable(id, 0, limit, tilesLimit).subscribe({
        next: (page) => {
          dispatch(fetchPageSuccess(id, page));
          resolve(page);
        },
        error: (error) => {
          dispatch(fetchPageError(id, error));
          reject(error);
        },
      });
    });
  };
};
