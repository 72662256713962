import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";
import { ApiError, FamilyStreams } from "@blacknut/javascript-sdk/dist";
import { getStreams } from "@blacknut/javascript-sdk/dist/api/Stream/getStreams";
const getCurrentStreamsRequest = (): Action => ({
  type: ActionTypes.GET_CURRENT_STREAM_REQUEST,
  loading: true,
});

export const getCurrentStreamsSuccess = (streams: FamilyStreams): Action => ({
  type: ActionTypes.GET_CURRENT_STREAM_SUCCESS,
  streams,
  loading: false,
});

const getCurrentStreamsError = (error: ApiError): Action => ({
  type: ActionTypes.GET_CURRENT_STREAM_ERROR,
  error,
  loading: false,
});

export const getCurrentStreams = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch(getCurrentStreamsRequest());
    return new Promise<FamilyStreams>((resolve, reject) => {
      getStreams().subscribe({
        next: (streams: FamilyStreams) => {
          dispatch(getCurrentStreamsSuccess(streams));
          resolve(streams);
        },
        error: (error) => {
          dispatch(getCurrentStreamsError(error));
          reject(error);
        },
      });
    });
  };
};
