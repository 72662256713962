import { Tile } from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { addToFavorite, removeFromFavorite } from "../../redux/actions/Game";
import { State } from "../../redux/reducers";

const TAG = "TileContainer";

export const useTile = (props: {
  item: Tile;
  previewMode?: boolean;
  pageUuid?: string;
}) => {
  const { item, previewMode = false, pageUuid } = props;

  const gameId = item.game?.id;
  const link = item.link?.url;
  const gameState = useSelector((state: State) => state.gamesState.games[gameId || ""]);
  const dispatch = useDispatch();
  const history = useHistory();
  return {
    onClick: useCallback(() => {
      if (previewMode) {
        logD(TAG, "tile clicked in preview mode");
        return;
      }
      if (item.game) {
        history.push(`/game/${item.game.id}`);
      }
      if (link) {
        if (link?.startsWith("blacknut://")) {
          history.push(link.replace("blacknut://", "/")); //FIXME deeplink scheme
        } else if (link.startsWith("http")) {
          if (item?.link?.uuid && pageUuid) {
            history.push(`/link/${pageUuid}/${item.link.uuid}`);
          } else {
            window.open(link, "_blank");
          }
        } else {
          history.push(link);
        }
      }
      if (item.page) {
        history.push(`/page/${item.page.uuid}`);
      }
      if (item.marketing) {
        history.push(`/lemag/${item.marketing.uuid}`);
      }
      if (item.lemag) {
        history.push(`/lemag/${item.lemag.uuid}`);
      }
    }, [
      previewMode,
      item.game,
      item.page,
      item.marketing,
      item.lemag,
      item.link?.uuid,
      link,
      history,
      pageUuid,
    ]),

    toggleFavorite: useCallback(async () => {
      if (!gameState || !gameState.favoritePending || !gameState?.game) {
        return;
      }
      if (gameState?.favorite) {
        try {
          await removeFromFavorite(gameState?.game)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error toggling favorite", e);
        }
      } else {
        try {
          await addToFavorite(gameState?.game)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error toggling favorite", e);
        }
      }
    }, [dispatch, gameState]),
  };
};
