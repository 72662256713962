import { logD, logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  dismissFamilyInfoLoadError,
  dismissResetPinError,
  loadFamilyInfo,
  resetPin,
  selectProfile,
} from "../../redux/actions/Profiles";
import { dismissAuthenticationError, logout } from "../../redux/actions/User";
import { State } from "../../redux/reducers";
import { usePrevious } from "../../utils/Hooks";

const TAG = "ProfilePin";
export const useProfileUnlock = (props: { returnUrl?: string }) => {
  const history = useHistory();
  const { id: pId, returnUrl: pReturnUrl } = useParams<{
    id: string;
    returnUrl?: string;
  }>();
  const dispatch = useDispatch();

  const {
    familyProfiles: profilesState,
    profile: authenticatedProfile,
    resetPin: resetPinState,
  } = useSelector((state: State) => state.profilesState);

  const {
    error: profilesLoadError,
    inProgress: profilesLoadInProgress,
    profiles,
  } = profilesState;
  const { error: resetPinError, inProgress: resetPinInProgress } = resetPinState;
  const profile = profiles?.find((p) => p.id === pId);
  const { familyToken, authentication } = useSelector(
    (state: State) => state.globalState,
  );
  const { error: authenticationError, inProgress: authenticating } = authentication;
  const [profileOnMount] = React.useState(authenticatedProfile);

  const { config } = useSelector((state: State) => state.globalState);

  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (familyToken && !profiles && !mounted.current) {
      const init = async () => {
        mounted.current = true;
        try {
          await loadFamilyInfo()(dispatch);
        } catch (e) {
          logE(TAG, "Caught error loading profiles");
        }
      };
      init();
    }
  }, [dispatch, familyToken, profiles, profilesLoadError, profilesLoadInProgress]);

  // see https://blacknut.atlassian.net/browse/CA-1394
  const prevToken = usePrevious(familyToken);
  React.useEffect(() => {
    if (prevToken && !familyToken) {
      history.push("/welcome");
    }
  }, [prevToken, familyToken, history]);

  return {
    profile,
    resetPinError,
    resetPinInProgress,
    authenticationError,
    authenticating,
    loading: profilesLoadInProgress,
    loadError: profilesLoadError,
    config,
    dismissLoadError: React.useCallback(
      () => dismissFamilyInfoLoadError()(dispatch),
      [dispatch],
    ),
    dismissAuthenticationError: React.useCallback(
      () => dismissAuthenticationError()(dispatch),
      [dispatch],
    ),
    dismissResetPinError: React.useCallback(
      () => dismissResetPinError()(dispatch),
      [dispatch],
    ),
    onSelectProfile: React.useCallback(
      async (pin: string) => {
        const returnUrl = props.returnUrl || pReturnUrl;
        if (!profile) {
          return Promise.reject("no input profile");
        }
        if (profile && profile.id === profileOnMount?.id) {
          // When selecting same profile ignore return url and pin lock
          logD(TAG, "Selecting same profile");
          if (returnUrl) {
            history.push(returnUrl);
          } else {
            history.replace("/catalog");
          }
          return Promise.resolve();
        } else {
          try {
            await selectProfile(profile, pin, true)(dispatch);
            // User selected a profile
            if (returnUrl) {
              // open via deeplink
              history.push(returnUrl);
            } else {
              history.replace("/catalog");
            }
          } catch (e) {
            logE(TAG, "Caught error selecting profile", e);
            throw e;
          }
        }
      },
      [dispatch, history, pReturnUrl, profile, profileOnMount?.id, props.returnUrl],
    ),

    resetPin: React.useCallback(async () => {
      dismissAuthenticationError()(dispatch);
      try {
        await resetPin()(dispatch);
      } catch (e) {
        logE(TAG, "Caught error on reset pin", e);
        throw e;
      }
    }, [dispatch]),
    logout: React.useCallback(() => {
      logout()(dispatch);
    }, [dispatch]),
  };
};
