import {
  ApiError,
  apiService,
  GameStreamProtocol,
  isTizen,
  leMagService,
  RemoteNotification,
  RemoteNotificationImpl,
  Token,
  UserProfile,
} from "@blacknut/javascript-sdk/dist";
import { ConsoleAppender, logD, logE, LogLevel } from "@blacknut/logging/dist";
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Dispatch,
  Middleware,
  MiddlewareAPI,
} from "redux";
import thunkMiddleware from "redux-thunk";
import { v1 as uuidv1 } from "uuid";
import {
  DeprecatedStorageKey,
  IStorageService,
  StorageKey,
} from "../services/IStorageService";
import {
  Action,
  ActionTypes,
  ActionTypes as AppActionTypes,
  initializeSuccess,
  setAUDID,
  setDeviceId,
  setIncubatingFeatures,
  setLocale,
  setPreshippingId,
  setShowFPS,
} from "./actions/App";
import {
  loadFamilyInfoSuccess,
  ProfilesAction as ProfileAction,
  ProfilesActionTypes as ProfilesActionType,
  setCurrentProfile,
  setProfilePrefs,
} from "./actions/Profiles";
//FIX
import {
  fetchRemoteNotifications,
  onUserTokenRetrieved,
  setFamilyToken,
  setRemoteNotifications,
  setUserToken,
  UserAction,
  UserActionTypes,
} from "./actions/User";
import reducers, { State } from "./reducers";
import { State as GlobalState } from "./reducers/Global";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";
const matchLocale = (current: string, expected: string) => {
  const matchFull = current && current.match(/([a-zA-Z]{2})-[a-zA-Z]{2}/);
  const matchPartial = current && current.match(/([a-zA-Z]{2})/);
  return (
    (matchPartial && matchPartial[1].toLowerCase() == expected) ||
    (matchFull && matchFull[1].toLowerCase()) === expected
  );
};

const TAG = "Store";
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
  ...reducers,
});

declare interface DefaultSettings {
  startUrl?: string;
  apiPath: string;
  protocole?: GameStreamProtocol;
  apiVersion: string;
  allowAnonymous?: boolean;
}

const storeUserToken = (storage: IStorageService, token: Token | undefined) => {
  storage
    .getItem(StorageKey.TOKEN)
    .then((val) => {
      val = val || {};
      if (token) {
        val.userToken = token;
      } else {
        delete val.userToken;
      }

      // Tizen private preview
      // FIXME review this, use native bridge
      if (window.parent && isTizen()) {
        window.parent.postMessage(
          JSON.stringify({
            message: StorageKey.TOKEN,
            payload: val,
          }),
          "*",
        );
      }

      return storage.setItem(StorageKey.TOKEN, val);
    })
    .catch((err) => {
      logE(TAG, "Error persisting user token", err);
    });
};

const CONSOLE_APPENDER = new ConsoleAppender();
CONSOLE_APPENDER.level = LogLevel.LEVEL_DEBUG;

const customMiddleWare =
  (storage: IStorageService, opts: DefaultSettings) =>
  (store: MiddlewareAPI<Dispatch, State>) =>
  (next: Dispatch<AnyAction>) =>
  (action: Action | UserAction | ProfileAction) => {
    if (action.type === ActionTypes.INITIALIZE_REQUEST) {
      Promise.all([
        storage.getItem(StorageKey.PLATFORM),
        storage.getItem(DeprecatedStorageKey.DEBUG),
        storage.getItem(DeprecatedStorageKey.PLAYER_DEBUG),
        storage.getItem(DeprecatedStorageKey.PROTOCOL),
        storage.getItem(StorageKey.TOKEN),
        storage.getItem(StorageKey.API_VERSION),
        storage.getItem(StorageKey.AUDID),
        storage.getItem(StorageKey.UDP_OPTION),
        storage.getItem(StorageKey.INCUBATING_FEATURES),
        storage.getItem(StorageKey.RTP_DELAY),
        storage.getItem(StorageKey.PROFILES_DATA),
        storage.getItem(StorageKey.SHOW_FPS),
        storage.getItem(StorageKey.PRESHIPPING_ID),
        storage.getItem(StorageKey.DEVICE_ID),
        storage.getItem(DeprecatedStorageKey.PLAYER_INPUT_METHOD),
        storage.getItem(StorageKey.LOCALE),
        storage.getItem(StorageKey.START_URL),
      ]).then((values) => {
        const token = values[4];
        let audid = values[6];
        const incubating = values[8];
        const profilesData = values[10];
        const showFPS = values[11];
        const preshippingId = values[12];
        const deviceId = values[13];
        const lang = values[15];
        const startUrl = values[16];

        logD(TAG, "GOT FROM LOCAL STORAGE %o", values);

        store.dispatch({
          type: AppActionTypes.SET_PLATFORM,
          platform: (values[0] || opts.apiPath).replace(/\/v1$/, ""),
        });

        if (!audid) {
          audid = uuidv1();
        }
        setAUDID(audid)(store.dispatch);
        setShowFPS(showFPS || false)(store.dispatch);

        setIncubatingFeatures(incubating)(store.dispatch);
        setDeviceId(deviceId, false)(store.dispatch);
        setLocale(lang)(store.dispatch);

        store.dispatch({
          type: AppActionTypes.SET_START_URL,
          startUrl: startUrl || opts.startUrl || "https://app.blacknut.com",
        });

        const deserializedProfilesData = profilesData || {};
        // Convert back acknowledgedGamepadGames as array to set
        for (const key in deserializedProfilesData) {
          if (deserializedProfilesData.hasOwnProperty(key)) {
            const p = deserializedProfilesData[key];
            if (p.acknowledgedGamepadGames) {
              deserializedProfilesData[key].acknowledgedGamepadGames = new Set(
                p.acknowledgedGamepadGames,
              );
            }
          }
        }
        setProfilePrefs(deserializedProfilesData)(store.dispatch);

        setPreshippingId(preshippingId)(store.dispatch);

        setFamilyToken(token?.familyToken)(store.dispatch);

        if (token?.familyToken) {
          getFamilyInfo().subscribe({
            next: (res) => {
              const { profiles, subscription } = res;
              store.dispatch(loadFamilyInfoSuccess(profiles, subscription));

              let userSessionToken: Token | undefined = undefined;
              if (opts.allowAnonymous && token.userToken) {
                userSessionToken = token.userToken;
              } else if (sessionStorage && sessionStorage.getItem) {
                const sToken =
                  sessionStorage && sessionStorage.getItem(StorageKey.TOKEN);
                try {
                  if (sToken) {
                    const oToken = JSON.parse(sToken);
                    userSessionToken = oToken.userToken;
                  }
                } catch (e) {
                  logE(TAG, "Error parsing session token ", e);
                }
              }

              if (userSessionToken) {
                onUserTokenRetrieved(
                  store.dispatch,
                  userSessionToken as Token,
                  true,
                  false,
                ).subscribe({
                  next: async (res) => {
                    let profile: UserProfile | undefined;
                    if (profiles.length === 1) {
                      profile = profiles[0];
                    } else {
                      profile = profiles.find((pp) => pp.id === res[0].id);
                    }
                    if (profile) {
                      store.dispatch(setCurrentProfile(profile, res[0], false));
                      try {
                        await fetchRemoteNotifications(res[0])(store.dispatch);
                      } catch (e) {
                        logE(TAG, "Got error getting notifications: %o", e);
                      }
                    }

                    store.dispatch(initializeSuccess(res[2], res[0]));
                  },
                  error: (error) => {
                    logE(TAG, "Error on init", error);
                    store.dispatch(
                      initializeSuccess(store.getState().globalState.config),
                    );
                  },
                });
              } else {
                store.dispatch(initializeSuccess(store.getState().globalState.config));
              }
            },
            error: (e: ApiError) => {
              logE(TAG, "Error on init - getFamilySubscription", e);
              store.dispatch(initializeSuccess(store.getState().globalState.config));
            },
          });
        } else {
          store.dispatch(initializeSuccess(store.getState().globalState.config));
        }
      });
      next(action);
    } else if (action.type === ActionTypes.SET_PLATFORM) {
      storage.setItem(StorageKey.PLATFORM, action.platform);
    } else if (action.type === ActionTypes.SET_DEVICE_ID) {
      storage.setItem(StorageKey.DEVICE_ID, action.deviceId);
    } else if (action.type === ActionTypes.SET_SHOW_FPS) {
      storage.setItem(StorageKey.SHOW_FPS, action.showFPS);
    } else if (action.type === ActionTypes.SET_AUDID) {
      storage.setItem(StorageKey.AUDID, action.audid);
    } else if (action.type === ActionTypes.SET_INCUBATING_FEATURES) {
      storage.setItem(StorageKey.INCUBATING_FEATURES, action.incubating);
    } else if (action.type === ActionTypes.SET_PRESHIPPING_ID) {
      storage.setItem(StorageKey.PRESHIPPING_ID, action.preshippingId);
    } else if (action.type === ActionTypes.SET_LOCALE) {
      storage.setItem(StorageKey.LOCALE, action.locale);
      if (action.locale) {
        apiService.locale = action.locale;
        if (matchLocale(action.locale, "fr")) {
          leMagService.locale = "fr";
        } else if (matchLocale(action.locale, "en")) {
          leMagService.locale = "en";
        } else if (matchLocale(action.locale, "es")) {
          leMagService.locale = "es";
        }
      }
    } else if (action.type === UserActionTypes.SET_USER_TOKEN) {
      const globalState: GlobalState = store.getState().globalState;
      const currentProfile = store.getState().profilesState.profile;
      if (globalState.app.initialized) {
        if (
          (currentProfile && !currentProfile.isPinLocked) ||
          (store.getState().globalState.user?.anonymous &&
            opts.allowAnonymous) /* Required since no profile selection*/
        ) {
          storeUserToken(storage, action.token);
        }
      }
    } else if (action.type === UserActionTypes.SET_FAMILY_TOKEN) {
      if (store.getState().globalState.app.initialized || action.fromRenew) {
        if (action.fromRenew) {
          logD(TAG, `action fT - fromRenew, expiresAt: ${action.token?.expiresAt}`);
        }
        storage
          .setItem(StorageKey.TOKEN, {
            familyToken: action.token,
          })
          .catch((err) => {
            logE(TAG, "Error persisting user token", err);
          });
      }
    } else if (action.type === UserActionTypes.ADD_REMOTE_NOTIFICATIONS) {
      // Merge new notifications with old ones
      storage
        .getItem(`blacknut:user:${action.user.id}.notifications` as StorageKey)
        .then((savedNotifications: RemoteNotification[] | null) => {
          savedNotifications = savedNotifications || [];
          const newOnes = [];
          for (const n of action.notifications) {
            if (
              savedNotifications.findIndex(
                (x) =>
                  x.id === n.id || (x.description === n.description && n.description),
              ) === -1
            ) {
              newOnes.push(n);
            }
          }

          const merged: RemoteNotification[] = newOnes.concat(savedNotifications);
          storage
            .setItem(
              `blacknut:user:${action.user.id}.notifications` as StorageKey,
              merged,
            )
            .catch((e) => {
              logE(TAG, "Error persisting notifications", e);
            });
        })
        .catch((e) => {
          logE(TAG, "Error getting persisted notifications", e);
        });
    } else if (action.type === UserActionTypes.SET_REMOTE_NOTIFICATIONS) {
      storage
        .setItem(
          `blacknut:user:${action.user.id}.notifications` as StorageKey,
          action.notifications,
        )
        .catch((e) => {
          logE(TAG, "Error persisting notifications", e);
        });
    } else if (action.type === UserActionTypes.DELETE_REMOTE_NOTIFICATION) {
      storage
        .getItem(`blacknut:user:${action.user.id}.notifications` as StorageKey)
        .then((savedNotifications: RemoteNotification[] | null) => {
          if (savedNotifications) {
            const res = savedNotifications.filter(
              (n) => n.id !== action.notification.id,
            );
            storage
              .setItem(
                `blacknut:user:${action.user.id}.notifications` as StorageKey,
                res,
              )
              .catch((e) => {
                logE(TAG, "Error persisting notifications", e);
              });
          }
        })
        .catch((e) => {
          logE(TAG, "Error getting persisted notifications", e);
        });
    } else if (action.type === ProfilesActionType.SELECT_PROFILE_SUCCESS) {
      // Restore local notifications
      if (action.profile && action.profile.isMaster && action.user) {
        storage
          .getItem(`blacknut:user:${action.user.id}.notifications` as StorageKey)
          .then((savedNotifications: RemoteNotification[] | null) => {
            setRemoteNotifications(
              action.user!,
              (savedNotifications || []).map((n) => new RemoteNotificationImpl(n)),
            )(store.dispatch);
          })
          .catch((e) => {
            logE(TAG, "Error getting persisted notifications", e);
          });
      }

      storeUserToken(
        storage,
        action.profile?.isPinLocked ? undefined : store.getState().globalState.userToken,
      );
    } else if (action.type === ProfilesActionType.SELECT_THEME) {
      storage.getItem(StorageKey.PROFILES_DATA).then((data) => {
        if (store.getState().profilesState.profile) {
          data = data || {};

          const profileData = data[action.profile.id] || {};
          profileData.theme = action.theme;
          data[action.profile.id] = profileData;
          storage
            .setItem(StorageKey.PROFILES_DATA, data)
            .then(() => {
              logD(TAG, "Prefs saved");
            })
            .catch((e) => {
              logE(TAG, "Error persisting prefs", e);
            });
        }
      });
    } else if (action.type === ProfilesActionType.ACKNOWLEGE_GAMEPAD_ONLY) {
      storage.getItem(StorageKey.PROFILES_DATA).then((data) => {
        if (store.getState().profilesState.profile) {
          data = data || {};

          const profileData = data[action.profile.id] || {};
          profileData.acknowledgedGamepadGames =
            profileData.acknowledgedGamepadGames || [];
          profileData.acknowledgedGamepadGames.push(action.game.id);
          data[action.profile.id] = profileData;
          storage
            .setItem(StorageKey.PROFILES_DATA, data)
            .then(() => {
              logD(TAG, "Prefs saved");
            })
            .catch((e) => {
              logE(TAG, "Error persisting prefs", e);
            });
        }
      });
    } else if (action.type === ProfilesActionType.DELETE_PROFILE_SUCCESS) {
      storage.getItem(StorageKey.PROFILES_DATA).then((data) => {
        if (data && action.id in data) {
          delete data[action.id];
          storage
            .setItem(StorageKey.PROFILES_DATA, data)
            .then(() => {
              logD(TAG, "Prefs saved");
            })
            .catch((e) => {
              logE(TAG, "Error persisting prefs", e);
            });
        }
      });
    } else if (action.type === ProfilesActionType.SAVE_PROFILE_SUCCESS) {
      if (
        action.profile &&
        action.profile?.id === store.getState().profilesState.profile?.id &&
        action.profile.isPinLocked
      ) {
        storeUserToken(storage, undefined);
      } else if (
        action.profile &&
        action.profile?.id === store.getState().profilesState.profile?.id &&
        !action.profile.isPinLocked
      ) {
        storeUserToken(storage, store.getState().globalState.userToken);
      }
    }

    next(action);
  };

const configureStore = (storage: IStorageService, defaultSettings: DefaultSettings) => {
  const middlewares: Middleware[] = [
    thunkMiddleware,
    customMiddleWare(storage, defaultSettings),
  ];
  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  apiService.endpoint = defaultSettings.apiPath;

  apiService.onTokenRenewed().subscribe((res) => {
    if (res.type === "user") {
      setUserToken(res.token, true)(store.dispatch);
    } else {
      setFamilyToken(res.token, true)(store.dispatch);
    }
  });

  return store;
};

export default configureStore;
export { configureStore };
