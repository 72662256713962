import {
  ApiError,
  BillingInfo,
  getCurrentSubscription,
} from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { concatMap } from "rxjs";
import { UserAction, UserActionTypes, fetchSubscriptionSuccess } from "../User";
import { amazonSubscribeUpdate as apiAmazonSubscribeUpdate } from "@blacknut/javascript-sdk/dist/api/User/Action/amazonSubscribeUpdate";

const userAmazonUpdateRequest = (): UserAction => ({
  type: UserActionTypes.USER_AMAZON_UPDATE_REQUEST,
});
const userAmazonUpdateSuccess = (billingInfo: BillingInfo): UserAction => ({
  type: UserActionTypes.USER_AMAZON_UPDATE_SUCCESS,
  billingInfo,
});
const userAmazonUpdateError = (error?: ApiError): UserAction => ({
  type: UserActionTypes.USER_AMAZON_UPDATE_ERROR,
  error,
});

export const dismissAmazonSubscribeUpdateError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(userAmazonUpdateError());
  };
};
export const amazonSubscribeUpdate = (purchaseResponseJson: any) => {
  return (dispatch: Dispatch<UserAction>) => {
    return new Promise<BillingInfo>((resolve, reject) => {
      dispatch(userAmazonUpdateRequest());

      apiAmazonSubscribeUpdate(purchaseResponseJson)
        .pipe(concatMap(() => getCurrentSubscription()))
        .subscribe({
          next: (res) => {
            dispatch(userAmazonUpdateSuccess(res));
            dispatch(fetchSubscriptionSuccess(res));
            resolve(res);
          },
          error: (error) => {
            dispatch(userAmazonUpdateError(error));
            reject(error);
          },
        });
    });
  };
};
