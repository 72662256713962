import { ListRendererFn } from "./ListRenderer";
import React from "react";
import GridView from "../componentView/GridView";

const FullRenderer: ListRendererFn = ({ list, onFocus, leaveFor, page }) => {
  return (
    <GridView
      list={list}
      key={list.uuid}
      leaveFor={leaveFor}
      onFocus={onFocus}
      page={page}
    />
  );
};
export { FullRenderer };
