import { logD } from "@blacknut/logging/dist";
import { AppLayout, State } from "core";
import debounce from "lodash/debounce";
import React, {
  PropsWithChildren,
  RefObject,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useOrientation } from "../OrientationContext";
import { LOGGING_TAG } from "../Utils";
import styles from "./styles.module.scss";
import { getNumColumns } from "src/theme/dimens";

/**
 * Pixel perfect tile width
 */

export const TileWidthContext = React.createContext<{
  standardTileSize: { width: number; height: number };
  featuredTileSize: { width: number; height: number };
  featured16By9TileSize: { width: number; height: number };
  bonusGameTileSize: { width: number; height: number };
  leMagTileSize: { width: number; height: number };
}>({
  standardTileSize: { width: 0, height: 0 },
  featuredTileSize: { width: 0, height: 0 },
  featured16By9TileSize: { width: 0, height: 0 },
  bonusGameTileSize: { width: 0, height: 0 },
  leMagTileSize: { width: 0, height: 0 },
});

const standardTileRef: RefObject<HTMLDivElement> = React.createRef();
const featuredTileRef: RefObject<HTMLDivElement> = React.createRef();
const bonusGameTileRef: RefObject<HTMLDivElement> = React.createRef();
const leMagTileRef: RefObject<HTMLDivElement> = React.createRef();
const featured16By9TileRef: RefObject<HTMLDivElement> = React.createRef();

export const TileWidthContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [standardTileSize, setStandardTileSize] = useState({
    width: 0,
    height: 0,
  });
  const [featuredTileSize, setFeaturedTileSize] = useState({
    width: 0,
    height: 0,
  });
  const [featured16By9TileSize, setFeatured16By9TileSize] = useState({
    width: 0,
    height: 0,
  });
  const [bonusGameTileSize, setBonusGameTileSize] = useState({
    width: 0,
    height: 0,
  });
  const [leMagTileSize, setLeMagTileSize] = useState({
    width: 0,
    height: 0,
  });
  const { layout } = useSelector((state: State) => state.globalState);
  const { orientation } = useOrientation();
  const [width, setWindowWidth] = useState(window.innerWidth);

  const nbCols = getNumColumns(layout, orientation);
  useEffect(() => {
    document.documentElement.style.setProperty("--nb-cols", `${nbCols}`);
    document.documentElement.style.setProperty(
      "--nb-cols-16By9",
      layout === AppLayout.PHONE ? `2` : `4`,
    );
    document.documentElement.style.setProperty(
      "--nb-cols-bonus",
      layout === AppLayout.PHONE ? `2` : `5`,
    );
    document.documentElement.style.setProperty(
      "--nb-cols-leMag",
      layout === AppLayout.PHONE ? `2` : `4`,
    );
    if (standardTileRef.current) {
      const rect = standardTileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        //Fix https://blacknut.atlassian.net/browse/CA-2662
        logD(LOGGING_TAG, "setStandardTileSize %o", rect.width);
        setStandardTileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
    if (featuredTileRef.current) {
      const rect = featuredTileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        //Fix https://blacknut.atlassian.net/browse/CA-2662
        logD(LOGGING_TAG, "setStandardTileSize %o", rect.width);
        setFeaturedTileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
    if (featured16By9TileRef.current) {
      const rect = featured16By9TileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        setFeatured16By9TileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
    if (bonusGameTileRef.current) {
      const rect = bonusGameTileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        //Fix https://blacknut.atlassian.net/browse/CA-2662
        logD(LOGGING_TAG, "setBonusGameTileSize %o", rect.width);
        setBonusGameTileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
    if (leMagTileRef.current) {
      const rect = leMagTileRef.current.getBoundingClientRect();
      if (rect.width != 0) {
        logD(LOGGING_TAG, "setLeMagTileize %o", rect.width);
        setLeMagTileSize({
          width: Math.floor(rect.width),
          height: Math.floor(rect.height),
        });
      }
    }
  }, [orientation, nbCols, width, layout]);

  const resizeListener = debounce(() => {
    setWindowWidth(window.innerWidth);
  }, 200);
  window.addEventListener("resize", resizeListener);
  return (
    <TileWidthContext.Provider
      value={{
        standardTileSize,
        featuredTileSize,
        bonusGameTileSize,
        leMagTileSize,
        featured16By9TileSize,
      }}
    >
      {
        <>
          <div ref={standardTileRef} className={styles.stdTile} />
          <div ref={featuredTileRef} className={styles.featured} />
          <div ref={bonusGameTileRef} className={styles.bonus} />
          <div ref={leMagTileRef} className={styles.leMag} />
          <div ref={featured16By9TileRef} className={styles.featured16By9} />
        </>
      }
      {standardTileSize.width > 0 &&
        featuredTileSize.width > 0 &&
        bonusGameTileSize.width > 0 &&
        leMagTileSize.width > 0 &&
        featured16By9TileSize.width > 0 &&
        children}
    </TileWidthContext.Provider>
  );
};

export const useTileSize = () => useContext(TileWidthContext);
