import { ListRendererFn } from "./ListRenderer";
import React, { useCallback } from "react";
import clsx from "clsx";
import styles from "../styles.module.scss";
import { useProfiler } from "src/hooks/useProfiler";
import { useInView } from "react-intersection-observer";
import HorizontalTileList, {
  RenderTileFn,
} from "src/components/List/HorizontalTileList/HorizontalTileList";
import TileLeMag from "src/components/TileLeMag";
/******
 * Marketing list
 * Desktop: List of 4 tiles
 * Phone:
 *  Portrait: Scrollable List of n tiles with 2 fully visible and 1 partialy visible
 *  Landscape: Scrollable List of n tiles  with 2 fully visible and 1 partialy visible
 * Tablet:
 *  Portrait: Scrollable List of n tiles with 4 fully visible and 1 partialy visible
 *  Landscape: Scrollable List of n tiles  with 3 fully visible and 1 partialy visible
 */
const MarketingRenderer: ListRendererFn = ({
  list,
  leaveFor,
  scrollable,
  onFocus,
  config,
  page,
}) => {
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  const Root = useProfiler("flatList", { uuid: list.uuid });

  const renderTile: RenderTileFn = useCallback(
    (t) => {
      return <TileLeMag item={t} list={list} />;
    },
    [list],
  );
  if (config?.features?.marketing === false) return null;

  return (
    <Root>
      <div
        key={list.uuid}
        ref={tileRef}
        className={clsx(styles.flat, scrollable && styles.scrollable)}
      >
        <HorizontalTileList
          list={list}
          key={list.uuid}
          leaveFor={leaveFor}
          inView={inView}
          onFocus={onFocus}
          scrollable={scrollable}
          renderTile={renderTile}
          page={page}
        />
      </div>
    </Root>
  );
};
export { MarketingRenderer };
