import { ApiError, Game } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { forkJoin } from "rxjs";
import { GameAction, GameActionTypes } from "./Game";
import { isGameFavorite } from "@blacknut/javascript-sdk/dist/api/isGameFavorite";
import { getGame as apiGetGame } from "@blacknut/javascript-sdk/dist/api/getGame";
const fetchGameRequest = (id: string): GameAction => ({
  type: GameActionTypes.GAME_FETCH_REQUEST,
  id,
});

export const fetchGameSuccess = (
  id: string,
  game: Game,
  isFavorite: boolean,
): GameAction => ({
  type: GameActionTypes.GAME_FETCH_SUCCESS,
  game,
  isFavorite,
});

const fetchGameError = (id: string, error?: ApiError): GameAction => ({
  type: GameActionTypes.GAME_FETCH_ERROR,
  id,
  error,
});

export const dismissGameError = (id: string) => {
  return (dispatch: Dispatch<GameAction>) => {
    dispatch(fetchGameError(id));
  };
};

export const fetchGame = (id: string) => {
  return (dispatch: Dispatch<GameAction>) => {
    return new Promise<{ game: Game; favorite: boolean }>((resolve, reject) => {
      dispatch(fetchGameRequest(id));
      forkJoin({ game: apiGetGame(id), favorite: isGameFavorite(id) }).subscribe({
        next: (res) => {
          dispatch(fetchGameSuccess(id, res.game, res.favorite));
          resolve(res);
        },
        error: (error) => {
          dispatch(fetchGameError(id, error));
          reject(error);
        },
      });
    });
  };
};
