import { List, Page } from "@blacknut/javascript-sdk/dist";
import { AppLayout, State } from "core";
import {
  Focusable,
  FocusableSection,
  FocusableSectionProps,
} from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import * as React from "react";
import { useSelector } from "react-redux";
import { ReactComponent as Arrow } from "../../../assets/dist/ic_arrow.svg";
import dimens, { FLAT_FEATURE_TILE_WEIGHT, getNumColumns } from "../../../theme/dimens";
import { OrientationType, useOrientation } from "../../../utils/OrientationContext";
import { tile2Id } from "../../../utils/Utils";
import Tile from "../../Tile/Tile";
import ArrowButton from "../ArrowButton";
import styles from "./styles.module.scss";
import { useProfiler } from "../../../hooks/useProfiler";
import { useMoreLink } from "src/utils/useMoreLink";

interface TileListProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  showTitle?: boolean;
  list: List;
  className?: string;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  scrollable?: boolean;
  onGenre?: boolean;
  page?: Page;
}

const arrange = (
  list: List,
  layout: AppLayout,
  orientation: OrientationType,
  scrollable: boolean,
) => {
  if (scrollable) return list.tiles;
  const nbColsFeatured =
    FLAT_FEATURE_TILE_WEIGHT *
      (getNumColumns(layout, orientation) - FLAT_FEATURE_TILE_WEIGHT) +
    1;

  console.log("xxx", nbColsFeatured);

  return list.tiles;
};
const FeaturedTileList = ({
  showTitle = true,
  list,
  className,
  leaveFor,
  disabled,
  onFocus,
  scrollable = false,
  page,
}: TileListProps) => {
  const { layout } = useSelector((state: State) => state.globalState);
  const { handleMoreLink } = useMoreLink(list.moreLink);
  const { orientation } = useOrientation();
  const tiles = React.useMemo(
    () => arrange(list, layout, orientation, scrollable),
    [list, layout, orientation, scrollable],
  );

  const hasTitle = showTitle && list.title && list.title.trim().length > 0;

  const Root = useProfiler("featuredList");
  return (
    <Root>
      <FocusableSection
        focusKey={list.uuid}
        className={clsx(styles.container, scrollable && styles.scrollable, className)}
        leaveFor={leaveFor}
        disabled={disabled}
        defaultElement={{ down: "first", up: ".tile" }}
        config={{ restrict: "self-first" }}
        onFocus={onFocus}
      >
        {(hasTitle || (!!list.moreLink && layout === AppLayout.PHONE)) && (
          <div className={clsx(styles.titleContainer, scrollable && styles.scrollable)}>
            {hasTitle && (
              <Focusable
                className={clsx(styles.title, !!list.moreLink && styles.clickable)}
                onPressEnter={handleMoreLink}
                onClick={handleMoreLink}
                disabled={
                  !list.moreLink || window.innerWidth >= dimens.breakpoints.desktop
                }
              >
                {list.title} <Arrow />
              </Focusable>
            )}
          </div>
        )}
        <div className={styles.content}>
          <ul className={clsx(styles.list, scrollable && styles.scrollable)}>
            {tiles.map((e, index) => (
              <li
                className={clsx(styles.tile, index === 0 && styles.featured)}
                key={`${list.uuid}-${tile2Id(e)}`}
              >
                <Tile item={e} list={list} featured={index === 0} page={page} />
              </li>
            ))}
          </ul>

          {list.moreLink && (
            <ArrowButton
              onPress={handleMoreLink}
              className={clsx([styles.arrowButton, styles.arrow])}
            />
          )}
        </div>
      </FocusableSection>
    </Root>
  );
};

export default FeaturedTileList;
