export enum StorageKey {
  TOKEN = "blacknut:user:token",
  VERSION = "blacknut:version",
  PLATFORM = "blacknut:platform",
  STATS_4_NERDS = "blacknut:stats4Nerds",
  API_VERSION = "blacknut:apiVersion",
  AUDID = "blacknut:audid",
  UPDATE_AVAILABLE = "blacknut:UpdateAvailable",
  UDP_OPTION = "blacknut:udpOptions",
  INCUBATING_FEATURES = "blacknut:incubating",
  RTP_DELAY = "blacknut:rtpDelay",
  WS_PLATFORM = "blacknut:wsplatform",
  PROFILES_DATA = "blacknut:profilesData",
  SHOW_FPS = "blacknut:showFps",
  PRESHIPPING_ID = "blacknut:preshippingId",
  DEVICE_ID = "blacknut:deviceId",
  SUBSCRIBED = "blacknut:subscribed",
  LOCALE = "blacknut:locale",
  SPEED_TEST_RESULT = "blacknut:speedtest",
  START_URL = "blacknut:startUrl",
  INSTALL_NOTIF = "blacknut:installNotif",
  WELCOME_NOTIF = "blacknut:welcomeNotif",
  ALLOW_PUSH_NOTIF = "blacknut:allowPushNotif",
  WINDOWED = "blacknut:windowed",
  TV_LEGACY = "blacknut:TVLegacy",
  STREAM_MUTED = "blacknut:streamMute",
  PERFORMANCE_MONITORING = "blacknut:performanceMonitoring",
  THEME_BW = "blacknut:theme",
  WARMUP_VIDEO = "blacknut:warmupVideo",
}

/** @deprecated */
export enum DeprecatedStorageKey {
  TOKEN = "blacknut:user:tokenFamily",
  GSTREAMER_OPTION = "blacknut:gStreamerOption",
  GSTREAMER_AUDIO_PORT = "blacknut:gStreamerAudioPort",
  GSTREAMER_LOG_FORMAT = "blacknut:gStreamerLogFormat",
  DEBUG = "blacknut:debug",
  PLAYER_DEBUG = "blacknut:player:debug",
  PLAYER_INPUT_METHOD = "blacknut:playerInputMethod",
  PROTOCOL = "blacknut:protocol",
}

export interface IStorageService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem(key: StorageKey | DeprecatedStorageKey): Promise<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setItem(key: StorageKey | DeprecatedStorageKey, value: unknown): Promise<any>;
  removeItem(key: StorageKey | DeprecatedStorageKey): Promise<void>;
}
