import { Dispatch } from "redux";
import { PageAction, PageActionTypes } from "./Page";
import {
  ApiError,
  getPageGamesByCategory as apiGetPageGamesByCategory,
  Page,
} from "@blacknut/javascript-sdk/dist";

const fetchPageCategoryRequest = (category: string, id: string): PageAction => ({
  type: PageActionTypes.PAGE_FETCH_CATEGORY_REQUEST,
  category,
  id,
});

export const fetchPageCategorySuccess = (
  category: string,
  id: string,
  page: Page,
): PageAction => ({
  type: PageActionTypes.PAGE_FETCH_CATEGORY_SUCCESS,
  category,
  id,
  page,
});

const fetchPageCategoryError = (
  category: string,
  id: string,
  error?: ApiError,
): PageAction => ({
  type: PageActionTypes.PAGE_FETCH_CATEGORY_ERROR,
  category,
  id,
  error,
});

export const fetchPageByCategory = (category: string, id: string) => {
  return (dispatch: Dispatch<PageAction>) => {
    return new Promise<Page>((resolve, reject) => {
      dispatch(fetchPageCategoryRequest(category, id));
      apiGetPageGamesByCategory(category, id).subscribe({
        next: (page) => {
          dispatch(fetchPageCategorySuccess(category, id, page));
          resolve(page);
        },
        error: (error) => {
          dispatch(fetchPageCategoryError(category, id, error));
          reject(error);
        },
      });
    });
  };
};
