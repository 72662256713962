import React from "react";
import { HeaderStyle } from "../../theme/Theme";
import WebHeader from "./WebHeader";

interface HeaderProps {
  title?: string;
  headerRight?: JSX.Element;
  headerLeft?: JSX.Element;
  headerStyle?: Partial<HeaderStyle>;
  className?: string;
  /**@deprecated */
  classname?: string;
  hidden?: boolean;
  setHeaderStyle?: (style: Partial<HeaderStyle> | undefined) => void;
}

const Header = (props: HeaderProps) => {
  return <WebHeader {...props} />;
};
export default Header;
