import { TAG } from "@blacknut/javascript-sdk/dist/api/ApiService";
import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useStore } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { GameGenre, SearchFormData } from "@blacknut/javascript-sdk/dist";
import { dismissGenresLoadError, getGameGenres } from "../../redux/actions/App";
import {
  dismissSearchError,
  paginateSearchResults,
  search,
} from "../../redux/actions/Search";
import { State } from "../../redux/reducers";

const sortGenresIfNeeded = (genres: GameGenre[] | undefined, sortGenres?: boolean) => {
  if (!sortGenres || !genres) return genres;
  return genres.sort((a, b) => {
    return a.title.localeCompare(b.title);
  });
};
export const useSearch = (props: { limit?: number; sortGenres?: boolean }) => {
  const { limit, sortGenres } = props;
  const dispatch = useDispatch();
  const { inProgress, error, paginating, query, results } = useSelector(
    (state: State) => state.searchState,
  );
  const {
    genres,
    error: genresLoadingError,
    loading: genresLoading,
  } = useSelector((state: State) => state.globalState.genres);
  const mounted = React.useRef(false);
  React.useEffect(() => {
    const init = async () => {
      if (
        (!genres || genres.length === 0) &&
        !genresLoading &&
        !genresLoadingError &&
        !mounted.current
      ) {
        mounted.current = true;
        try {
          await getGameGenres()(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting genres: %o", e);
        }
      }
    };
    init();
  }, [dispatch, genres, genresLoading, genresLoadingError]);
  const _sortedGenres = React.useMemo(
    () => sortGenresIfNeeded(genres, sortGenres),
    [genres, sortGenres],
  );
  const store = useStore<State>();
  return {
    inProgress,
    error,
    paginating,
    query,
    results,
    genres: _sortedGenres,
    genresLoadingError,
    genresLoading,
    dismissSearchError: React.useCallback(
      () => dismissSearchError()(dispatch),
      [dispatch],
    ),
    dismissGenresLoadingError: React.useCallback(
      () => dismissGenresLoadError()(dispatch),
      [dispatch],
    ),
    search: React.useCallback(
      async (data: SearchFormData) => {
        try {
          await search(data, limit)(dispatch, store.getState);
        } catch (e) {
          if (e !== "canceled") {
            logE(TAG, "Caught error on search");
          }
          throw e;
        }
      },
      [dispatch, store, limit],
    ),
    paginate: React.useCallback(async () => {
      if (query && results) {
        try {
          await paginateSearchResults(query, results, limit)(dispatch);
        } catch (e) {
          if (e !== "canceled") {
            logE(TAG, "Caught error on search");
          }
        }
      }
    }, [query, results, limit, dispatch]),
  };
};
