import BannerView from "../componentView/BannerView";
import { ListRendererFn } from "./ListRenderer";
import React from "react";
const BannerRenderer: ListRendererFn = ({ onFocus, leaveFor, list, page }) => {
  return (
    <BannerView
      list={list}
      key={list.uuid}
      leaveFor={leaveFor}
      onFocus={onFocus}
      page={page}
    />
  );
};
export default BannerRenderer;
