import { List, getList as apiGetList } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { ListAction, ListActionTypes } from "./List";

export const paginateList = (pageId: string, list: List, limit = 6) => {
  console.trace("heir");
  return (dispatch: Dispatch<ListAction>) => {
    return new Promise<List>((resolve, reject) => {
      dispatch({ type: ListActionTypes.LIST_PAGINATION_REQUEST, id: list.uuid });
      apiGetList(pageId, list.uuid, list.tiles.length, limit).subscribe({
        next: (res) => {
          const newList = {
            ...list,
            tiles: [...list.tiles, ...res.tiles],
          };
          dispatch({
            type: ListActionTypes.LIST_PAGINATION_SUCCESS,
            id: list.uuid,
            list: newList,
          });
          resolve(newList);
        },
        error: (error) => {
          dispatch({
            type: ListActionTypes.LIST_PAGINATION_ERROR,
            error,
            id: list.uuid,
          });
          reject(error);
        },
      });
    });
  };
};
