import { User } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { concatMap, forkJoin } from "rxjs";
import { Action as AppAction } from "../App";
import { loadFamilyInfoSuccess, ProfilesAction, setCurrentProfile } from "../Profiles";
import { onUserTokenRetrieved, setFamilyToken, UserAction } from "../User";
import { M1Action, M1ActionTypes } from "./M1";
import { m1GenerateToken as apiM1GenerateToken } from "@blacknut/javascript-sdk/dist/api/User/M1/m1GenerateToken";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";

export const m1DismissGenerateTokenError = () => (dispatch: Dispatch<M1Action>) =>
  dispatch({ type: M1ActionTypes.M1_GENERATE_TOKEN_ERROR });

export const m1GenerateToken = () => {
  return (dispatch: Dispatch<M1Action | UserAction | AppAction | ProfilesAction>) => {
    return new Promise<User>((resolve, reject) => {
      dispatch({ type: M1ActionTypes.M1_GENERATE_TOKEN_REQUEST });
      apiM1GenerateToken()
        .pipe(
          concatMap((token) => {
            setFamilyToken(token.familyToken)(dispatch);
            return forkJoin({
              family: getFamilyInfo(),
              data: onUserTokenRetrieved(dispatch, token.userToken!, true, false),
            });
          }),
        )
        .subscribe({
          next: (res) => {
            const user = res.data[0];
            dispatch(
              loadFamilyInfoSuccess(res.family.profiles, res.family.subscription),
            );
            dispatch(setCurrentProfile(res.family.profiles[0], user, false));
            dispatch({ type: M1ActionTypes.M1_GENERATE_TOKEN_SUCCESS, user });
            resolve(res.data[0]);
          },
          error: (error) => {
            dispatch({ type: M1ActionTypes.M1_GENERATE_TOKEN_ERROR, error });
            reject(error);
          },
        });
    });
  };
};
