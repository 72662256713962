import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { useEffect } from "react";
import { useHistory } from "react-router";

const useSpatialNavigationDisabler = () => {
  const history = useHistory();
  const { pause: pauseSpatialNav } = useSpatialNavigation();

  useEffect(() => {
    const unreg2 = history.block((targetLocation) => {
      if (history.location.pathname !== targetLocation.pathname) pauseSpatialNav();
    });

    return () => {
      unreg2();
    };
  }, [history, pauseSpatialNav]);
};
export { useSpatialNavigationDisabler };
