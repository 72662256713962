import { Dispatch } from "redux";
import { PageAction, PageActionTypes } from "./Page";
import {
  ApiError,
  getPageGamesByCategory as apiGetPageGamesByCategory,
  Page,
} from "@blacknut/javascript-sdk/dist";

const paginatePageGamesByCategoryRequest = (id: string): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_REQUEST,
  id,
});
const paginatePageGamesByCategorySuccess = (id: string, page: Page): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_SUCCESS,
  id,
  page,
});

const paginatePageGamesByCategoryError = (id: string, error?: ApiError): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_ERROR,
  id,
  error,
});

export const paginatePageGamesByCategory = (
  category: string,
  id: string,
  page: Page,
) => {
  return (dispatch: Dispatch<PageAction>) => {
    return new Promise<Page>((resolve, reject) => {
      dispatch(paginatePageGamesByCategoryRequest(id));

      apiGetPageGamesByCategory(id, category).subscribe({
        next: (newPage) => {
          let newList = page.lists || [];
          newList = (page.lists || []).concat(newPage.lists!);
          dispatch(
            paginatePageGamesByCategorySuccess(id, {
              ...page,
              lists: newList,
            }),
          );

          resolve(page);
        },
        error: (error) => {
          dispatch(paginatePageGamesByCategoryError(id, error));
          reject(error);
        },
      });
    });
  };
};
