import { Dispatch } from "redux";
import { logout } from "../User";
import { ActionTypes, Action } from "./App";
import { UserAction } from "../User";
import { ProfilesAction } from "../Profiles";
export const setStartUrl = (startUrl: string) => {
  return (dispatch: Dispatch<Action | UserAction | ProfilesAction>) => {
    logout()(dispatch);
    dispatch({
      type: ActionTypes.SET_START_URL,
      startUrl: startUrl,
    });
  };
};
