import { createDarkTheme } from "../default/Dark";

const AisTheme = createDarkTheme({ colorAccent: "#B2D234" });
AisTheme.default.logoSize = { width: 15.7, height: 2.4 };

AisTheme.default.bottomBarStyle = {
  ...AisTheme.default.bottomBarStyle,
  activeFocusedTintColor: "#2D2D2D",
};

AisTheme.default.primaryButton = {
  ...AisTheme.default.primaryButton,
  activeTextColor: "#2D2D2D",
  inactiveTextColor: "#2D2D2D",
  hoverBackgroundColor: "#92AF20",
};

export default AisTheme;
