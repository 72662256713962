import { setAndroidToken } from "@blacknut/javascript-sdk/dist/api/setAndroidToken";
import { logD, logE } from "@blacknut/logging/dist";
import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";

const TAG = "Android";
export const setDeviceId = (deviceId?: string, sendToBackend = true) => {
  return (dispatch: Dispatch<Action>) => {
    if (sendToBackend && deviceId) {
      setAndroidToken(deviceId).subscribe({
        next: () => {
          logD(TAG, "Device token sent");
        },
        error: (error) => {
          logE(TAG, "Error sending token", error);
        },
      });
    }
    dispatch({
      type: ActionTypes.SET_DEVICE_ID,
      deviceId,
    });
  };
};
