import { ApiError, DreiConfig } from "@blacknut/javascript-sdk/dist";
import { Reducer } from "redux";
import { DreiAction, DreiActionTypes } from "../actions/Drei";
export interface State {
  getConfig: {
    inProgress: boolean;
    config?: DreiConfig;
    error?: ApiError;
  };
}
const initialState: State = {
  getConfig: { inProgress: false },
};

export const reducer: Reducer<State> = (state = initialState, action: DreiAction) => {
  switch (action.type) {
    case DreiActionTypes.DREI_GET_CONFIG_REQUEST: {
      return { ...state, getConfig: { inProgress: true } };
    }
    case DreiActionTypes.DREI_GET_CONFIG_SUCCESS: {
      return {
        ...state,
        getConfig: { inProgress: false, config: action.config },
      };
    }
    case DreiActionTypes.DREI_GET_CONFIG_ERROR: {
      return { ...state, getConfig: { inProgress: false, error: action.error } };
    }

    default:
      return state;
  }
};
