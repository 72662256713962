import { logD } from "@blacknut/logging/dist";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { useInView } from "react-intersection-observer";
import { useTheme } from "../../../../theme/ThemeProvider";
import { LOGGING_TAG } from "../../../../utils/Utils";
import HorizontalTileList from "../../../List/HorizontalTileList";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { useProfiler } from "../../../../hooks/useProfiler";
import { List, Page, getImageByThemeName } from "@blacknut/javascript-sdk/dist";

interface BrandedViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  leaveFor?: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  scrollable?: boolean;
  className?: string;
  page?: Page;
}

const BrandedView = ({
  list,
  leaveFor,
  onFocus,
  scrollable,
  className,
  page,
}: BrandedViewProps) => {
  logD(LOGGING_TAG, "BrandedView");
  const { theme } = useTheme();
  const [brandedContainerRef, inView] = useInView({
    threshold: 0,
  });

  let sectionTitleColor: string | undefined;

  // Get the first image
  const backgroundImage = getImageByThemeName(theme.name, list.imgBackground ?? []);
  const logo = getImageByThemeName(theme.name, list.imgLogo ?? []);

  if (backgroundImage) {
    if (backgroundImage.brandedOption === "brandedDark") {
      sectionTitleColor = "#191414";
    } else if (backgroundImage.brandedOption === "brandedLight") {
      sectionTitleColor = "#fff";
    }
  }
  const style = backgroundImage
    ? { backgroundImage: `url(${backgroundImage.url})` }
    : undefined;
  const Root = useProfiler("brandedList", { uuid: list.uuid });
  return (
    <Root>
      <div
        ref={brandedContainerRef}
        style={inView ? style : undefined}
        key={list.uuid}
        className={clsx(styles.container, logo && styles.hasLogo, className)}
      >
        {inView && logo && (
          <img className={styles.logo} src={logo.urlMini || logo.url} />
        )}
        <HorizontalTileList
          list={list}
          sectionTitleColor={sectionTitleColor}
          leaveFor={leaveFor}
          inView={inView}
          onFocus={onFocus}
          className={clsx(
            styles.brandedViewList,
            scrollable && styles.scrollable,
            list.moreLink && styles.moreLink,
          )}
          arrowClassName={styles.arrow}
          isBranded={true}
          scrollable={scrollable}
          page={page}
        />
      </div>
    </Root>
  );
};

export default BrandedView;
