import { logError } from "@blacknut/logging/dist";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Subscription } from "rxjs";
import { autologin, onUserRemoteRegistrationSuccess } from "../../redux/actions/User";
import { State } from "../../redux/reducers";
import legacyService, {
  MediationServerService,
  MessageKind,
} from "../../services/MediationServerService";
const mediationServerService = new MediationServerService();
export const useWebSocketSignin = () => {
  const dispatch = useDispatch();
  const { audid, loginWebSocketUrl, user, accountUrl, sessionId } = useSelector(
    (state: State) => state.globalState,
  );
  mediationServerService.sessionId = sessionId;
  const { profiles } = useSelector((state: State) => state.profilesState.familyProfiles);
  const [websocketIsConnecting, setWebsocketIsConnecting] = useState(false);
  const [websocketError, setWebsocketError] = useState<string | undefined>();
  const subscriptionsRemoteReady = useRef<Subscription | null>(null);
  const subscriptionsRemoteSuccess = useRef<Subscription | null>(null);

  useEffect(() => {
    if (audid && loginWebSocketUrl) {
      mediationServerService.url = loginWebSocketUrl;
      mediationServerService.audid = audid;
      mediationServerService.lang = legacyService.lang;
      mediationServerService.userAgentString = legacyService.userAgentString;
      mediationServerService.init();
    }
  }, [audid, loginWebSocketUrl]);

  const [shouldRestart, setShouldRestart] = useState<number>();

  const listen = useCallback(() => {
    subscriptionsRemoteReady.current = mediationServerService.onReady().subscribe(
      () => {
        if (subscriptionsRemoteReady.current) {
          subscriptionsRemoteReady.current.unsubscribe();
          subscriptionsRemoteReady.current = null;
        }

        setWebsocketIsConnecting(false);

        subscriptionsRemoteSuccess.current = mediationServerService
          .onMessage()
          .subscribe((res) => {
            if (res.kind === MessageKind.REQ_ACCOUNT_SUBSCRIBED) {
              if (res.payload.userToken) {
                onUserRemoteRegistrationSuccess(res.payload)(dispatch);
              } else {
                // family with multiple profiles has no userToken
                autologin(res.payload)(dispatch);
              }
              mediationServerService.send(MessageKind.RESP_ACCOUNT_SUBSCRIBED);
              mediationServerService.stop();
            }
          });
        setShouldRestart(undefined);
      },
      (err) => {
        logError("Caught error onReady", err);
        mediationServerService.stop();
        setWebsocketError(err);
        setWebsocketIsConnecting(false);
        setShouldRestart(undefined);
      },
    );
  }, [dispatch]);

  const restartIfStopped = useCallback(() => {
    if (!mediationServerService.isAvailable()) {
      listen();
      mediationServerService.init();
    }
  }, [listen]);

  useEffect(() => {
    return () => {
      if (subscriptionsRemoteReady.current) {
        subscriptionsRemoteReady.current.unsubscribe();
        subscriptionsRemoteReady.current = null;
      }
      if (subscriptionsRemoteSuccess.current) {
        subscriptionsRemoteSuccess.current.unsubscribe();
        subscriptionsRemoteSuccess.current = null;
      }
      mediationServerService.stop();
    };
  }, []);

  useEffect(() => {
    listen();
  }, [dispatch, listen, shouldRestart]);

  return {
    connecting: websocketIsConnecting,
    error: websocketError,
    sessionId: mediationServerService.sessionId,
    user,
    profiles,
    accountUrl,
    mediationServerService,
    restartIfStopped,
  };
};
