import { UpdateFamilyProfileData, UserProfile } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { ProfilesAction, ProfilesActionTypes } from "./Profiles";
import { updateFamilyProfile } from "@blacknut/javascript-sdk/dist/api/Family/FamilyProfile/updateFamilyProfile";
export const dismissSaveProfileError = () => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({ type: ProfilesActionTypes.SAVE_PROFILE_ERROR });
  };
};

export const saveProfile = (data: UpdateFamilyProfileData) => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    return new Promise<UserProfile>((resolve, reject) => {
      dispatch({ type: ProfilesActionTypes.SAVE_PROFILE_REQUEST });
      updateFamilyProfile(data).subscribe({
        next: (res) => {
          dispatch({ type: ProfilesActionTypes.SAVE_PROFILE_SUCCESS, profile: res });
          resolve(res);
        },
        error: (error) => {
          dispatch({ type: ProfilesActionTypes.SAVE_PROFILE_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
