import { UserProfile } from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { setDeeplink } from "../../redux/actions/App";
import {
  dismissFamilyInfoLoadError,
  loadFamilyInfo,
  selectProfile,
} from "../../redux/actions/Profiles";
import { dismissAuthenticationError } from "../../redux/actions/User";
import { State } from "../../redux/reducers";
const TAG = "Profiles";

//FIXME distinguish between returnUrl&deeplinkUrl
//In case of returnUrl honor active streams
//In case of deeplink cancel active streams
export const useProfiles = (props?: { returnUrl?: string }) => {
  const location = useLocation<{ returnUrl?: string }>();
  const pReturnUrl = location.state?.returnUrl;
  const history = useHistory();
  const { familyProfiles, profile } = useSelector((state: State) => state.profilesState);
  const { authentication, familyToken, user } = useSelector(
    (state: State) => state.globalState,
  );
  const { error: authenticationError, inProgress: authenticating } = authentication;
  const { deeplink } = useSelector((state: State) => state.globalState);

  const dispatch = useDispatch();

  React.useEffect(() => {
    const init = async () => {
      if (!familyProfiles.profiles && familyToken) {
        try {
          await loadFamilyInfo()(dispatch);
        } catch (e) {
          logE(TAG, "Caught error loading profiles", e);
        }
      }
    };
    init();
  }, [dispatch, familyProfiles.profiles, familyToken]);

  const _onSelectProfile = React.useCallback(
    async (aProfile: UserProfile, redirectUrl?: string) => {
      const returnUrl = (redirectUrl ?? props?.returnUrl) || pReturnUrl;
      if (profile && profile.id === aProfile.id) {
        logD(TAG, "Selecting same profile");
        if (returnUrl) {
          history.push(returnUrl);
          if (deeplink) {
            setDeeplink()(dispatch);
          }
        } else {
          history.push("/catalog");
        }
      } else if (aProfile.isPinLocked) {
        history.push(`/profiles/${aProfile.id}/pin`, { returnUrl });
      } else {
        try {
          await selectProfile(aProfile, null, true)(dispatch);

          // User selected a profile
          if (returnUrl) {
            history.push(returnUrl);
            if (deeplink) {
              setDeeplink()(dispatch);
            }
          } else {
            history.push("/catalog");
          }
        } catch (e) {
          logE(TAG, "Caught error selecting profile", e);
        }
      }
    },
    [deeplink, dispatch, history, pReturnUrl, profile, props?.returnUrl],
  );

  return {
    profiles: familyProfiles.profiles,
    profile,
    loading: familyProfiles.inProgress,
    loadingError: familyProfiles.error,
    dismissLoadingError: React.useCallback(() => {
      dismissFamilyInfoLoadError()(dispatch);
    }, [dispatch]),
    dismissAuthenticationError: React.useCallback(() => {
      dismissAuthenticationError()(dispatch);
    }, [dispatch]),
    authenticating,
    authenticationError,
    user,
    onSelectProfile: _onSelectProfile,
  };
};
