import {
  createUserAgentString,
  Token,
  UserAgent,
  ApiError,
  TIMEOUT_ERROR,
  GENERIC_ERROR,
} from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import { Observable, Subject } from "rxjs";
import { timeout } from "rxjs/operators";
import { v1 as uuidv4 } from "uuid";
const TAG = "Mediation";
export enum MessageKind {
  REQ_HELLO = "helloReq",
  RESP_HELLO = "helloResp",
  REQ_CREATE_ACCOUNT = "createAccountReq",
  RESP_CREATE_ACCOUNT = "createAccountResp",
  REQ_ACCOUNT_SUBSCRIBED = "accountSubscribedReq",
  RESP_ACCOUNT_SUBSCRIBED = "accountSubscribedResp",
}
export type MediationServerMessage =
  | {
      kind: MessageKind.REQ_HELLO;
      payload: {
        lang: string;
        userAgent: string;
        audid: string;
        sessionId: string;
      };
    }
  | {
      kind: MessageKind.RESP_HELLO;
      payload: unknown;
      status: number;
      error?: ApiError;
    }
  | {
      kind: MessageKind.REQ_CREATE_ACCOUNT;
      payload: unknown;
    }
  | {
      kind: MessageKind.RESP_CREATE_ACCOUNT;
      payload: {
        shortCode: string;
        url: string;
      };
      status: number;
      error?: ApiError;
    }
  | {
      kind: MessageKind.REQ_ACCOUNT_SUBSCRIBED;
      payload: {
        familyToken: Token;
        userToken: Token;
      };
      status: number;
    };

export class MediationServerService {
  // tslint:disable-next-line:variable-name
  private _url = "";

  private _lang = "en-US";

  private _userAgentString: string | undefined;
  public get userAgentString(): string | undefined {
    return this._userAgentString;
  }
  public set userAgentString(v: string | undefined) {
    this._userAgentString = v;
  }

  private retry = false;
  private retryCount = 0;

  private retryTimeout: any = null;

  // tslint:disable-next-line:variable-name
  private _onMessageSubject = new Subject<MediationServerMessage>();

  // tslint:disable-next-line:variable-name
  private _onReadySubject = new Subject<void>();

  private _audid: string | undefined;
  public get audid(): string | undefined {
    return this._audid;
  }
  public set audid(v: string | undefined) {
    this._audid = v;
  }

  private ws: WebSocket | null = null;

  private _sessionId: string = uuidv4();
  public get sessionId(): string {
    return this._sessionId;
  }
  public set sessionId(v: string) {
    this._sessionId = v;
  }

  public set url(v: string) {
    logD(TAG, "Using URL %s", v);
    this._url = v;
  }

  public get url(): string {
    return this._url;
  }

  public set lang(v: string) {
    this._lang = v;
  }

  public get lang(): string {
    return this._lang;
  }

  public onMessage(): Observable<MediationServerMessage> {
    return this._onMessageSubject.asObservable();
  }

  public onReady(): Observable<void> {
    return this._onReadySubject.asObservable();
  }

  public init() {
    this.retry = true;
    this.retryCount = 0;
    this.connect(this._url);
  }

  public isAvailable() {
    return this.ws && this.ws.readyState === WebSocket.OPEN;
  }

  public userAgent(params: UserAgent) {
    this._userAgentString = createUserAgentString(params);
  }

  public stop() {
    logD(TAG, "stop");
    if (this.isAvailable()) {
      this.ws!.close(1000, "completed");

      /* Sometimes close callback is not called or called with on error code, so force it */
      this._onClose({ code: 1000 });
    }
  }

  public askForAccountCreationCode(): Observable<{
    shortCode: string;
    url: string;
  }> {
    return this.sendSync(
      MessageKind.REQ_CREATE_ACCOUNT,
      {},
      MessageKind.RESP_CREATE_ACCOUNT,
    ) as Observable<{
      shortCode: string;
      url: string;
    }>;
  }

  public send(kind: MessageKind, payload?: any) {
    if (this.isAvailable()) {
      logD(TAG, "Sending to ws %o", { kind, payload });
      this.ws!.send(JSON.stringify({ kind, payload }));
    } else {
      throw new Error("Socked closed");
    }
  }

  public sendSync(
    kind: MessageKind.REQ_HELLO | MessageKind.REQ_CREATE_ACCOUNT,
    payload: any,
    responseKind: MessageKind.RESP_CREATE_ACCOUNT | MessageKind.RESP_HELLO,
  ) {
    if (this.isAvailable()) {
      const res = new Subject<any>();
      const subscription = this.onMessage()
        .pipe(timeout(2000))
        .subscribe(
          (m) => {
            if (m.kind === responseKind) {
              subscription.unsubscribe();
              if (m.status === 200) {
                res.next(m.payload);
              } else {
                res.error(m.error);
              }
            }
          },
          (err) => {
            logE(TAG, "Caught err", err);
            subscription.unsubscribe();
            res.error({
              status: TIMEOUT_ERROR,
              code: TIMEOUT_ERROR,
              title: TIMEOUT_ERROR,
            });
          },
        );

      this.send(kind, payload);

      return res.asObservable();
    } else {
      throw new Error("Socked closed");
    }
  }

  private connect(url: string) {
    this.create(url);
  }

  private create(url: string) {
    logD(TAG, "Connect %s", url);
    try {
      this.ws = new WebSocket(url);
      this.ws.onopen = this._onOpen.bind(this);
      this.ws.onmessage = this._onMessage.bind(this);
      this.ws.onerror = this._onError.bind(this);
      this.ws.onclose = this._onClose.bind(this);
    } catch (e) {
      logE(TAG, "Error connecting websocket ", e);
    }
  }

  private _onMessage(msg: any) {
    logD(TAG, "onMessage %o", msg);
    const requestData: MediationServerMessage = JSON.parse(msg.data);
    this._onMessageSubject.next(requestData);
  }

  private _onOpen(event: any) {
    logD(TAG, "onOpen %o", event);
    this.sendSync(
      MessageKind.REQ_HELLO,
      {
        lang: this._lang,
        userAgent: this._userAgentString,
        audid: this._audid,
        sessionId: this.sessionId,
      },
      MessageKind.RESP_HELLO,
    ).subscribe(
      () => {
        logD(TAG, "Hello OK");
        this._onReadySubject.next();
        this._onReadySubject.complete();
      },
      (err) => {
        logE(TAG, "Hello NOK", err);
        this._onReadySubject.error(err);
        this.stop();
      },
    );
  }

  private _onError(event: any) {
    logD(TAG, "onError %o", event);
    if (this.retry && this.retryCount < 10) {
      this.retryCount++;
      this.retryTimeout = setTimeout(() => {
        logD(TAG, `Retry (${this.retryCount})`);
        this.connect(this._url);
      }, 1000);
    } else {
      logD(TAG, "Giving up !");
      this._onReadySubject.error({
        status: GENERIC_ERROR,
        code: GENERIC_ERROR,
        title: GENERIC_ERROR,
      });
    }
  }

  private _onClose(event?: { code: number }) {
    if (this.ws) {
      this.ws!.onopen = null;
      this.ws!.onclose = null;
      this.ws!.onmessage = null;
      this.ws!.onerror = null;
    }
    this.ws = null;

    if (this.retryTimeout !== null) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }

    if (event && event.code !== 1000) {
      logD(TAG, "onCloseAbnormaly %o", event);
      // Retry
      if (this.retry && this.retryCount < 10) {
        this.retryTimeout = setTimeout(() => {
          this.retryCount++;
          logD(TAG, `Retry (${this.retryCount})`);
          this.connect(this._url);
        }, 1000);
      }
    } else {
      logD(TAG, "onCloseNormaly %o", event);
      this.retry = false;
      this._onReadySubject = new Subject<void>();
      this._onMessageSubject = new Subject<MediationServerMessage>();
    }
  }
}

export default new MediationServerService();
