import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { paginateList } from "../../redux/actions/List";
import { dismissPageError, fetchPage, paginatePage } from "../../redux/actions/Page";
import { State } from "../../redux/reducers";

const TAG = "Category";

export const usePage = (props: {
  tilesLimit: number;
  limit: number;
  id: string;
  pass?: boolean;
}) => {
  const { tilesLimit, limit, id, pass = false } = props;
  const dispatch = useDispatch();
  const { loading, page, error, paginating } = useSelector(
    (state: State) => state.pagesState.pages[id],
  ) || {
    loading: false,
    paginating: false,
  };
  const { lists } = useSelector((state: State) => state.listsState);
  let listPaginating = false;
  if (page && page.lists.length > 0) {
    const lastListState = lists[page.lists[page.lists.length - 1].uuid];
    listPaginating = lastListState ? lastListState.paginating : false;
  }

  React.useEffect(() => {
    if (!page) {
      const fetchNewPage = async () => {
        try {
          await fetchPage(id, limit, tilesLimit, pass)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting page %o %o", id, e);
        }
      };
      fetchNewPage();
    }
  }, [page, id, limit, tilesLimit, dispatch, pass]);

  return {
    loading,
    page,
    loadingError: error,
    paginating: paginating || listPaginating,
    dismissLoadingError: React.useCallback(() => {
      dismissPageError(id)(dispatch);
    }, [dispatch, id]),
    paginate: React.useCallback(async () => {
      if (page) {
        if (page.total > page.lists.length && !paginating) {
          try {
            await paginatePage(id, page, limit, tilesLimit, pass)(dispatch);
          } catch (e) {
            logE(TAG, "Caught eror on page pagination %o: %o", page.uuid, e);
          }
        } else {
          // case of list pagination
          // 20231030: Still used on mobile screen (games to discover)
          const l = page.lists[page.lists.length - 1];
          if (l.total > l.tiles.length && !listPaginating) {
            paginateList(page.uuid, l, tilesLimit)(dispatch);
          }
        }
      }
    }, [dispatch, id, limit, listPaginating, page, paginating, pass, tilesLimit]),
  };
};
