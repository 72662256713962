export const envSet = {
  dev: {
    availabe: {
      google_pay: false,
    },
    key: {
      google_signin:
        "878863684403-79bdmfui0q9oln9uuvpduegr05rhucki.apps.googleusercontent.com",
      stripe: "pk_test_20Ly29gQW5R3l9FEBDyqdVWG",
      paypal:
        "AVQuTZEAIbjARVuP4W4PwI5-jBdPKOXqzIKrzC3c3sFF4f0M47wlww2ppfBs1H5rWIDeovBnaZyVDmNX",
    },
    url: {
      metrics: "https://dev-metrics2.blacknut.net",
      mediationServer: "wss://dev-ws.blacknut.net/api/v1/ws",
      front: "https://dev.blacknut.net",
      notifications: "wss://dev-ws.blacknut.net/api/v1/ws/notifications",
      amazonLogin: "https://dev-profile.blacknut.net/amazon-login.html",
      googleLogin: "https://dev-profile.blacknut.net/google-login.html",
      analytics: "https://a.blacknut.com/dev",
      loginWebSocket: "wss://dev-ws.blacknut.net/api/v1/ws/login",
      account: "https://dev-profile.blacknut.net/",
      edito: "https://dev-edito-mgr.blacknut.net/api/v1",
    },
    passCulture: {
      apiPath: "https://backend.passculture-integration.beta.gouv.fr",
      offerId: "BY",
    },
  },
  staging: {
    availabe: {
      google_pay: false,
    },
    key: {
      google_signin:
        "878863684403-79bdmfui0q9oln9uuvpduegr05rhucki.apps.googleusercontent.com",
      stripe: "pk_test_gv600Cq4eDJDWJovS8vVIxLq",
      paypal:
        "AVQuTZEAIbjARVuP4W4PwI5-jBdPKOXqzIKrzC3c3sFF4f0M47wlww2ppfBs1H5rWIDeovBnaZyVDmNX",
    },
    url: {
      metrics: "https://staging-metrics2.blacknut.net",
      mediationServer: "wss://staging-ws.blacknut.net/api/v1/ws",
      notifications: "wss://staging-ws.blacknut.net/api/v1/ws/notifications",
      front: "https://staging.blacknut.net",
      amazonLogin: "https://staging-profile.blacknut.net/amazon-login.html",
      googleLogin: "https://staging-profile.blacknut.net/google-login.html",
      analytics: "https://a.blacknut.com/staging",
      loginWebSocket: "wss://staging-ws.blacknut.net/api/v1/ws/login",
      account: "https://staging-profile.blacknut.net/",
      edito: "https://staging-edito-mgr.blacknut.net/api/v1",
    },
    passCulture: {
      apiPath: "https://backend.passculture-integration.beta.gouv.fr",
      offerId: "BY",
    },
  },
  prod: {
    availabe: {
      google_pay: true,
    },
    key: {
      google_signin:
        "878863684403-79bdmfui0q9oln9uuvpduegr05rhucki.apps.googleusercontent.com",
      stripe: "pk_live_lb4DcOmBP7WxX2ZfeIa568Gp",
      paypal:
        "AVQuTZEAIbjARVuP4W4PwI5-jBdPKOXqzIKrzC3c3sFF4f0M47wlww2ppfBs1H5rWIDeovBnaZyVDmNX",
    },
    url: {
      metrics: "https://metrics2.blacknut.com",
      mediationServer: "wss://ws.blacknut.com/api/v1/ws",
      notifications: "wss://ws.blacknut.com/api/v1/ws/notifications",
      front: "https://www.blacknut.com",
      amazonLogin: "https://profile.blacknut.com/amazon-login.html",
      googleLogin: "https://profile.blacknut.com/google-login.html",
      analytics: "https://a.blacknut.com/event",
      loginWebSocket: "wss://ws.blacknut.com/api/v1/ws/login",
      account: "https://profile.blacknut.com/",
      edito: "https://edito-mgr.blacknut.com/api/v1",
    },
    passCulture: {
      apiPath: "https://backend.passculture.beta.gouv.fr",
      offerId: "A34A",
    },
  },
  integration: {
    availabe: {
      google_pay: false,
    },
    key: {
      google_signin:
        "878863684403-79bdmfui0q9oln9uuvpduegr05rhucki.apps.googleusercontent.com",
      stripe: "pk_test_gv600Cq4eDJDWJovS8vVIxLq",
      paypal:
        "AVQuTZEAIbjARVuP4W4PwI5-jBdPKOXqzIKrzC3c3sFF4f0M47wlww2ppfBs1H5rWIDeovBnaZyVDmNX",
    },
    url: {
      metrics: "https://integration-metrics2.blacknut.net",
      mediationServer: "wss://integration-ws.blacknut.net/api/v1/ws",
      notifications: "wss://integration-ws.blacknut.net/api/v1/ws/notifications",
      front: "https://integration.blacknut.net",
      amazonLogin: "https://integration-profile.blacknut.net/amazon-login.html",
      googleLogin: "https://integration-profile.blacknut.net/google-login.html",
      analytics: "",
      loginWebSocket: "wss://integration-ws.blacknut.net/api/v1/ws/login",
      account: "https://integration-profile.blacknut.net",
      edito: "https://integration-edito-mgr.blacknut.net/api/v1",
    },
    passCulture: {
      apiPath: "https://backend.passculture-integration.beta.gouv.fr",
      offerId: "BY",
    },
  },
};
