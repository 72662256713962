import { useSelector } from "react-redux";
import { State } from "core";
import { useHistory } from "react-router";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { useCallback } from "react";
import useAnonymousCheck from "../../../utils/useAnonymousCheck";
import { unreadNotificationsSelector } from "../../../components/AccountNavLink/AccountNavLink";
import AvatarImage from "../../../components/AvatarSelectionModal/AvatarImage";
import React from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { ReactComponent as AvatarDefault } from "../../../assets/dist/ic_account_default.svg";

export const AccountHeaderRight = () => {
  const { profile } = useSelector((state: State) => state.profilesState);
  const unreadNotificationsLen = useSelector(unreadNotificationsSelector);

  const history = useHistory();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const { checkForAnonymous } = useAnonymousCheck();
  const onClick = useCallback(() => {
    if (checkForAnonymous()) {
      history.push("/account/me");
    }
  }, [checkForAnonymous, history]);
  if (!profile) return null;
  return (
    <Focusable
      className={clsx(
        styles.icon,
        spatialNavigationActive && styles.spatialNavigationActive,
      )}
      onClick={onClick}
      onPressEnter={onClick}
    >
      {profile.avatarId && <AvatarImage avatarId={profile.avatarId} />}

      {!profile.avatarId && <AvatarDefault className="svg" />}
      {unreadNotificationsLen > 0 && (
        <div className={styles.newBadge} data-testid="badge" />
      )}
    </Focusable>
  );
};
