import { Dispatch } from "redux";
import {
  ListAction,
  FAVORITES_LIST_ID,
  favOrLastPlayedToList,
  fetchListError,
  fetchListRequest,
  fetchListSuccess,
} from "./List";
import { List, ListSortMethod } from "@blacknut/javascript-sdk/dist";
import { map } from "rxjs";
import { getFavorites as apiGetFavorites } from "@blacknut/javascript-sdk/dist/api/Catalog/getFavorites";

export const fetchFavorites = (sortBy: ListSortMethod, limit?: number) => {
  return (dispatch: Dispatch<ListAction>) => {
    return new Promise<List>((resolve, reject) => {
      dispatch(fetchListRequest(FAVORITES_LIST_ID, sortBy));
      apiGetFavorites(sortBy, 1, limit)
        .pipe(
          map(({ games, meta }) => {
            return favOrLastPlayedToList(FAVORITES_LIST_ID, sortBy, games, meta.total);
          }),
        )
        .subscribe({
          next: (list) => {
            dispatch(fetchListSuccess(FAVORITES_LIST_ID, sortBy, list));
            resolve(list);
          },
          error: (error) => {
            dispatch(fetchListError(FAVORITES_LIST_ID, error));
            reject(error);
          },
        });
    });
  };
};
