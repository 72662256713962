import { Page, ApiError } from "@blacknut/javascript-sdk/dist";

export enum PageActionTypes {
  PAGE_FETCH_REQUEST = "PAGE_FETCH_REQUEST",
  PAGE_FETCH_SUCCESS = "PAGE_FETCH_SUCCESS",
  PAGE_FETCH_ERROR = "PAGE_FETCH_ERROR",
  PAGE_FETCH_CATEGORY_REQUEST = "PAGE_FETCH_CATEGORY_REQUEST",
  PAGE_FETCH_CATEGORY_SUCCESS = "PAGE_FETCH_CATEGORY_SUCCESS",
  PAGE_FETCH_CATEGORY_ERROR = "PAGE_FETCH_CATEGORY_ERROR",
  REMOVE_PAGE = "REMOVE_PAGE",
  PAGE_PAGINATE_REQUEST = "PAGE_PAGINATE_REQUEST",
  PAGE_PAGINATE_SUCCESS = "PAGE_PAGINATE_SUCCESS",
  PAGE_PAGINATE_ERROR = "PAGE_PAGINATE_ERROR",
  OTHER = "OTHER",
}

export type PageAction =
  | {
      type: PageActionTypes.PAGE_FETCH_REQUEST;
      id: string;
    }
  | {
      type: PageActionTypes.PAGE_FETCH_SUCCESS;
      id: string;
      page: Page;
    }
  | {
      type: PageActionTypes.PAGE_FETCH_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: PageActionTypes.PAGE_FETCH_CATEGORY_REQUEST;
      category: string;
      id: string;
    }
  | {
      type: PageActionTypes.PAGE_FETCH_CATEGORY_SUCCESS;
      category: string;
      id: string;
      page: Page;
    }
  | {
      type: PageActionTypes.PAGE_FETCH_CATEGORY_ERROR;
      category: string;
      id: string;
      error?: ApiError;
    }
  | {
      type: PageActionTypes.PAGE_PAGINATE_REQUEST;
      id: string;
    }
  | {
      type: PageActionTypes.PAGE_PAGINATE_SUCCESS;
      id: string;
      page: Page;
    }
  | {
      type: PageActionTypes.PAGE_PAGINATE_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: PageActionTypes.REMOVE_PAGE;
      id: string;
    }
  | {
      type: PageActionTypes.OTHER;
    };

export const removePage = (id: string): PageAction => ({
  type: PageActionTypes.REMOVE_PAGE,
  id,
});
