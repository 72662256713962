import { logD } from "@blacknut/logging/dist";
import React, { PropsWithChildren, useCallback, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const LOGGING_TAG = "ScrollContext";
const NOP = () => {
  logD(LOGGING_TAG, "NOP");
};
export const ScrollContext = React.createContext<{
  reset: () => void;
  clear: () => void;
}>({
  reset: NOP,
  clear: NOP,
});

const MAP = new Map<string, number | { overflowing: string; scrollY: number }>();

export const ScrollContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  useEffect(() => {
    const listener = () => {
      MAP.set(window.location.pathname, window.scrollY);
    };
    window.addEventListener("scroll", listener);
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, []);

  const history = useHistory();
  const location = useLocation();
  useEffect(() => {
    const unlisten = history.listen((action) => {
      const val = MAP.get(action.pathname);
      logD(LOGGING_TAG, "location changed: %o", action);

      if (typeof val === "number") {
        // Case of window,
        // use a timeout since page content may not have already been displayed
        setTimeout(() => {
          logD(LOGGING_TAG, "Scroll to: %o", val);
          window.scrollTo({
            left: 0,
            top: val,
            behavior: "smooth",
          });
        }, 200);
      } else if (typeof val === "object") {
        // case of overflowing div
        // div can be out of the dom, so use a timeout here efore getting it
        setTimeout(() => {
          const e = document.getElementById(val.overflowing);
          if (e) {
            logD(LOGGING_TAG, "Scroll to: %o", val.scrollY);
            e.scrollTo({
              left: 0,
              top: val.scrollY,
              behavior: "smooth",
            });
          }
        }, 200);
      }
    });
    return () => {
      unlisten();
    };
  }, [history, location, location.pathname]);
  const reset = useCallback(() => {
    logD(LOGGING_TAG, "Reset scroll position for %o", window.location.pathname);
    MAP.set(window.location.pathname, 0);
  }, []);
  const clear = useCallback(() => {
    logD(LOGGING_TAG, "Clear saved scroll positions");
    MAP.clear();
  }, []);
  return (
    <ScrollContext.Provider value={{ reset, clear }}>{children}</ScrollContext.Provider>
  );
};

export const useScrollContext = () => useContext(ScrollContext);

export const useScrollProvider = ({ overflowing }: { overflowing: string }) => {
  useEffect(() => {
    const e = document.getElementById(overflowing);
    if (e) {
      const listener = () => {
        MAP.set(window.location.pathname, { overflowing, scrollY: e.scrollTop });
      };
      e?.addEventListener("scroll", listener);
      return () => {
        e?.removeEventListener("scroll", listener);
      };
    }
    return () => {};
  }, [overflowing]);
};

const ScrollRestorer = (props: { id?: string; scrollKey?: string }) => {
  const context = useContext(ScrollContext);
  const location = useLocation();
  const scrollKey = props.scrollKey || location.pathname;
  useEffect(() => {
    // const e = props.id ? document.getElementById(props.id) : document.getElementById("main");
    // const scrollTop = context.scrollPositions.get(scrollKey);
    // logger.debug("On mount (%o) scroll position is %o, overflowing is %o", scrollKey, scrollTop, e);
    // if (e && e.scrollTo) {
    //     e.scrollTo({ top: scrollTop || 0 });
    // }
    // return () => {
    //     const e = props.id ? document.getElementById(props.id) : document.getElementById("main");
    //     logger.debug(
    //         "On unmount (%o) scroll position is %o, overflowing is %o",
    //         scrollKey,
    //         e ? e.scrollTop : "N/A",
    //         e,
    //     );
    //     if (e) {
    //         context.setScrollPosition(scrollKey, e.scrollTop);
    //     }
    // };
  }, [context, location, props.id, scrollKey]);

  return null;
};

export { ScrollRestorer };
