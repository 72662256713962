import { ListRendererFn } from "./ListRenderer";
import styles from "../styles.module.scss";
import React from "react";
import clsx from "clsx";
import HorizontalTileList from "src/components/List/HorizontalTileList";
import { useProfiler } from "src/hooks/useProfiler";
import { useInView } from "react-intersection-observer";
import { logD } from "@blacknut/logging/dist";
import { LOGGING_TAG } from "src/utils/Utils";

/*********
 * Flat
 *
 * Desktop: 1 rows of 6 tiles
 *           0 1 2 3 4 5
 *
 * Phone:
 *  Portrait :
 *      Scrollable : 1 row of 6 tiles with 3 fully visible tiles
 *           0 1 2 3 4 5
 *      Not scrollable : 2 rows of 3 tiles
 *           0 1 2
 *           3 4 5
 *  Landscape :
 *      Scrollable : 1 row of 6 tiles with 3 fully visible tiles
 *           0 1 2 3 4 5
 *      Not scrollable: 2 rows of 3 tiles
 *           0 1 2
 *           3 4 5
 *
 */
const FlatRenderer: ListRendererFn = ({ list, leaveFor, scrollable, onFocus, page }) => {
  logD(LOGGING_TAG, "Render flat");
  const Root = useProfiler("flatList", { uuid: list.uuid });
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  return (
    <Root>
      <div ref={tileRef} className={clsx(styles.flat, scrollable && styles.scrollable)}>
        <HorizontalTileList
          list={list}
          leaveFor={leaveFor}
          inView={inView}
          onFocus={onFocus}
          scrollable={scrollable}
          page={page}
        />
      </div>
    </Root>
  );
};
export default FlatRenderer;
