import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissMenuError, getMenu } from "../../redux/actions/App";
import { State } from "../../redux/reducers";

const TAG = "Browse";
export const useBrowse = () => {
  const dispatch = useDispatch();
  const { menu: menuState } = useSelector((state: State) => state.globalState);
  const { menu, inProgress, error } = menuState;

  const _getMenu = React.useCallback(async () => {
    try {
      await getMenu()(dispatch);
    } catch (e) {
      logE(TAG, "Caught error getting menu");
    }
  }, [dispatch]);

  React.useEffect(() => {
    if (!menu) {
      _getMenu();
    }
  }, [menu, _getMenu]);

  return {
    menu,
    loadError: error,
    loading: inProgress,
    getMenu: _getMenu,
    dismissError: React.useCallback(() => {
      dismissMenuError()(dispatch);
    }, [dispatch]),
    tiles: menu,
  };
};
