import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import HeroTile from "../../TileHero/HeroTile";
import { FocusableSection } from "@blacknut/spatialnav-sdk/dist";
import { useProfiler } from "../../../hooks/useProfiler";
import { List, Page } from "@blacknut/javascript-sdk/dist";
import HeroTilePass from "src/components/TileHero/HeroTilePass";

interface HeroViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  leaveFor?: LeaveFor;
  className: string;
  page?: Page;
}

const HeroView = ({ list, leaveFor, className, page }: HeroViewProps) => {
  const t = list.tiles[0];
  const Root = useProfiler("heroList", { uuid: list.uuid });

  return (
    <Root>
      <FocusableSection
        className={className}
        focusKey={list.uuid}
        key={list.uuid}
        leaveFor={leaveFor}
      >
        {t.gamePass && <HeroTilePass item={t} list={list} />}
        {t.game && <HeroTile item={t} list={list} page={page} />}
      </FocusableSection>
    </Root>
  );
};

export default HeroView;
