import { DDAppender } from "@blacknut/logging-datadog/dist";
import { ConsoleAppender, LogLevel, logger } from "@blacknut/logging/dist";
import { State } from "core";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const CONSOLE_APPENDER = new ConsoleAppender();
export const DD_APPENDER = new DDAppender({ type: "App" });
export const useLogging = () => {
  const { config } = useSelector((state: State) => state.globalState);

  useEffect(() => {
    if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
      logger.configure({
        level: LogLevel.LEVEL_DEBUG,
        appenders: [
          ...(logger.appenders || []).filter((f) => f !== CONSOLE_APPENDER),
          CONSOLE_APPENDER,
        ],
      });
    }
  }, []);

  useEffect(() => {
    switch (config?.userConf?.levelLogClient) {
      case "debug":
      case "trace":
        logger.level = LogLevel.LEVEL_DEBUG;
        logger.configure({
          level: LogLevel.LEVEL_DEBUG,
          appenders: [
            ...(logger.appenders || []).filter((f) => f !== CONSOLE_APPENDER),
            CONSOLE_APPENDER,
          ],
        });
        break;
      case "info":
        logger.level = LogLevel.LEVEL_INFO;
        break;
      case "warn":
        logger.level = LogLevel.LEVEL_WARN;
        break;
    }
  }, [config]);
};
