import { List, Page, Tile } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import { FocusableSectionProps } from "@blacknut/spatialnav-sdk/dist";
import React, { useCallback, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { useProfiler } from "../../../../hooks/useProfiler";
import { LOGGING_TAG } from "../../../../utils/Utils";
import HorizontalTileList from "../../../List/HorizontalTileList";
import TileBonusGame from "src/components/TileBonusGame";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { KeyProviderFn } from "src/components/List/HorizontalTileList/HorizontalTileList";

interface BonusGameViewProps
  extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  scrollable?: boolean;
  className?: string;
  page?: Page;
}
const MAX_BONUS = 5;
const makeDummyTile = (): Tile => {
  return {};
};
const DUMMY_TILES = new Array(MAX_BONUS).fill(1).map(() => makeDummyTile());

const arrange = (list: List) => {
  const tilesAvailableForUser = list.tiles.filter(
    (tile) => tile.game?.addonOption?.isAvailableForCurrentUser,
  );
  const tilesNotAvailableForUser = list.tiles.filter(
    (tile) => !tile.game?.addonOption?.isAvailableForCurrentUser,
  );
  return {
    ...list,
    tiles: [...tilesAvailableForUser, ...tilesNotAvailableForUser]
      .concat(...DUMMY_TILES)
      .slice(0, MAX_BONUS),
  };
};
const BonusGameView = ({
  list: _list,
  leaveFor,
  onFocus,
  scrollable,
  className,
  page,
}: BonusGameViewProps) => {
  logD(LOGGING_TAG, "Render bonus game list");
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  const list = useMemo(() => arrange(_list), [_list]);
  const Root = useProfiler("bonusGameList", { uuid: list.uuid });

  const renderTile = useCallback((t: Tile) => {
    return <TileBonusGame item={t} />;
  }, []);

  const keyProvider: KeyProviderFn = useCallback(
    (item, index) => {
      return `${list.uuid}-${index}`;
    },
    [list],
  );

  return (
    <Root>
      <div ref={tileRef} className={clsx(styles.container, className)}>
        <HorizontalTileList
          list={list}
          leaveFor={leaveFor}
          inView={inView}
          onFocus={onFocus}
          scrollable={scrollable}
          renderTile={renderTile}
          keyProvider={keyProvider}
          sectionTitleColor="#fff"
          page={page}
        />
      </div>
    </Root>
  );
};

export default BonusGameView;
