import { logD, logE } from "@blacknut/logging/dist";
import { selectTheme, State, StorageKey } from "core";
import { debounce } from "lodash";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { LOGGING_TAG } from "../utils/Utils";
import AisDarkTheme from "./ais/Dark";
import AisLightTheme from "./ais/Light";
import DarkTheme from "./default/Dark";
import LightTheme from "./default/Light";
import { mediaQueryDesktop, mediaQueryTablet } from "./dimens";
import DreiTheme from "./drei/Light";
import GameloftDarkTheme from "./gameloft/Dark";
import GameloftLightTheme from "./gameloft/Light";
import M1Theme from "./m1/Light";
import TelecallTheme from "./telecall/Light";
import { ButtonStyle, hex2rgb, NamedTheme, Theme } from "./Theme";
import nativeBridge from "src/utils/NativeBridge";

const prefersDarkMode =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
const findThemes = () => {
  switch (process.env.REACT_APP_FLAVOR) {
    case "drei":
      return [DreiTheme];
    case "gameloft":
      return [GameloftLightTheme, GameloftDarkTheme];
    case "ais":
      return [AisLightTheme, AisDarkTheme];
    case "telecall":
      return [TelecallTheme];
    case "m1":
      return [M1Theme];
  }
  return [LightTheme, DarkTheme];
};

export interface ThemeInjectedProps {
  theme: Theme;
  themes: string[];
  setThemeId: (id: string) => void;
}
export const Themes: NamedTheme[] = findThemes();

const defaultTheme = Themes[prefersDarkMode && Themes.length > 1 ? 1 : 0];
export const ThemeContext = React.createContext<{
  theme: Theme;
  themes: string[];
  setThemeId: (id: string) => void;
}>({
  theme: { name: defaultTheme.name, ...defaultTheme.default },
  themes: Themes.map((th) => th.name),
  setThemeId: () => {
    console.warn("Wrap encosing element with a theme provider");
  },
});

const setupButton = (
  btnStyle: Partial<ButtonStyle>,
  key: "primary" | "secondary" | "toggle",
) => {
  if (btnStyle.scaleFactor) {
    document.documentElement.style.setProperty(
      `--button-${key}-scaleFactor`,
      `${btnStyle.scaleFactor}`,
    );
  }
  if (btnStyle.activeTextColor) {
    document.documentElement.style.setProperty(
      `--button-${key}-active-color`,
      `${btnStyle.activeTextColor}`,
    );
  }
  // button background color
  if (btnStyle.activeBackgroundColor) {
    document.documentElement.style.setProperty(
      `--button-${key}-active-background`,
      `${btnStyle.activeBackgroundColor}`,
    );
  }
  if (btnStyle.hoverBackgroundColor) {
    document.documentElement.style.setProperty(
      `--button-${key}-hover-background`,
      `${btnStyle.hoverBackgroundColor}`,
    );
  }

  if (btnStyle.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      `--button-${key}-inactive-background`,
      `${btnStyle.inactiveBackgroundColor}`,
    );
  }

  if (btnStyle.inactiveTextColor) {
    document.documentElement.style.setProperty(
      `--button-${key}-inactive-color`,
      `${btnStyle.inactiveTextColor}`,
    );
  }
};

const merge = (th: NamedTheme) => {
  let variant: Partial<Theme> | undefined;
  if (!mediaQueryDesktop.matches) {
    if (mediaQueryTablet.matches) {
      variant = th.tablet;
    } else {
      variant = th.phone;
    }
  }
  const res = { name: th.name, ...th.default };
  if (variant) {
    for (const key of Object.keys(variant)) {
      if (typeof variant[key] === "string") {
        res[key] = variant[key];
      } else {
        res[key] = { ...th.default[key], ...variant[key] };
      }
    }
  }
  return res;
};

const updateMetaTag = (theme: Theme) => {
  const meta = document.querySelector('meta[name="theme-color"]');
  if (meta) {
    const content = document.createAttribute("content");
    content.value = theme.headerStyle.backgroundColor || "";
    meta.attributes.setNamedItem(content);
  }
};

const updateCssVars = (theme: Theme) => {
  const { r, g, b } = hex2rgb(theme.rippleColor);
  document.documentElement.style.setProperty("--accent-color", `${theme.rippleColor}`);
  document.documentElement.style.setProperty(
    "--player-accent-color",
    `${theme.rippleColor}`,
  );
  document.documentElement.style.setProperty(
    "--player-cover-radius",
    `${theme.tileCardStyle.radius || theme.cardStyle.radius}rem`,
  );

  if (theme.defaultFontFamily) {
    document.documentElement.style.setProperty(
      "--player-font-family",
      theme.defaultFontFamily,
    );
  }
  document.documentElement.style.setProperty("--background", theme.backgroundColor);
  if (theme.iconButton.hoverBackgroundColor) {
    document.documentElement.style.setProperty(
      "--icon-button-hover-background-color",
      theme.iconButton.hoverBackgroundColor,
    );
  }
  if (theme.iconButton.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      "--icon-button-inactive-background",
      theme.iconButton.inactiveBackgroundColor,
    );
  }
  if (theme.iconButton.activeBackgroundColor) {
    document.documentElement.style.setProperty(
      "--icon-button-active-background",
      theme.iconButton.activeBackgroundColor,
    );
  }
  if (theme.textInputStyle.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      "--textinput-inactive-background",
      theme.textInputStyle.inactiveBackgroundColor,
    );
  }
  if (theme.textInputStyle.activeBorderColor) {
    document.documentElement.style.setProperty(
      "--textinput--active-border",
      theme.textInputStyle.activeBorderColor,
    );
  }

  if (theme.textInputStyle.inactiveTextColor) {
    document.documentElement.style.setProperty(
      "--textinput-inactive-color",
      theme.textInputStyle.inactiveTextColor,
    );
  }

  if (theme.textStyle.color) {
    document.documentElement.style.setProperty("--text-color", theme.textStyle.color);
    // FIXME remove
    document.documentElement.style.setProperty(
      "--player-text-color",
      theme.textStyle.color,
    );
  }
  if (theme.textStyle.fontWeight) {
    document.documentElement.style.setProperty(
      "--text-weight",
      `${theme.textStyle.fontWeight}`,
    );
  }

  if (theme.textStyle.size) {
    document.documentElement.style.setProperty("--text-size", `${theme.textStyle.size}`);
  }
  if (theme.textStyle.fontFamily) {
    document.documentElement.style.setProperty(
      "--text-font",
      `${theme.textStyle.fontFamily}`,
    );
    document.documentElement.style.setProperty(
      "--font-family",
      `${theme.textStyle.fontFamily}`,
    );
  }

  if (theme.textStyle2.color) {
    document.documentElement.style.setProperty("--text2-color", theme.textStyle2.color);
  }
  if (theme.textStyle2.fontWeight) {
    document.documentElement.style.setProperty(
      "--text2-weight",
      `${theme.textStyle.fontWeight}`,
    );
  }

  if (theme.textStyle2.size) {
    document.documentElement.style.setProperty(
      "--text2-size",
      `${theme.textStyle2.size}`,
    );
  }
  if (theme.textStyle2.fontFamily) {
    document.documentElement.style.setProperty(
      "--text2-font",
      `${theme.textStyle2.fontFamily}`,
    );
  }
  if (theme.textStyle3.size) {
    document.documentElement.style.setProperty(
      "--text3-size",
      `${theme.textStyle3.size}`,
    );
  }

  // Section title
  if (theme.sectionTextStyle.color) {
    document.documentElement.style.setProperty(
      "--section-text-color",
      theme.sectionTextStyle.color,
    );
  }

  if (theme.sectionTextStyle.fontWeight) {
    document.documentElement.style.setProperty(
      "--section-text-weight",
      `${theme.sectionTextStyle.fontWeight}`,
    );
  }

  if (theme.sectionTextStyle.size) {
    document.documentElement.style.setProperty(
      "--section-text-size",
      `${theme.sectionTextStyle.size}`,
    );
  }
  if (theme.sectionTextStyle.fontFamily) {
    document.documentElement.style.setProperty(
      "--section-text-font",
      `${theme.sectionTextStyle.fontFamily}`,
    );
  }

  // Title text
  if (theme.titleTextStyle.color) {
    document.documentElement.style.setProperty(
      "--title-text-color",
      theme.titleTextStyle.color,
    );
  }
  if (theme.titleTextStyle.fontWeight) {
    document.documentElement.style.setProperty(
      "--title-text-weight",
      `${theme.titleTextStyle.fontWeight}`,
    );
  }

  if (theme.titleTextStyle.size) {
    document.documentElement.style.setProperty(
      "--title-text-size",
      `${theme.titleTextStyle.size}`,
    );
  }
  if (theme.titleTextStyle.fontFamily) {
    document.documentElement.style.setProperty(
      "--title-text-font",
      `${theme.titleTextStyle.fontFamily}`,
    );
  }

  document.documentElement.style.setProperty(
    "--card-title-text-size",
    `${(theme.textStyle.size || 1) * 1.2}`,
  );
  if (theme.cardStyle.radius) {
    document.documentElement.style.setProperty(
      "--card-radius",
      `${theme.cardStyle.radius}`,
    );
  }
  if (theme.cardStyle.scaleFactor) {
    document.documentElement.style.setProperty(
      "--card-scaleFactor",
      `${theme.cardStyle.scaleFactor}`,
    );
  }
  if (theme.accountCardStyle.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      "--card-account-inactive-background",
      `${theme.accountCardStyle.inactiveBackgroundColor}`,
    );
  }

  if (theme.accountCardStyle.scaleFactor) {
    document.documentElement.style.setProperty(
      "--card-account-scaleFactor",
      `${theme.accountCardStyle.scaleFactor}`,
    );
  }

  if (theme.accountCardStyle.radius) {
    document.documentElement.style.setProperty(
      "--card-account-radius",
      `${theme.accountCardStyle.radius}`,
    );
  }

  if (theme.cardStyle.activeBackgroundColor) {
    document.documentElement.style.setProperty(
      "--card-active-background",
      `${theme.cardStyle.activeBackgroundColor}`,
    );
  }
  if (theme.cardStyle.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      "--card-inactive-background",
      `${theme.cardStyle.inactiveBackgroundColor}`,
    );
  }
  if (theme.cardStyle.activeBorderColor) {
    document.documentElement.style.setProperty(
      "--card-active-border",
      `${theme.cardStyle.activeBorderColor}`,
    );
  }

  if (theme.cardStyle.inactiveBorderColor) {
    document.documentElement.style.setProperty(
      "--card-inactive-border",
      `${theme.cardStyle.inactiveBorderColor}`,
    );
  }

  if (theme.cardStyle.inactiveTextColorLight) {
    document.documentElement.style.setProperty(
      "--card-inactive-textColorLight",
      `${theme.cardStyle.inactiveTextColorLight}`,
    );
  }

  if (theme.cardStyle.inactiveTextColor) {
    // FIXME remove textColor since all other card style are colors
    document.documentElement.style.setProperty(
      "--card-inactive-textColor",
      `${theme.cardStyle.inactiveTextColor}`,
    );
    document.documentElement.style.setProperty(
      "--card-inactive-color",
      `${theme.cardStyle.inactiveTextColor}`,
    );
  }

  if (theme.cardStyle.activeTextColor) {
    document.documentElement.style.setProperty(
      "--card-active-textColor",
      `${theme.cardStyle.activeTextColor}`,
    );
    document.documentElement.style.setProperty(
      "--card-active-color",
      `${theme.cardStyle.activeTextColor}`,
    );
  }

  if (theme.separatorColor) {
    document.documentElement.style.setProperty(
      "--separator-color",
      `${theme.separatorColor}`,
    );
  }
  if (theme.textInputStyle.errorColor) {
    document.documentElement.style.setProperty(
      "--error-color",
      `${theme.textInputStyle.errorColor}`,
    );
  }
  if (theme.headerStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--header-background",
      `${theme.headerStyle.backgroundColor}`,
    );
  }
  if (theme.tileCardStyle.scaleFactor) {
    document.documentElement.style.setProperty(
      "--card-tile-scaleFactor",
      `${theme.tileCardStyle.scaleFactor}`,
    );
  }
  if (theme.tileCardStyle.radius) {
    document.documentElement.style.setProperty(
      "--card-tile-radius",
      `${theme.tileCardStyle.radius}`,
    );
  }
  if (theme.bottomBarStyle.borderWidth) {
    document.documentElement.style.setProperty(
      "--bottombar-border-width",
      `${theme.bottomBarStyle.borderWidth}`,
    );
  }
  if (theme.bottomBarStyle.borderColor) {
    document.documentElement.style.setProperty(
      "--bottombar-border-color",
      `${theme.bottomBarStyle.borderColor}`,
    );
  }
  if (theme.bottomBarStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--bottombar-background-color",
      `${theme.bottomBarStyle.backgroundColor}`,
    );
  }
  if (theme.bottomBarStyle.inactiveTintColor) {
    document.documentElement.style.setProperty(
      "--bottombar-tint-color-inactive",
      `${theme.bottomBarStyle.inactiveTintColor}`,
    );
  }
  if (theme.bottomBarStyle.activeTintColor) {
    document.documentElement.style.setProperty(
      "--bottombar-tint-color-active",
      `${theme.bottomBarStyle.activeTintColor}`,
    );
  }
  if (theme.bottomBarStyle.activeFocusedTintColor) {
    document.documentElement.style.setProperty(
      "--bottombar-tint-color-active-focused",
      `${theme.bottomBarStyle.activeFocusedTintColor}`,
    );
  }
  if (theme.bottomBarStyle.inactiveFocusedTintColor) {
    document.documentElement.style.setProperty(
      "--bottombar-tint-color-inactive-focused",
      `${theme.bottomBarStyle.inactiveFocusedTintColor}`,
    );
  }
  if (theme.bottomBarStyle.scaleFactor) {
    document.documentElement.style.setProperty(
      "--bottombar-scale-factor",
      `${theme.bottomBarStyle.scaleFactor}`,
    );
  }
  if (theme.modalStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--modal-background",
      `${theme.modalStyle.backgroundColor}`,
    );
  }

  if (theme.modalStyle.textStyle?.color) {
    document.documentElement.style.setProperty(
      "--modal-text-color",
      `${theme.modalStyle.textStyle?.color}`,
    );
  }

  if (theme.modalStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--modal-background",
      `${theme.modalStyle.backgroundColor}`,
    );
  }

  if (theme.modalStyle.padding) {
    document.documentElement.style.setProperty(
      "--modal-padding",
      `${theme.modalStyle.padding}`,
    );
  }
  document.documentElement.style.setProperty(
    "--separator-color",
    `${theme.separatorColor}`,
  );
  if (theme.visibilityInputIconColor) {
    document.documentElement.style.setProperty(
      "--visibility-input-icon-color",
      `${theme.visibilityInputIconColor}`,
    );
  }

  if (theme.labelSecureStyle.color) {
    document.documentElement.style.setProperty(
      "--labelSecure-color",
      `${theme.labelSecureStyle.color}`,
    );
  }

  if (theme.labelSecureStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--labelSecure-background",
      `${theme.labelSecureStyle.backgroundColor}`,
    );
  }

  if (theme.secondaryButton.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      "--button-secondary-inactive-background",
      `${theme.secondaryButton.inactiveBackgroundColor}`,
    );
  }

  if (theme.secondaryButton.hoverBackgroundColor) {
    document.documentElement.style.setProperty(
      "--button-secondary-hover-background",
      `${theme.secondaryButton.hoverBackgroundColor}`,
    );
  }

  if (theme.primaryButton.borderRadius) {
    document.documentElement.style.setProperty(
      "--button-radius",
      `${theme.primaryButton.borderRadius}`,
    );
  }

  setupButton(theme.primaryButton, "primary");
  setupButton(theme.secondaryButton, "secondary");
  setupButton(theme.toggleButton, "toggle");

  if (theme.headerStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--header-background-color",
      `${theme.headerStyle.backgroundColor}`,
    );
  }
  if (theme.headerStyle.borderColor) {
    document.documentElement.style.setProperty(
      "--header-border-color",
      `${theme.headerStyle.borderColor}`,
    );
  }
  if (theme.headerStyle.textStyle?.fontFamily) {
    document.documentElement.style.setProperty(
      "--header-font-family",
      `${theme.headerStyle.textStyle.fontFamily}`,
    );
  }
  if (theme.headerStyle.textStyle?.fontWeight) {
    document.documentElement.style.setProperty(
      "--header-font-weight",
      `${theme.headerStyle.textStyle.fontWeight}`,
    );
  }
  if (theme.headerStyle.textStyle?.size) {
    document.documentElement.style.setProperty(
      "--header-font-size",
      `${theme.headerStyle.textStyle.size}`,
    );
  }
  if (theme.headerStyle.textStyle?.color) {
    document.documentElement.style.setProperty(
      "--header-color",
      `${theme.headerStyle.textStyle.color}`,
    );
  }
  if (theme.headerStyle.textStyle?.fontWeight) {
    document.documentElement.style.setProperty(
      "--titleList-font-weight",
      `${theme.titleListStyle.fontWeight}`,
    );
  }
  if (theme.headerStyle.textStyle?.size) {
    document.documentElement.style.setProperty(
      "--titleList-font-size",
      `${theme.titleListStyle.size}`,
    );
  }
  if (theme.headerStyle.textStyle?.color) {
    document.documentElement.style.setProperty(
      "--titleList-color",
      `${theme.titleListStyle.color}`,
    );
  }
  if (theme.headerStyle.buttonsTintColor) {
    document.documentElement.style.setProperty(
      "--header-tint-color",
      `${theme.headerStyle.buttonsTintColor}`,
    );
  }
  if (theme.profileCardStyle.inactiveTextColor) {
    document.documentElement.style.setProperty(
      "--profile-card-inactive-color",
      `${theme.profileCardStyle.inactiveTextColor}`,
    );
  }
  if (theme.profileCardStyle.activeTextColor) {
    document.documentElement.style.setProperty(
      "--profile-card-active-color",
      `${theme.profileCardStyle.activeTextColor}`,
    );
  }
  if (theme.profileCardStyle.inactiveBackgroundColor) {
    document.documentElement.style.setProperty(
      "--profile-card-inactive-background-color",
      `${theme.profileCardStyle.inactiveBackgroundColor}`,
    );
  }
  if (theme.profileCardStyle.activeBackgroundColor) {
    document.documentElement.style.setProperty(
      "--profile-card-active-background-color",
      `${theme.profileCardStyle.activeBackgroundColor}`,
    );
  }
  if (theme.profileCardStyle.inactiveBorderColor) {
    document.documentElement.style.setProperty(
      "--profile-card-inactive-border-color",
      `${theme.profileCardStyle.inactiveBorderColor}`,
    );
  }
  if (theme.profileCardStyle.activeBorderColor) {
    document.documentElement.style.setProperty(
      "--profile-card-active-border-color",
      `${theme.profileCardStyle.activeBorderColor}`,
    );
  }

  if (theme.profileCardStyle.radius) {
    document.documentElement.style.setProperty(
      "--profile-card-radius",
      `${theme.profileCardStyle.radius}`,
    );
  }
  if (theme.profileCardStyle.scaleFactor) {
    document.documentElement.style.setProperty(
      "--profile-card-scaleFactor",
      `${theme.profileCardStyle.scaleFactor}`,
    );
  }

  if (theme.listStyle.backgroundColor) {
    document.documentElement.style.setProperty(
      "--list-inactive-background",
      `${theme.listStyle.backgroundColor}`,
    );
  }
  if (theme.listStyle.radius) {
    document.documentElement.style.setProperty(
      "--list-radius",
      `${theme.listStyle.radius}`,
    );
  }
  if (theme.listStyle.textColor) {
    document.documentElement.style.setProperty(
      "--list-color",
      `${theme.listStyle.textColor}`,
    );
  }
};
export const ThemeContextProvider = (props: PropsWithChildren<unknown>) => {
  const [theme, setTheme] = useState<Theme>({
    name: defaultTheme.name,
    ...defaultTheme.default,
  });

  const profile = useSelector((state: State) => state.profilesState.profile);
  const profilePrefs = useSelector(
    (state: State) => profile && state.profilesState.profilePrefs[profile.id],
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const saveTheme = (id: string) => {
    if (profile) {
      selectTheme(id, profile)(dispatch);
    }
  };

  useEffect(() => {
    const resizeListener = debounce(
      () => {
        setWindowWidth(window.innerWidth);
      },
      200,
      { leading: true, trailing: true },
    );
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const setThemeId = useCallback((id: string) => {
    const th = Themes.find((th2) => th2.name === id);
    if (th) {
      const merged = merge(th);
      setTheme(merged);
      localStorage.setItem(StorageKey.THEME_BW, id);
      saveTheme(id);
      document.body.style.backgroundColor = theme.backgroundColor;
      updateMetaTag(merged);
      updateCssVars(merged);
    }
  }, []);

  useEffect(() => {
    const lastStoredThemeID = localStorage.getItem(StorageKey.THEME_BW);
    console.log("last stored", lastStoredThemeID);
    let storedTheme: NamedTheme | undefined;
    if (profilePrefs && profilePrefs.theme) {
      // restore theme from profile prefs
      storedTheme = Themes.find((th) => th.name === profilePrefs.theme);
      console.log("stored ", storedTheme, profilePrefs.theme, lastStoredThemeID);
      if (storedTheme && profilePrefs.theme !== lastStoredThemeID) {
        localStorage.setItem(StorageKey.THEME_BW, profilePrefs.theme);
      }
    }

    if (!storedTheme && lastStoredThemeID) {
      console.log("fallback");
      storedTheme = Themes.find((th) => th.name === lastStoredThemeID);
    }

    let finalTheme: Theme;
    if (storedTheme) {
      finalTheme = merge(storedTheme);
    } else {
      finalTheme = merge(defaultTheme);
    }
    logD(LOGGING_TAG, "Using theme: %o", finalTheme);
    document.body.style.backgroundColor = finalTheme.backgroundColor;
    setTheme(finalTheme);
    updateMetaTag(finalTheme);
    updateCssVars(finalTheme);

    if (nativeBridge.module?.getDefaultTheme) {
      nativeBridge.module
        ?.getDefaultTheme()
        .then((id) => {
          logD(LOGGING_TAG, "Preferred theme from device: %o", id);
          if (id !== finalTheme.name) {
            setThemeId(id);
          }
        })
        .catch((e) => {
          logE(LOGGING_TAG, "Caught error getting default theme: %o", e);
        });
    }
  }, [profilePrefs, setThemeId, windowWidth]);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themes: Themes.map((th) => th.name),
        setThemeId,
      }}
    >
      {theme && props.children}
    </ThemeContext.Provider>
  );
};

export function useTheme() {
  return useContext(ThemeContext);
}

export function withTheme<TOriginalProps extends {}>(
  Component: React.ComponentType<TOriginalProps>,
) {
  return (props: TOriginalProps) => {
    const { theme, themes, setThemeId } = useContext(ThemeContext);
    return (
      <Component {...props} theme={theme} themes={themes} setThemeId={setThemeId} />
    ) as React.ReactElement<Omit<TOriginalProps, "themes" | "theme" | "setThemeId">>;
  };
}
