import { getConfig } from "@blacknut/javascript-sdk/dist";
import { logD, logE } from "@blacknut/logging/dist";
import { Dispatch } from "redux";
import { Action as AppAction, setConfig } from "../App";
import { ProfilesAction, loadFamilyInfoSuccess, setCurrentProfile } from "../Profiles";
import { UserAction, setFamilyToken, setUserToken } from "./User";
import { logout as apiLogout } from "@blacknut/javascript-sdk/dist/api/User/Action/logout";
const TAG = "Logout";
export const logout = () => {
  return (dispatch: Dispatch<UserAction | AppAction | ProfilesAction>) => {
    return new Promise<void>((resolve) => {
      const onLogout = () => {
        setFamilyToken()(dispatch);
        setUserToken()(dispatch);
        dispatch(setCurrentProfile());
        dispatch(loadFamilyInfoSuccess());
        dispatch(setConfig());
        getConfig().subscribe({
          next: (config) => {
            dispatch(setConfig(config));
            resolve();
          },
          error: (error) => {
            logE(TAG, "Errror getting config", error);
            resolve();
          },
        });
      };
      apiLogout().subscribe({
        next: () => {
          logD(TAG, "Logout sucess");
          onLogout();
        },
        error: (error) => {
          logE(TAG, "Logout error ", error);
          onLogout();
        },
      });
    });
  };
};
