import { apiService, UserToken } from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { setOrganization, Action as AppAction } from "../App";
import { setCurrentProfile } from "../Profiles";
import { logout } from "./logout";
import { UserAction, setFamilyToken, setUserToken } from "./User";
import { ProfilesAction } from "../Profiles";
import { TAG } from "@blacknut/javascript-sdk/dist/api/ApiService";
import { getUserInfo } from "@blacknut/javascript-sdk/dist/api/User/Action/getUserInfo";

/*
Sync tokens between two tabs
If family has changed, set family token
If user has changed, get current user with currently provided token
*/
export const syncTokens = (tokens?: UserToken) => {
  return (
    dispatch: Dispatch<UserAction | AppAction | ProfilesAction>,
    getState: () => State,
  ) => {
    const { familyToken, userToken } = getState().globalState;
    const { profile, familyProfiles } = getState().profilesState;
    if (!tokens || !tokens.familyToken) {
      logout()(dispatch);
    } else {
      // test for !== since when setting family token & user token local storage is updated therefore we produce an infinite loop
      if (familyToken?.accessToken !== tokens.familyToken.accessToken) {
        setFamilyToken(tokens.familyToken, true)(dispatch);
      }

      if (tokens.userToken && tokens.userToken.accessToken !== userToken?.accessToken) {
        apiService.userToken = tokens.userToken;
        getUserInfo().subscribe({
          next: (res) => {
            if (res.user.id !== profile?.id) {
              setUserToken(tokens.userToken, false)(dispatch);
              dispatch(setOrganization(res.organization));
              const p = familyProfiles.profiles?.find((p) => p.id === res.user.id);
              if (p) {
                dispatch(setCurrentProfile(p, res.user, false));
              }
            } else {
              //same profile, just store the new tken
              setUserToken(tokens.userToken, true)(dispatch);
            }
          },
          error: (error) => {
            logE(TAG, "Caught error getting user data", error);
            logout()(dispatch);
          },
        });
      }
    }
  };
};
