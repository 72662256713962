import { Reducer } from "redux";
import { Action as AppAction } from "../actions/App";
import { ListAction, ListActionTypes, FAVORITES_LIST_ID } from "../actions/List";
import { PageAction, PageActionTypes } from "../actions/Page";
import { UserAction, UserActionTypes } from "../actions/User";
import { GameAction, GameActionTypes } from "../actions/Game";
import { ApiError, List, ListSortMethod } from "@blacknut/javascript-sdk/dist";
export interface State {
  lists: {
    [id: string]: {
      loading: boolean;
      list?: List;
      error?: ApiError;
      paginating: boolean;
      sort: ListSortMethod;
    };
  };
}
const initialState: State = {
  lists: {},
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: ListAction | AppAction | UserAction | PageAction | GameAction,
) => {
  switch (action.type) {
    case ListActionTypes.LIST_FETCH_REQUEST: {
      const lists = Object.assign(state.lists, {});
      const prev = action.id in lists ? lists[action.id] : { paginating: false };
      lists[action.id] = {
        ...prev,
        loading: true,
        sort: action.sort,
      };
      return { ...state, lists };
    }
    case ListActionTypes.LIST_FETCH_SUCCESS: {
      const lists = Object.assign(state.lists, {});
      lists[action.id] = {
        loading: false,
        list: action.list,
        paginating: false,
        sort: action.sort,
      };
      return { ...state, lists };
    }
    case ListActionTypes.REMOVE_LIST: {
      const lists = Object.assign(state.lists, {});
      delete lists[action.id];
      return { ...state, lists };
    }
    case ListActionTypes.LIST_FETCH_ERROR: {
      const lists = Object.assign(state.lists, {});
      lists[action.id] = {
        ...lists[action.id],
        loading: false,
        paginating: false,
        error: action.error,
      };
      return { ...state, lists };
    }
    case ListActionTypes.LIST_PAGINATION_REQUEST: {
      const lists = Object.assign(state.lists, {});
      lists[action.id] = {
        ...lists[action.id],
        paginating: true,
      };
      return { ...state, lists };
    }
    case ListActionTypes.LIST_PAGINATION_SUCCESS: {
      const lists = Object.assign(state.lists, {});
      lists[action.id] = {
        ...lists[action.id],
        paginating: false,
        list: action.list,
      };
      return { ...state, lists };
    }
    case ListActionTypes.LIST_PAGINATION_ERROR: {
      const lists = Object.assign(state.lists, {});
      lists[action.id] = {
        ...lists[action.id],
        paginating: false,
        error: action.error,
      };
      return { ...state, lists };
    }
    case UserActionTypes.SET_USER_TOKEN: {
      if (!action.fromRenew) {
        return { ...state, lists: {} };
      }
      return state;
    }
    case PageActionTypes.PAGE_FETCH_SUCCESS:
    case PageActionTypes.PAGE_PAGINATE_SUCCESS: {
      const lists = Object.assign(state.lists, {});
      for (const l of action.page.lists) {
        lists[l.uuid] = {
          ...lists[l.uuid],
          list: l,
          loading: false,
          paginating: false,
        };
      }
      return { ...state, lists };
    }
    case GameActionTypes.ADD_TO_FAVORITE_SUCCESS:
    case GameActionTypes.REMOVE_FROM_FAVORITE_SUCCESS: {
      const lists = { ...state.lists };
      if (state.lists.hasOwnProperty(FAVORITES_LIST_ID)) {
        delete lists[FAVORITES_LIST_ID];
      }
      return { ...state, lists };
    }
    default:
      return state;
  }
};
