import { ListRendererFn } from "./ListRenderer";
import styles from "../styles.module.scss";
import React from "react";
import clsx from "clsx";
import HorizontalTileList from "src/components/List/HorizontalTileList";
import { useProfiler } from "src/hooks/useProfiler";
import { useInView } from "react-intersection-observer";

/*********
 * Featured
 * Desktop: 1 rows of 4 16/9 tiles
 * Phone:
 *  Portrait :
 *      Scrollable: 1 row of n 16/9 tiles with 4 fully visible tiles
 *      Not scrollable : N/A
 *  Landscape :
 *      Scrollable: 1 row of n 16/9 tiles with 2 fully visible tiles
 *      Not scrollable: N/A
 * Tablet:
 *  Portrait:
 *     Scrollable: 1 row of n 16/9 tiles with 4 fully visible tiles
 *     Not scrollable: N/A
 *  Landscape:
 *      Scrollable: 1 row of n 16/9 tiles with 4 fully visible tiles
 *      Not scrollable: N/A
 *
 */
const FeaturedRenderer: ListRendererFn = ({
  list,
  leaveFor,
  scrollable,
  onFocus,
  page,
}) => {
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  const Root = useProfiler("flatList", { uuid: list.uuid });

  return (
    <Root>
      <div ref={tileRef} className={clsx(styles.flat, scrollable && styles.scrollable)}>
        <HorizontalTileList
          list={list}
          key={list.uuid}
          leaveFor={leaveFor}
          inView={inView}
          onFocus={onFocus}
          scrollable={scrollable}
          page={page}
        />
      </div>
    </Root>
  );
};
export { FeaturedRenderer };
