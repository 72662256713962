import { TAG } from "@blacknut/javascript-sdk/dist/api/ApiService";
import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { v1 as uuidv1 } from "uuid";
import {
  dismissOnboardingFlowLoadError,
  getSubscriptionFlowV3,
} from "../../redux/actions/App";
import {
  dismissDreiGetConfigError,
  dreiClearConfig,
  dreiGetConfig,
  dreiLogin,
} from "../../redux/actions/Drei";
import { dismissAuthenticationError } from "../../redux/actions/User";
import { State } from "../../redux/reducers";
import { OnboardingFlowV3 } from "@blacknut/javascript-sdk/dist";
import { PreshippingId } from "core/utils";
export const WRONG_CRM_ERROR_CODE = "wrong_crm";

const useDreiOnboarding = () => {
  const { user, billingInfo, authentication, onboardingFlow, organization } =
    useSelector((state: State) => state.globalState);
  const { getConfig } = useSelector((state: State) => state.dreiState);
  const { inProgress: configInProgress, error: configError, config } = getConfig;
  const { loading: flowLoading, loadingError: flowLoadError, flow } = onboardingFlow;
  const { inProgress: authenticationInProgress, error: authenticationError } =
    authentication;
  const dispatch = useDispatch();
  const shouldGetConfig = React.useRef(true);
  React.useEffect(() => {
    const init = async () => {
      if (!config && shouldGetConfig.current) {
        shouldGetConfig.current = false;
        try {
          await dreiGetConfig()(dispatch);
        } catch (e) {
          logE(TAG, "Error getting config", e);
        }
      }
    };
    init();
  }, [config, configInProgress, configError, dispatch]);

  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (!flow && !flowLoading && !flowLoadError && !mounted.current) {
      const init = async () => {
        try {
          getSubscriptionFlowV3(PreshippingId.DREI)(dispatch);
        } catch (e) {
          logE(TAG, "Error getting subflow", e);
        }
      };
      init();
    }
  }, [flow, flowLoading, flowLoadError, dispatch]);

  const state = React.useRef(process.env.NODE_ENV === "test" ? "foo" : uuidv1());
  return {
    configInProgress,
    configError,
    state: state.current,
    authenticationInProgress,
    authenticationError,
    user,
    config,
    billingInfo,
    flowLoading,
    flowLoadError,
    flow: flow as OnboardingFlowV3 | undefined,
    organization,
    onCodeRetrieved: React.useCallback(
      async (code: string) => {
        try {
          await dreiLogin(code)(dispatch);
        } catch (e) {
          logE(TAG, "caught error on login", e);
        }
      },
      [dispatch],
    ),
    dismissAuthenticationError: React.useCallback(() => {
      if (authenticationError) {
        if (authenticationError.code === WRONG_CRM_ERROR_CODE) {
          dreiClearConfig()(dispatch);
        }
        dismissAuthenticationError()(dispatch);
      }
    }, [dispatch, authenticationError]),
    dismissConfigError: React.useCallback(() => {
      dismissDreiGetConfigError()(dispatch);
    }, [dispatch]),
    dismissSubflowLoadError: React.useCallback(() => {
      dismissOnboardingFlowLoadError()(dispatch);
    }, [dispatch]),
  };
};
export { useDreiOnboarding };
