import { AppLayout } from "core";
import { ReactComponent as MyList } from "src/assets/dist/ic_collection.svg";
import { ReactComponent as Browse } from "src/assets/dist/ic_explorer.svg";
import { ReactComponent as Home } from "src/assets/dist/ic_home.svg";
import { ReactComponent as Search } from "src/assets/dist/ic_search.svg";
import { ReactComponent as BnPlus } from "src/assets/dist/ic_blacknut_plus.svg";
import { ReactComponent as LeMag } from "src/assets/dist/ic_lemag.svg";

import NavLink from "../../NavLink";
import styles from "../styles.module.scss";
import React from "react";
import { UserProfile } from "@blacknut/javascript-sdk/dist";

export interface NavsLinksProps {
  layout: AppLayout;
  pathname: string;
  activeItem: string;
  lemag: boolean;

  profile: UserProfile | undefined;
  bonusGames: boolean;
}

const NavsLinksMobile = ({
  pathname,
  activeItem,
  lemag,
  bonusGames,
}: NavsLinksProps) => {
  return (
    <div className={styles.isOnMobil}>
      <NavLink
        to="/catalog"
        replace={pathname === "/catalog"}
        isActive={() => activeItem === "catalog"}
        data-testid="catalog"
      >
        <Home className="svg" />
      </NavLink>

      {bonusGames && (
        <NavLink
          to="/plus"
          replace={pathname === "/plus"}
          isActive={() => activeItem === "plus"}
        >
          <BnPlus className="svg" />
        </NavLink>
      )}

      {lemag && !bonusGames && (
        <NavLink
          to="/lemag"
          replace={pathname === "/lemag"}
          isActive={() => activeItem === "lemag"}
          data-testid="lemag"
        >
          <LeMag className="svg" />
        </NavLink>
      )}

      <NavLink
        replace={pathname === "/myList"}
        to="/myList"
        isActive={() => activeItem === "myList"}
        data-testid="myList"
        requiresAnonymousCheck={true}
      >
        <MyList className="svg" />
      </NavLink>

      <NavLink
        to="/browse"
        replace={pathname === "/browse"}
        isActive={() => activeItem === "browse"}
        data-testid="browse"
      >
        <Browse className="svg" />
      </NavLink>

      <NavLink
        to="/search"
        replace={pathname === "/search"}
        isActive={() => activeItem === "search"}
        data-testid="search"
      >
        <Search className="svg" />
      </NavLink>
    </div>
  );
};

export default NavsLinksMobile;
