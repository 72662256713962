import { UserToken } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { forkJoin } from "rxjs";
import { Action } from "../App";
import { loadFamilyInfoSuccess, ProfilesAction, setCurrentProfile } from "../Profiles";
import { fetchRemoteNotifications } from "./fetchRemoteNotifications";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";

import {
  onUserTokenRetrieved,
  setFamilyToken,
  UserAction,
  UserActionTypes,
} from "./User";
export const autologin = (token: UserToken) => {
  return (dispatch: Dispatch<UserAction | ProfilesAction | Action>) => {
    return new Promise<UserToken>((resolve, reject) => {
      dispatch({ type: UserActionTypes.AUTO_LOGIN_REQUEST });
      setFamilyToken(token.familyToken)(dispatch);
      if (token.userToken) {
        // In case of web or if family contains only one profile, user token is also retrieved
        forkJoin([
          getFamilyInfo(),
          onUserTokenRetrieved(dispatch, token.userToken, true, false),
        ]).subscribe({
          next: (res) => {
            const { profiles, subscription } = res[0];
            const user = res[1][0];
            dispatch(loadFamilyInfoSuccess(profiles, subscription));
            dispatch(setCurrentProfile(profiles[0], user, false));
            dispatch({ type: UserActionTypes.AUTO_LOGIN_SUCCESS, user });
            fetchRemoteNotifications(user)(dispatch);
            resolve(token);
          },
          error: (error) => {
            dispatch({ type: UserActionTypes.AUTO_LOGIN_ERROR, error });
            reject(error);
          },
        });
      } else {
        getFamilyInfo().subscribe({
          next: (res) => {
            const { profiles, subscription } = res;
            dispatch(loadFamilyInfoSuccess(profiles, subscription));
            dispatch({ type: UserActionTypes.AUTO_LOGIN_SUCCESS });
            resolve(token);
          },
          error: (error) => {
            dispatch({ type: UserActionTypes.AUTO_LOGIN_ERROR, error });
            reject(error);
          },
        });
      }
    });
  };
};
