import { apiService } from "@blacknut/javascript-sdk/dist";
import React from "react";
import { isAISBuild, isDreiBuild, isM1Build, isTelecallBuild } from "src/utils/Utils";
import nativeBridge from "../utils/NativeBridge";

import "react-simple-keyboard/build/css/index.css";

const PreviewPage = () => {
  const Page = React.lazy(() => import(`src/pages/Preview`));
  return <Page />;
};
export const PreviewPageMemo = React.memo(PreviewPage);

const AccountPage = () => {
  const Page = React.lazy(() => import(`src/pages/Account`));
  return <Page />;
};
export const AccountPageMemo = React.memo(AccountPage);

const SearchPage = () => {
  const Page = React.lazy(() => import(`src/pages/Search`));
  return <Page />;
};
export const SearchPageMemo = React.memo(SearchPage);

const SearchResultsPage = () => {
  const Page = React.lazy(() => import(`src/pages/SearchResults`));
  return <Page />;
};
export const SearchResultsPageMemo = React.memo(SearchResultsPage);

const LeMagPage = () => {
  const Page = React.lazy(() => import(`src/pages/LeMag`));
  return <Page />;
};
export const LeMagPageMemo = React.memo(LeMagPage);

const LeMagArticlePage = () => {
  const Page = React.lazy(() => import(`src/pages/LeMagArticle`));
  return <Page />;
};
export const LeMagArticlePageMemo = React.memo(LeMagArticlePage);

const MyListPage = () => {
  const Page = React.lazy(() => import(`src/pages/MyList`));
  return <Page />;
};
export const MyListPageMemo = React.memo(MyListPage);

const BrowsePage = () => {
  const Page = React.lazy(() => import(`src/pages/Browse`));
  return <Page />;
};
export const BrowsePageMemo = React.memo(BrowsePage);

const CategoryPage = () => {
  const Page = React.lazy(() => import(`src/pages/Category`));
  return <Page />;
};
export const CategoryPageMemo = React.memo(CategoryPage);

const LinkPage = () => {
  const Page = React.lazy(() => import(`src/pages/Link`));
  return <Page />;
};
export const LinkPageMemo = React.memo(LinkPage);

const PassPage = () => {
  const Page = React.lazy(() => import(`src/pages/Pass`));
  return <Page />;
};
export const PassPageMemo = React.memo(PassPage);

const GameGenrePage = () => {
  const Page = React.lazy(() => import(`src/pages/Game/PageGameGenre`));
  return <Page />;
};
export const GameGenrePageMemo = React.memo(GameGenrePage);

const GamePage = () => {
  const Page = React.lazy(() => import(`src/pages/Game`));
  return <Page />;
};
export const GamePageMemo = React.memo(GamePage);

const GamePlayPage = () => {
  const Page = React.lazy(() => import(`src/pages/GamePlayPage`));
  return <Page />;
};
export const GamePlayPageMemo = React.memo(GamePlayPage);

const ActivateGamePage = () => {
  const Page = React.lazy(() => import(`src/pages/GameActivate`));
  return <Page />;
};
export const ActivateGamePageMemo = React.memo(ActivateGamePage);

const BonusPage = () => {
  const Page = React.lazy(() => import(`src/pages/Bonus`));
  return <Page />;
};
export const BonusPageMemo = React.memo(BonusPage);

const AllBonusPage = () => {
  const Page = React.lazy(() => import(`src/pages/BonusGames`));
  return <Page />;
};
export const AllBonusPageMemo = React.memo(AllBonusPage);

const AddBonusGamePage = () => {
  const Page = React.lazy(
    () => import(`src/pages/BlacknutPlus/AddBonusGame/AddBonusGame`),
  );
  return <Page />;
};
export const AddBonusGamePageMemo = React.memo(AddBonusGamePage);

// const ConfirmBlacknutPlusPage = () => {
//   const Page = React.lazy(() => import(`src/pages/BlacknutPlus/ConfirmedPage`));
//   return <Page />;
// };
// export const ConfirmBlacknutPlusPageMemo = React.memo(ConfirmBlacknutPlusPage);

// const UnlockPassPage = () => {
//   const Page = React.lazy(() => import(`src/pages/BlacknutPlus/UnlockPass/UnlockPass`));
//   return <Page />;
// };
// export const UnlockPassPageMemo = React.memo(UnlockPassPage);

const UnlockPassPage2 = () => {
  const Page = React.lazy(() => import(`src/pages/BlacknutPlus/UnlockPass2`));
  return <Page />;
};
export const UnlockPassPageMemo2 = React.memo(UnlockPassPage2);

const LoginPage = () => {
  const LoginPage = React.lazy(() => {
    if (isM1Build()) return import("src/pages/Onboarding/m1/Signin/Signin");
    if (apiService.userAgent.os === "Fireos" && nativeBridge.module?.handleInAppSignin) {
      return import("src/pages/Onboarding/amazon/signin");
    }

    throw new Error("unsupported");
  });
  return <LoginPage />;
};

export const LoginPageMemo = React.memo(LoginPage);

const OnboardingPage = () => {
  const OnboardingPage = React.lazy(() => {
    if (isM1Build()) return import("src/pages/Onboarding/m1/Welcome");
    if (isDreiBuild()) return import("src/pages/Onboarding/drei");
    if (isAISBuild()) return import("src/pages/Onboarding/ais");
    if (isTelecallBuild()) return import("src/pages/Onboarding/telecall");

    if (apiService.userAgent.os === "Fireos" && nativeBridge.module?.handleInAppSignup) {
      return import("src/pages/Onboarding/amazon");
    }
    return import("src/pages/Onboarding/Onboarding");
  });
  return <OnboardingPage />;
};
export const OnboardingageMemo = React.memo(OnboardingPage);

const SubscribePage = () => {
  const SubscribePage = React.lazy(() => {
    if (
      apiService.userAgent.os === "Fireos" &&
      nativeBridge.module?.handleInAppSubscribe
    ) {
      return import("src/pages/Onboarding/amazon/subscribe");
    }
    throw new Error("unsupported");
  });

  return <SubscribePage />;
};
export const SubscribePageMemo = React.memo(SubscribePage);

const ProfilesPage = () => {
  const Page = React.lazy(() => import(`src/pages/Profiles`));
  return <Page />;
};
export const ProfilesPageMemo = React.memo(ProfilesPage);

const ProfilePinPage = () => {
  const Page = React.lazy(() => import(`src/pages/ProfilesPin`));
  return <Page />;
};
export const ProfilePinPageMemo = React.memo(ProfilePinPage);

const OfflinePage = () => {
  const Page = React.lazy(() => import(`src/pages/Offline`));
  return <Page />;
};
export const OfflinePageMemo = React.memo(OfflinePage);
const NotificationsPage = () => {
  const Page = React.lazy(() => import(`src/pages/NotificationsPage`));
  return <Page />;
};
export const NotificationsPageMemo = React.memo(NotificationsPage);
const GamesByCategoryPage = () => {
  const Page = React.lazy(
    () => import(`src/pages/Game/PageGameGenre/PageGamesByCategory`),
  );
  return <Page />;
};
export const GamesByCategoryPageMemo = React.memo(GamesByCategoryPage);
