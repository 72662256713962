import { ListRendererFn } from "./ListRenderer";
import styles from "../styles.module.scss";
import HeroView from "../componentView/HeroView";
import React from "react";
const HeroRenderer: ListRendererFn = ({ list, leaveFor, page }) => {
  return (
    <HeroView
      list={list}
      leaveFor={leaveFor}
      key={list.uuid}
      className={styles.hero}
      page={page}
    />
  );
};
export { HeroRenderer };
