import { List, Tile, getImageByThemeName } from "@blacknut/javascript-sdk/dist";
import { useAnalytics } from "core";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useTheme } from "../../theme/ThemeProvider";
import { Button } from "@blacknut/react-sdk/dist";
import FadeInImage from "../FadeInImage/FadeInImage";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import styles from "./styles.module.scss";

interface RandomTileProps {
  item: Tile;
  list: List;
  className?: string;
  inView: boolean;
}

const RandomTile = ({ list, className, inView = true }: RandomTileProps) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  const { trackSurpriseMe } = useAnalytics();

  const _onClick = useCallback(() => {
    const idx = Math.floor(Math.random() * list.tiles.length);
    history.push(`/game/${list.tiles[idx].game?.id}`);
    trackSurpriseMe();
  }, [history, list.tiles, trackSurpriseMe]);

  const imgBackground = getImageByThemeName(theme.name, list.imgBackground ?? []);

  return (
    <Focusable
      className={clsx(styles.container, styles.focusable, className)}
      onClick={_onClick}
      onPressEnter={_onClick}
    >
      {inView && <FadeInImage image={imgBackground} className={styles.image} />}
      <div className={styles.textContainer}>
        <span className={styles.title}>{t("randomTile.text")}</span>
        <Button className={styles.button} variant="primary" onClick={_onClick} disabled>
          {t("randomTile.button")}
        </Button>
      </div>
    </Focusable>
  );
};

export default RandomTile;
