import { List, ListSortMethod } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { map } from "rxjs";
import {
  favOrLastPlayedToList,
  fetchListError,
  fetchListRequest,
  fetchListSuccess,
  LAST_PLAYED_LIST_ID,
  ListAction,
} from "./List";
import { getLastPlayed as apiGetLastPlayed } from "@blacknut/javascript-sdk/dist/api/Catalog/getLastPlayed";

export const fetchLastPlayed = (sortBy: ListSortMethod, limit?: number) => {
  return (dispatch: Dispatch<ListAction>) => {
    return new Promise<List>((resolve, reject) => {
      dispatch(fetchListRequest(LAST_PLAYED_LIST_ID, sortBy));
      apiGetLastPlayed(sortBy, 1, limit)
        .pipe(
          map(({ games, meta }) => {
            return favOrLastPlayedToList(LAST_PLAYED_LIST_ID, sortBy, games, meta.total);
          }),
        )
        .subscribe({
          next: (list) => {
            dispatch(fetchListSuccess(LAST_PLAYED_LIST_ID, sortBy, list));
            resolve(list);
          },
          error: (error) => {
            dispatch(fetchListError(LAST_PLAYED_LIST_ID, error));
            reject(error);
          },
        });
    });
  };
};
