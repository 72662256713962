import { ApiError } from "@blacknut/javascript-sdk/dist";
import { getPassPlanInfo } from "@blacknut/javascript-sdk/dist/api/BlacknutPlus/getPassPlanInfo";
import { logD, logE } from "@blacknut/logging/dist";
import { MessageModal, ModalSubscription, useModal } from "@blacknut/react-sdk";
import { AppLayout, State, useLayout, useProfiles } from "core";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { firstValueFrom } from "rxjs";
import useSubscribed from "src/hooks/useSubscribed";
import { LOGGING_TAG } from "../Utils";
import { useErrorHandling } from "../V2ErrorHandler";
import { useWarioURL } from "../useWarioURL";
import React from "react";
export const useGamePassUnlock = (passId: string) => {
  const { profile, familyProfiles } = useSelector((state: State) => state.profilesState);
  const layout = useLayout();
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const { t } = useTranslation();
  const history = useHistory();
  const { create: openWario } = useWarioURL();
  const unlockPassUrl = `/pass/${passId}/unlock`;
  const pathname = history.location?.pathname;
  const { onSelectProfile } = useProfiles();

  const [error, setError] = useState<ApiError>();
  useErrorHandling({
    error,
    clearError: () => {
      setError(undefined);
    },
  });
  const closeModal = useCallback(() => {
    modalSubscription.current?.remove();
    modalSubscription.current = undefined;
  }, []);
  const masterProfile = familyProfiles.profiles?.find((p) => p.isMaster);

  const { checkForValidSubscription } = useSubscribed();

  const unlockPass = useCallback(async () => {
    if (!profile?.isMaster) {
      logD(LOGGING_TAG, "Profile is not master");

      const redirectUrl = history.location.pathname;
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          title={t("dialogs.unlockPass.title")}
          message={t("dialogs.unlockPass.message")}
          onPressPositive={async () => {
            if (masterProfile) {
              await onSelectProfile(masterProfile, redirectUrl);
            }
          }}
          onPressNegative={closeModal}
          onClose={closeModal}
          positiveButtonText={t("buttons.switchUser")}
          negativeButtonText={t("buttons.cancel")}
        />
      ));
      return;
    }

    if (!checkForValidSubscription()) {
      return;
    }

    logD(LOGGING_TAG, "Profile is master");
    try {
      if (layout === AppLayout.TV) {
        history.replace(unlockPassUrl, { returnUrl: pathname });
      } else {
        const plan = await firstValueFrom(getPassPlanInfo(passId));
        const path = `dashboard/subscriptions/pass/${plan.publicId}/checkout`;
        const warioUrl = await openWario(path, {
          returnUrl: window.location.toString(),
        });
        window.open(warioUrl, "_self");
      }
    } catch (e) {
      logE(LOGGING_TAG, "Error BN+ get pass plan", e);
      setError(e as ApiError);
    }
  }, [
    closeModal,
    history,
    layout,
    checkForValidSubscription,
    masterProfile,
    modalPush,
    openWario,
    passId,
    pathname,
    onSelectProfile,
    profile?.isMaster,
    t,
    unlockPassUrl,
  ]);
  return { unlockPass };
};

export default useGamePassUnlock;
