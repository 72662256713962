import { Dispatch } from "redux";
import Notification from "@blacknut/javascript-sdk/dist/model/Notification";
import { Action, ActionTypes } from "./App";

export const addNotification = (notification: Notification, appearTime = 5000) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.ADD_NOTIFICATION,
      notification,
    });
    setTimeout(() => {
      clearNotification(notification)(dispatch);
    }, appearTime);
  };
};

// For tests
export const clearNotification = (notification: Notification) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.ACKNOWLEDGE_NOTIFICATION,
      notification,
    });
  };
};
// For tests
export const clearNotifications = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.CLEAR_NOTIFICATION,
    });
  };
};
