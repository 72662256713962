import { Image, List, Tile as TileModel } from "@blacknut/javascript-sdk/dist";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import { AppLayout, useAnalytics, useLayout, useTile } from "core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useInView } from "react-intersection-observer";
import { ReactComponent as Home } from "../../assets/dist/ic_home.svg";
import { useProfiler } from "../../hooks/useProfiler";
import { useTileSize } from "../../utils/TileWidthProvider";
import FadeInImage from "../FadeInImage/FadeInImage";
import styles from "./styles.module.scss";
import LeMagArticleModal from "../LeMagArticleModal/LeMagArticleModal";
import { useTranslation } from "react-i18next";
import { ModalSubscription, useModal } from "@blacknut/react-sdk";
import { useHistory } from "react-router";
/*

*/
interface TileProps {
  className?: string;
  item: TileModel;
  list: List;
}
const Tile = ({ className, item, list }: TileProps) => {
  const { trackLemagRead } = useAnalytics();
  const image = (item.lemag?.image || item.marketing?.image) as Image | undefined;
  const text = item.lemag?.name || item.marketing?.name;
  const [hovering, setHovering] = useState(false);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const { t } = useTranslation();
  const history = useHistory();
  const { onClick: onClickDefault } = useTile({
    item,
  });

  const onMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    unstable_batchedUpdates(() => {
      setHovering(false);
    });
  }, []);

  useEffect(() => {
    if (spatialNavigationActive && hovering) {
      setHovering(false);
    }
  }, [hovering, spatialNavigationActive]);

  const layout = useLayout();

  const [ref, inView] = useInView({
    threshold: 0,
  });

  const { leMagTileSize } = useTileSize();

  const Root = useProfiler("tile");
  const modalSubscription = useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const closeArticleModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);
  const onClickTV = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <LeMagArticleModal
        {...props}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        articles={list.tiles.map((e) => e.lemag!)}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        article={item.lemag!}
        onClose={closeArticleModal}
        closeButtonText={t("buttons.cancel")}
        history={history}
      />
    ));
  }, [closeArticleModal, history, item.lemag, list.tiles, modalPush, t]);
  const onClick = layout === AppLayout.TV ? onClickTV : onClickDefault;

  const handleClick = useCallback(() => {
    trackLemagRead();
    onClick();
  }, [onClick, trackLemagRead]);

  return (
    <Root>
      <Focusable
        onPressEnter={handleClick}
        className={clsx([
          styles.container,
          // !WARNING! DO NOT use class "tile" for styling
          // It is purposely not scoped since it needs to be accessible from FocusableSection defaultElement prop
          "tile",
          styles.focusable,
          text && styles.imgDescContainer,
          className,
        ])}
        onMouseEnter={layout === AppLayout.DESKTOP ? onMouseEnter : undefined}
        onMouseLeave={layout === AppLayout.DESKTOP ? onMouseLeave : undefined}
        onClick={handleClick}
        data-testid="container"
        style={{
          width: leMagTileSize.width,
          height: "100%",
        }}
      >
        <div className={clsx([styles.wrap, text && styles.noBg])} ref={ref}>
          {(inView || process.env.NODE_ENV === "test") && (
            <>
              <div className={clsx(text && styles.imgDescWrapper)}>
                <div className={styles.imageContainer}>
                  {image && (
                    <FadeInImage
                      image={image}
                      suffixOnPixelRatio1={"_sm"}
                      className={clsx([styles.fadeOut])}
                    />
                  )}

                  {item.lemag && (
                    <>
                      <div className={styles.leMagMask} />
                      <img
                        src={`https://images.blacknut.com/website/logo/lemag-white.svg`}
                        alt={item?.lemag?.name}
                        className={styles.leMagIcon}
                      />
                    </>
                  )}
                </div>

                {text && <p>{text}</p>}
              </div>

              {!image && (
                <div className={styles.missing} data-testid="placeholder">
                  <Home />
                </div>
              )}
            </>
          )}
        </div>
      </Focusable>
    </Root>
  );
};
export default React.memo(Tile);
