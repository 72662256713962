import { logD, logW } from "@blacknut/logging/dist";
import { State } from "core";
import React, { PropsWithChildren, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { nativeBridge } from "../NativeBridge";
export type BatchEventOpts = Record<string, string | number | boolean>;

const TAG = "Batch";
export const BatchContext = React.createContext<{
  trackEvent: (event: string, opts?: BatchEventOpts, tags?: string[]) => void;
  pauseMobileLanding: () => void;
  resumeMobileLanding: () => void;
}>({
  trackEvent: () => {
    logW(TAG, "noop");
  },
  pauseMobileLanding: () => {
    logW(TAG, "noop");
  },
  resumeMobileLanding: () => {
    logW(TAG, "noop");
  },
});
export const BatchContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const { user } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    nativeBridge.module?.setUser?.(user);
  }, [user]);

  const trackEvent = useCallback(
    (event: string, opts?: BatchEventOpts, tags?: string[]) => {
      if (nativeBridge.module?.trackBatchEvent)
        nativeBridge.module?.trackBatchEvent(event, opts, tags);
      else {
        logD(TAG, "Batch event %o %o %o not dispatched", event, opts, tags);
      }
    },
    [],
  );
  const pauseMobileLanding = useCallback(() => {
    nativeBridge.module?.batchPauseMobileLanding &&
      nativeBridge.module.batchPauseMobileLanding();
  }, []);

  const resumeMobileLanding = useCallback(() => {
    nativeBridge.module?.batchResumeMobileLanding &&
      nativeBridge.module.batchResumeMobileLanding();
  }, []);

  return (
    <BatchContext.Provider
      value={{ trackEvent, pauseMobileLanding, resumeMobileLanding }}
    >
      {children}
    </BatchContext.Provider>
  );
};
export const useBatch = () => useContext(BatchContext);
