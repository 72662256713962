import { useCallback } from "react";
import { useHistory } from "react-router";
import { usePreview } from "src/components/Modals/PreviewContext";

export const useMoreLink = (moreLink: string | undefined) => {
  const { active: previewActive, goToPage: goToPagePreview } = usePreview();
  const history = useHistory();

  const goToMoreLink = useCallback(() => {
    if (previewActive && moreLink) {
      goToPagePreview(moreLink);
    } else if (moreLink?.startsWith(`${process.env.REACT_APP_DEEPLINK_SCHEME}://`)) {
      history.push(
        moreLink?.replace(`${process.env.REACT_APP_DEEPLINK_SCHEME}://`, "/"),
      );
    } else if (moreLink?.startsWith("http")) {
      window.open(moreLink, "_self");
    } else if (!!moreLink) {
      history.push(`/page/${moreLink}`);
    }
  }, []);

  return { handleMoreLink: goToMoreLink };
};
