import { User, ApiError } from "@blacknut/javascript-sdk/dist";

export enum M1ActionTypes {
  M1_GENERATE_TOKEN_REQUEST = "M1_GENERATE_TOKEN_REQUEST",
  M1_GENERATE_TOKEN_SUCCESS = "M1_GENERATE_TOKEN_SUCCESS",
  M1_GENERATE_TOKEN_ERROR = "M1_GENERATE_TOKEN_ERROR",
}

export type M1Action =
  | {
      type: M1ActionTypes.M1_GENERATE_TOKEN_REQUEST;
    }
  | {
      type: M1ActionTypes.M1_GENERATE_TOKEN_SUCCESS;
      user: User;
    }
  | {
      type: M1ActionTypes.M1_GENERATE_TOKEN_ERROR;
      error?: ApiError;
    };
