import {
  ApiError,
  Config,
  FamilyStreams,
  GameGenre,
  GameStreamProtocol,
  Menu,
  OnboardingFlowV3,
  OnboardingFlowV4,
  Organization,
  Page,
  User,
  analyticsService,
} from "@blacknut/javascript-sdk/dist";
import { getSubscriptionFlow } from "@blacknut/javascript-sdk/dist/api/Subscription/Flow/getSubscriptionFlow";
import { Dispatch } from "redux";
import { IncubatingFeatures } from "../../../utils/Constants";
import Notification from "@blacknut/javascript-sdk/dist/model/Notification";
import { AppLayout } from "../../reducers/Global";
declare type OnboardingFlow = OnboardingFlowV3 | OnboardingFlowV4;

export enum ActionTypes {
  INITIALIZE_REQUEST = "INITIALIZE_REQUEST",
  INITIALIZE_SUCCESS = "INITIALIZE_SUCESS",
  INITIALIZE_ERROR = "INITIALIZE_ERROR",

  SEND_CONTACT_MESSAGE_REQUEST = "SEND_CONTACT_MESSAGE_REQUEST",
  SEND_CONTACT_MESSAGE_SUCCESS = "SEND_CONTACT_MESSAGE_SUCCESS",
  SEND_CONTACT_MESSAGE_ERROR = "SEND_CONTACT_MESSAGE_ERROR",

  ADD_NOTIFICATION = "ADD_NOTIFICATION",
  ACKNOWLEDGE_NOTIFICATION = "ACKNOWLEDGE_NOTIFICATION",
  CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION",

  SET_PLATFORM = "SET_PLATFORM",

  SET_DEEPLINK = "SET_DEEPLINK",
  SET_STATS_4_NERDS = "SET_STATS_4_NERDS",
  SET_SHOW_FPS = "SET_SHOW_FPS",
  SET_INCUBATING_FEATURES = "SET_INCUBATING_FEATURES",
  OTHER = "OTHER",

  FETCH_MENU_REQUEST = "FETCH_MENU_REQUEST",
  FETCH_MENU_SUCCESS = "FETCH_MENU_SUCCESS",
  FETCH_MENU_ERROR = "FETCH_MENU_ERROR",

  GET_ONBOARDING_FLOW_REQUEST = "GET_ONBOARDING_FLOW_REQUEST",
  GET_ONBOARDING_FLOW_SUCCESS = "GET_ONBOARDING_FLOW_SUCCESS",
  GET_ONBOARDING_FLOW_ERROR = "GET_ONBOARDING_FLOW_ERROR",

  SET_CONFIG = "SET_CONFIG",
  SET_START_URL = "SET_START_URL",

  SET_APP_LAYOUT = "SET_APP_LAYOUT",

  GET_REGISTRATION_CODE_REQUEST = "GET_REGISTRATION_CODE_REQUEST",
  GET_REGISTRATION_CODE_SUCCESS = "GET_REGISTRATION_CODE_SUCCESS",
  GET_REGISTRATION_CODE_ERROR = "GET_REGISTRATION_CODE_ERROR",

  SET_AUDID = "SET_AUDID",
  SET_DEVICE_ID = "SET_DEVICE_ID",
  SET_PRESHIPPING_ID = "SET_PRESHIPPING_ID",
  TOGGLE_MUTE = "TOGGLE_MUTE",
  SET_MUTED = "SET_MUTED",

  TOGGLE_SPATIAL_NAVIGATION = "TOGGLE_SPATIAL_NAVIGATION",

  GET_PAGE_GENRES_REQUEST = "GET_PAGE_GENRES_REQUEST",
  GET_PAGE_GENRES_SUCCESS = "GET_PAGE_GENRES_SUCCESS",
  GET_PAGE_GENRES_ERROR = "GET_PAGE_GENRES_ERROR",

  GET_PAGE_CATEGORY_REQUEST = "GET_PAGE_CATEGORY_REQUEST",
  GET_PAGE_CATEGORY_SUCCESS = "GET_PAGE_CATEGORY_SUCCESS",
  GET_PAGE_CATEGORY_ERROR = "GET_PAGE_CATEGORY_ERROR",

  GET_GENRES_REQUEST = "GET_GENRES_REQUEST",
  GET_GENRES_SUCCESS = "GET_GENRES_SUCCESS",
  GET_GENRES_ERROR = "GET_GENRES_ERROR",

  GET_CURRENT_STREAM_REQUEST = "GET_CURRENT_STREAM_REQUEST",
  GET_CURRENT_STREAM_SUCCESS = "GET_CURRENT_STREAM_SUCCESS",
  GET_CURRENT_STREAM_ERROR = "GET_CURRENT_STREAM_ERROR",

  SET_SUPPORTED_PROTOCOLS = "SET_SUPPORTED_PROTOCOLS",
  SET_LOCALE = "SET_LOCALE",

  //PWA update available
  SET_UPDATE_AVAILABLE = "SET_UPDATE_AVAILABLE",
  SET_ORGANIZATION = "SET_ORGANIZATION",
}

export type Action =
  | {
      type: ActionTypes.INITIALIZE_REQUEST;
    }
  | {
      type: ActionTypes.INITIALIZE_SUCCESS;
      config?: Config;
      user?: User;
    }
  | {
      type: ActionTypes.INITIALIZE_ERROR;
      error: ApiError;
    }
  | {
      type: ActionTypes.SEND_CONTACT_MESSAGE_REQUEST;
    }
  | {
      type: ActionTypes.SEND_CONTACT_MESSAGE_SUCCESS;
    }
  | {
      type: ActionTypes.SEND_CONTACT_MESSAGE_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.ADD_NOTIFICATION;
      notification: Notification;
    }
  | {
      type: ActionTypes.CLEAR_NOTIFICATION;
    }
  | {
      type: ActionTypes.ACKNOWLEDGE_NOTIFICATION;
      notification: Notification;
    }
  | {
      type: ActionTypes.SET_PLATFORM;
      platform: string;
    }
  | {
      type: ActionTypes.SET_DEEPLINK;
      deeplink?: string;
    }
  | {
      type: ActionTypes.SET_STATS_4_NERDS;
      active: boolean;
    }
  | {
      type: ActionTypes.SET_SHOW_FPS;
      showFPS: boolean;
    }
  | {
      type: ActionTypes.SET_SUPPORTED_PROTOCOLS;
      protocols: GameStreamProtocol[];
    }
  | {
      type: ActionTypes.GET_ONBOARDING_FLOW_REQUEST;
    }
  | {
      type: ActionTypes.GET_ONBOARDING_FLOW_SUCCESS;
      flow: OnboardingFlow;
    }
  | {
      type: ActionTypes.GET_ONBOARDING_FLOW_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.FETCH_MENU_REQUEST;
    }
  | {
      type: ActionTypes.FETCH_MENU_SUCCESS;
      menu?: Menu;
    }
  | {
      type: ActionTypes.FETCH_MENU_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.SET_CONFIG;
      config?: Config;
    }
  | {
      type: ActionTypes.SET_APP_LAYOUT;
      appLayout: AppLayout;
    }
  | {
      type: ActionTypes.GET_REGISTRATION_CODE_REQUEST;
    }
  | {
      type: ActionTypes.GET_REGISTRATION_CODE_SUCCESS;
      code: string;
      url: string;
    }
  | {
      type: ActionTypes.GET_REGISTRATION_CODE_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.GET_GENRES_REQUEST;
    }
  | {
      type: ActionTypes.GET_GENRES_SUCCESS;
      genres: GameGenre[];
    }
  | {
      type: ActionTypes.GET_GENRES_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.GET_PAGE_GENRES_REQUEST;
    }
  | {
      type: ActionTypes.GET_PAGE_GENRES_SUCCESS;
      page: Page;
    }
  | {
      type: ActionTypes.GET_PAGE_GENRES_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.GET_PAGE_CATEGORY_REQUEST;
    }
  | {
      type: ActionTypes.GET_PAGE_CATEGORY_SUCCESS;
      page: Page;
    }
  | {
      type: ActionTypes.GET_PAGE_CATEGORY_ERROR;
      error?: ApiError;
    }
  | {
      type: ActionTypes.SET_AUDID;
      audid?: string;
    }
  | {
      type: ActionTypes.SET_DEVICE_ID;
      deviceId?: string;
    }
  | {
      type: ActionTypes.SET_PRESHIPPING_ID;
      preshippingId?: string;
    }
  | {
      type: ActionTypes.TOGGLE_MUTE;
    }
  | {
      type: ActionTypes.SET_MUTED;
      muted: boolean;
    }
  | {
      type: ActionTypes.TOGGLE_SPATIAL_NAVIGATION;
    }
  | {
      type: ActionTypes.SET_INCUBATING_FEATURES;
      incubating: IncubatingFeatures;
    }
  | {
      type: ActionTypes.GET_CURRENT_STREAM_REQUEST;
      loading: boolean;
    }
  | {
      type: ActionTypes.GET_CURRENT_STREAM_SUCCESS;
      streams: FamilyStreams;
      loading: boolean;
    }
  | {
      type: ActionTypes.GET_CURRENT_STREAM_ERROR;
      loading: boolean;
      error?: ApiError;
    }
  | {
      type: ActionTypes.SET_LOCALE;
      locale?: string;
    }
  | {
      type: ActionTypes.SET_UPDATE_AVAILABLE;
      available: boolean;
    }
  | {
      type: ActionTypes.SET_ORGANIZATION;
      organization?: Organization;
    }
  | {
      type: ActionTypes.SET_START_URL;
      startUrl: string;
    };

export type AppAction = Action;

export const initializeSuccess = (config?: Config, user?: User): Action => ({
  type: ActionTypes.INITIALIZE_SUCCESS,
  config,
  user,
});

export const setSupportedProtocols = (protocols: GameStreamProtocol[]): Action => ({
  type: ActionTypes.SET_SUPPORTED_PROTOCOLS,
  protocols,
});

export const setOrganization = (organization: Organization): Action => ({
  type: ActionTypes.SET_ORGANIZATION,
  organization,
});

export const setDeeplink = (deeplink?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_DEEPLINK,
      deeplink,
    });
  };
};

export const setStats4Nerds = (active: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_STATS_4_NERDS,
      active,
    });
  };
};
export const setShowFPS = (showFPS: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_SHOW_FPS,
      showFPS,
    });
  };
};

export const setAUDID = (audid?: string) => {
  analyticsService.setDeviceId(audid);
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_AUDID,
      audid,
    });
  };
};

export const setPreshippingId = (preshippingId?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_PRESHIPPING_ID,
      preshippingId,
    });
  };
};

export const getSubscriptionFlowV3 = (preshippingId?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.GET_ONBOARDING_FLOW_REQUEST });
    return getSubscriptionFlow(preshippingId, undefined, "v3").subscribe(
      (flow) => {
        dispatch({
          type: ActionTypes.GET_ONBOARDING_FLOW_SUCCESS,
          flow: flow as OnboardingFlowV3,
        });
      },
      (error) => {
        dispatch({ type: ActionTypes.GET_ONBOARDING_FLOW_ERROR, error });
      },
    );
  };
};

export const dismissOnboardingFlowLoadError = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.GET_ONBOARDING_FLOW_ERROR });
  };
};

export const setAppLayout = (appLayout: AppLayout): Action => {
  return {
    type: ActionTypes.SET_APP_LAYOUT,
    appLayout,
  };
};

export const setIncubatingFeatures = (incubating: IncubatingFeatures) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionTypes.SET_INCUBATING_FEATURES,
      incubating,
    });
  };
};

export const toggleSpatialNavigation = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.TOGGLE_SPATIAL_NAVIGATION });
  };
};

export const setLocale = (locale?: string) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.SET_LOCALE, locale });
  };
};

export const setUpdateAvailable = (available: boolean) => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.SET_UPDATE_AVAILABLE, available });
  };
};
