import networkService from "./services/NetworkService";
export { networkService };

import mediationServerService from "./services/MediationServerService";
export { mediationServerService };

import notificationsService from "./services/NotificationsService";
export { notificationsService };

export * from "./hooks";
export * from "./utils";
export * from "./services";
export * from "./redux";

import notificationService from "./services/NotificationsService";
export { notificationService };

import speedTestService from "./services/SpeedTestService";
export { speedTestService };

import { User } from "@blacknut/javascript-sdk/dist";

export const getAisReceiptUri = (user: User) => {
  return `https://wapshop.gameloft.com/aisgamecloud/receipt.php?pid=${encodeURIComponent(
    user.partnerId as string,
  )}`;
};
