import { logE } from "@blacknut/logging/dist";
import { AppLayout, State, useLayout } from "core";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { firstValueFrom } from "rxjs";
import electronService from "../../services/ElectronService";
import { LOGGING_TAG } from "../../utils/Utils";
import { deriveToken } from "@blacknut/javascript-sdk/dist/api/deriveToken";
// Component that redirects on plan selection or payment selection when no subscription is available

const SubscriptionRedirect = (props: { onRedirect: () => void }) => {
  const { redirectToSubscription } = useSubscriptionRedirect();
  useEffect(() => {
    redirectToSubscription();
    props.onRedirect();
  }, [props, redirectToSubscription]);

  return null;
};

export const useSubscriptionRedirect = () => {
  const { organization, accountUrl, familyToken, userToken } = useSelector(
    (state: State) => state.globalState,
  );
  const { profile } = useSelector((state: State) => state.profilesState);
  const history = useHistory();
  const layout = useLayout();

  const redirectToSubscription = useCallback(async () => {
    if (!profile || !profile.isMaster) {
      return;
    }
    if (layout === AppLayout.TV) {
      history.push("/checkout/tv");
    } else if (organization && organization.subscriptionUrl) {
      window.open(organization.subscriptionUrl, "_blank");
      history.push("/");
    } else {
      try {
        const derived = await firstValueFrom(deriveToken("profile"));
        const uri = `${accountUrl}/dashboard/subscriptions?fat=${derived.familyToken.accessToken}&frt=${derived.familyToken.refreshToken}&uat=${derived.userToken?.accessToken}&urt=${derived.userToken?.refreshToken}`;
        if (electronService.isAvailable()) {
          window.open(uri, "_blank");
        } else {
          window.location.replace(uri);
        }
      } catch (e) {
        logE(LOGGING_TAG, "Caught error on token derivation %o", e);
        const uri = `${accountUrl}/dashboard/subscriptions?fat=${familyToken?.accessToken}&frt=${familyToken?.refreshToken}&uat=${userToken?.accessToken}&urt=${userToken?.refreshToken}`;
        if (electronService.isAvailable()) {
          window.open(uri, "_blank");
        } else {
          window.location.replace(uri);
        }
      }
      history.push("/");
    }
  }, [accountUrl, familyToken, history, layout, organization, profile, userToken]);
  return {
    redirectToSubscription,
  };
};
export default SubscriptionRedirect;
