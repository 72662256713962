import React, { Suspense } from "react";
import styles from "./styles.module.scss";
import { CircularProgress } from "@blacknut/react-sdk";

const SuspenseCircular = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense fallback={<CircularProgress className={styles.mainLoading} size={36} />}>
      {children}
    </Suspense>
  );
};

export default SuspenseCircular;
