import { Reducer } from "redux";
import { Action as AppAction } from "../actions/App";
import { GameAction, GameActionTypes } from "../actions/Game";
import { UserAction, UserActionTypes } from "../actions/User";
import {
  ProfilesAction,
  ProfilesActionTypes as ProfilesActionType,
} from "../actions/Profiles";

import { ApiError, Game, TipsMap } from "@blacknut/javascript-sdk/dist";

interface GameState {
  loading: boolean;
  game?: Game;
  loadingError?: ApiError;
  favorite: boolean;
  favoritePending: boolean;
  favoriteError?: ApiError;
  bonusGameError?: ApiError;
  tipsLoading: boolean;
  tipsError?: ApiError;
  tips?: TipsMap;
}
export interface GamesStateMap {
  [id: string]: GameState;
}

export interface State {
  games: GamesStateMap;
  bulkFavoritesUpdateInProgress: boolean;
  bulkFavoritesUpdateError?: ApiError;
  bulkLastPlayedUpdateInProgress: boolean;
  bulkLastPlayedUpdateError?: ApiError;
}

const initialState: State = {
  games: {},
  bulkFavoritesUpdateInProgress: false,
  bulkLastPlayedUpdateInProgress: false,
};

const updateGameState = (state: State, id: string, newState: Partial<GameState>) => {
  const currentState: GameState =
    id in state.games
      ? state.games[id]
      : { loading: false, tipsLoading: false, favoritePending: false, favorite: false };
  const res: State = { ...state, games: { ...state.games } };
  res.games[id] = { ...currentState, ...newState };
  return res;
};

export const reducer: Reducer<State> = (
  state = initialState,
  action: GameAction | AppAction | UserAction | ProfilesAction,
) => {
  switch (action.type) {
    case GameActionTypes.GAME_FETCH_REQUEST: {
      return updateGameState(state, action.id, { loading: true });
    }
    case GameActionTypes.GAME_FETCH_SUCCESS: {
      return updateGameState(state, action.game.id, {
        loading: false,
        game: action.game,
        favorite: action.isFavorite,
      });
    }
    case GameActionTypes.GAME_FETCH_ERROR: {
      return updateGameState(state, action.id, {
        loading: false,
        loadingError: action.error,
      });
    }
    case GameActionTypes.ADD_TO_FAVORITE_REQUEST:
    case GameActionTypes.REMOVE_FROM_FAVORITE_REQUEST: {
      return updateGameState(state, action.id, {
        favoritePending: true,
      });
    }
    case GameActionTypes.FAVORITE_ERROR: {
      return updateGameState(state, action.id, {
        favoritePending: false,
        favoriteError: action.error,
      });
    }
    case GameActionTypes.ADD_TO_FAVORITE_SUCCESS: {
      return updateGameState(state, action.id, {
        favorite: true,
        favoritePending: false,
      });
    }
    case GameActionTypes.REMOVE_FROM_FAVORITE_SUCCESS: {
      return updateGameState(state, action.id, {
        favorite: false,
        favoritePending: false,
      });
    }

    case GameActionTypes.BULK_REMOVE_FROM_FAVORITE_REQUEST: {
      return { ...state, bulkFavoritesUpdateInProgress: true };
    }

    case GameActionTypes.BULK_REMOVE_FROM_FAVORITE_ERROR: {
      return {
        ...state,
        bulkFavoritesUpdateError: action.error,
        bulkFavoritesUpdateInProgress: false,
      };
    }

    case GameActionTypes.BULK_REMOVE_FROM_LAST_PLAYED_REQUEST: {
      return { ...state, bulkLastPlayedUpdateInProgress: true };
    }

    case GameActionTypes.BULK_REMOVE_FROM_LAST_PLAYED_ERROR: {
      return {
        ...state,
        bulkLastPlayedUpdateError: action.error,
        bulkLastPlayedUpdateInProgress: false,
      };
    }

    case ProfilesActionType.SELECT_PROFILE_SUCCESS: {
      return {
        ...state,
        games: {},
      };
    }

    case GameActionTypes.BULK_REMOVE_FROM_FAVORITE_SUCCESS: {
      let newState = { ...state, bulkFavoritesUpdateInProgress: false };
      action.ids.forEach((id) => {
        if (id in state.games) {
          newState = updateGameState(newState, id, {
            favorite: false,
            favoritePending: false,
          });
        }
      });
      return newState;
    }

    case GameActionTypes.BULK_REMOVE_FROM_LAST_PLAYED_SUCCESS: {
      return { ...state, bulkLastPlayedUpdateInProgress: false };
    }

    case UserActionTypes.USER_UPDATE: {
      return { ...state, games: {} };
    }

    case GameActionTypes.ADD_BONUS_GAME_SUCCESS: {
      return updateGameState(state, action.game.id, {
        game: action.game,
      });
    }
    case GameActionTypes.ADD_BONUS_GAME_ERROR: {
      return updateGameState(state, action.id, {
        bonusGameError: action.error,
      });
    }
    default:
      return state;
  }
};
