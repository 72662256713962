import { List, Page, Tile as TileModel } from "@blacknut/javascript-sdk/dist";
import {
  Focusable,
  FocusableSection,
  FocusableSectionProps,
} from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import { AppLayout, useLayout } from "core";
import React, { FocusEvent } from "react";
import { useMoreLink } from "src/utils/useMoreLink";
import { ReactComponent as Arrow } from "../../../assets/dist/ic_arrow.svg";
import dimens from "../../../theme/dimens";
import { useOrientation } from "../../../utils/OrientationContext";
import { tile2Id } from "../../../utils/Utils";
import Tile from "../../Tile/Tile";
import ArrowButton from "../ArrowButton";
import globalListStyles from "../styles.module.scss";
import styles from "./styles.module.scss";

export declare type KeyProviderFn = (item: TileModel, index: number) => string;
export declare type RenderTileFn = (item: TileModel) => JSX.Element;

interface HorizontalTileListNewProps
  extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  showTitle?: boolean;
  inView?: boolean;
  className?: string;
  arrowClassName?: string;
  sectionTitleColor?: string;
  onFocus?: (e: FocusEvent<HTMLDivElement>) => void;
  isBranded?: boolean;
  scrollable?: boolean;
  renderTile?: RenderTileFn;
  keyProvider?: KeyProviderFn;
  page?: Page;
}

const Items = ({
  list,
  items,
  inView,
  renderTile,
  keyProvider,
  page,
}: {
  list: List;
  items: TileModel[];
  inView: boolean;
  renderTile?: RenderTileFn;
  keyProvider?: KeyProviderFn;
  page?: Page;
}) => {
  return (
    <ul className={styles.list}>
      {items.map((tile, index) => {
        return (
          (inView || index === 0) && (
            <li
              key={
                keyProvider ? keyProvider(list, index) : `${list.uuid}-${tile2Id(tile)}`
              }
            >
              {renderTile && renderTile(tile)}
              {!renderTile && <Tile item={tile} list={list} page={page} />}
            </li>
          )
        );
      })}
    </ul>
  );
};

const HorizontalTileListNew = ({
  list,
  showTitle = true,
  className,
  arrowClassName,
  sectionTitleColor,
  leaveFor,
  disabled,
  inView = true,
  onFocus,
  isBranded = false,
  scrollable = false,
  renderTile,
  keyProvider,
  page,
}: HorizontalTileListNewProps) => {
  const layout = useLayout();
  const { handleMoreLink } = useMoreLink(list.moreLink);
  const hasTitle = showTitle && list.title && list.title.trim().length > 0;

  return (
    <FocusableSection
      className={clsx(className, styles.container)}
      focusKey={list.uuid}
      leaveFor={leaveFor}
      disabled={disabled}
      defaultElement={{ down: "first", up: ".tile" }}
      config={{ restrict: "self-first" }}
      onFocus={onFocus}
    >
      {(hasTitle || (!!list.moreLink && layout === AppLayout.PHONE)) && (
        <div className={clsx(styles.titleContainer, scrollable && styles.scrollable)}>
          {hasTitle && (
            <Focusable
              className={clsx(
                styles.title,
                !!list.moreLink && styles.titleClickable,
                isBranded && styles.titleBranded,
              )}
              style={{ color: sectionTitleColor }}
              onClick={handleMoreLink}
              onPressEnter={handleMoreLink}
              disabled={
                !list.moreLink || window.innerWidth >= dimens.breakpoints.desktop
              }
            >
              {list.title}
              {process.env.NODE_ENV === "development" && `(${list.display})`}
              <Arrow />
            </Focusable>
          )}
        </div>
      )}

      <div className={clsx(styles.listContainer, scrollable && styles.scrollable)}>
        <Items
          list={list}
          items={list.tiles}
          inView={inView}
          renderTile={renderTile}
          keyProvider={keyProvider}
          page={page}
        />

        {list.moreLink && (
          <ArrowButton
            onPress={handleMoreLink}
            className={clsx(globalListStyles.arrowButton, arrowClassName, styles.arrow)}
          />
        )}
      </div>
    </FocusableSection>
  );
};

export default HorizontalTileListNew;
