import { createLightTheme } from "../default/Light";
import { FontWeight } from "../Theme";

const DreiTheme = createLightTheme({ colorAccent: "#00A0D2" });
const boldFont = {
  fontWeight: "normal" as FontWeight,
  fontFamily: "Helvetica600",
};
DreiTheme.default.defaultFontFamily = "Helvetica";
DreiTheme.default.backgroundColor = "#fff";
DreiTheme.default.separatorColor = "#CBCACC";

DreiTheme.default.tileCardStyle = {
  ...DreiTheme.default.tileCardStyle,
  radius: DreiTheme.default.cardStyle.radius,
};

DreiTheme.default.modalStyle = {
  ...DreiTheme.default.modalStyle,
  titleTextStyle: {
    ...DreiTheme.default.modalStyle.titleTextStyle,
    ...boldFont,
  },
};

DreiTheme.default.titleTextStyle = {
  ...DreiTheme.default.titleTextStyle,
  ...boldFont,
};
DreiTheme.default.sectionTextStyle = {
  ...DreiTheme.default.sectionTextStyle,
  ...boldFont,
};
DreiTheme.default.headerStyle = {
  ...DreiTheme.default.headerStyle,
  buttonsTintColor: "#141414",
};
DreiTheme.default.bottomBarStyle = {
  ...DreiTheme.default.bottomBarStyle,
  backgroundColor: "#141414",
  inactiveTintColor: "#ffffff",
  borderColor: "#141414",
};

DreiTheme.default.browseCardItemCardStyle = {
  ...DreiTheme.default.browseCardItemCardStyle,
  inactiveBackgroundColor: "#F1F1F1",
  ...boldFont,
};

DreiTheme.default.profileCardStyle = {
  ...DreiTheme.default.profileCardStyle,
  inactiveBackgroundColor: "#F1F1F1",
  radius: 0,
};
DreiTheme.default.primaryButton = {
  ...DreiTheme.default.primaryButton,
  hoverBackgroundColor: "#000",
  ...boldFont,
};

DreiTheme.default.secondaryButton = {
  ...DreiTheme.default.secondaryButton,
  ...boldFont,

  inactiveBorderColor: "#000000",
  inactiveTextColor: "#000000",
  activeBorderColor: "#00A0D2",
  borderWidth: 0.2,
};

DreiTheme.default.search = {
  ...DreiTheme.default.search,
  backgroundColor: "#F1F1F1",
  searchCriteriaStyle: {
    backgroundColor: "#FFFFFF",
  },
  textInputStyle: {
    inactiveBackgroundColor: "#F1F1F1",
  },
  toggleButtonStyle: {
    // ...DreiTheme.default.toggleButton,
    // ...boldFont,
    // selectedTextColor: "#00A0D2",
    // selectedTintColor: "#F1F1F1",
    // tintColor: "#F1F1F1",
    // activeTintColor: "#00A0D2",
  },
};
DreiTheme.default.accountPage = {
  ...DreiTheme.default.accountPage,
  backgroundColor: "#F1F1F1",
};

DreiTheme.default.pinPage = {
  ...DreiTheme.default.accountPage,
  backgroundColor: "#F1F1F1",
};

DreiTheme.default.checkboxStyle = {
  ...DreiTheme.default.checkboxStyle,
  inactiveTintColor: "#000000",
};

DreiTheme.default.game = {
  ...DreiTheme.default.game,
  favoriteButtonStyle: {
    ...DreiTheme.default.secondaryButton,
    inactiveBorderColor: "#FFFFFF",
    inactiveTextColor: "#FFFFFF",
  },
};

DreiTheme.default.myList = {
  ...DreiTheme.default.myList,
  headerStyle: {
    ...DreiTheme.default.myList?.headerStyle,
    textStyle: {
      ...DreiTheme.default.myList?.headerStyle?.textStyle,
      ...boldFont,
    },
  },
};

DreiTheme.default.accountCardStyle = {
  ...DreiTheme.default.accountCardStyle,
  inactiveBackgroundColor: "#F1F1F1",
};
DreiTheme.default.cardStyle = {
  ...DreiTheme.default.cardStyle,
  inactiveBackgroundColor: "#F1F1F1",
};
DreiTheme.phone = {
  ...DreiTheme.phone,

  textInputStyle: {
    ...DreiTheme.default.textInputStyle,
    inactiveBackgroundColor: "#F1F1F1",
  },
  cardStyle: {
    ...DreiTheme.default.cardStyle,
    inactiveBackgroundColor: "#F1F1F1",
  },
  profileListItemCardStyle: {
    ...DreiTheme.default.profileListItemCardStyle,
    inactiveBackgroundColor: "#F1F1F1",
  },
  listStyle: {
    ...DreiTheme.default.listStyle,
    backgroundColor: "#F1F1F1",
  },
  accountPage: {
    ...DreiTheme.default.accountPage,
    backgroundColor: "#FFFFFF",
  },
  search: {
    toggleButtonStyle: {
      ...DreiTheme.default.toggleButton,
      // tintColor: "#F1F1F1",
      // selectedTintColor: "#F1F1F1",
    },
  },
};

DreiTheme.default.logoSize = { width: 17.2, height: 2.4 };

DreiTheme.default.notificationPWA = {
  backgroundColor: "#141414",
  textColor: "#fff",
};

DreiTheme.default.toggleButton = {
  ...DreiTheme.default.toggleButton,
  inactiveBackgroundColor: "#F1F1F1",
  hoverBackgroundColor: "#000",
  hoverTextColor: "#fff",
};

DreiTheme.default.textInputStyle = {
  ...DreiTheme.default.textInputStyle,
  inactiveBackgroundColor: "#F1F1F1",
};

export default DreiTheme;
