import { ApiError, Subscription, UserProfile } from "@blacknut/javascript-sdk/dist";
import { Reducer } from "redux";
import { ProfilePref } from "@blacknut/javascript-sdk/dist";
import {
  ProfilesAction,
  ProfilesActionTypes as ProfilesActionType,
} from "../actions/Profiles";
import { UserAction, UserActionTypes as UserActionType } from "../actions/User";

export interface State {
  familyProfiles: {
    inProgress: boolean;
    error?: ApiError;
    profiles?: UserProfile[];
  };
  //FIXME check if can be removed
  //According to Eric, it can be ...
  familySubscription: {
    inProgress: boolean;
    error?: ApiError;
    subscription?: Subscription;
  };
  profile?: UserProfile;

  profilePrefs: { [id: string]: ProfilePref };

  createProfile: {
    inProgress: boolean;
    error?: ApiError;
  };
  saveProfile: {
    inProgress: boolean;
    error?: ApiError;
  };
  deleteProfile: {
    inProgress: boolean;
    error?: ApiError;
  };
  resetPin: {
    inProgress: boolean;
    error?: ApiError;
  };
}
const initialState: () => State = () => {
  return {
    createProfile: {
      inProgress: false,
    },
    saveProfile: {
      inProgress: false,
    },
    deleteProfile: {
      inProgress: false,
    },
    familyProfiles: {
      inProgress: false,
    },
    familySubscription: {
      inProgress: false,
    },
    resetPin: {
      inProgress: false,
    },
    profilePrefs: {},
  };
};

export const reducer: Reducer<State> = (
  state = initialState(),
  action: ProfilesAction | UserAction,
) => {
  switch (action.type) {
    case ProfilesActionType.SELECT_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: action.profile,
      };
    }

    case ProfilesActionType.LOAD_FAMILY_INFO_REQUEST: {
      return {
        ...state,
        familyProfiles: {
          inProgress: true,
          error: undefined,
        },
        familySubscription: { inProgress: true, error: undefined },
      };
    }
    case ProfilesActionType.LOAD_FAMILY_INFO_SUCCESS: {
      return {
        ...state,
        familyProfiles: {
          inProgress: false,
          profiles: action.profiles,
        },
        familySubscription: { inProgress: false, subscription: action.subscription },
      };
    }

    case ProfilesActionType.LOAD_FAMILY_INFO_ERROR: {
      return {
        ...state,
        familyProfiles: {
          inProgress: false,
          error: action.error,
        },
        familySubscription: { inProgress: false, error: action.error },
      };
    }

    case ProfilesActionType.CREATE_PROFILE_REQUEST: {
      return {
        ...state,
        createProfile: {
          inProgress: true,
          error: undefined,
        },
      };
    }
    case ProfilesActionType.CREATE_PROFILE_SUCCESS: {
      let profiles: UserProfile[] | undefined;
      if (state.familyProfiles && state.familyProfiles!.profiles) {
        profiles = state.familyProfiles!.profiles!.slice(0);
        profiles.push(action.profile);
      }
      return {
        ...state,
        createProfile: {
          inProgress: false,
        },
        familyProfiles: {
          inProgress: false,
          profiles,
        },
      };
    }
    case ProfilesActionType.CREATE_PROFILE_ERROR: {
      return {
        ...state,
        createProfile: {
          inProgress: false,
          error: action.error,
        },
      };
    }
    case ProfilesActionType.SAVE_PROFILE_REQUEST: {
      return {
        ...state,
        saveProfile: {
          inProgress: true,
          error: undefined,
        },
      };
    }
    case ProfilesActionType.SAVE_PROFILE_SUCCESS: {
      let profiles: UserProfile[] | undefined;
      let profile = state.profile;
      if (profile && action.profile.id === profile.id) {
        profile = action.profile;
      }
      if (state.familyProfiles && state.familyProfiles!.profiles) {
        profiles = state.familyProfiles!.profiles!.slice(0);
        const idx = profiles.findIndex((p) => p.id === action.profile.id);
        if (idx >= 0) {
          profiles[idx] = action.profile;
        }
      }
      return {
        ...state,
        saveProfile: {
          inProgress: false,
        },
        familyProfiles: {
          inProgress: false,
          profiles,
        },
        profile,
      };
    }
    case ProfilesActionType.SAVE_PROFILE_ERROR: {
      return {
        ...state,
        saveProfile: {
          inProgress: false,
          error: action.error,
        },
      };
    }
    case ProfilesActionType.DELETE_PROFILE_REQUEST: {
      return {
        ...state,
        deleteProfile: {
          inProgress: true,
          error: undefined,
        },
      };
    }
    case ProfilesActionType.DELETE_PROFILE_SUCCESS: {
      let profiles: UserProfile[] | undefined;
      if (state.familyProfiles && state.familyProfiles!.profiles) {
        profiles = state
          .familyProfiles!.profiles!.slice(0)
          .filter((p) => p.id !== action.id);
      }
      return {
        ...state,
        deleteProfile: {
          inProgress: false,
        },
        familyProfiles: {
          inProgress: false,
          profiles,
        },
      };
    }
    case ProfilesActionType.DELETE_PROFILE_ERROR: {
      return {
        ...state,
        deleteProfile: {
          inProgress: false,
          error: action.error,
        },
      };
    }

    case ProfilesActionType.RESET_PIN_REQUEST: {
      return {
        ...state,
        resetPin: {
          inProgress: true,
          error: undefined,
        },
      };
    }
    case ProfilesActionType.RESET_PIN_SUCCESS: {
      return {
        ...state,
        resetPin: {
          inProgress: false,
        },
      };
    }
    case ProfilesActionType.RESET_PIN_ERROR: {
      return {
        ...state,
        resetPin: {
          inProgress: false,
          error: action.error,
        },
      };
    }
    case UserActionType.SET_USER_TOKEN:
      if (action.fromRenew) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        profile: undefined,
      };
    case UserActionType.SET_FAMILY_TOKEN: {
      if (action.fromRenew) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        profile: undefined,
        familyProfiles: { inProgress: false },
      };
    }
    case ProfilesActionType.SELECT_THEME: {
      const myPref = state.profilePrefs[action.profile.id] || {};
      const newPref = { ...myPref, theme: action.theme };
      const newPrefs = { ...state.profilePrefs };
      newPrefs[action.profile.id] = newPref;
      return {
        ...state,
        profilePrefs: newPrefs,
      };
    }

    case ProfilesActionType.ACKNOWLEGE_GAMEPAD_ONLY: {
      const myPref = state.profilePrefs[action.profile.id] || {};
      const set = new Set(myPref.acknowledgedGamepadGames);
      set.add(action.game.id);
      const newPref = { ...myPref, acknowledgedGamepadGames: set };
      const newPrefs = { ...state.profilePrefs };
      newPrefs[action.profile.id] = newPref;
      return {
        ...state,
        profilePrefs: newPrefs,
      };
    }

    case ProfilesActionType.SET_PROFILE_PREFS: {
      return {
        ...state,
        profilePrefs: action.prefs,
      };
    }

    default:
      return state;
  }
};
