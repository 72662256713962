import { Tile as TileModel, Image } from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import React, { useCallback } from "react";
import { useInView } from "react-intersection-observer";
import { useHistory } from "react-router";
import { ReactComponent as MyAccount } from "../../assets/dist/ic_bonus_game_empty_slot_avatar.svg";
import { ReactComponent as Home } from "../../assets/dist/ic_home.svg";
import { useProfiler } from "../../hooks/useProfiler";
import { useTileSize } from "../../utils/TileWidthProvider";
import { LOGGING_TAG } from "../../utils/Utils";
import FadeInImage from "../FadeInImage/FadeInImage";
import ProfileIcon from "../ProfileIcon";
import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";

interface TileProps {
  className?: string;
  item: TileModel;
}

const Tile = ({ className, item }: TileProps) => {
  const history = useHistory();
  const onClick = useCallback(() => {
    if (item.game) {
      history.push(`/game/${item.game?.id}`);
    } else {
      history.push("/plus/games");
    }
  }, [history, item.game]);
  const { active } = useSpatialNavigation();

  const isForbiddenSlot = item.game && !item.game.name;

  const clickable = !isForbiddenSlot;

  let image: Image | undefined;
  if (item.game && !isForbiddenSlot) {
    const mainImages =
      item.game &&
      item.game.images &&
      item.game.images.filter((im) =>
        ["thumbnail", "thumbnail_featured", "main"].includes(im.type),
      );
    image = mainImages ? mainImages[0] : undefined;
  }
  if (!image && !isForbiddenSlot && item.game) {
    logE(LOGGING_TAG, "Invalid tile: %o", item);
  }

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const { bonusGameTileSize } = useTileSize();
  const { t } = useTranslation();
  const Root = useProfiler("tile");

  return (
    <Root>
      <Focusable
        onPressEnter={onClick}
        className={clsx([
          // !WARNING! DO NOT use class "tile" for styling
          // It is purposely not scoped since it needs to be accessible from FocusableSection defaultElement prop
          "tile",
          styles.container,
          clickable && styles.clickable,
          active && styles.focusable,
          className,
        ])}
        {...(clickable && { onClick })}
        data-testid="container"
        style={{
          width: bonusGameTileSize.width,
        }}
        disabled={!clickable}
      >
        <div className={styles.imgContainer} ref={ref}>
          {(inView || process.env.NODE_ENV === "test") && (
            <>
              {item.game && !isForbiddenSlot && image && <FadeInImage image={image} />}

              {item.game && !isForbiddenSlot && !image && (
                <div className={styles.missing} data-testid="placeholder">
                  <Home />
                </div>
              )}
              {!item.game && <div className={styles.empty} />}
              {isForbiddenSlot && <div className={styles.forbidden} />}
            </>
          )}
        </div>

        <div className={styles.descWrap}>
          {item.game && (
            <span>
              {t("bnPlus.nbDaysLeftTile", {
                count: item.game.addonOption?.bonusGameDaysLeft,
              })}
            </span>
          )}
          {!item.game && <span>{t("bnPlus.selectGame")}</span>}

          {item.game?.addonOption?.isBonusGame &&
            item.game.addonOption.bonusGameUser && (
              <ProfileIcon
                profile={item.game.addonOption.bonusGameUser}
                className={styles.avatar}
              />
            )}

          {!item.game && <MyAccount className={styles.avatar} />}
        </div>
      </Focusable>
    </Root>
  );
};
export default React.memo(Tile);
