import {
  ApiError,
  BillingInfo,
  Config,
  FamilyStreams,
  getConfig,
  LinkedAccount,
  Organization,
  RemoteNotification,
  Token,
  User,
  UserConfig,
} from "@blacknut/javascript-sdk/dist";
import { getCurrentSubscription } from "@blacknut/javascript-sdk/dist/api/Subscription/Current/getCurrentSubscription";
import { getUserInfo } from "@blacknut/javascript-sdk/dist/api/User/Action/getUserInfo";
import { getStreams } from "@blacknut/javascript-sdk/dist/api/Stream/getStreams";
import { batch } from "react-redux";
import { Dispatch } from "redux";
import { forkJoin, Observable, of } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import notificationsService from "../../../services/NotificationsService";
import {
  Action as AppAction,
  getCurrentStreamsSuccess,
  setConfig,
  setOrganization,
} from "../App";
export enum UserActionTypes {
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_ERROR = "USER_LOGIN_ERROR",

  USER_CREATE_REQUEST = "USER_CREATE_REQUEST",
  USER_CREATE_SUCCESS = "USER_CREATE_SUCCESS",
  USER_CREATE_ERROR = "USER_CREATE_ERROR",

  USER_AMAZON_LOGIN_REQUEST = "USER_AMAZON_LOGIN_REQUEST",
  USER_AMAZON_LOGIN_SUCCESS = "USER_AMAZON_LOGIN_SUCCESS",
  USER_AMAZON_LOGIN_ERROR = "USER_AMAZON_LOGIN_ERROR",

  USER_AMAZON_CREATE_REQUEST = "USER_AMAZON_CREATE_REQUEST",
  USER_AMAZON_CREATE_SUCCESS = "USER_AMAZON_CREATE_SUCCESS",
  USER_AMAZON_CREATE_ERROR = "USER_AMAZON_CREATE_ERROR",

  USER_AMAZON_SUBSCRIBE_REQUEST = "USER_AMAZON_SUBSCRIBE_REQUEST",
  USER_AMAZON_SUBSCRIBE_SUCCESS = "USER_AMAZON_SUBSCRIBE_SUCCESS",
  USER_AMAZON_SUBSCRIBE_ERROR = "USER_AMAZON_SUBSCRIBE_ERROR",

  USER_AMAZON_UPDATE_REQUEST = "USER_AMAZON_UPDATE_REQUEST",
  USER_AMAZON_UPDATE_SUCCESS = "USER_AMAZON_UPDATE_SUCCESS",
  USER_AMAZON_UPDATE_ERROR = "USER_AMAZON_UPDATE_ERROR",

  USER_UPDATE = "USER_UPDATE",

  FETCH_SUBSCRIPTION_REQUEST = "FETCH_SUBSCRIPTION_REQUEST",
  FETCH_SUBSCRIPTION_SUCCESS = "FETCH_SUBSCRIPTION_SUCCESS",
  FETCH_SUBSCRIPTION_ERROR = "FETCH_SUBSCRIPTION_ERROR",

  GET_LINKED_ACCOUNTS_REQUEST = "GET_LINKED_ACCOUNTS_REQUEST",
  GET_LINKED_ACCOUNTS_SUCCESS = "GET_LINKED_ACCOUNTS_SUCCESS",
  GET_LINKED_ACCOUNTS_ERROR = "GET_LINKED_ACCOUNTS_ERROR",

  SET_USER_TOKEN = "SET_USER_TOKEN",
  SET_FAMILY_TOKEN = "SET_FAMILY_TOKEN",

  SET_REMOTE_NOTIFICATIONS = "SET_REMOTE_NOTIFICATIONS",
  ADD_REMOTE_NOTIFICATIONS = "ADD_REMOTE_NOTIFICATIONS",
  DELETE_REMOTE_NOTIFICATION = "DELETE_REMOTE_NOTIFICATION",

  // FIXME Merge with FETCH_SUBSCRIPTION_SUCCESS
  SUBSCRIPTION_FETCH_SUCCESS = "SUBSCRIPTION_FETCH_SUCCESS",

  TOGGLE_NEWSLETTER_REQUEST = "TOGGLE_NEWSLETTER_REQUEST",
  TOGGLE_NEWSLETTER_SUCCESS = "TOGGLE_NEWSLETTER_SUCCESS",
  TOGGLE_NEWSLETTER_ERROR = "TOGGLE_NEWSLETTER_ERROR",

  SET_USER_CONFIG = "SET_USER_CONFIG",

  AUTO_LOGIN_REQUEST = "AUTO_LOGIN_REQUEST",
  AUTO_LOGIN_SUCCESS = "AUTO_LOGIN_SUCCESS",
  AUTO_LOGIN_ERROR = "AUTO_LOGIN_ERROR",
}

export type UserAction =
  | {
      type: UserActionTypes.USER_LOGIN_SUCCESS;
      user?: User;
    }
  | {
      type: UserActionTypes.USER_LOGIN_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.USER_CREATE_REQUEST;
    }
  | {
      type: UserActionTypes.USER_CREATE_SUCCESS;
      user: User;
    }
  | {
      type: UserActionTypes.USER_CREATE_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.USER_AMAZON_LOGIN_REQUEST;
    }
  | {
      type: UserActionTypes.USER_AMAZON_LOGIN_SUCCESS;
    }
  | {
      type: UserActionTypes.USER_AMAZON_LOGIN_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.USER_AMAZON_CREATE_REQUEST;
    }
  | {
      type: UserActionTypes.USER_AMAZON_CREATE_SUCCESS;
    }
  | {
      type: UserActionTypes.USER_AMAZON_CREATE_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.USER_AMAZON_SUBSCRIBE_REQUEST;
    }
  | {
      type: UserActionTypes.USER_AMAZON_SUBSCRIBE_SUCCESS;
      billingInfo: BillingInfo;
    }
  | {
      type: UserActionTypes.USER_AMAZON_SUBSCRIBE_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.USER_AMAZON_UPDATE_REQUEST;
    }
  | {
      type: UserActionTypes.USER_AMAZON_UPDATE_SUCCESS;
      billingInfo: BillingInfo;
    }
  | {
      type: UserActionTypes.USER_AMAZON_UPDATE_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.USER_UPDATE;
      user: User;
    }
  | {
      type: UserActionTypes.FETCH_SUBSCRIPTION_REQUEST;
    }
  | {
      type: UserActionTypes.FETCH_SUBSCRIPTION_SUCCESS;
      billingInfo: BillingInfo;
    }
  | {
      type: UserActionTypes.FETCH_SUBSCRIPTION_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.SET_USER_TOKEN;
      token?: Token;
      fromRenew: boolean;
    }
  | {
      type: UserActionTypes.SET_FAMILY_TOKEN;
      token?: Token;
      fromRenew: boolean;
    }
  | {
      type: UserActionTypes.SET_REMOTE_NOTIFICATIONS;
      notifications: RemoteNotification[];
      user: User;
    }
  | {
      type: UserActionTypes.ADD_REMOTE_NOTIFICATIONS;
      notifications: RemoteNotification[];
      user: User;
    }
  | {
      type: UserActionTypes.DELETE_REMOTE_NOTIFICATION;
      notification: RemoteNotification;
      user: User;
    }
  | {
      type: UserActionTypes.SUBSCRIPTION_FETCH_SUCCESS;
      billingInfo: BillingInfo;
    }
  | {
      type: UserActionTypes.TOGGLE_NEWSLETTER_REQUEST;
    }
  | {
      type: UserActionTypes.TOGGLE_NEWSLETTER_SUCCESS;
      user: User;
    }
  | {
      type: UserActionTypes.TOGGLE_NEWSLETTER_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.SET_USER_CONFIG;
      userConf?: UserConfig;
    }
  | {
      type: UserActionTypes.AUTO_LOGIN_REQUEST;
    }
  | {
      type: UserActionTypes.AUTO_LOGIN_SUCCESS;
      user?: User;
    }
  | {
      type: UserActionTypes.AUTO_LOGIN_ERROR;
      error?: ApiError;
    }
  | {
      type: UserActionTypes.GET_LINKED_ACCOUNTS_REQUEST;
    }
  | {
      type: UserActionTypes.GET_LINKED_ACCOUNTS_SUCCESS;
      linkedAccounts: LinkedAccount[];
    }
  | {
      type: UserActionTypes.GET_LINKED_ACCOUNTS_ERROR;
      error?: ApiError;
    };

export const userLoginSuccess = (user?: User): UserAction => ({
  type: UserActionTypes.USER_LOGIN_SUCCESS,
  user,
});

export const userLoginError = (error?: ApiError): UserAction => ({
  type: UserActionTypes.USER_LOGIN_ERROR,
  error,
});

export const setUserConfig = (userConf?: UserConfig): UserAction => ({
  type: UserActionTypes.SET_USER_CONFIG,
  userConf,
});

export const userCreateRequest = (): UserAction => ({
  type: UserActionTypes.USER_CREATE_REQUEST,
});

export const userCreateSuccess = (user: User): UserAction => ({
  type: UserActionTypes.USER_CREATE_SUCCESS,
  user,
});

export const userCreateError = (error?: ApiError): UserAction => ({
  type: UserActionTypes.USER_CREATE_ERROR,
  error,
});

export const userUpdate = (user: User): UserAction => ({
  type: UserActionTypes.USER_UPDATE,
  user,
});

/** @deprecated use onUserTokenRetrieved_ */
export const onUserTokenRetrieved = (
  dispatch: Dispatch<UserAction | AppAction>,
  token: Token,
  fetchSubscription: boolean,
  fetchActiveStreams: boolean,
): Observable<
  [User, Organization, Config, BillingInfo | undefined, FamilyStreams | undefined]
> => {
  setUserToken(token)(dispatch);
  //[User, Organization, Config, BillingInfo | undefined, FamilyStreams | undefined]
  return onUserTokenRetrievedNext(dispatch, fetchSubscription, fetchActiveStreams).pipe(
    map((res) => [res.user, res.organization, res.config, res.billingInfo, res.streams]),
  );
};

export const onUserTokenRetrieved_ = (
  dispatch: Dispatch<UserAction | AppAction>,
  token: Token,
  fetchSubscription: boolean,
  fetchActiveStreams: boolean,
) => {
  setUserToken(token)(dispatch);

  return onUserTokenRetrievedNext(dispatch, fetchSubscription, fetchActiveStreams);
};

const onUserTokenRetrievedNext = (
  dispatch: Dispatch<UserAction | AppAction>,
  fetchSubscription: boolean,
  fetchActiveStreams: boolean,
) => {
  return getUserInfo()
    .pipe(
      concatMap((res) => {
        const subs: {
          user: Observable<User>;
          organization: Observable<Organization>;
          config: Observable<Config>;
          billingInfo?: ReturnType<typeof getCurrentSubscription>;
          streams?: ReturnType<typeof getStreams>;
        } = {
          user: of(res.user),
          organization: of(res.organization),
          config: getConfig(),
        };

        if (fetchSubscription) {
          subs.billingInfo = getCurrentSubscription();
        }
        if (fetchActiveStreams) {
          subs.streams = getStreams();
        }

        return forkJoin(subs);
      }),
    )
    .pipe(
      concatMap((res) => {
        return new Observable<{
          user: User;
          organization: Organization;
          config: Config;
          billingInfo?: BillingInfo;
          streams?: FamilyStreams;
        }>((observer) => {
          batch(() => {
            dispatch(setOrganization(res.organization));
            dispatch(setConfig(res.config));

            if (res.billingInfo) {
              dispatch(fetchSubscriptionSuccess(res.billingInfo));
            }
            if (res.streams) {
              dispatch(getCurrentStreamsSuccess(res.streams));
            }
            observer.next(res);
            observer.complete();
          });
        });
      }),
    );
};
/**/
export const fetchSubscriptionSuccess = (billingInfo: BillingInfo): UserAction => ({
  type: UserActionTypes.SUBSCRIPTION_FETCH_SUCCESS,
  billingInfo,
});

export const dismissAuthenticationError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    return dispatch(userLoginError());
  };
};

export const setUserToken = (token?: Token, fromRenew = false) => {
  // https://blacknut.atlassian.net/browse/CA-742
  if (token) {
    if (notificationsService.token) {
      notificationsService.stop();
    }
    notificationsService.token = token;
    notificationsService.start();
  } else if (!token) {
    notificationsService.stop();
  }
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.SET_USER_TOKEN,
      token,
      fromRenew,
    });
  };
};

export const setFamilyToken = (token?: Token, fromRenew = false) => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch({
      type: UserActionTypes.SET_FAMILY_TOKEN,
      token,
      fromRenew,
    });
  };
};

export const dismissGetSubscriptionError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    return dispatch({ type: UserActionTypes.FETCH_SUBSCRIPTION_ERROR });
  };
};
