import { GameAction, GameActionTypes } from "./Game";
import { ApiError, Game } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { concatMap } from "rxjs";
import { addBonusGame as apiAddBonusGame } from "@blacknut/javascript-sdk/dist/api/BlacknutPlus/addBonusGame";
import { getGame as apiGetGame } from "@blacknut/javascript-sdk/dist/api/getGame";

const addBonusGameRequest = (id: string): GameAction => ({
  type: GameActionTypes.ADD_BONUS_GAME_REQUEST,
  id,
});

export const addBonusGameSuccess = (game: Game): GameAction => ({
  type: GameActionTypes.ADD_BONUS_GAME_SUCCESS,
  game,
});
export const addBonusGameError = (id: string, e?: ApiError): GameAction => ({
  type: GameActionTypes.ADD_BONUS_GAME_ERROR,
  id,
  error: e,
});
export const dismissBonusGameError = (id: string) => {
  return (dispatch: Dispatch<GameAction>) => {
    dispatch(addBonusGameError(id));
  };
};

export const addToBonusGame = (id: string) => {
  return (dispatch: Dispatch<GameAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(addBonusGameRequest(id));
      apiAddBonusGame(id)
        .pipe(concatMap(() => apiGetGame(id)))
        .subscribe({
          next: (newGame) => {
            dispatch(addBonusGameSuccess(newGame));
            resolve();
          },
          error: (error) => {
            dispatch(addBonusGameError(id, error));
            reject(error);
          },
        });
    });
  };
};
