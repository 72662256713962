export enum IncubatingFeaturesKey {
  INCUBATING_FEATURE_GAMEPAD_NAVIGATION = "gamepadNavigation",
  INCUBATING_FEATURE_INPUT_SERVER = "inputServer",
}

export type IncubatingFeatures = { [key in IncubatingFeaturesKey]?: boolean };

export const hasIncubatingFeature = (
  incubatingFeatures: IncubatingFeatures | null,
  key: IncubatingFeaturesKey,
) => {
  return incubatingFeatures && key in incubatingFeatures && incubatingFeatures[key];
};
