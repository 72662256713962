import { is_iOS } from "@blacknut/javascript-sdk/dist";
import { AppLayout, StorageKey } from "core";
import React, { useCallback } from "react";
import { useOrientation } from "src/utils/OrientationContext";
import electronService from "../../services/ElectronService";
import SuspenseCircular from "../SuspenseCircular";

const ContentLoader = ({
  deviceInfos,
  onClose,
}: {
  deviceInfos: any;
  onClose: (e: React.UIEvent<unknown>) => void;
}) => {
  const { orientation } = useOrientation();

  const browserName = deviceInfos.deviceInfos.browserName;
  const deviceType = deviceInfos.deviceInfos.deviceType;
  const os = deviceInfos.deviceInfos.userAgent;
  const model = deviceInfos.deviceInfos.deviceModel;
  const Content = React.lazy(() => {
    if (
      (browserName == "chrome" && os != "android") ||
      (browserName == "chrome" &&
        orientation === "LANDSCAPE" &&
        deviceType === AppLayout.TABLET)
    ) {
      if (orientation === "LANDSCAPE" && deviceType === AppLayout.TABLET) {
        return import("./NotificationChromeMobile");
      }
      return import("./NotificationChrome");
    }
    if (browserName == "chrome" && os === "android") {
      return import("./NotificationChromeMobile");
    }
    if (
      (browserName == "mobile-safari" && model === "ipad") ||
      (browserName == "safari" && is_iOS())
    ) {
      return import("./NotificationSafariIpad");
    }
    if (browserName == "mobile-safari" && os === "ios") {
      return import("./NotificationSafariMobile");
    }
    return Promise.resolve({ default: () => <div /> });
  });
  return <Content onClose={onClose} />;
};

const ContentLoaderMemo = React.memo(ContentLoader);

const NotificationInstall = (deviceInfos: any) => {
  const setNotificationInstall = useCallback((e: React.UIEvent<unknown>) => {
    localStorage.setItem(StorageKey.INSTALL_NOTIF, "false");
    e.stopPropagation();
    window.dispatchEvent(new Event("storage"));
  }, []);
  const deviceType = deviceInfos.deviceInfos.deviceType;
  if (deviceType === "unknown" || electronService.isAvailable()) {
    return null;
  }
  return (
    <SuspenseCircular>
      <ContentLoaderMemo deviceInfos={deviceInfos} onClose={setNotificationInstall} />
    </SuspenseCircular>
  );
};
export default NotificationInstall;
