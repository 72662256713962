import { ApiError, DreiConfig, User } from "@blacknut/javascript-sdk/dist";

export enum DreiActionTypes {
  DREI_GET_CONFIG_REQUEST = "DREI_GET_CONFIG_REQUEST",
  DREI_GET_CONFIG_SUCCESS = "DREI_GET_CONFIG_SUCCESS",
  DREI_GET_CONFIG_ERROR = "DREI_GET_CONFIG_ERROR",

  DREI_LOGIN_REQUEST = "DREI_LOGIN_REQUEST",
  DREI_LOGIN_SUCCESS = "DREI_LOGIN_SUCCESS",
  DREI_LOGIN_ERROR = "DREI_LOGIN_ERROR",
}

export type DreiAction =
  | {
      type: DreiActionTypes.DREI_GET_CONFIG_REQUEST;
    }
  | {
      type: DreiActionTypes.DREI_GET_CONFIG_SUCCESS;
      config?: DreiConfig;
    }
  | {
      type: DreiActionTypes.DREI_GET_CONFIG_ERROR;
      error?: ApiError;
    }
  | {
      type: DreiActionTypes.DREI_LOGIN_REQUEST;
    }
  | {
      type: DreiActionTypes.DREI_LOGIN_SUCCESS;
      user?: User;
    }
  | {
      type: DreiActionTypes.DREI_LOGIN_ERROR;
      error?: ApiError;
    };
