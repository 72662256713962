import { ApiError, User, UserProfile } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { forkJoin } from "rxjs";
import { loadFamilyInfoSuccess, ProfilesAction, setCurrentProfile } from "../Profiles";
import {
  fetchRemoteNotifications,
  onUserTokenRetrieved,
  setFamilyToken,
  UserAction,
} from "../User";
import { DreiAction, DreiActionTypes } from "./Drei";
import { dreiLogin as apiLogin } from "@blacknut/javascript-sdk/dist/api/User/Drei/dreiLogin";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";
export const dreiLogin = (code: string) => {
  return (dispatch: Dispatch<DreiAction | ProfilesAction | UserAction>) => {
    dispatch({ type: DreiActionTypes.DREI_LOGIN_REQUEST });
    return new Promise<{ user: User; profile: UserProfile }>((resolve, reject) => {
      apiLogin(code).subscribe({
        next: (token) => {
          setFamilyToken(token.familyToken)(dispatch);
          if (token.userToken) {
            forkJoin([
              getFamilyInfo(),
              onUserTokenRetrieved(dispatch, token.userToken, true, false),
            ]).subscribe({
              next: (res) => {
                const { profiles, subscription } = res[0];
                const user = res[1][0];

                dispatch(loadFamilyInfoSuccess(profiles, subscription));
                dispatch(setCurrentProfile(profiles[0], user, false));
                dispatch({ type: DreiActionTypes.DREI_LOGIN_SUCCESS, user });
                fetchRemoteNotifications(user)(dispatch);
                resolve({ user, profile: profiles[0] });
              },
              error: (error) => {
                dispatch({ type: DreiActionTypes.DREI_LOGIN_ERROR, error });
                reject(error);
              },
            });
          } else {
            // user token is required to get subscription. If missing it is an error
            const err: ApiError = {
              status: "500",
              code: "500",
              title: "Invalid response",
            };
            dispatch({
              type: DreiActionTypes.DREI_LOGIN_ERROR,
              error: err,
            });
            reject(err);
          }
        },
        error: (error) => {
          dispatch({ type: DreiActionTypes.DREI_LOGIN_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
