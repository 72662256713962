import { useModal } from "@blacknut/react-sdk";
import {
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router";

const useSpatialNavigationEnabler = (
  condition?: () => boolean,
  defaultFocusPath?: string,
  options?: { delayAutofocus?: number }, //ugly fix for pin page on firetv
) => {
  const { resume: resumeSpatialNav, active: spatialNavActive } = useSpatialNavigation();
  const location = useLocation();
  const locationRef = useRef<string>();
  const { delayAutofocus } = options || {};
  const { Modal } = useModal();
  const shouldAutoFocus = useRef(false);
  useLayoutEffect(() => {
    if (location.pathname !== locationRef.current && (!condition || condition())) {
      locationRef.current = location.pathname;
      resumeSpatialNav();
      if (defaultFocusPath) {
        shouldAutoFocus.current = true;
      }
    }
  }, [
    condition,
    resumeSpatialNav,
    spatialNavActive,
    defaultFocusPath,
    location.pathname,
    delayAutofocus,
  ]);

  useEffect(() => {
    if (spatialNavActive && defaultFocusPath && shouldAutoFocus.current) {
      shouldAutoFocus.current = false;
      if (delayAutofocus) {
        setTimeout(() => {
          focusSectionWithPath(defaultFocusPath);
        }, delayAutofocus);
      } else {
        focusSectionWithPath(defaultFocusPath);
      }
    }
  }, [defaultFocusPath, spatialNavActive, delayAutofocus, Modal]);
};

export { useSpatialNavigationEnabler };
