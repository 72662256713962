import * as H from "history";
import React, { PropsWithChildren } from "react";
import { Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useModal } from "@blacknut/react-sdk";

export const ModalSwitch = ({ children }: PropsWithChildren<unknown>) => {
  const location = useLocation<{ background: H.Location<unknown> }>();
  const background = location.state && location.state.background;
  const { Modal } = useModal();
  const match = useRouteMatch("/modal");

  return (
    <>
      <Switch location={background || location}>{children}</Switch>

      {/* Show the modal when a background page is set */}
      {match && Modal && <Modal isOpen={true} />}
    </>
  );
};
