import { RemoteNotification } from "@blacknut/javascript-sdk/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  markAllRemoteNotificationsAsRead,
  markRemoteNotificationAsRead,
} from "../../redux/actions/User";
import { State } from "../../redux/reducers";

export const useRemoteNotifications = () => {
  const dispatch = useDispatch();
  const { user, remoteNotifications } = useSelector((state: State) => state.globalState);
  const notifications = remoteNotifications.sort((n1, n2) => {
    return new Date(n1.createdAt).getTime() - new Date(n2.createdAt).getTime();
  });
  return {
    notifications,
    markAllAsRead: React.useCallback(() => {
      if (user) {
        markAllRemoteNotificationsAsRead(user)(dispatch);
      }
    }, [dispatch, user]),
    markAsRead: React.useCallback(
      (n: RemoteNotification) => {
        if (user) {
          markRemoteNotificationAsRead(user, n)(dispatch);
        }
      },
      [dispatch, user],
    ),
  };
};
