import { LeMagArticle } from "@blacknut/javascript-sdk/dist";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import { CSSProperties } from "react";
import styles from "./styles.module.scss";
import React from "react";
import clsx from "clsx";

interface LeMagArticleListProps {
  articles: LeMagArticle[];
  ItemProps?: {
    style?: CSSProperties;
    className?: string;
  };
  onClick: (art: LeMagArticle) => void;
  className?: string;
}
const LeMagArticleList = ({
  articles,
  ItemProps,
  onClick,
  className,
}: LeMagArticleListProps) => {
  return (
    <ul className={clsx(styles.list, className)}>
      {articles.map((art) => {
        const _onClickArticle = () => {
          onClick(art);
        };
        return (
          <Focusable
            className={styles.item}
            component="li"
            onPressEnter={_onClickArticle}
            onClick={_onClickArticle}
            key={art.title}
          >
            {art.image && (
              <div className={styles.containerImage}>
                <div className={styles.mask} />
                <img
                  src={`https://images.blacknut.com/website/logo/lemag-white.svg`}
                  alt={art.title}
                  className={styles.icon}
                />
                <img className={styles.image} alt={art.title} src={art.image.url} />
              </div>
            )}
            <span className={styles.title} style={ItemProps?.style}>
              {art.title}
            </span>
          </Focusable>
        );
      })}
    </ul>
  );
};
export default LeMagArticleList;
