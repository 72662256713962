import { List, SearchFormData } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { map } from "rxjs";
import { SearchAction, SearchActionTypes, searchResults2List } from "./Search";
import { searchGames } from "@blacknut/javascript-sdk/dist/api/searchGames";

export const paginateSearchResults = (
  query: SearchFormData,
  previousResults: List,
  limit?: number,
) => {
  limit = limit || 6;
  return (dispatch: Dispatch<SearchAction>) => {
    return new Promise<List>((resolve, reject) => {
      dispatch({ type: SearchActionTypes.SEARCH_PAGINATION_REQUEST });
      searchGames(query, previousResults.tiles.length, limit)
        .pipe(map((res) => searchResults2List(res)))
        .subscribe({
          next: (newList) => {
            const results = {
              ...previousResults,
              tiles: previousResults.tiles.concat(newList.tiles),
            };
            dispatch({
              type: SearchActionTypes.SEARCH_PAGINATION_SUCCESS,
              results,
            });
            resolve(results);
          },
          error: (error) => {
            dispatch({ type: SearchActionTypes.SEARCH_PAGINATION_ERROR, error });
            reject(error);
          },
        });
    });
  };
};
