import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";
import { GameGenre } from "@blacknut/javascript-sdk/dist";
import { getGenres as apiGetGenres } from "@blacknut/javascript-sdk/dist/api/Catalog/getGenres";
export const getGameGenres = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.GET_GENRES_REQUEST });

    return new Promise<GameGenre[]>((resolve, reject) => {
      apiGetGenres().subscribe({
        next: (res) => {
          dispatch({
            type: ActionTypes.GET_GENRES_SUCCESS,
            genres: res,
          });
          resolve(res);
        },
        error: (error) => {
          dispatch({ type: ActionTypes.GET_GENRES_ERROR, error });
          reject(error);
        },
      });
    });
  };
};

export const clearGenres = () => {
  return { type: ActionTypes.GET_GENRES_SUCCESS };
};

export const dismissGenresLoadError = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.GET_GENRES_ERROR });
  };
};
