import { FeaturedResponse, ProfileType } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { LeMagAction, LeMagActionTypes } from "./LeMag";
import { getFeaturedArticles } from "@blacknut/javascript-sdk/dist/api/LeMag/getFeaturedArticles";

export const fetchArticles = (
  page = 1,
  limit = 10,
  userProfile: ProfileType = ProfileType.GENERIC,
) => {
  return (dispatch: Dispatch<LeMagAction>) => {
    return new Promise<FeaturedResponse>((resolve, reject) => {
      dispatch({ type: LeMagActionTypes.ARTICLES_FETCH_REQUEST });
      getFeaturedArticles(page, limit, userProfile).subscribe({
        next: (response) => {
          dispatch({ type: LeMagActionTypes.ARTICLES_FETCH_SUCCESS, response });
          resolve(response);
        },
        error: (error) => {
          dispatch({ type: LeMagActionTypes.ARTICLES_FETCH_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
