import { Dispatch } from "redux";
import { UserActionTypes, UserAction } from "./User";
import { LinkedAccount } from "@blacknut/javascript-sdk/dist";
import { getLinkedAccounts as apiGetLinkedAccounts } from "@blacknut/javascript-sdk/dist/api/User/getLinkedAccounts";
export const getLinkedAccounts = () => {
  return (dispatch: Dispatch<UserAction>) => {
    return new Promise<LinkedAccount[]>((resolve, reject) => {
      dispatch({
        type: UserActionTypes.GET_LINKED_ACCOUNTS_REQUEST,
      });
      apiGetLinkedAccounts().subscribe({
        next: (res) => {
          dispatch(setLinkedAccounts(res));
          resolve(res);
        },
        error: (error) => {
          dispatch({
            type: UserActionTypes.GET_LINKED_ACCOUNTS_ERROR,
            error,
          });
          reject(error);
        },
      });
    });
  };
};

export const setLinkedAccounts = (accounts: LinkedAccount[]): UserAction => {
  return {
    type: UserActionTypes.GET_LINKED_ACCOUNTS_SUCCESS,
    linkedAccounts: accounts,
  };
};
