import { setUpdateAvailable, State, StorageKey, usePrevious } from "core";
import React, { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import electronService, {
  ElectronEvent,
  ElectronEventType,
} from "../../services/ElectronService";
import LocalStorageService from "../../services/LocalStorageService";
import {
  Button,
  MessageModal,
  ModalSubscription,
  useModal,
} from "@blacknut/react-sdk/dist";
import styles from "./styles.module.scss";

const UpdateManager = () => {
  const { user, frontUrl, updateAvailable } = useSelector(
    (state: State) => state.globalState,
  );
  const dispatch = useDispatch();
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const { t, i18n } = useTranslation();

  const updateChecked = useRef(false);

  const closeModal = useCallback(() => {
    if (modalSubscription.current) {
      modalSubscription.current.remove();
      modalSubscription.current = undefined;
    }
  }, []);

  const onUpdateWebConfirmed = useCallback(() => {
    dispatch(setUpdateAvailable(false));
    closeModal();
    window.location.reload();
  }, [closeModal, dispatch]);

  const goToDownload = useCallback(() => {
    closeModal();
    const downloadUrl =
      process.env.REACT_APP_DOWNLOAD_URL ||
      `${frontUrl}/${(i18n.language || "en-US").split("-")[0]}/download`;

    LocalStorageService.removeItem(StorageKey.UPDATE_AVAILABLE);
    window.open(downloadUrl);
  }, [closeModal, frontUrl, i18n.language]);

  const openElectronUpdateError = useCallback(() => {
    modalSubscription.current = modalPush((props) => (
      <MessageModal
        {...props}
        title={t("dialogs.updateApp.title")}
        message={t("dialogs.updateApp.updateManual")}
        buttons={
          <Button key="ok" onClick={goToDownload} className={styles.restart}>
            {t("buttons.ok")}
          </Button>
        }
      />
    ));
  }, [goToDownload, modalPush, t]);

  const onUpdateConfirmed = useCallback(() => {
    closeModal();
    LocalStorageService.removeItem(StorageKey.UPDATE_AVAILABLE);
    electronService.acceptUpdate();
  }, [closeModal]);

  const openElectronUpdateInfo = useCallback(
    (newVersion: string) => {
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          title={t("dialogs.updateApp.title")}
          message={t("dialogs.updateApp.message", { version: newVersion })}
          buttons={
            <Button key="ok" onClick={onUpdateConfirmed} className={styles.restart}>
              {t("buttons.restart")}
            </Button>
          }
        />
      ));
    },
    [modalPush, onUpdateConfirmed, t],
  );

  const prevUpdateAvailable = usePrevious(updateAvailable);
  useEffect(() => {
    if (updateAvailable && !prevUpdateAvailable) {
      modalSubscription.current = modalPush((props) => (
        <MessageModal
          {...props}
          title={t("dialogs.updateApp.title")}
          message={t("dialogs.updateApp.updateWeb")}
          buttons={
            <Button key="ok" onClick={onUpdateWebConfirmed} className={styles.restart}>
              {t("buttons.restart")}
            </Button>
          }
        />
      ));
    }
  }, [modalPush, onUpdateWebConfirmed, prevUpdateAvailable, t, updateAvailable]);

  useEffect(() => {
    if (user) {
      const checkUpdates =
        (process.env.REACT_APP_CHECK_FOR_UPDATES === undefined ||
          process.env.REACT_APP_CHECK_FOR_UPDATES === "true") &&
        electronService.isAvailable() &&
        !updateChecked.current;

      if (!checkUpdates) {
        return () => {
          //nop
        };
      }

      const subscription = electronService
        .onElectronSubject()
        .subscribe((e: ElectronEvent) => {
          const { event, data } = e;
          switch (event) {
            case ElectronEventType.onUpdateDownloaded:
              LocalStorageService.getItem(StorageKey.UPDATE_AVAILABLE).then(
                (count: number) => {
                  count = count || 0;
                  if (count > 2) {
                    openElectronUpdateError();
                    return;
                  } else {
                    openElectronUpdateInfo(data as string);
                  }
                  LocalStorageService.setItem(StorageKey.UPDATE_AVAILABLE, count + 1);
                },
              );
              break;
            case ElectronEventType.onUpdateError:
              LocalStorageService.getItem(StorageKey.UPDATE_AVAILABLE).then(
                (count: number) => {
                  count = count || 0;
                  if (count > 2) {
                    openElectronUpdateError();
                    return;
                  }
                  LocalStorageService.setItem(StorageKey.UPDATE_AVAILABLE, count + 1);
                },
              );
              break;
          }
        });
      updateChecked.current = true;
      electronService.checkUpdate();
      return () => {
        subscription.unsubscribe();
      };
    }
    return () => {
      //nope
    };
  }, [openElectronUpdateError, openElectronUpdateInfo, updateChecked, user]);
  return null;
};
export default React.memo(UpdateManager);
