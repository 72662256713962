import clsx from "clsx";
import { AppLayout } from "core/redux";
import FeaturedTileList from "src/components/List/FeaturedTileList";
import styles from "../styles.module.scss";
import { ListRendererFn } from "./ListRenderer";
import React from "react";
import HorizontalTileList from "src/components/List/HorizontalTileList";
import { useInView } from "react-intersection-observer";

/*********
 * FlatFeatured
 *
 * Desktop: 2 rows of 5 tiles; the first tile taking 2 rows
 *           0 0 1 2 3 4
 *           0 0 5 6 7 8
 *
 * Phone:
 *  Portrait :
 *      Scrollable : 2 rows of 2 tiles and 2 partially visible tiles; the first tile taking 2 rows
 *           0 0 1 2 ...
 *           0 0 n n + 1
 *      Not scrollable : 4 rows of 3 tiles; the first tile taking 2 rows
 *           0 0 1
 *           0 0 2
 *           3 4 5
 *           6 7 8
 *  Landscape :
 *      Scrollable : 1 row of 9 tiles with 3 fully visible tiles
 *           0 1 2 3 4 5 6 7 8
 *      Not scrollable : 3 rows of 3 tiles
 *           0 1 2
 *           3 4 5
 *           6 7 8
 *
 */
const FlatFeaturedRenderer: ListRendererFn = ({
  orientation,
  layout,
  scrollable,
  onFocus,
  leaveFor,
  list,
  page,
}) => {
  const [tileRef, inView] = useInView({
    threshold: 0,
  });
  if (layout === AppLayout.PHONE && orientation === "LANDSCAPE") {
    return (
      <div ref={tileRef} className={clsx(styles.flat, scrollable && styles.scrollable)}>
        <HorizontalTileList
          list={list}
          key={list.uuid}
          leaveFor={leaveFor}
          inView={inView}
          onFocus={onFocus}
          page={page}
          scrollable={scrollable}
        />
      </div>
    );
  }
  return (
    <FeaturedTileList
      list={list}
      key={list.uuid}
      leaveFor={leaveFor}
      onFocus={onFocus}
      scrollable={scrollable}
      className={clsx(
        styles.featured,
        scrollable && styles.scrollable,
        list.moreLink && styles.moreLink,
      )}
      page={page}
    />
  );
};
export default FlatFeaturedRenderer;
