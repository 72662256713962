import { logout, State } from "core";
import React, { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  MessageModal,
  useModal,
  ModalSubscription,
} from "@blacknut/react-sdk/dist";

const useAnonymousCheck = () => {
  const { push: modalPush } = useModal();
  const modalSubscription = useRef<ModalSubscription>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { user } = useSelector((state: State) => state.globalState);
  const history = useHistory();
  const onConfirmed = useCallback(() => {
    modalSubscription.current?.remove();
    logout()(dispatch);
    history.push("/welcome");
  }, [history, dispatch]);
  return {
    checkForAnonymous: useCallback(() => {
      if (user?.anonymous) {
        modalSubscription.current = modalPush((props) => (
          <MessageModal
            {...props}
            title={t("dialogs.anonymous.m1.title")}
            onClose={() => {
              modalSubscription.current?.remove();
            }}
            testID="anon"
            message={t("dialogs.anonymous.m1.message")}
            buttons={[
              <Button
                variant="secondary"
                key="cancel"
                onClick={() => {
                  modalSubscription.current?.remove();
                }}
                testID="cancel"
              >
                {t("buttons.cancel")}
              </Button>,
              <Button variant="primary" key="ok" onClick={onConfirmed} testID="confirm">
                {t("buttons.register")}
              </Button>,
            ]}
          />
        ));
        return false;
      }
      return true;
    }, [modalPush, onConfirmed, t, user?.anonymous]),
  };
};

export default useAnonymousCheck;
