import { logD, logE } from "@blacknut/logging/dist";
import {
  AppLayout,
  HasNativeNotif,
  NETWORK_EVENTS,
  State,
  StorageKey,
  initialize,
  networkService,
  useAnalytics,
  useLayout,
} from "core";
import { analyticsService, apiService } from "@blacknut/javascript-sdk/dist";
import {
  Button,
  CircularProgress,
  Notifications,
  useBackKey,
  useCustomBack,
} from "@blacknut/react-sdk/dist";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import GlobalErrorHandler from "src/components/ErrorHandler/GlobalErrorHandler";
import Header from "src/components/Header/Header";
import { HeaderContext } from "src/components/Header/HeaderProvider";
import { MenuContext } from "src/components/Menu/MenuProvider";
import SpeedTestsHandler from "src/components/SpeedTests/SpeedTestsHandler";
import UpdateManager from "src/components/UpdateManager/UpdateManager";
import useDebug from "src/hooks/useDebug";
import useDeeplink from "src/hooks/useDeeplink";
import useDeviceInfo from "src/hooks/useDeviceInfo";
import HomePage from "src/pages/Home/Home";
import { HeaderStyle } from "src/theme/Theme";
import {
  LOGGING_TAG,
  isAISBuild,
  isDreiBuild,
  isM1Build,
  isTelecallBuild,
} from "src/utils/Utils";
import { usePreview } from "../components/Modals/PreviewContext";
import useToken from "../hooks/useToken";
import { useBatch } from "../utils/BatchContext";
import nativeBridge from "../utils/NativeBridge";
import useConfig from "../utils/useConfig";
import usePerformanceMonitoring from "../utils/usePerformanceMonitoring";

import {
  MessageModal,
  ModalContextProvider,
  ModalSubscription,
  useModal,
} from "@blacknut/react-sdk";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import "react-simple-keyboard/build/css/index.css";
import { ScrollContextProvider } from "src/utils/scroll/ScrollProvider";
import Menu from "../components/Menu/Menu";
import { ModalSwitch } from "../components/Modals/ModalContext";
import { useSpatialNavigationDisabler } from "../utils/Navigation";
import {
  AccountPageMemo,
  AddBonusGamePageMemo,
  AllBonusPageMemo,
  ActivateGamePageMemo,
  BonusPageMemo,
  BrowsePageMemo,
  CategoryPageMemo,
  // ConfirmBlacknutPlusPageMemo,
  GameGenrePageMemo,
  GamePageMemo,
  GamePlayPageMemo,
  GamesByCategoryPageMemo,
  LeMagArticlePageMemo,
  LeMagPageMemo,
  LoginPageMemo,
  MyListPageMemo,
  NotificationsPageMemo,
  OfflinePageMemo,
  OnboardingageMemo,
  PassPageMemo,
  PreviewPageMemo,
  ProfilePinPageMemo,
  ProfilesPageMemo,
  SearchPageMemo,
  SearchResultsPageMemo,
  SubscribePageMemo,
  UnlockPassPageMemo2,
  LinkPageMemo,
} from "./routes";
import styles from "./styles.module.scss";
import SuspenseCircular from "src/components/SuspenseCircular";
import { detectDevice } from "src/services/detectDevice";
import { useTheme } from "src/theme/ThemeProvider";
import { CompatRoute, CompatRouter } from "react-router-dom-v5-compat";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Index = () => {
  const menuWillShowListeners = useRef<Map<() => void, () => void>>(new Map()).current;
  const menuWillHideListeners = useRef<Map<() => void, () => void>>(new Map()).current;
  const [menuHidden, setMenuHidden] = useState(false);
  const [headerHidden, setHeaderHidden] = useState(false);
  const hideMenu = useCallback(() => {
    for (const key of menuWillHideListeners.keys()) {
      key();
    }
    setMenuHidden(true);
  }, [menuWillHideListeners]);

  const showMenu = useCallback(() => {
    for (const key of menuWillShowListeners.keys()) {
      key();
    }
    setMenuHidden(false);
  }, [menuWillShowListeners]);

  const usingLayout = useLayout();
  const isOnTv = usingLayout === AppLayout.TV;
  const [headerTitle, setHeaderTitle] = useState<string | undefined>();
  const [headerRight, setHeaderRight] = useState<JSX.Element | undefined>();
  const [headerLeft, setHeaderLeft] = useState<JSX.Element | undefined>();
  const [headerStyle, setHeaderStyle] = useState<Partial<HeaderStyle> | undefined>();
  const showHeader = useCallback(() => {
    isOnTv ? setHeaderHidden(true) : setHeaderHidden(false);
  }, [isOnTv]);
  const hideHeader = useCallback(() => {
    setHeaderHidden(true);
  }, []);

  return (
    <HeaderContext.Provider
      value={{
        setTitle: setHeaderTitle,
        setHeaderRight,
        setHeaderLeft,
        showHeader,
        hideHeader,
        headerHidden,
        setHeaderStyle,
      }}
    >
      <div className={styles.content}>
        <ScrollToTop />

        <Header
          title={headerTitle}
          headerRight={headerRight}
          headerLeft={headerLeft}
          hidden={isOnTv ? true : headerHidden}
          headerStyle={headerStyle}
        />
        <div className={styles.menuContainer}>
          <MenuContext.Provider
            value={{
              showMenu,
              hideMenu,
            }}
          >
            <Menu
              className={clsx(styles.menu, menuHidden && styles.hidden)}
              hidden={menuHidden}
            />
            <div className={clsx(styles.routes, menuHidden && styles.hidden)} id="main">
              <Switch>
                <CompatRoute path="/catalog">
                  <SuspenseCircular>
                    <HomePage />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/lemag" exact>
                  <SuspenseCircular>
                    <LeMagPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/lemag/:id" exact>
                  <SuspenseCircular>
                    <LeMagArticlePageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/browse">
                  <SuspenseCircular>
                    <BrowsePageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/page/:id">
                  <SuspenseCircular>
                    <CategoryPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/link/:pageId/:linkId">
                  <SuspenseCircular>
                    <LinkPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/pass/:id" exact>
                  <SuspenseCircular>
                    <PassPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/genre/:id">
                  <SuspenseCircular>
                    <GameGenrePageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <Route path="/pages/games/:category/:id(.*)">
                  <SuspenseCircular>
                    <GamesByCategoryPageMemo />
                  </SuspenseCircular>
                </Route>
                <CompatRoute path="/game/:id" exact>
                  <SuspenseCircular>
                    <GamePageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/game/:gameId/play">
                  <SuspenseCircular>
                    <GamePlayPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/game/:gameId/activate/:provider">
                  <SuspenseCircular>
                    <ActivateGamePageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/search" exact>
                  <SuspenseCircular>
                    <SearchPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/search/results" exact>
                  <SuspenseCircular>
                    <SearchResultsPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/myList" exact>
                  <SuspenseCircular>
                    <MyListPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <Route path="/account">
                  <SuspenseCircular>
                    <AccountPageMemo />
                  </SuspenseCircular>
                </Route>
                <CompatRoute path="/preview">
                  <SuspenseCircular>
                    <PreviewPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/plus" exact>
                  <SuspenseCircular>
                    <BonusPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/plus/games">
                  <SuspenseCircular>
                    <AllBonusPageMemo />
                  </SuspenseCircular>
                </CompatRoute>
                <CompatRoute path="/addBonusGame/:gameId">
                  <SuspenseCircular>
                    <AddBonusGamePageMemo />
                  </SuspenseCircular>
                </CompatRoute>

                {/* <CompatRoute path="/unlockPass/:passId/:publisherId">
                  <SuspenseCircular>
                    <UnlockPassPageMemo />
                  </SuspenseCircular>
                </CompatRoute> */}

                <CompatRoute path="/pass/:passId/unlock">
                  <SuspenseCircular>
                    <UnlockPassPageMemo2 />
                  </SuspenseCircular>
                </CompatRoute>

                {/* <CompatRoute path="/confirmPage/:paramName/:Uuid">
                  <SuspenseCircular>
                    <ConfirmBlacknutPlusPageMemo />
                  </SuspenseCircular>
                </CompatRoute> */}

                <Route path="*">
                  <Redirect to="/catalog" />
                </Route>
              </Switch>
            </div>
          </MenuContext.Provider>
        </div>

        <SpeedTestsHandler />
      </div>
    </HeaderContext.Provider>
  );
};

const Offline = () => {
  const history = useHistory();
  useEffect(() => {
    networkService.onNetworkEvent().subscribe((e: any) => {
      if (e === NETWORK_EVENTS.OFFLINE) {
        history.replace("/offline");
      } else if (e === NETWORK_EVENTS.ONLINE) {
        history.replace("/");
      }
    });
  }, [history]);
  return null;
};

const DeeplinkHandler = () => {
  useDeeplink();
  return null;
};

const RedirectOnStartup = ({
  tokenFromCommandLine,
}: {
  tokenFromCommandLine?: boolean;
}) => {
  const { familyToken, layout } = useSelector((state: State) => state.globalState);
  const { profile } = useSelector((state: State) => state.profilesState);
  const location = useLocation();
  const { active: previewActive } = usePreview();

  const history = useHistory();
  const mounted = useRef(false);

  // Redirect on notifications first time if notifications are disabled
  useEffect(() => {
    if (
      (profile || familyToken) &&
      nativeBridge.module?.hasNativeNotif &&
      !localStorage.getItem(StorageKey.ALLOW_PUSH_NOTIF)
    ) {
      nativeBridge.module
        .hasNativeNotif()
        .then((res) => {
          if (res === HasNativeNotif.DENIED) {
            history.replace("/notifications");
            return;
          }
        })
        .catch((e) => {
          logE("Caught error on hasNativeNotif: %o", e);
        });
    }
  }, [familyToken, history, profile]);

  useEffect(() => {
    if (mounted.current) return;
    mounted.current = true;
    logD(
      LOGGING_TAG,
      "in RedirectOnStartup profile=%o, family token=%o tokenFromCommandLine: %o, preview=%o",
      profile,
      familyToken,
      tokenFromCommandLine,
      previewActive,
    );
    logD(LOGGING_TAG, "location.pathname: %o", location.pathname);

    if (previewActive) {
      return;
    }
    if (!profile && familyToken) {
      const qs = new URLSearchParams(window.location.search);
      // Passthrought all params except return url which will be given as state in order to be removed on page change
      const oldReturnUrl = qs.get("returnUrl");
      if (oldReturnUrl) {
        qs.delete("returnUrl");
      }
      let returnUrl: string | undefined = oldReturnUrl || location.pathname;
      if (returnUrl === "/profiles") {
        returnUrl = undefined;
      }
      const query = qs ? `?${qs.toString()}` : "";
      const redirect = `/profiles${query}`;
      logD(LOGGING_TAG, "Redirect to %o return=%o", redirect, returnUrl);
      history.replace(redirect, { returnUrl });
    } else if (!profile && !familyToken) {
      if (location.pathname !== "/welcome") {
        const qs = new URLSearchParams(window.location.search);
        // Passthrought all params except return url which will be given as state in order to be removed on page change
        const oldReturnUrl = qs.get("returnUrl");
        if (oldReturnUrl) {
          qs.delete("returnUrl");
        }
        const query = qs ? `?${qs.toString()}` : "";
        const redirect = `/welcome${query}`;
        let returnUrl: string | undefined = oldReturnUrl || location.pathname;
        if (returnUrl === "/welcome") {
          returnUrl = undefined;
        }
        logD(LOGGING_TAG, "Redirect to %o", redirect, returnUrl);
        history.replace(redirect, { returnUrl });
      }
    } else if (profile) {
      const qs = new URLSearchParams(window.location.search);
      const query = qs ? `?${qs.toString()}` : "";
      let redirect = `${location.pathname}`;
      if (redirect === "" || redirect === "/" || redirect === "/modal") {
        redirect = `/catalog`;
      }
      logD(
        LOGGING_TAG,
        "Redirect (3) to %o (%o,%o)",
        qs.toString(),
        location.pathname,
        redirect,
      );
      history.replace(`${redirect}${query}`);
    } else if (tokenFromCommandLine) {
      const qs = new URLSearchParams(window.location.search);
      // Passthrought all params except return url which will be given as state in order to be removed on page change
      const oldReturnUrl = qs.get("returnUrl");
      if (oldReturnUrl) {
        qs.delete("returnUrl");
      }
      const query = qs ? `?${qs.toString()}` : "";
      const redirect = `/profiles${query}`;
      let returnUrl: string | undefined = oldReturnUrl || location.pathname;
      if (returnUrl === "/profiles") {
        returnUrl = undefined;
      }
      logD(LOGGING_TAG, "Redirect to %o %o", redirect, returnUrl);
      history.replace(redirect, { returnUrl });
    }
  }, [
    history,
    profile,
    familyToken,
    location.pathname,
    tokenFromCommandLine,
    layout,
    previewActive,
  ]);
  return null;
};

const BackHandler = () => {
  useBackKey();

  const { push: backPush } = useCustomBack();
  const modalSub = useRef<ModalSubscription>();
  const { push: modalPush } = useModal();
  const { t } = useTranslation();

  useEffect(() => {
    backPush(() => {
      if (
        (nativeBridge.module?.exit &&
          (window.location.pathname === "/catalog" ||
            (apiService.userAgent.os === "Vidaa" &&
              window.location.pathname === "/profiles"))) ||
        window.location.pathname === "/welcome"
      ) {
        const onClose = () => {
          modalSub.current?.remove();
          modalSub.current = undefined;
        };
        const onConfirmed = () => {
          modalSub.current?.remove();
          modalSub.current = undefined;
          nativeBridge.module?.exit && nativeBridge.module.exit();
        };
        modalSub.current = modalPush((props) => (
          <MessageModal
            {...props}
            title={t("dialogs.exitApp.title")}
            message={t("dialogs.exitApp.message")}
            buttons={[
              <Button variant="secondary" key="cancel" onClick={onClose} testID="cancel">
                {t("buttons.cancel")}
              </Button>,
              <Button variant="primary" key="ok" onClick={onConfirmed} testID="confirm">
                {t("buttons.ok")}
              </Button>,
            ]}
          />
        ));
        return true;
      }

      return false;
    });
  }, [backPush, modalPush, t]);

  return null;
};

const SpatialNavigationDisabler = () => {
  useSpatialNavigationDisabler();
  return null;
};

const MainRoutes = ({ tokenFromCommandLine }: { tokenFromCommandLine?: boolean }) => {
  //  (to: To, options?: NavigateOptions): void;
  // (delta: number): void;
  const location = useLocation() as any;
  const history = useHistory();
  const navigate: any = useCallback(
    (to: string, options: any) => {
      //FIXME compatibility layer. to be removed once react router has been updated to version 6
      if (typeof to === "string") {
        const { state } = options || {};
        history.push(to, state);
      } else if (typeof to === "number") {
        history.go(to);
      }
    },
    [history],
  );
  const { profile } = useSelector((state: State) => state.profilesState);
  const { authentication } = useSelector((state: State) => state.globalState);

  return (
    <ModalContextProvider location={location} navigate={navigate}>
      <ScrollContextProvider>
        <SpatialNavigationDisabler />
        <RedirectOnStartup tokenFromCommandLine={tokenFromCommandLine} />
        <SuspenseCircular>
          <GlobalErrorHandler />
        </SuspenseCircular>
        <SuspenseCircular>
          <UpdateManager />
        </SuspenseCircular>
        <Offline />

        <DeeplinkHandler />

        <SuspenseCircular>
          <BackHandler />
        </SuspenseCircular>
        <ModalSwitch>
          <CompatRoute path="/offline">
            <SuspenseCircular>
              <OfflinePageMemo />
            </SuspenseCircular>
          </CompatRoute>
          <CompatRoute path="/welcome">
            <SuspenseCircular>
              <OnboardingageMemo />
            </SuspenseCircular>
          </CompatRoute>

          <CompatRoute path="/signin" exact>
            <SuspenseCircular>
              <LoginPageMemo />
            </SuspenseCircular>
          </CompatRoute>

          <CompatRoute path="/subscribe" exact>
            <SuspenseCircular>
              <SubscribePageMemo />
            </SuspenseCircular>
          </CompatRoute>

          <CompatRoute path="/profiles" exact>
            <SuspenseCircular>
              <ProfilesPageMemo />
            </SuspenseCircular>
          </CompatRoute>
          <CompatRoute path="/profiles/:id/pin">
            <SuspenseCircular>
              <ProfilePinPageMemo />
            </SuspenseCircular>
          </CompatRoute>
          <CompatRoute path="/notifications">
            <SuspenseCircular>
              <NotificationsPageMemo />
            </SuspenseCircular>
          </CompatRoute>
          <CompatRoute path="/preview">
            <SuspenseCircular>
              <PreviewPageMemo />
            </SuspenseCircular>
          </CompatRoute>

          {profile && (
            <Route path="/">
              <Index />
            </Route>
          )}
          {!profile && !authentication?.inProgress && (
            <Route path="/">
              <Redirect to="/welcome" />
            </Route>
          )}
        </ModalSwitch>
      </ScrollContextProvider>
    </ModalContextProvider>
  );
};

const AppRouter = ({ tokenFromCommandLine }: { tokenFromCommandLine?: boolean }) => {
  const _isAIS = isAISBuild();
  const _isDreiBuild = isDreiBuild();
  const _isTelecallBuild = isTelecallBuild();
  const _isM1Build = isM1Build();
  const { theme } = useTheme();

  const { appState, user, notifications } = useSelector((state: State) => ({
    appState: state.globalState.app,
    user: state.globalState.user,
    notifications: state.globalState.notifications,
  }));
  const { initialized, initializing } = appState;

  const { pauseMobileLanding, resumeMobileLanding } = useBatch();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!initialized) {
      initialize()(dispatch);
    }
  }, [dispatch, initialized]);

  useEffect(() => {
    if (!initialized) {
      pauseMobileLanding();
    } else {
      resumeMobileLanding();
    }
  }, [initialized, pauseMobileLanding, resumeMobileLanding]);

  const { trackAppOpen } = useAnalytics();

  useConfig();
  useDeviceInfo();
  useDebug();
  usePerformanceMonitoring();
  useEffect(() => {
    if ((initialized && !_isM1Build) || (_isM1Build && !user?.anonymous)) {
      const locale = apiService.locale || "NA";
      const deviceInfos = detectDevice(navigator.userAgent);

      analyticsService.setDeviceTheme(theme?.name);
      analyticsService.setDeviceBrowser(deviceInfos?.browserName);
      analyticsService.setLang(locale);
      trackAppOpen();
    }
  }, [_isM1Build, initialized, theme, trackAppOpen, user?.anonymous]);

  //FIXME review this, use new behaviour
  useEffect(() => {
    if (initialized && window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          message: "ready",
        }),
        "*",
      );
    }
  }, [initialized]);

  // in case of pin protected profile, the local storage set item is not enougth, since not stored
  const { userToken, familyToken } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    nativeBridge.module?.setUserToken && nativeBridge.module?.setUserToken(userToken);
  }, [userToken]);
  useEffect(() => {
    nativeBridge.module?.setFamilyToken &&
      nativeBridge.module?.setFamilyToken(familyToken);
  }, [familyToken]);

  useToken();

  if (!initialized || initializing) {
    return <CircularProgress size={36} className={styles.mainLoading} />;
  }
  const Router = BrowserRouter as any; /*Missing className*/

  if (_isM1Build) {
    import("src/style-m1.css");
  } else if (isDreiBuild()) {
    import("src/style-drei.css");
  } else if (isTelecallBuild()) {
    import("src/style-telecall.css");
  }
  return (
    <>
      <div className={styles.app}>
        {initialized && (
          <Router className={styles.router}>
            <CompatRouter>
              <MainRoutes tokenFromCommandLine={tokenFromCommandLine}></MainRoutes>
            </CompatRouter>
          </Router>
        )}
      </div>
      <Notifications notifications={notifications} />
    </>
  );
};
export default React.memo(AppRouter);
