import { ImageType, List, Page, Tile } from "@blacknut/javascript-sdk/dist";
import { AppLayout, State, StorageKey, useGame, useTile } from "core";
import clsx from "clsx";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import styles from "./styles.module.scss";
import stylesPass from "./pass.module.scss";
import pass from "./pass.module.scss";
import React from "react";
import { Button } from "@blacknut/react-sdk";
import { useTranslation } from "react-i18next";
import useAnonymousCheck from "src/utils/useAnonymousCheck";
import { ReactComponent as Logo } from "../../assets/dist/ic_logo.svg";
import { ReactComponent as FavoriteOff } from "../../assets/dist/ic_favorite_off_filled.svg";
import { ReactComponent as FavoriteOn } from "../../assets/dist/ic_favorite_on_filled.svg";
import { useProfiler } from "../../hooks/useProfiler";
import { useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import { ReactComponent as IcBonus } from "../../assets/dist/ic_bonus.svg";
import { ReactComponent as IcPass } from "../../assets/dist/ic_pass.svg";

export interface HeroTileProps {
  item: Tile;
  list: List;
  page?: Page;
}
const scrollTop = () => {
  document.documentElement.scroll && document.documentElement.scroll({ top: 0 });
};
const HeroTile = ({ item, list, page }: HeroTileProps) => {
  const [, setVideoStarted] = useState(false);
  const { active: previewActive } = usePreview();
  const { t } = useTranslation();
  const { checkForAnonymous } = useAnonymousCheck();
  const { onClick } = useTile({
    item,
    previewMode: previewActive,
    pageUuid: page?.uuid,
  });
  const game = item.game;
  const { favorite, toggleFavorite } = useGame({
    gameId: game?.id || "",
  });

  const image = game?.images?.filter((im) => im.type === ImageType.BACKGROUND)?.[0];

  const { layout, config } = useSelector((state: State) => state.globalState);

  const [focused, setFocused] = useState<boolean>(false);
  useEffect(() => {
    if (layout !== AppLayout.TV) return;
    const handleScroll = debounce(() => setFocused(window?.scrollY === 0), 5, {
      leading: true,
      trailing: true,
    });
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [layout]);

  const onVideoStarted = useCallback(() => {
    setVideoStarted(true);
  }, []);

  const { active: spatialNavigationActive } = useSpatialNavigation();

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const isTVLegacy = localStorage.getItem(StorageKey.TV_LEGACY) === "true";
  const Root = useProfiler("hero", {
    id: item.game?.id || "",
  });
  const _toggleFavorite = useCallback(() => {
    if (checkForAnonymous()) {
      toggleFavorite();
    }
  }, [checkForAnonymous, toggleFavorite]);
  const logo = list.imgLogo && list.imgLogo.length > 0 && list.imgLogo[0];
  if (!game) return null;
  const video = game.videos && game.videos.find((v) => v.type === "trailer");
  const bonusGames = !!config?.features?.blacknutplus;

  return (
    <Root>
      <div
        className={clsx(
          styles.container,
          spatialNavigationActive && styles.focusableBtn,
        )}
      >
        <>
          {image && (inView || process.env.NODE_ENV === "test") && (
            <FadeInImage className={styles.image} image={image} />
          )}
          <div className={clsx(styles.content)} ref={ref}>
            {logo && (
              <div className={pass.passLogoContainer}>
                <Logo className={stylesPass.passBnLogo} />
                <div className={stylesPass.passLogoSeparator} />
                <img className={pass.passLogo} src={logo.url} />
              </div>
            )}
            <h1 className={styles.title}>{game.name}</h1>
            {layout !== AppLayout.PHONE && game.descriptionHtml && (
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: game.descriptionHtml }}
              />
            )}
            <div className={styles.buttonsContainer}>
              <Button
                className={styles.seeMoreBtn}
                variant={"primary"}
                testID="seeMoreBtn"
                onClick={onClick}
                onFocus={scrollTop}
              >
                {bonusGames && !item.game?.addonOption?.isAvailableForCurrentUser && (
                  <>
                    {item.game?.addonOption?.isBonusGame && (
                      <span className={styles.bonus} data-testid="bonus">
                        <IcBonus />
                      </span>
                    )}
                    {!item.game?.addonOption?.isBonusGame &&
                      item.game?.addonOption?.inGamePass && (
                        <span className={styles.bonus} data-testid="pass">
                          <IcPass />
                        </span>
                      )}
                  </>
                )}
                {t("buttons.learnMore")}
              </Button>
              {game?.id && (
                <Button
                  className={clsx(styles.favBtn, favorite && styles.favorite)}
                  variant="icon"
                  onFocus={scrollTop}
                  testID="favorite"
                  onClick={_toggleFavorite}
                >
                  {favorite && <FavoriteOn />}
                  {!favorite && <FavoriteOff />}
                </Button>
              )}
            </div>
          </div>

          {(layout !== AppLayout.TV || focused || window?.scrollY === 0) &&
            (inView || process.env.NODE_ENV === "test") &&
            !isTVLegacy &&
            video && (
              <video
                src={video.url}
                loop
                className={styles.video}
                muted={true}
                autoPlay
                onPlaying={onVideoStarted}
                playsInline
              />
            )}
        </>
      </div>
    </Root>
  );
};
export default HeroTile;
