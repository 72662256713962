import { ContactMessageFormData, NotificationType } from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNotification,
  dismissContactSendError,
  sendContactMessage,
} from "../../redux/actions/App";
import { State } from "../../redux/reducers";
const TAG = "Support";

const useSupport = (props?: { handleErrorAsNotifications?: boolean }) => {
  const dispatch = useDispatch();
  const { user, contact } = useSelector((state: State) => state.globalState);
  const handleErrorAsNotifications =
    !props ||
    props.handleErrorAsNotifications === undefined ||
    props.handleErrorAsNotifications;

  const dismissSendError = React.useCallback(() => {
    dismissContactSendError()(dispatch);
  }, [dispatch]);

  const send = React.useCallback(
    async (data: ContactMessageFormData) => {
      try {
        await sendContactMessage(data)(dispatch);
        addNotification({
          type: NotificationType.SUCCESS,
          time: new Date().getTime(),
          message: "notifications.contact.success",
        })(dispatch);
      } catch (e) {
        logE(TAG, "Caught error sending message", handleErrorAsNotifications, e);
        if (handleErrorAsNotifications) {
          addNotification({
            type: NotificationType.ERROR,
            time: new Date().getTime(),
            message: "notifications.contact.error",
          })(dispatch);
          dismissSendError();
        } else {
          throw e;
        }
      }
    },
    [dismissSendError, dispatch, handleErrorAsNotifications],
  );
  return {
    sending: contact.inProgress,
    sendError: contact.error,
    user,
    send,
    dismissSendError,
  };
};

export { useSupport };
