import { setDeeplink, State } from "core";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import electronService, { ElectronEventType } from "../services/ElectronService";
const useDeeplink = () => {
  const { deeplink } = useSelector((state: State) => state.globalState);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    if (deeplink) {
      const path = deeplink.replace(`${process.env.REACT_APP_DEEPLINK_SCHEME}://`, "/");
      const unregister = history.listen((location) => {
        if (location.pathname === path) {
          //clear deeplink: final url reached
          setDeeplink()(dispatch);
        }
      });
      return () => {
        history.push(path);
        unregister();
      };
    }
    return () => {
      //NOP
    };
  }, [deeplink, dispatch, history]);

  useEffect(() => {
    if (!electronService.isAvailable()) return;
    const sub = electronService.onElectronSubject().subscribe((e) => {
      console.log("deeplink electronService 1: ", e);
      if (e.event === ElectronEventType.deeplinkingUrl) {
        const deeplink = e.data as string;
        console.log("deeplink electronService 2: ", deeplink);
        history.push(deeplink);
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [history]);

  useEffect(() => {
    if (electronService.isAvailable()) return;
    const onMessage = (e: MessageEvent) => {
      let data = e.data;
      if (data && typeof data === "string") {
        try {
          data = JSON.parse(data);
        } catch (error) {
          console.error("Caught error parsing data", data, e);
        }
        if (data) {
          switch (data.message) {
            case "deeplink":
              if (data.payload && typeof data.payload === "string") {
                const path = data.payload.replace(
                  `${process.env.REACT_APP_DEEPLINK_SCHEME}://`,
                  "/",
                );
                history.push(path);
              }
          }
        }
      }
    };
    window.addEventListener("message", onMessage);
    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, [history]);
};

export default useDeeplink;
