import {
  ListDisplayType,
  SearchResults,
  SearchFormData,
  List,
  ApiError,
} from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
export enum SearchActionTypes {
  SEARCH_REQUEST = "SEARCH_REQUEST",
  SEARCH_SUCCESS = "SEARCH_SUCCESS",
  SEARCH_ERROR = "SEARCH_ERROR",
  SEARCH_PAGINATION_REQUEST = "SEARCH_PAGINATION_REQUEST",
  SEARCH_PAGINATION_SUCCESS = "SEARCH_PAGINATION_SUCCESS",
  SEARCH_PAGINATION_ERROR = "SEARCH_PAGINATION_ERROR",
  CLEAR = "CLEAR",
}

export type SearchAction =
  | {
      type: SearchActionTypes.SEARCH_REQUEST;
      query: SearchFormData;
    }
  | {
      type: SearchActionTypes.SEARCH_SUCCESS;
      results: List;
    }
  | {
      type: SearchActionTypes.SEARCH_ERROR;
      error?: ApiError;
    }
  | {
      type: SearchActionTypes.SEARCH_PAGINATION_REQUEST;
    }
  | {
      type: SearchActionTypes.SEARCH_PAGINATION_SUCCESS;
      results: List;
    }
  | {
      type: SearchActionTypes.SEARCH_PAGINATION_ERROR;
      error?: ApiError;
    }
  | {
      type: SearchActionTypes.CLEAR;
    };

export const searchResults2List = ({
  total,
  tiles,
  isPaginated,
}: SearchResults): List => {
  return {
    total,
    tiles,
    isPaginated,
    display: ListDisplayType.FULL,
    uuid: "results",
  };
};

export const clear = (): SearchAction => {
  return { type: SearchActionTypes.CLEAR };
};

export const dismissSearchError = () => {
  return (dispatch: Dispatch<SearchAction>) => {
    dispatch({ type: SearchActionTypes.SEARCH_ERROR });
  };
};
