import { RemoteNotification } from "@blacknut/javascript-sdk/dist";
import { setAndroidToken } from "@blacknut/javascript-sdk/dist/api/setAndroidToken";
import { logD, logE } from "@blacknut/logging/dist";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { dismissHomePageError, fetchPage, paginatePage } from "../../redux/actions/Page";
import { markRemoteNotificationAsRead } from "../../redux/actions/User";
import { State } from "../../redux/reducers";
const TAG = "Home";
export const useHomePage = (props: { tilesLimit: number; limit: number }) => {
  const { tilesLimit, limit } = props;
  const dispatch = useDispatch();
  const { loading, page, error, paginating } = useSelector(
    (state: State) => state.pagesState.pages.home,
  ) || {
    loading: false,
    paginating: false,
  };
  const { deviceId, remoteNotifications, user } = useSelector(
    (state: State) => state.globalState,
  );

  const mounted = React.useRef(false);
  React.useEffect(() => {
    if (!page && !mounted.current) {
      const init = async () => {
        mounted.current = true;
        try {
          await fetchPage("home", limit, tilesLimit)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error getting home");
        }
        if (deviceId) {
          setAndroidToken(deviceId).subscribe({
            next: () => {
              logD(TAG, "Device token sent");
            },
            error: (error) => {
              logE(TAG, "Error sending token", error);
            },
          });
        }
      };
      init();
    }
  }, [page, loading, error, limit, tilesLimit, dispatch, deviceId]);
  return {
    loading,
    page,
    loadingError: error,
    paginating,
    dismissLoadingError: React.useCallback(() => {
      dismissHomePageError()(dispatch);
    }, [dispatch]),
    paginate: React.useCallback(async () => {
      if (page) {
        try {
          await paginatePage("home", page, limit, tilesLimit)(dispatch);
        } catch (e) {
          logE(TAG, "Caught error on pagination", e);
        }
      }
    }, [dispatch, limit, page, tilesLimit]),
    remoteNotifications,
    markRemoteNotificationAsRead: React.useCallback(
      (n: RemoteNotification) => {
        if (user) {
          markRemoteNotificationAsRead(user, n)(dispatch);
        }
      },
      [user, dispatch],
    ),
  };
};
