import { Dispatch } from "redux";
import { Action, ActionTypes } from "./App";
import { ApiError, Menu } from "@blacknut/javascript-sdk/dist";
import { getMenu as apiGetMenu } from "@blacknut/javascript-sdk/dist/api/Catalog/getMenu";
export const fetchMenuSuccess = (menu?: Menu): Action => {
  return { type: ActionTypes.FETCH_MENU_SUCCESS, menu };
};

export const getMenu = () => {
  return (dispatch: Dispatch<Action>) => {
    return new Promise<Menu>((resolve, reject) => {
      dispatch({ type: ActionTypes.FETCH_MENU_REQUEST });
      apiGetMenu().subscribe({
        next: (menu: Menu) => {
          dispatch(fetchMenuSuccess(menu));
          resolve(menu);
        },
        error: (error: ApiError) => {
          dispatch({ type: ActionTypes.FETCH_MENU_ERROR, error });
          reject(error);
        },
      });
    });
  };
};

export const dismissMenuError = () => {
  return (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionTypes.FETCH_MENU_ERROR, error: undefined });
  };
};
