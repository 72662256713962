import { logE } from "@blacknut/logging/dist";
import { Image } from "@blacknut/javascript-sdk/dist";
import clsx from "clsx";
import * as React from "react";
import { ReactComponent as Home } from "../../assets/dist/ic_home.svg";
import { LOGGING_TAG } from "../../utils/Utils";
import styles from "./styles.module.scss";

export interface FadeInImageProps {
  image?: Pick<Image, "url">;
  className?: string;
  width?: number;
  height?: number;
  suffixOnPixelRatio1?: string;
}

const FadeInImage = ({
  className,
  width,
  height,
  image,
  suffixOnPixelRatio1,
}: FadeInImageProps) => {
  const style: { width?: number; height?: number; filter?: string } = {};
  const [error, setError] = React.useState(false);
  if (width !== undefined) {
    style.width = width;
  }
  if (height !== undefined) {
    style.height = height;
  }

  let url = image && image.url;
  if (url && window.devicePixelRatio === 1 && suffixOnPixelRatio1) {
    url = `${url.slice(0, url.length - 4)}${suffixOnPixelRatio1}${url.slice(
      url.length - 4,
    )}`;
  }
  const onError = React.useCallback(() => {
    logE(LOGGING_TAG, "Caught error getting image %o", url);
    setError(true);
  }, [url]);
  return (
    <>
      {image && !error && (
        <img
          onError={onError}
          className={clsx(styles.image, className)}
          style={style}
          src={url}
        />
      )}
      {(!image || error) && (
        <div className={clsx(styles.placeholder, className)} style={style}>
          <Home />
        </div>
      )}
    </>
  );
};
export default React.memo(FadeInImage);
