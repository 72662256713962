import { GameAction, GameActionTypes, favoriteError } from "./Game";
import { Game } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { removeFromFavorite as apiRemoveFromFavorite } from "@blacknut/javascript-sdk/dist/api/removeFromFavorite";
export const removeFromFavoriteRequest = (id: string): GameAction => ({
  type: GameActionTypes.REMOVE_FROM_FAVORITE_REQUEST,
  id,
});

export const removeFromFavoriteSuccess = (gameId: string): GameAction => ({
  type: GameActionTypes.REMOVE_FROM_FAVORITE_SUCCESS,
  id: gameId,
});

export const removeFromFavorite = (game: Game) => {
  return (dispatch: Dispatch<GameAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(removeFromFavoriteRequest(game.id));
      apiRemoveFromFavorite(game.id).subscribe({
        next: () => {
          dispatch(removeFromFavoriteSuccess(game.id));
          resolve();
        },
        error: (error) => {
          dispatch(favoriteError(game.id, error));
          reject(error);
        },
      });
    });
  };
};
