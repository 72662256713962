import {
  ApiError,
  Game,
  List,
  ListDisplayType,
  ListSortMethod,
  Tile,
} from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
export const LAST_PLAYED_LIST_ID = "last_played";
export const FAVORITES_LIST_ID = "favorites";
export enum ListActionTypes {
  LIST_FETCH_REQUEST = "LIST_FETCH_REQUEST",
  LIST_FETCH_SUCCESS = "LIST_FETCH_SUCCESS",
  LIST_FETCH_ERROR = "LIST_FETCH_ERROR",
  LIST_PAGINATION_REQUEST = "LIST_PAGINATION_REQUEST",
  LIST_PAGINATION_SUCCESS = "LIST_PAGINATION_SUCCESS",
  LIST_PAGINATION_ERROR = "LIST_PAGINATION_ERROR",
  OTHER = "OTHER",
  REMOVE_LIST = "REMOVE_LIST",
}

export type ListAction =
  | {
      type: ListActionTypes.LIST_FETCH_REQUEST;
      id: string;
      sort: ListSortMethod;
    }
  | {
      type: ListActionTypes.LIST_FETCH_SUCCESS;
      id: string;
      list: List;
      sort: ListSortMethod;
    }
  | {
      type: ListActionTypes.LIST_FETCH_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: ListActionTypes.LIST_PAGINATION_REQUEST;
      id: string;
    }
  | {
      type: ListActionTypes.LIST_PAGINATION_SUCCESS;
      id: string;
      list: List;
    }
  | {
      type: ListActionTypes.LIST_PAGINATION_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: ListActionTypes.REMOVE_LIST;
      id: string;
    }
  | {
      type: ListActionTypes.OTHER;
    };

export const fetchListRequest = (id: string, sort: ListSortMethod): ListAction => ({
  type: ListActionTypes.LIST_FETCH_REQUEST,
  id,
  sort,
});

export const removeList = (id: string): ListAction => ({
  type: ListActionTypes.REMOVE_LIST,
  id,
});

export const fetchListSuccess = (
  id: string,
  sort: ListSortMethod,
  list: List,
): ListAction => ({
  type: ListActionTypes.LIST_FETCH_SUCCESS,
  id,
  list,
  sort,
});

export const fetchListError = (id: string, error?: ApiError): ListAction => ({
  type: ListActionTypes.LIST_FETCH_ERROR,
  id,
  error,
});

export const dismissListError = (id: string) => {
  return (dispatch: Dispatch<ListAction>) => {
    dispatch(fetchListError(id));
  };
};

export const favOrLastPlayedToList = (
  uuid: string,
  sort: ListSortMethod,
  games: Game[],
  total: number,
) => {
  return {
    display: ListDisplayType.FULL,
    uuid,
    total,
    sort,
    isPaginated: false,
    tiles: games.map((game) => {
      return {
        game,
      } as Tile;
    }),
  } as List;
};
