import { State } from "core";
import { RemoteNotification, UserProfile } from "@blacknut/javascript-sdk/dist";

import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import dimens from "src/theme/dimens";
import { ReactComponent as AvatarDefault } from "../../assets/dist/ic_account_default.svg";
import useAnonymousCheck from "../../utils/useAnonymousCheck";
import AvatarImage from "../AvatarSelectionModal/AvatarImage";
import NavLink from "../NavLink";
import styles from "./styles.module.scss";

export const unreadNotificationsSelector = createSelector(
  (state: State) => state.globalState.remoteNotifications,
  (remoteNotifications: RemoteNotification[]) => {
    return remoteNotifications.filter((notif) => notif.isNew).length;
  },
);

interface AccountIconProps {
  activeItem: string;
  profile: UserProfile;
}
const AccountIcon = ({ activeItem, profile }: AccountIconProps) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const unreadNotificationsLen = useSelector(unreadNotificationsSelector);
  const { checkForAnonymous } = useAnonymousCheck();
  const onClick = useCallback(
    (e: React.UIEvent<unknown>) => {
      e.preventDefault();
      if (checkForAnonymous()) {
        history.push(
          window?.innerWidth < dimens.breakpoints.desktop
            ? "/account/me"
            : "/account/profiles/switch",
        );
      }
    },
    [checkForAnonymous, history],
  );
  return (
    <NavLink
      data-testid="account"
      to={"/account/me"}
      replace={pathname === "/account/me"}
      isActive={() => activeItem === "account"}
      activeClassName={styles.active}
      className={styles.focusable}
      onClick={onClick}
      linkClassName={styles.link}
    >
      {profile.avatarId && <AvatarImage avatarId={profile.avatarId} />}

      {!profile.avatarId && <AvatarDefault className="svg" />}

      {unreadNotificationsLen > 0 && (
        <div className={styles.newBadge} data-testid="badge" />
      )}
    </NavLink>
  );
};

export default AccountIcon;
