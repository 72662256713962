import { FeaturedResponse, ArticleDetailsResponse } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
export enum LeMagActionTypes {
  ARTICLES_FETCH_REQUEST = "ARTICLES_FETCH_REQUEST",
  ARTICLES_FETCH_SUCCESS = "ARTICLES_FETCH_SUCCESS",
  ARTICLES_FETCH_ERROR = "ARTICLES_FETCH_ERROR",
  ARTICLES_PAGINATE_REQUEST = "ARTICLES_PAGINATE_REQUEST",
  ARTICLES_PAGINATE_SUCCESS = "ARTICLES_PAGINATE_SUCCESS",
  ARTICLES_PAGINATE_ERROR = "ARTICLES_PAGINATE_ERROR",
  GET_ARTICLEDETAILS_REQUEST = "GET_ARTICLEDETAILS_REQUEST",
  GET_ARTICLEDETAILS_SUCCESS = "GET_ARTICLEDETAILS_SUCCESS",
  GET_ARTICLEDETAILS_ERROR = "GET_ARTICLEDETAILS_ERROR",
}

export type LeMagAction =
  | {
      type: LeMagActionTypes.ARTICLES_FETCH_REQUEST;
    }
  | {
      type: LeMagActionTypes.ARTICLES_FETCH_SUCCESS;
      response: FeaturedResponse;
    }
  | {
      type: LeMagActionTypes.ARTICLES_FETCH_ERROR;
      error?: any;
    }
  | {
      type: LeMagActionTypes.ARTICLES_PAGINATE_REQUEST;
    }
  | {
      type: LeMagActionTypes.ARTICLES_PAGINATE_SUCCESS;
      response: FeaturedResponse;
    }
  | {
      type: LeMagActionTypes.ARTICLES_PAGINATE_ERROR;
      error?: any;
    }
  | {
      type: LeMagActionTypes.GET_ARTICLEDETAILS_REQUEST;
    }
  | {
      type: LeMagActionTypes.GET_ARTICLEDETAILS_SUCCESS;
      response: ArticleDetailsResponse;
    }
  | {
      type: LeMagActionTypes.GET_ARTICLEDETAILS_ERROR;
      error?: any;
    };

export const dismissError = () => {
  return (dispatch: Dispatch<LeMagAction>) => {
    dispatch({ type: LeMagActionTypes.ARTICLES_FETCH_ERROR });
  };
};
