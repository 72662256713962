import {
  Lemag,
  LeMagArticle,
  LeMagFeaturedArticle,
  Game,
} from "@blacknut/javascript-sdk/dist";
import { useLeMagFeaturedArticles } from "core";
import {
  bachEnableSections,
  disableAllSectionsExcept,
  FocusableSection,
  focusSectionWithPath,
  useSpatialNavigation,
} from "@blacknut/spatialnav-sdk/dist";
import { QRCodeSVG } from "qrcode.react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import { ReactComponent as ElectronBackIcon } from "../../assets/dist/ic_arrow_back_active.svg";
import { CustomBackSubscription, useCustomBack } from "@blacknut/react-sdk/dist";
import { useTheme } from "../../theme/ThemeProvider";
import useAnonymousCheck from "../../utils/useAnonymousCheck";
import { Button } from "@blacknut/react-sdk/dist";
import LeMagArticleList from "../LeMagArticleList/LeMagArticleList";

import * as H from "history";
import styles from "./styles.module.scss";
import clsx from "clsx";

//FIXME replace modal with it's own page

export interface LeMagArticleModalProps extends ReactModal.Props {
  articles: (LeMagArticle | LeMagFeaturedArticle | Lemag)[];
  article: LeMagArticle | LeMagFeaturedArticle | Lemag;
  game?: Game;
  testID?: string;
  onClose?: () => void;
  buttons?: React.ReactNode;
  onAfterOpen?: () => void;
  closeButtonText?: string;
  // Auto add OK button is close prop is set bu no buttons
  addOKButton?: boolean;
  history: H.History;
}

const articleIsArticle = (
  art: LeMagArticle | LeMagFeaturedArticle | Lemag,
): art is LeMagArticle => {
  return "link" in art;
};

const articleIsLemag = (
  art: LeMagArticle | LeMagFeaturedArticle | Lemag,
): art is Lemag => {
  return "uuid" in art;
};

const NOP = () => {
  //nope obviously
};
const LeMagArticleModal = ({
  isOpen,
  articles,
  article: initialArticle,
  game,
  buttons,
  onClose,
  onAfterOpen,
  className,
  history,
}: React.PropsWithChildren<LeMagArticleModalProps>) => {
  const { getArticleDetails, articleDetails } = useLeMagFeaturedArticles({
    limit: 1,
  });

  const { checkForAnonymous } = useAnonymousCheck();

  const [article, setArticle] = React.useState(initialArticle);
  const onClickArticle = React.useCallback((art: LeMagArticle) => {
    setArticle(art);
  }, []);

  React.useEffect(() => {
    if (articleIsArticle(article)) {
      const link = article.link;
      const params = link.split("https://www.blacknutlemag.com")[1].split("/");
      const slug = params[2];
      getArticleDetails(slug);
    } else if (articleIsLemag(article)) {
      getArticleDetails(article.uuid);
    } else {
      getArticleDetails(article.short_slug);
    }
  }, [article, getArticleDetails]);

  let descHTMLNoP = "";
  if (articleDetails) {
    if (articleDetails.content) {
      if (typeof articleDetails.content === "object") {
        descHTMLNoP = (articleDetails.content as string[])
          .join("\n")
          .replace("<p>", "")
          .replace("</p>", "");
      } else if (typeof articleDetails.content === "string") {
        descHTMLNoP = articleDetails.content.replace("<p>", "").replace("</p>", "");
      }
    } else if (articleDetails.intro) {
      descHTMLNoP = articleDetails.intro;
    }
  }

  const onPlayClicked = React.useCallback(
    (gameId: string) => {
      setTimeout(() => {
        if (checkForAnonymous()) {
          onClose && onClose();
          history.push(`/game/${gameId}/play`);
        }
      }, 300);
    },
    [checkForAnonymous, history, onClose],
  );
  const { resume } = useSpatialNavigation();

  const { theme } = useTheme();
  const { t } = useTranslation();
  const disabledSections = React.useRef([] as string[]);
  const { push: backPush } = useCustomBack();
  const backSubscription = React.useRef<CustomBackSubscription>();
  const _onAfterOpen = React.useCallback(() => {
    resume();

    //Disable all focusable sections except the current one
    disabledSections.current = disableAllSectionsExcept("/modal");
    focusSectionWithPath("/modal");

    if (onClose) {
      backSubscription.current = backPush(() => {
        onClose();
        return true;
      });
    }

    if (onAfterOpen) {
      onAfterOpen();
    }
  }, [backPush, onAfterOpen, onClose, resume]);

  const _onAfterClose = React.useCallback(() => {
    bachEnableSections(disabledSections.current);
    backSubscription.current?.remove();
    //Wait till app.router disable spatial nav to reenable it
    setTimeout(() => {
      resume(); //FIXME
    }, 200);
  }, [resume]);

  const similarArticles: LeMagArticle[] = [];
  if (articles && articles.length > 0 && articleIsArticle(article)) {
    for (const art of articles) {
      if (
        articleIsArticle(art) &&
        art.image.id === article.image.id &&
        art !== article
      ) {
        similarArticles.push(art);
      }
    }
  }

  return (
    <ReactModal
      testId={game?.id}
      isOpen={isOpen}
      onAfterOpen={_onAfterOpen}
      className={clsx(styles.modal, className)}
      onRequestClose={onClose}
      onAfterClose={_onAfterClose}
      style={{
        overlay: {
          position: "fixed",
          zIndex: 1002,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.modalStyle.overlayColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        content: {
          width: "100%",
          height: "100%",
          background: "RGBA(0,0,0,1)",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "0px",
          outline: "none",
          padding: `${theme.modalStyle.padding}rem`,
          border: "none",
          color: "white",
          boxShadow: "0 0 24px rgba(0,0,0,.3)",
        },
      }}
    >
      <FocusableSection
        focusKey="modal"
        defaultElement={".leMagPlay"}
        config={{ restrict: "self-only" }}
      >
        <div className={styles.header}>
          {(buttons || onClose) && (
            <Button
              variant="iconButton"
              onClick={onClose || NOP}
              className={styles.close}
            >
              <ElectronBackIcon />
            </Button>
          )}
          {game ? t("game.backFromModal", { game: game?.name }) : t("buttons.back")}
        </div>
        <div className={styles.container}>
          <div className={styles.top}>
            <div className={styles.left}>
              <div className={styles.title}>{articleDetails?.title}</div>
              <div className={styles.subtitle}>{articleDetails?.subtitle}</div>
              <div className={styles.descriptionContainer}>
                <div
                  className={styles.desc}
                  dangerouslySetInnerHTML={{
                    __html: descHTMLNoP,
                  }}
                />
                <div className={styles.mask} />
              </div>
              {articleDetails?.game_id?.length === 1 && (
                <div className={styles.buttonContainer}>
                  <Button
                    variant="primary"
                    onClick={() => onPlayClicked(articleDetails?.game_id[0])}
                    className={clsx(styles.play, "leMagPlay")}
                  >
                    {t("buttons.play")}
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.right}>
              <QRCodeSVG
                className={styles.qrCode}
                value={articleIsArticle(article) ? article.link : article.slug}
                includeMargin={true}
              />
              <div>{t("game.qrCodeArticleLeMag")}</div>
            </div>
          </div>
          <div className={styles.list}>
            {similarArticles.length > 0 && (
              <>
                <div className={styles.separator} />
                <div className={styles.titleLeMag}>{t("game.similarArticles")}</div>
                <LeMagArticleList
                  articles={similarArticles}
                  ItemProps={{ style: { color: "#fff" } }}
                  onClick={onClickArticle}
                />
              </>
            )}
          </div>
        </div>
      </FocusableSection>
    </ReactModal>
  );
};

export default LeMagArticleModal;
