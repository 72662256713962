import * as React from "react";
import { useSelector } from "react-redux";
import { State } from "../redux/reducers";

export const usePrevious = <T extends unknown>(value: T) => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useLayout = () => {
  return useSelector((state: State) => state.globalState.layout);
};
