import { logD, logE } from "@blacknut/logging/dist";
import { State } from "core";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { firstValueFrom } from "rxjs";
import { useTheme } from "src/theme/ThemeProvider";
import { LOGGING_TAG } from "./Utils";
import { deriveToken } from "@blacknut/javascript-sdk/dist/api/deriveToken";

export const useWarioURL = () => {
  const { accountUrl, familyToken, userToken } = useSelector(
    (state: State) => state.globalState,
  );
  const { theme } = useTheme();
  const create = useCallback(
    async (path = "/", params?: Record<string, string>, opts?: { tokens: boolean }) => {
      const url = new URL(
        path,
        process.env.NODE_ENV === "test" ? "https://blacknut.com" : accountUrl,
      );
      const tokens = opts?.tokens || true;
      url.searchParams.append("theme", theme.name);
      url.searchParams.append("frompwa", "true");
      if (tokens) {
        let _userToken = userToken;
        let _familyToken = familyToken;

        try {
          const derived = await firstValueFrom(deriveToken("profile"));
          _userToken = derived.userToken;
          _familyToken = derived.familyToken;
        } catch (e) {
          logE(LOGGING_TAG, "Caught error on token derivation", e);
        }
        if (_familyToken) {
          url.searchParams.append("fat", _familyToken.accessToken);
          url.searchParams.append("frt", _familyToken.refreshToken);
        }
        if (_userToken) {
          url.searchParams.append("uat", _userToken.accessToken);
          url.searchParams.append("urt", _userToken.refreshToken);
        }
      }

      if (params) {
        const keys = Object.keys(params);
        for (const idx in keys) {
          url.searchParams.append(keys[idx], params[keys[idx]]);
        }
      }

      logD(LOGGING_TAG, "Using URL: %o", url);
      return url;
    },
    [accountUrl, familyToken, theme.name, userToken],
  );
  return {
    create,
  };
};
