import { FirebasePerformance, PerformanceTrace } from "firebase/performance";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
export const PerformanceContext = React.createContext<{
  trace: (name: string) => PerformanceTrace | undefined;
}>({
  trace: (name: string) => {
    return undefined;
  },
});

const USE_FIREBASE =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_USE_PROFILER === "true";

export const PerformanceContextProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [perf, setPerf] = useState<{
    perf: FirebasePerformance;
    trace: (perf: FirebasePerformance, name: string) => PerformanceTrace;
  }>();
  useEffect(() => {
    USE_FIREBASE &&
      import("firebase/app").then((e) => {
        const firebaseConfig = {
          apiKey: "AIzaSyBC5FUh5AvKVOLlG5Hw4jH_8ckV-GfmNFo",
          authDomain: "main-app-9b31d.firebaseapp.com",
          projectId: "main-app-9b31d",
          storageBucket: "main-app-9b31d.appspot.com",
          messagingSenderId: "1070454849139",
          appId: "1:1070454849139:web:65a7a3e516057855647406",
        };

        // Initialize Firebase
        e.initializeApp(firebaseConfig);
        import("firebase/performance").then((e2) => {
          const perf = e2.getPerformance();
          setPerf({
            perf: perf,
            trace: e2.trace,
          });
        });
      });
  }, []);

  const trace = useCallback(
    (s: string) => {
      if (perf) {
        return perf.trace(perf.perf, s);
      }
      return undefined;
    },
    [perf],
  );

  return (
    <PerformanceContext.Provider
      value={{
        trace,
      }}
    >
      {children}
    </PerformanceContext.Provider>
  );
};
export const usePerformanceMonitoring = () => useContext(PerformanceContext);
