import {
  ActiveStream,
  ApiError,
  StreamStatus,
  Game,
} from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
export enum GameActionTypes {
  GAME_FETCH_REQUEST = "GAME_FETCH_REQUEST",
  GAME_FETCH_SUCCESS = "GAME_FETCH_SUCCESS",
  GAME_FETCH_ERROR = "GAME_FETCH_ERROR",

  ADD_BONUS_GAME_REQUEST = "ADD_BONUS_GAME_REQUEST",
  ADD_BONUS_GAME_SUCCESS = "ADD_BONUS_GAME_SUCCESS",
  ADD_BONUS_GAME_ERROR = "ADD_BONUS_GAME_ERROR",

  ADD_TO_FAVORITE_REQUEST = "ADD_TO_FAVORITE_REQUEST",
  ADD_TO_FAVORITE_SUCCESS = "ADD_TO_FAVORITE_SUCCESS",
  FAVORITE_ERROR = "FAVORITE_ERROR",

  REMOVE_FROM_FAVORITE_REQUEST = "REMOVE_FROM_FAVORITE_REQUEST",
  REMOVE_FROM_FAVORITE_SUCCESS = "REMOVE_FROM_FAVORITE_SUCCESS",

  BULK_REMOVE_FROM_FAVORITE_REQUEST = "BULK_REMOVE_FROM_FAVORITE_REQUEST",
  BULK_REMOVE_FROM_FAVORITE_SUCCESS = "BULK_REMOVE_FROM_FAVORITE_SUCCESS",
  BULK_REMOVE_FROM_FAVORITE_ERROR = "BULK_REMOVE_FROM_FAVORITE_ERROR",

  BULK_REMOVE_FROM_LAST_PLAYED_REQUEST = "BULK_REMOVE_FROM_LAST_PLAYED_REQUEST",
  BULK_REMOVE_FROM_LAST_PLAYED_SUCCESS = "BULK_REMOVE_FROM_LAST_PLAYED_SUCCESS",
  BULK_REMOVE_FROM_LAST_PLAYED_ERROR = "BULK_REMOVE_FROM_LAST_PLAYED_ERROR",
}

export type GameAction =
  | {
      type: GameActionTypes.GAME_FETCH_REQUEST;
      id: string;
    }
  | {
      type: GameActionTypes.GAME_FETCH_SUCCESS;
      game: Game;
      isFavorite: boolean;
    }
  | {
      type: GameActionTypes.GAME_FETCH_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: GameActionTypes.ADD_BONUS_GAME_REQUEST;
      id: string;
    }
  | {
      type: GameActionTypes.ADD_BONUS_GAME_SUCCESS;
      game: Game;
    }
  | {
      type: GameActionTypes.ADD_BONUS_GAME_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: GameActionTypes.ADD_TO_FAVORITE_REQUEST;
      id: string;
    }
  | {
      type: GameActionTypes.ADD_TO_FAVORITE_SUCCESS;
      id: string;
    }
  | {
      type: GameActionTypes.FAVORITE_ERROR;
      id: string;
      error?: ApiError;
    }
  | {
      type: GameActionTypes.REMOVE_FROM_FAVORITE_REQUEST;
      id: string;
    }
  | {
      type: GameActionTypes.REMOVE_FROM_FAVORITE_SUCCESS;
      id: string;
    }
  | {
      type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_REQUEST;
      ids: Set<string>;
    }
  | {
      type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_SUCCESS;
      ids: Set<string>;
    }
  | {
      type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_ERROR;
      error?: ApiError;
    }
  | {
      type: GameActionTypes.BULK_REMOVE_FROM_LAST_PLAYED_REQUEST;
      ids: Set<string>;
    }
  | {
      type: GameActionTypes.BULK_REMOVE_FROM_LAST_PLAYED_SUCCESS;
      ids: Set<string>;
    }
  | {
      type: GameActionTypes.BULK_REMOVE_FROM_LAST_PLAYED_ERROR;
      error?: ApiError;
    };

export const favoriteError = (id: string, error?: ApiError): GameAction => ({
  type: GameActionTypes.FAVORITE_ERROR,
  id,
  error,
});

export const dismissFavoriteError = (id: string) => {
  return (dispatch: Dispatch<GameAction>) => {
    dispatch(favoriteError(id));
  };
};

export const getDeviceStreams = (streams: ActiveStream[], audid: string) => {
  return streams.filter(
    (u) =>
      u.stream && u.stream.audid === audid && u.stream.status === StreamStatus.Playing,
  );
  // return streams;
};
