import {
  ApiError,
  BillingInfo,
  User,
  UserToken,
  AmazonUser,
} from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { forkJoin } from "rxjs";
import {
  loadFamilyInfoSuccess,
  ProfilesAction,
  setCurrentProfile,
} from "../../Profiles";
import {
  onUserTokenRetrieved_,
  setFamilyToken,
  UserAction,
  UserActionTypes,
} from "../User";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";

import { Action } from "../../App";
import { amazonCreate } from "@blacknut/javascript-sdk/dist/api/User/Action/amazonCreate";

const userAmazonCreateRequest = (): UserAction => ({
  type: UserActionTypes.USER_AMAZON_CREATE_REQUEST,
});

const userAmazonCreateSuccess = (): UserAction => ({
  type: UserActionTypes.USER_AMAZON_CREATE_SUCCESS,
});

const userAmazonCreateError = (error?: ApiError): UserAction => ({
  type: UserActionTypes.USER_AMAZON_CREATE_ERROR,
  error,
});

export const dismissAmazonCreateError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(userAmazonCreateError());
  };
};

export const amazonSignup = (jsonResult: AmazonUser) => {
  return (dispatch: Dispatch<UserAction | ProfilesAction | Action>) => {
    return new Promise<{ token: UserToken; user?: User; billingInfo?: BillingInfo }>(
      (resolve, reject) => {
        dispatch(userAmazonCreateRequest());
        amazonCreate({
          ...jsonResult.userInfo,
          accessToken: jsonResult.accessToken,
        }).subscribe({
          next: (token) => {
            setFamilyToken(token.familyToken)(dispatch);
            const sub: {
              familyInfo: ReturnType<typeof getFamilyInfo>;
              userInfo?: ReturnType<typeof onUserTokenRetrieved_>;
            } = {
              familyInfo: getFamilyInfo(),
            };

            if (token.userToken) {
              sub.userInfo = onUserTokenRetrieved_(
                dispatch,
                token.userToken,
                true,
                false,
              );
            }

            forkJoin(sub).subscribe({
              next: (res) => {
                const { profiles, subscription } = res.familyInfo;
                dispatch(loadFamilyInfoSuccess(profiles, subscription));
                if (res.userInfo) {
                  const { user, billingInfo } = res.userInfo;
                  dispatch(setCurrentProfile(profiles[0], user, false));
                  dispatch(userAmazonCreateSuccess());
                  resolve({ token, user, billingInfo });
                } else {
                  dispatch(userAmazonCreateSuccess());
                  resolve({ token });
                }
              },
              error: (error) => {
                dispatch(userAmazonCreateError(error));
                reject(error);
              },
            });
          },

          error: (error) => {
            dispatch(userAmazonCreateError(error));
            reject(error);
          },
        });
      },
    );
  };
};
