import { List, Page, Tile as TileModel } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import { AppLayout, useLayout } from "core";
import { FocusableSection, FocusableSectionProps } from "@blacknut/spatialnav-sdk/dist";
import React, { useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";
import { OrientationType, useOrientation } from "../../../utils/OrientationContext";
import { LOGGING_TAG, tile2Id } from "../../../utils/Utils";
import { CircularProgress } from "@blacknut/react-sdk/dist";
import Tile from "../../Tile/Tile";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { getNumColumns } from "../../../theme/dimens";

const arrange = (
  list: List,
  layout: AppLayout,
  orientation: OrientationType,
  lastRowComplete: boolean,
) => {
  const items = list.tiles;
  if (!lastRowComplete) return items;

  let nbItems = items.length;
  if (layout !== AppLayout.DESKTOP && list.moreLink) {
    nbItems++; // for the more tile
  }
  const nbCols = getNumColumns(layout, orientation);
  let nbRows = Math.ceil(nbItems / nbCols);
  if (nbItems % nbCols != 0) {
    nbRows--; // last row not complete
  }

  const res = items.slice(0, nbRows * nbCols);

  return res;
};

interface TileGridProps
  extends Pick<
    FocusableSectionProps,
    "leaveFor" | "disabled" | "config" | "onNavigationFailed"
  > {
  decorate?: (
    t: TileModel,
    focused: boolean,
  ) => React.ComponentType<unknown> | React.ReactElement | null;
  onClickTile?: (t: TileModel) => void;
  className?: string;
  numColumns?: number;
  showTitle?: boolean;
  list: List;
  paginating?: boolean;
  paginate?: () => void;
  lastRowComplete?: boolean; // If true list will be trimed in order to display a full grid without empty cols
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  page?: Page;
}
const TileGrid = ({
  list,
  decorate,
  className,
  numColumns,
  showTitle = true,
  paginating,
  paginate,
  onClickTile,
  lastRowComplete = false,
  leaveFor,
  disabled,
  config,
  onNavigationFailed,
  onFocus,
  page,
}: TileGridProps) => {
  const layout = useLayout();
  const { orientation } = useOrientation();
  const tiles = useMemo(
    () => arrange(list, layout, orientation, lastRowComplete),
    [list, layout, orientation, lastRowComplete],
  );
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      const total = list.total;
      if (total > list.tiles.length && !paginating) {
        logD(
          LOGGING_TAG,
          "Start pagination (uuid=%o ) (%o/%o)",
          list.uuid,
          list.tiles.length,
          total,
        );
        if (paginate) {
          paginate();
        }
      }
    }
  }, [inView, list, paginate, paginating]);
  const nbCols = numColumns || getNumColumns(layout, orientation);
  const hasTitle = showTitle && list.title && list.title.trim().length > 0;

  return (
    <FocusableSection
      className={clsx(styles.container, className)}
      focusKey={list.uuid}
      leaveFor={leaveFor}
      disabled={disabled}
      config={config}
      onNavigationFailed={onNavigationFailed}
      onFocus={onFocus}
    >
      {(hasTitle || (!!list.moreLink && layout === AppLayout.PHONE)) && (
        <div className={styles.titleContainer}>
          {hasTitle && <span className={styles.title}>{list.title}</span>}
        </div>
      )}

      <ul
        className={styles.list}
        style={{ gridTemplateColumns: `repeat(${nbCols}, 1fr)` }}
      >
        {tiles.map((tile, index) => {
          const _onClick = onClickTile ? () => onClickTile(tile) : undefined;
          return (
            <li key={`${list.uuid}-${tile2Id(tile)}`}>
              <Tile
                item={tile}
                list={list}
                decorate={decorate}
                onClick={_onClick}
                page={page}
              />
            </li>
          );
        })}
      </ul>
      <div ref={ref} />
      {paginating && <CircularProgress className={styles.progress} size={36} />}
    </FocusableSection>
  );
};
export default TileGrid;
