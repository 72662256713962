import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import React, { UIEvent, useCallback } from "react";
import { NavLink, NavLinkProps, useHistory } from "react-router-dom";
import useAnonymousCheck from "../../utils/useAnonymousCheck";
import styles from "./styles.module.scss";
import { useScrollContext } from "src/utils/scroll/ScrollProvider";

const MyNavLink = (
  props: NavLinkProps & { linkClassName?: string; requiresAnonymousCheck?: boolean },
) => {
  const history = useHistory();

  const { requiresAnonymousCheck = false, linkClassName } = props;
  const { checkForAnonymous } = useAnonymousCheck();
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const { clear: clearScrollContext } = useScrollContext();
  const onPress = useCallback(
    (e: UIEvent<unknown>) => {
      e.preventDefault();

      const callback = () => {
        clearScrollContext();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        if (props.replace) {
          history.replace(props.to.toString());
        } else {
          history.push(props.to.toString());
        }
      };
      if (requiresAnonymousCheck && checkForAnonymous()) {
        callback();
      } else if (!requiresAnonymousCheck) {
        callback();
      }
    },
    [
      checkForAnonymous,
      clearScrollContext,
      history,
      props.replace,
      props.to,
      requiresAnonymousCheck,
    ],
  );
  const remainingProps = { ...props };
  delete remainingProps.linkClassName;
  delete remainingProps.requiresAnonymousCheck;

  return (
    <Focusable
      className={clsx([styles.focusable, props.className])}
      onPressEnter={(props.onClick as (e: UIEvent<unknown>) => void) || onPress}
    >
      <NavLink
        onClick={onPress}
        activeClassName={styles.active}
        {...remainingProps}
        className={clsx([
          styles.link,
          spatialNavigationActive && styles.spatialActive,
          linkClassName,
        ])}
      >
        {props.children}
      </NavLink>
    </Focusable>
  );
};

export default MyNavLink;
