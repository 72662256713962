import {
  Image,
  List,
  ListDisplayType,
  Page,
  Tile as TileModel,
  Video,
} from "@blacknut/javascript-sdk/dist";
import { logE } from "@blacknut/logging/dist";
import { Focusable, useSpatialNavigation } from "@blacknut/spatialnav-sdk/dist";
import clsx from "clsx";
import { AppLayout, State, StorageKey, useLayout, useTile } from "core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import { useTheme } from "src/theme/ThemeProvider";
import { ReactComponent as IcBonus } from "../../assets/dist/ic_bonus_decoration.svg";
import { ReactComponent as Home } from "../../assets/dist/ic_home.svg";
import { ReactComponent as IcPass } from "../../assets/dist/ic_pass_decoration.svg";
import { useProfiler } from "../../hooks/useProfiler";
import { useTileSize } from "../../utils/TileWidthProvider";
import { LOGGING_TAG } from "../../utils/Utils";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import styles from "./styles.module.scss";

interface TileProps {
  className?: string;
  item: TileModel;
  featured?: boolean;
  list: List;
  decorate?: (t: TileModel, focused: boolean) => React.ReactNode;
  onClick?: () => void;
  page?: Page;
}

const Tile = ({
  className,
  item,
  featured = false,
  list,
  page,
  decorate,
  onClick: onClickFromProps,
}: TileProps) => {
  const { theme } = useTheme();
  const list16_9 = list.display === ListDisplayType.FEATURED;

  const { active: previewActive } = usePreview();
  const { onClick } = useTile({
    item,
    previewMode: previewActive,
    pageUuid: page?.uuid,
  });
  const { config } = useSelector((state: State) => state.globalState);
  const [videoLoaded, setVideoLoaded] = useState(false);
  let image: Image | undefined;
  let videoThumbnail: Video | undefined;
  const videoItem = useRef<HTMLVideoElement | null>(null);
  if (item.game) {
    const mainImages =
      item.game &&
      item.game.images &&
      item.game.images.filter((im) =>
        ["thumbnail", "thumbnail_featured", "main"].includes(im.type),
      );
    image = mainImages ? mainImages[0] : undefined;
    const videoThumbnails =
      item.game &&
      item.game.videos &&
      item.game.videos.filter((v) => v.type === "thumbnail");
    videoThumbnail = videoThumbnails ? videoThumbnails[0] : undefined;
  } else if (item.link) {
    const themedImg = item.link.images?.filter((im) => im.theme === theme.name)?.[0];
    image = themedImg || undefined;
  } else if (item.page) {
    const themedImgs = item.page[list16_9 ? "imgRectangular" : "imgSquare"] as Image[];
    const themedImg = themedImgs?.filter((im: Image) => im.theme === theme.name)?.[0];
    image = themedImg || undefined;
  } else {
    logE(LOGGING_TAG, "invalid tile: %o", item);
  }

  const [videoPlaying, setVideoPlaying] = useState(false);
  const [focused, setFocused] = useState(false);
  const [hovering, setHovering] = useState(false);
  const { active: spatialNavigationActive } = useSpatialNavigation();
  const isTVLegacy = localStorage.getItem(StorageKey.TV_LEGACY) === "true";
  const onTimeUpdate = useCallback(() => {
    if (videoItem && videoItem.current && videoItem.current.currentTime > 0.25) {
      setVideoLoaded(true);
      setVideoPlaying(true);
    }
  }, []);

  const onMouseEnter = useCallback(() => {
    setHovering(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    unstable_batchedUpdates(() => {
      setFocused(false);
      setVideoLoaded(false);
      setHovering(false);
    });
  }, []);

  const onBlur = useCallback(() => {
    unstable_batchedUpdates(() => {
      setVideoLoaded(false);
      setFocused(false);
    });
  }, []);
  const onFocus = useCallback(() => {
    setFocused(true);
  }, []);

  useEffect(() => {
    if (spatialNavigationActive && hovering) {
      setHovering(false);
    }
  }, [hovering, spatialNavigationActive]);

  const layout = useLayout();
  const isWaitingTimeout: { current: NodeJS.Timeout | null } = useRef(null);
  const [longFocused, setLongFocused] = useState(false);
  useEffect(() => {
    if (layout !== AppLayout.TV) return;
    if (!focused) {
      setLongFocused(false);
      clearTimeout(isWaitingTimeout.current as NodeJS.Timeout);
    } else {
      isWaitingTimeout.current = setTimeout(() => {
        setLongFocused(true);
      }, 1000);
    }

    return () => {
      clearTimeout(isWaitingTimeout.current as NodeJS.Timeout);
    };
  }, [focused, layout]);

  const [ref, inView] = useInView({
    threshold: 0,
  });
  const { standardTileSize, featuredTileSize, featured16By9TileSize } = useTileSize();

  const Root = useProfiler("tile");
  const bonusGames = !!config?.features?.blacknutplus;

  return (
    <Root>
      <Focusable
        onPressEnter={onClickFromProps || onClick}
        className={clsx([
          styles.container,
          // !WARNING! DO NOT use class "tile" for styling
          // It is purposely not scoped since it needs to be accessible from FocusableSection defaultElement prop
          "tile",
          styles.focusable,
          className,
        ])}
        onMouseEnter={layout === AppLayout.DESKTOP ? onMouseEnter : undefined}
        onMouseLeave={layout === AppLayout.DESKTOP ? onMouseLeave : undefined}
        onClick={onClickFromProps || onClick}
        data-testid="container"
        style={{
          width:
            list.display === ListDisplayType.FEATURED
              ? featured16By9TileSize.width
              : featured
              ? featuredTileSize.width
              : standardTileSize.width,
          height:
            list.display === ListDisplayType.FEATURED
              ? featured16By9TileSize.height
              : featured
              ? featuredTileSize.height
              : standardTileSize.height,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      >
        <div className={clsx([styles.wrap])} ref={ref}>
          {(inView || process.env.NODE_ENV === "test") && (
            <>
              <div className={styles.imageContainer}>
                {image && (
                  <FadeInImage
                    image={image}
                    suffixOnPixelRatio1={featured ? "_md" : "_sm"}
                    className={clsx([videoLoaded ? styles.fadeIn : styles.fadeOut])}
                  />
                )}
              </div>

              {!image && (
                <div className={styles.missing} data-testid="placeholder">
                  <Home />
                </div>
              )}
              {!decorate && (
                <>
                  {!isTVLegacy &&
                    !list16_9 &&
                    (layout !== AppLayout.TV || longFocused) &&
                    videoThumbnail &&
                    ((hovering && !spatialNavigationActive) ||
                      (spatialNavigationActive && focused)) && (
                      <video
                        ref={videoItem}
                        data-testid="video"
                        className={clsx([styles.video, videoPlaying && styles.playing])}
                        muted
                        autoPlay
                        playsInline
                        onTimeUpdate={videoPlaying ? undefined : onTimeUpdate}
                        src={videoThumbnail?.url}
                        loop={true}
                      />
                    )}
                </>
              )}

              {bonusGames && !item.game?.addonOption?.isAvailableForCurrentUser && (
                <span
                  className={clsx(
                    styles.bonus,
                    layout === AppLayout.PHONE && styles.onMobileBonus,
                  )}
                  data-testid="bonus"
                >
                  {item.game?.addonOption?.isBonusGame && <IcBonus />}
                  {!item.game?.addonOption?.isBonusGame &&
                    item.game?.addonOption?.inGamePass && <IcPass />}
                </span>
              )}

              {decorate && decorate(item, false)}
            </>
          )}
        </div>
      </Focusable>
    </Root>
  );
};
export default React.memo(Tile);
