import {
  BillingInfo,
  FamilyStreams,
  User,
  UserProfile,
} from "@blacknut/javascript-sdk/dist";
import { batch } from "react-redux";
import { Dispatch } from "redux";
import { fetchRemoteNotifications, onUserTokenRetrieved, UserAction } from "../User";
import { ProfilesAction, ProfilesActionTypes } from "./Profiles";
import { Action as AppAction } from "../App";
import { getUserToken as apiGetUserToken } from "@blacknut/javascript-sdk/dist/api/User/getUserToken";
export const selectProfile = (
  profile: UserProfile,
  pin: string | null,
  fetchActiveStreams: boolean,
) => {
  return (dispatch: Dispatch<ProfilesAction | UserAction | AppAction>) => {
    return new Promise<{
      user: User;
      profile: UserProfile;
      billingInfo?: BillingInfo;
      activeStreams?: FamilyStreams;
    }>((resolve, reject) => {
      dispatch({ type: ProfilesActionTypes.SELECT_PROFILE_REQUEST });
      apiGetUserToken(profile.id, pin || undefined).subscribe({
        next: (token) => {
          // Now that we have a token, we can fetch config, menu & notifications
          onUserTokenRetrieved(dispatch, token, true, fetchActiveStreams).subscribe({
            next: (res) => {
              batch(() => {
                dispatch({
                  type: ProfilesActionTypes.SELECT_PROFILE_SUCCESS,
                  profile,
                  user: res[0],
                  fromProfileSelection: true,
                });
              });
              fetchRemoteNotifications(res[0])(dispatch);
              resolve({
                user: res[0],
                profile,
                billingInfo: res[3],
                activeStreams: res[4],
              });
            },
            error: (error) => {
              dispatch({ type: ProfilesActionTypes.SELECT_PROFILE_ERROR, error });
              reject(error);
            },
          });
        },
        error: (error) => {
          dispatch({ type: ProfilesActionTypes.SELECT_PROFILE_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
