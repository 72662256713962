import { AppLayout, useLayout } from "core";
import { RemoteNotification } from "@blacknut/javascript-sdk/dist";
import React from "react";
import { useHistory } from "react-router";
import { ReactComponent as Close } from "../../../assets/dist/ic_close.svg";
import { Button } from "@blacknut/react-sdk/dist";
import dimens from "../../../theme/dimens";
import styles from "./styles.module.scss";

const BannerNotification = (props: {
  notification: RemoteNotification;
  onClose: () => void;
}) => {
  const { notification, onClose } = props;
  const layout = useLayout();
  const history = useHistory();
  const onClickLink = async (event: React.MouseEvent<unknown>) => {
    if (notification.link) {
      if (notification.link.startsWith(`${process.env.REACT_APP_DEEPLINK_SCHEME}://`)) {
        history.push(
          notification.link.replace(`${process.env.REACT_APP_DEEPLINK_SCHEME}://`, "/"),
        );
      } else {
        window.open(notification.link, "_blank");
      }
    }
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      onClose();
    }, 500);
  };
  const [yPosition, setYPosition] = React.useState(-1000);

  const _onClose = () => {
    setYPosition(-100);
    setTimeout(onClose, 500);
  };

  React.useEffect(() => {
    setYPosition(0);
  }, []);

  const linkText: string | undefined = notification.linkText;

  return (
    <>
      <div
        className={styles.container}
        style={{
          top: `calc(${
            layout === AppLayout.DESKTOP
              ? dimens.desktop.headerHeight
              : dimens.phone.headerHeight
          }rem + ${yPosition}px)`,
        }}
      >
        <span className={styles.desc}>{notification.description}</span>
        {linkText && (
          <a href="#" onClick={onClickLink} className={styles.link} data-testid="link">
            {linkText}
          </a>
        )}
        <Button
          variant="iconButton"
          className={styles.button}
          onClick={_onClose}
          testID="close"
        >
          <Close />
        </Button>
      </div>
    </>
  );
};
export default BannerNotification;
