import {
  GameStreamProtocol,
  PlayerLogLevel,
  Stream,
} from "@blacknut/javascript-sdk/dist";
import { Subject } from "rxjs";
import { logD } from "@blacknut/logging/dist";
import { LOGGING_TAG } from "../utils/Utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const electron = (window as any).electron;

const isElectronAvailable = () => {
  return !!electron;
};

export enum ElectronEventType {
  onUpdateDownloaded = "onUpdateDownloaded",
  onUpdateAvailable = "onUpdateAvailable",
  onUpdateError = "onUpdateError",
  devToolsChange = "devToolsChange",
  uncaughtException = "uncaughtException",
  sendStreamLog = "sendStreamLog",
  onNetworkResultFailed = "onNetworkResultFailed",
  onNetworkResult = "onNetworkResult",
  onPlayerClosed = "onPlayerClosed",
  onPlayerError = "onPlayerError",
  onPlayerStart = "onPlayerStart",
  deeplinkingUrl = "deeplinkingUrl",
  onNavigateInPage = "onNavigateInPage",
  onDeviceInfo = "onDeviceInfo",
  onGoogleLogin = "onGoogleLogin",
  postToken = "postToken",
}

export declare interface ElectronEvent {
  event: string;
  data?: unknown;
}

export class ElectronService {
  // private windowHasFocus = true;
  private isGamePlaying = false;
  private platform = "web";
  private subject: Subject<ElectronEvent> = new Subject();
  // tslint:disable-next-line:variable-name
  private _nativePlayerVersion = "";
  // tslint:disable-next-line:variable-name
  private _version = "";
  constructor() {
    if (!isElectronAvailable()) {
      return;
    }
    this.platform = electron.getPlatform();

    // Handle navigation intra page
    electron.onNavigateInPage(
      (e: unknown, data: { url: string; canGoBack: boolean }) => {
        this.subject.next({ event: ElectronEventType.onNavigateInPage, data });
      },
    );

    electron.onDevToolsToogle((closed: boolean) => {
      this.subject.next({
        event: ElectronEventType.devToolsChange,
        data: `${!!closed}`,
      });
    });

    // Handle Exception
    electron.onUncaughtException((error: unknown) => {
      this.subject.next({
        event: ElectronEventType.uncaughtException,
        data: error,
      });
    });

    // Handle update
    electron.onUpdateDownloaded((version: string) => {
      this.subject.next({
        event: ElectronEventType.onUpdateDownloaded,
        data: version,
      });
    });

    electron.onUpdateAvailable(() => {
      this.subject.next({ event: ElectronEventType.onUpdateAvailable });
    });

    electron.onUpdateError(() => {
      this.subject.next({ event: ElectronEventType.onUpdateError });
    });

    electron.onDeviceInfo((data: unknown) => {
      this.subject.next({ event: ElectronEventType.onDeviceInfo, data });
    });

    // Handle Network Test Failed
    electron.onNetworkResultFailed(() => {
      this.subject.next({ event: ElectronEventType.onNetworkResultFailed });
    });

    // Handle Network Test Pass
    electron.onNetworkResult((results: unknown) => {
      this.subject.next({
        event: ElectronEventType.onNetworkResult,
        data: results,
      });
    });

    // Handle Player close
    electron.onPlayerClosed((results: unknown) => {
      this.isGamePlaying = false;
      this.subject.next({
        event: ElectronEventType.onPlayerClosed,
        data: results,
      });
    });

    // Handle Player Error
    electron.onPlayerError((results: unknown) => {
      this.isGamePlaying = false;
      this.subject.next({
        event: ElectronEventType.onPlayerError,
        data: results,
      });
    });

    // Handle Player start
    electron.onPlayerStart((results: unknown) => {
      this.isGamePlaying = true;
      this.subject.next({
        event: ElectronEventType.onPlayerStart,
        data: results,
      });
    });

    // get Deeplinking
    electron.onDeeplink((results: string | string[] | null) => {
      logD(LOGGING_TAG, "deeplinkingUrl %o %o", results);
      // Delete last char '/' and '\s'

      let url;
      if (typeof results === "string") {
        url = results;
      } else if (results && results.length) {
        url = results[0];
      }

      if (url) {
        const deeplinkingUrl = url
          ? url.replace(/(\/|\s)*$/, "").slice("blacknut:/".length)
          : null;
        this.subject.next({
          event: ElectronEventType.deeplinkingUrl,
          data: deeplinkingUrl,
        });
      }
    });

    electron.onPostToken((token: string) => {
      logD(LOGGING_TAG, "postToken %o %o", token);
      this.subject.next({
        event: ElectronEventType.postToken,
        data: token,
      });
    });

    const { appVersion, playerVersion }: { appVersion: string; playerVersion: string } =
      electron.getVersions();
    logD(LOGGING_TAG, "getVersions %o %o", appVersion, playerVersion);
    this._nativePlayerVersion = playerVersion;
    this._version = appVersion;
  }

  public recoverFromV3(): {
    token?: string;
    profilesData?: string;
    theme?: string;
  } {
    return electron && electron.recoverFromV3();
  }

  public isAvailable() {
    return isElectronAvailable();
  }

  public getCommandLineArgs() {
    return electron && electron.getCommandLineArgs();
  }

  public get nativePlayerVersion(): string {
    return this._nativePlayerVersion;
  }

  public get version(): string {
    return this._version;
  }

  public onElectronSubject() {
    return this.subject.asObservable();
  }

  public isPlaying() {
    return this.isGamePlaying;
  }

  public getPlatform() {
    return this.platform;
  }

  public onMinimize() {
    return electron && electron.minimize();
  }

  public clearHistory() {
    return electron && electron.clearHistory();
  }

  public onFullscreen() {
    return electron && electron.maximize();
  }

  public requestFullscreen() {
    return electron && electron.requestFullscreen();
  }

  public requestFullscreenAvailable() {
    return !!electron && electron.requestFullscreen;
  }

  public getDeviceInfo() {
    return electron && electron.getDeviceInfo();
  }

  // Send close to electron
  public onClose() {
    return electron && electron.close();
  }

  public sendDebug(debug: boolean) {
    logD(LOGGING_TAG, "Sending debug to electron");
    return electron && electron.toggleDebug(debug);
  }

  public killPlayer() {
    return electron && electron.killPlayer();
  }
  public checkUpdate() {
    return electron && electron.checkUpdate();
  }

  public canGoBack() {
    return electron && electron.ipcRenderer.canGoBack();
  }

  public acceptUpdate() {
    return electron && electron.acceptUpdate();
  }

  public startGame(
    stream: Stream,
    email: string,
    userId: string,
    opts?: {
      protocol?: GameStreamProtocol;
      logLevel: PlayerLogLevel;
    },
  ) {
    logD(LOGGING_TAG, "Start game with opts %o", opts);
    return electron && electron.startGame(stream, email, userId, opts);
  }

  //TODO check what it is used for
  public playerStarted() {
    return electron && electron.onPlayerStarted();
  }

  public sendNetworkTestConfig(testConfig: unknown) {
    return electron && electron.startSpeedTest(testConfig);
  }

  public openWebView(params: {
    id: string;
    bounds: { x: number; y: number; width: number; height: number };
    uri: string;
  }) {
    return electron && electron.openWebview(params);
  }

  public closeWebView(id: string) {
    return electron && electron.closeWebView(id);
  }

  public googleLogin(): Promise<{ id_token: string }> {
    return electron && electron.googleLogin();
  }

  public getNetworkConnectionType(): Promise<string> {
    return electron && electron.getNetworkConnectionType();
  }
}

export default new ElectronService();
