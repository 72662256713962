import {
  ApiError,
  BillingInfo,
  User,
  UserToken,
  AmazonUser,
} from "@blacknut/javascript-sdk/dist";
import { amazonLogin as apiAmazonLogin } from "@blacknut/javascript-sdk/dist/api/User/Action/amazonLogin";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";

import { Dispatch } from "redux";
import { forkJoin } from "rxjs";
import { AppAction } from "../../App";
import {
  loadFamilyInfoSuccess,
  ProfilesAction,
  setCurrentProfile,
} from "../../Profiles";
import { fetchRemoteNotifications } from "../fetchRemoteNotifications";
import {
  onUserTokenRetrieved_,
  setFamilyToken,
  UserAction,
  UserActionTypes,
} from "../User";
export const userAmazonLoginRequest = (): UserAction => ({
  type: UserActionTypes.USER_AMAZON_LOGIN_REQUEST,
});

export const userAmazonLoginSuccess = (): UserAction => ({
  type: UserActionTypes.USER_AMAZON_LOGIN_SUCCESS,
});

export const userAmazonLoginError = (error?: ApiError): UserAction => ({
  type: UserActionTypes.USER_AMAZON_LOGIN_ERROR,
  error,
});

export const dismissAmazonLoginError = () => {
  return (dispatch: Dispatch<UserAction>) => {
    dispatch(userAmazonLoginError());
  };
};

export const amazonLogin = (jsonResult: AmazonUser) => {
  return (dispatch: Dispatch<UserAction | ProfilesAction | AppAction>) => {
    dispatch(userAmazonLoginRequest());
    return new Promise<{ token: UserToken; user?: User; billingInfo?: BillingInfo }>(
      (resolve, reject) => {
        apiAmazonLogin({
          ...jsonResult.userInfo,
          accessToken: jsonResult.accessToken,
        }).subscribe({
          next: (token: UserToken) => {
            setFamilyToken(token.familyToken)(dispatch);

            const sub: {
              familyInfo: ReturnType<typeof getFamilyInfo>;
              userInfo?: ReturnType<typeof onUserTokenRetrieved_>;
            } = {
              familyInfo: getFamilyInfo(),
            };

            if (token.userToken) {
              sub.userInfo = onUserTokenRetrieved_(
                dispatch,
                token.userToken,
                true,
                false,
              );
            }

            forkJoin(sub).subscribe({
              next: (res) => {
                const { profiles, subscription } = res.familyInfo;
                dispatch(loadFamilyInfoSuccess(profiles, subscription));
                if (res.userInfo) {
                  const { user, billingInfo } = res.userInfo;
                  dispatch(setCurrentProfile(profiles[0], user, false));
                  fetchRemoteNotifications(user)(dispatch);
                  dispatch(userAmazonLoginSuccess());
                  resolve({ token, user, billingInfo });
                } else {
                  dispatch(userAmazonLoginSuccess());
                  resolve({ token });
                }
              },
              error: (error) => {
                dispatch(userAmazonLoginError(error));
                reject(error);
              },
            });
          },
          error: (err: ApiError) => {
            dispatch(userAmazonLoginError(err));
            reject(err);
          },
        });
      },
    );
  };
};
