import { List, Page } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import { FocusableSectionProps, LeaveFor } from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import Grid from "../../List/Grid";
import { LOGGING_TAG } from "../../../utils/Utils";
import { useProfiler } from "../../../hooks/useProfiler";

interface GridViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  leaveFor?: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
  page?: Page;
}

const GridView = ({ list, leaveFor, onFocus, page }: GridViewProps) => {
  logD(LOGGING_TAG, "Render grid");
  const Root = useProfiler("fullList", { uuid: list.uuid });

  return (
    <Root>
      <Grid
        list={list}
        key={list.uuid}
        leaveFor={leaveFor}
        onFocus={onFocus}
        lastRowComplete={true}
        page={page}
      />
    </Root>
  );
};

export default GridView;
