import { State, useTile } from "core";
import { Focusable } from "@blacknut/spatialnav-sdk/dist";
import * as React from "react";
import { useTheme } from "../../theme/ThemeProvider";
import FadeInImage from "../FadeInImage/FadeInImage";
import { usePreview } from "../Modals/PreviewContext";
import styles from "./styles.module.scss";
import clsx from "clsx";
import { List, Page, Tile, getImageByThemeName } from "@blacknut/javascript-sdk/dist";
import { useSelector } from "react-redux";
import { ReactComponent as IcPass } from "../../assets/dist/ic_pass_decoration.svg";
import { ReactComponent as IcBonus } from "../../assets/dist/ic_bonus_decoration.svg";

interface V2BannerTileProps {
  item: Tile;
  list: List;
  className?: string;
  inView: boolean;
  page?: Page;
}

const BannerTile = ({
  item,
  list,
  className,
  inView = true,
  page,
}: V2BannerTileProps) => {
  const { theme } = useTheme();
  const { active: previewActive } = usePreview();
  const { config } = useSelector((state: State) => state.globalState);
  const bonusGames = !!config?.features?.blacknutplus;

  const { onClick } = useTile({
    item,
    previewMode: previewActive,
    pageUuid: page?.uuid,
  });
  const imgBanner = getImageByThemeName(theme.name, list.imgBackground ?? []);
  return (
    <Focusable
      className={clsx(styles.container, styles.focusable, className)}
      onClick={onClick}
      onPressEnter={onClick}
    >
      {inView && <FadeInImage image={imgBanner} className={styles.image} />}
      {bonusGames && !item.game?.addonOption?.isAvailableForCurrentUser && (
        <>
          {item.game?.addonOption?.isBonusGame && (
            <span className={styles.bonus} data-testid="bonus">
              <IcBonus />
            </span>
          )}
          {!item.game?.addonOption?.isBonusGame &&
            item.game?.addonOption?.inGamePass && (
              <span className={styles.bonus} data-testid="pass">
                <IcPass />
              </span>
            )}
        </>
      )}
    </Focusable>
  );
};

export default BannerTile;
