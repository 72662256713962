import { logD } from "@blacknut/logging/dist";
import React, {
  Profiler,
  ProfilerOnRenderCallback,
  PropsWithChildren,
  useCallback,
} from "react";
import { usePerformanceMonitoring } from "../utils/PerformanceContext/PerformanceContext";

const USE_PROFILER =
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_USE_PROFILER === "true";
const DEBUG_PERF = false;
const useProfiler = (
  id: string,
  props?: Record<string, string>,
  opts?: {
    memory?: boolean;
  },
) => {
  const { trace } = usePerformanceMonitoring();
  const { memory = false } = opts || {};
  const s = JSON.stringify(props);
  const onRender: ProfilerOnRenderCallback = useCallback(
    (id, phase, actualDuration, baseDuration, startTime) => {
      if (actualDuration === 0) return;

      const props = s ? JSON.parse(s) : {};
      let metrics: Record<string, number> = { actualDuration, baseDuration };
      if (memory && "memory" in window.performance) {
        //https://medium.com/@oryanmoshe/the-object-that-misbehaved-window-performance-memory-fe338736aed0
        const final = JSON.stringify(
          Object.defineProperties(
            window.performance.memory,
            Object.getOwnPropertyDescriptors(
              (window.performance as any).memory.__proto__,
            ),
          ),
        );
        metrics = { ...metrics, ...JSON.parse(final) };
      }
      DEBUG_PERF &&
        logD(
          "Perf",
          "Render %o with phase %o took %oms (%o). startTime=%o, baseDuration=%oms, metrics:%o",
          id,
          phase,
          actualDuration,
          props,
          startTime,
          baseDuration,
          metrics,
        );
      const t = trace?.(id);
      t?.start();
      t?.record(startTime, actualDuration, {
        attributes: {
          phase,
          ...props,
        },
        metrics,
      });
      t?.stop();
    },
    [memory, s, trace],
  );
  // eslint-disable-next-line react/display-name
  return React.useMemo(
    // eslint-disable-next-line react/display-name
    () => (props: PropsWithChildren<unknown>) => {
      return USE_PROFILER ? (
        <Profiler id={id} onRender={onRender}>
          {props.children}
        </Profiler>
      ) : (
        <>{props.children}</>
      );
    },
    [id, onRender],
  );
};

export { useProfiler };
