import { Dispatch } from "redux";
import { logout, UserAction } from "../User";
import { Action, ActionTypes } from "./App";
import { ProfilesAction } from "../Profiles";

export const setPlatform = (apiPath: string) => {
  return (dispatch: Dispatch<Action | UserAction | ProfilesAction>) => {
    logout()(dispatch);
    dispatch({
      type: ActionTypes.SET_PLATFORM,
      platform: apiPath,
    });
  };
};
