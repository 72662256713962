import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { SearchFormData, analyticsService } from "@blacknut/javascript-sdk/dist";
import { State } from "../../redux/reducers";

export const useAnalytics = () => {
  const { user } = useSelector((state: State) => state.globalState);
  useEffect(() => {
    if (user) {
      analyticsService.setUserId(user.id);
    }
    analyticsService.setDeviceScreen(
      `${window.screen.width * window.devicePixelRatio}x${
        window.screen.height * window.devicePixelRatio
      }`,
    );
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase().split("/");
    if (tz.length === 2) {
      analyticsService.setTzCountry(tz[1]);
      analyticsService.setTzRegion(tz[0]);
    }

    analyticsService.enabled = !user?.anonymous;
  }, [user]);
  return {
    trackSignupGoogle: useCallback(() => {
      analyticsService.trackEvent("create_account_google");
    }, []),
    trackSignupEmail: useCallback(() => {
      analyticsService.trackEvent("create_account_email");
    }, []),
    trackSignupAmazon: useCallback(() => {
      analyticsService.trackEvent("create_account_amazon");
    }, []),

    trackSigninGoogle: useCallback(() => {
      analyticsService.trackEvent("login_google");
    }, []),
    trackSigninEmail: useCallback(() => {
      analyticsService.trackEvent("login_email");
    }, []),
    trackSigninAmazon: useCallback(() => {
      analyticsService.trackEvent("login_amazon");
    }, []),

    trackSubscribeGoogle: useCallback(() => {
      analyticsService.trackEvent("subscribed_google");
    }, []),
    trackSubscribeStripe: useCallback(() => {
      analyticsService.trackEvent("subscribed_stripe");
    }, []),
    trackSubscribeAmazon: useCallback(() => {
      analyticsService.trackEvent("subscribed_amazon");
    }, []),

    trackPageView: useCallback((id: string) => {
      analyticsService.trackEvent("catalog", { page_global_id: id });
    }, []),
    trackSurpriseMe: useCallback(() => {
      analyticsService.trackEvent("surprise_me");
    }, []),
    trackAppOpen: useCallback(() => {
      analyticsService.trackEvent("app_open");
    }, []),
    trackLastPlayed: useCallback(() => {
      analyticsService.trackEvent("last_played");
    }, []),
    trackFavorites: useCallback(() => {
      analyticsService.trackEvent("favorites");
    }, []),
    trackExplorer: useCallback(() => {
      analyticsService.trackEvent("explorer");
    }, []),
    trackSearchView: useCallback(() => {
      analyticsService.trackEvent("search_view");
    }, []),
    trackSearch: useCallback((data: SearchFormData) => {
      const params: Record<string, string> = {};
      if (data.controllers && data.controllers.length > 0) {
        params.controllers = data.controllers.join(",");
      }
      if (data.genres && data.genres.length > 0) {
        params.genres = data.genres.map((g) => g.id).join(",");
      }
      if (data.multiplayer) {
        params.multiplayer = "1";
      }
      if (data.solo) {
        params.solo = "1";
      }
      if (data.name) {
        params.name = data.name;
      }
      if (data.sessionDurations && data.sessionDurations.length > 0) {
        params.sessionDurations = data.sessionDurations.join(",");
      }
      if (data.age) {
        params.age = data.age.join(",");
      }
      analyticsService.trackEvent("search_result", params);
    }, []),
    trackSearchResultsEmpty: useCallback(() => {
      analyticsService.trackEvent("search_result_empty");
    }, []),
    trackLeMag: useCallback(() => {
      analyticsService.trackEvent("lemag");
    }, []),
    trackSettings: useCallback(() => {
      analyticsService.trackEvent("settings");
    }, []),
    trackGameDetail: useCallback((id: string) => {
      analyticsService.trackEvent("game_detail", { game_global_id: id });
    }, []),
    trackDeeplink: useCallback((deeplink: string) => {
      analyticsService.trackEvent("deeplink", { deeplink });
    }, []),
    trackGamePlay: useCallback(() => {
      analyticsService.trackEvent("game_play");
    }, []),
    trackLemagRead: useCallback(() => {
      analyticsService.trackEvent("lemag_read");
    }, []),
    trackGameFavorite: useCallback(() => {
      analyticsService.trackEvent("game_favorite");
    }, []),
  };
};
