import { BillingInfo, User, UserToken } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { forkJoin } from "rxjs";
import { Action as AppAction } from "../App";
import { ProfilesAction, loadFamilyInfoSuccess, setCurrentProfile } from "../Profiles";
import {
  UserAction,
  onUserTokenRetrieved,
  setFamilyToken,
  userCreateError,
  userCreateRequest,
  userCreateSuccess,
} from "./User";
import { getFamilyInfo } from "@blacknut/javascript-sdk/dist/api/Family/getFamilyInfo";

export const onUserRemoteRegistrationSuccess = (token: UserToken) => {
  return (dispatch: Dispatch<UserAction | ProfilesAction | AppAction>) => {
    return new Promise<{ user: User; billingInfo: BillingInfo }>((resolve, reject) => {
      dispatch(userCreateRequest());
      setFamilyToken(token.familyToken)(dispatch);
      forkJoin([
        getFamilyInfo(),
        onUserTokenRetrieved(dispatch, token.userToken!, true, false),
      ]).subscribe({
        next: (res) => {
          const { profiles, subscription } = res[0];
          const user = res[1][0];

          dispatch(loadFamilyInfoSuccess(profiles, subscription));
          dispatch(setCurrentProfile(profiles[0], user, false));
          dispatch(userCreateSuccess(user));
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          resolve({ user, billingInfo: res[1][3]! });
        },
        error: (error) => {
          dispatch(userCreateError(error));
          reject(error);
        },
      });
    });
  };
};
