import { List, ListSortMethod } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { forkJoin, Observable } from "rxjs";
import { ListAction, fetchListSuccess } from "../List";
import { GameAction, GameActionTypes } from "./Game";
import { removeFromFavoriteRequest } from "./removeFromFavorite";
import { removeFromFavorite as apiRemoveFromFavorite } from "@blacknut/javascript-sdk/dist/api/removeFromFavorite";
export const dismissRemoveGamesFromFavoritesError = () => {
  return (dispatch: Dispatch<GameAction>) => {
    dispatch({ type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_ERROR });
  };
};

// FIXME provide unit test
export const removeGamesFromFavorites = (
  { list, sort }: { sort: ListSortMethod; list: List },
  games: Set<string>,
) => {
  return (dispatch: Dispatch<GameAction | ListAction>) => {
    return new Promise<void>((resolve, reject) => {
      dispatch({ type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_REQUEST, ids: games });

      const sub: Array<Observable<unknown>> = [];
      games.forEach((id) => {
        dispatch(removeFromFavoriteRequest(id));
        sub.push(apiRemoveFromFavorite(id));
      });

      // Dispatch the sooner the removal success
      dispatch(
        fetchListSuccess(list.uuid, sort, {
          ...list,
          tiles: list.tiles.filter((tile) => !games.has(tile.game!.id)),
          total: list.total - games.size,
        }),
      );

      forkJoin(sub).subscribe({
        next: () => {
          dispatch({
            type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_SUCCESS,
            ids: games,
          });
          resolve();
        },
        error: (error) => {
          dispatch({
            type: GameActionTypes.BULK_REMOVE_FROM_FAVORITE_ERROR,
            error,
          });
          reject(error);
        },
      });
    });
  };
};
