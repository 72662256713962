import { Dispatch } from "redux";
import { PageAction, PageActionTypes } from "./Page";
import {
  ApiError,
  getPageGameGenre as apiGetPageGameGenre,
  Page,
} from "@blacknut/javascript-sdk/dist";

const paginatePageGenreRequest = (id: string): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_REQUEST,
  id,
});
const paginatePageGenreSuccess = (id: string, page: Page): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_SUCCESS,
  id,
  page,
});

const paginatePageGenreError = (id: string, error?: ApiError): PageAction => ({
  type: PageActionTypes.PAGE_PAGINATE_ERROR,
  id,
  error,
});

export const paginatePagegameGenre = (
  id: string,
  page: Page,
  count?: number,
  tilesLimit?: number,
) => {
  return (dispatch: Dispatch<PageAction>) => {
    return new Promise<Page>((resolve, reject) => {
      dispatch(paginatePageGenreRequest(id));

      const offset = page.lists!.length;
      const limit = count || 5;

      apiGetPageGameGenre(id, offset, limit, tilesLimit).subscribe({
        next: (newPage) => {
          let newList = page.lists || [];
          newList = (page.lists || []).concat(newPage.lists!);
          dispatch(
            paginatePageGenreSuccess(id, {
              ...page,
              lists: newList,
            }),
          );

          resolve(page);
        },
        error: (error) => {
          dispatch(paginatePageGenreError(id, error));
          reject(error);
        },
      });
    });
  };
};
