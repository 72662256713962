import {
  UserProfile,
  User,
  ApiError,
  Subscription,
  Game,
  ProfilePref,
} from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
export enum ProfilesActionTypes {
  LOAD_FAMILY_INFO_REQUEST = "LOAD_FAMILY_INFO_REQUEST",
  LOAD_FAMILY_INFO_SUCCESS = "LOAD_FAMILY_INFO_SUCCESS",
  LOAD_FAMILY_INFO_ERROR = "LOAD_FAMILY_INFO_ERROR",

  SELECT_PROFILE_REQUEST = "SELECT_PROFILE_REQUEST",
  SELECT_PROFILE_SUCCESS = "SELECT_PROFILE_SUCCESS",
  SELECT_PROFILE_ERROR = "SELECT_PROFILE_ERROR",

  CREATE_PROFILE_REQUEST = "CREATE_PROFILE_REQUEST",
  CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS",
  CREATE_PROFILE_ERROR = "CREATE_PROFILE_ERROR",

  RESET_PIN_REQUEST = "RESET_PIN_REQUEST",
  RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS",
  RESET_PIN_ERROR = "RESET_PIN_ERROR",

  SAVE_PROFILE_REQUEST = "SAVE_PROFILE_REQUEST",
  SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS",
  SAVE_PROFILE_ERROR = "SAVE_PROFILE_ERROR",

  DELETE_PROFILE_REQUEST = "DELETE_PROFILE_REQUEST",
  DELETE_PROFILE_SUCCESS = "DELETE_PROFILE_SUCCESS",
  DELETE_PROFILE_ERROR = "DELETE_PROFILE_ERROR",

  SELECT_THEME = "SELECT_THEME",
  SET_PROFILE_PREFS = "SET_PROFILE_PREFS",
  ACKNOWLEGE_GAMEPAD_ONLY = "ACKNOWLEGE_GAMEPAD_ONLY",
}

export type ProfilesAction =
  | {
      type: ProfilesActionTypes.LOAD_FAMILY_INFO_REQUEST;
    }
  | {
      type: ProfilesActionTypes.LOAD_FAMILY_INFO_SUCCESS;
      profiles?: UserProfile[];
      subscription?: Subscription;
    }
  | {
      type: ProfilesActionTypes.LOAD_FAMILY_INFO_ERROR;
      error?: ApiError;
    }
  | {
      type: ProfilesActionTypes.SELECT_PROFILE_REQUEST;
    }
  | {
      type: ProfilesActionTypes.SELECT_PROFILE_SUCCESS;
      profile?: UserProfile;
      user?: User;
      fromProfileSelection: boolean;
    }
  | {
      type: ProfilesActionTypes.SELECT_PROFILE_ERROR;
      error?: ApiError;
    }
  | {
      type: ProfilesActionTypes.CREATE_PROFILE_REQUEST;
    }
  | {
      type: ProfilesActionTypes.CREATE_PROFILE_SUCCESS;
      profile: UserProfile;
    }
  | {
      type: ProfilesActionTypes.CREATE_PROFILE_ERROR;
      error?: ApiError;
    }
  | {
      type: ProfilesActionTypes.SAVE_PROFILE_REQUEST;
    }
  | {
      type: ProfilesActionTypes.SAVE_PROFILE_SUCCESS;
      profile: UserProfile;
    }
  | {
      type: ProfilesActionTypes.SAVE_PROFILE_ERROR;
      error?: ApiError;
    }
  | {
      type: ProfilesActionTypes.DELETE_PROFILE_REQUEST;
    }
  | {
      type: ProfilesActionTypes.DELETE_PROFILE_SUCCESS;
      id: string;
    }
  | {
      type: ProfilesActionTypes.DELETE_PROFILE_ERROR;
      error?: ApiError;
    }
  | {
      type: ProfilesActionTypes.RESET_PIN_REQUEST;
    }
  | {
      type: ProfilesActionTypes.RESET_PIN_SUCCESS;
    }
  | {
      type: ProfilesActionTypes.RESET_PIN_ERROR;
      error?: ApiError;
    }
  | {
      type: ProfilesActionTypes.SELECT_THEME;
      profile: UserProfile;
      theme: string;
    }
  | {
      type: ProfilesActionTypes.SET_PROFILE_PREFS;
      prefs: { [id: string]: ProfilePref };
    }
  | {
      type: ProfilesActionTypes.ACKNOWLEGE_GAMEPAD_ONLY;
      game: Game;
      profile: UserProfile;
    };

/* FIXME REVIEW fromProfileSelection */
export const setCurrentProfile = (
  profile?: UserProfile,
  user?: User,
  fromProfileSelection?: boolean,
): ProfilesAction => {
  return {
    type: ProfilesActionTypes.SELECT_PROFILE_SUCCESS,
    profile,
    user,
    fromProfileSelection: !!fromProfileSelection,
  };
};

export const selectTheme = (theme: string, profile: UserProfile) => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({ type: ProfilesActionTypes.SELECT_THEME, theme, profile });
  };
};

export const setProfilePrefs = (prefs: { [id: string]: ProfilePref }) => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({ type: ProfilesActionTypes.SET_PROFILE_PREFS, prefs });
  };
};

export const acknowledgeGamepadOnly = (game: Game, profile: UserProfile) => {
  return (dispatch: Dispatch<ProfilesAction>) => {
    dispatch({
      type: ProfilesActionTypes.ACKNOWLEGE_GAMEPAD_ONLY,
      game,
      profile,
    });
  };
};
