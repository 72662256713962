import { DreiConfig } from "@blacknut/javascript-sdk/dist";
import { Dispatch } from "redux";
import { DreiAction, DreiActionTypes } from "./Drei";
import { dreiGetConfig as apiDreiGetConfig } from "@blacknut/javascript-sdk/dist/api/User/Drei/dreiGetConfig";

export const dismissDreiGetConfigError = () => {
  return (dispatch: Dispatch<DreiAction>) => {
    return dispatch({ type: DreiActionTypes.DREI_GET_CONFIG_ERROR });
  };
};

export const dreiClearConfig = () => {
  return (dispatch: Dispatch<DreiAction>) => {
    return dispatch({ type: DreiActionTypes.DREI_GET_CONFIG_SUCCESS });
  };
};

export const dreiGetConfig = () => {
  return (dispatch: Dispatch<DreiAction>) => {
    dispatch({ type: DreiActionTypes.DREI_GET_CONFIG_REQUEST });
    return new Promise<DreiConfig>((resolve, reject) => {
      apiDreiGetConfig().subscribe({
        next: (config) => {
          dispatch({ type: DreiActionTypes.DREI_GET_CONFIG_SUCCESS, config });
          resolve(config);
        },
        error: (error) => {
          dispatch({ type: DreiActionTypes.DREI_GET_CONFIG_ERROR, error });
          reject(error);
        },
      });
    });
  };
};
