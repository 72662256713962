import { List } from "@blacknut/javascript-sdk/dist";
import { logD } from "@blacknut/logging/dist";
import {
  FocusableSection,
  FocusableSectionProps,
  LeaveFor,
} from "@blacknut/spatialnav-sdk/dist";
import React from "react";
import { LOGGING_TAG } from "../../../utils/Utils";
import RandomTile from "../../TileRandom/RandomTile";
import { useProfiler } from "../../../hooks/useProfiler";
import { useInView } from "react-intersection-observer";

interface RandomViewProps extends Pick<FocusableSectionProps, "leaveFor" | "disabled"> {
  list: List;
  leaveFor?: LeaveFor;
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => void;
}

const RandomView = ({ list, leaveFor, onFocus }: RandomViewProps) => {
  const t = list.tiles[0];
  logD(LOGGING_TAG, "Render random");
  const [randomRef, inView] = useInView({
    threshold: 0,
  });

  const Root = useProfiler("randomList", { uuid: list.uuid });
  return (
    <Root>
      <FocusableSection
        focusKey={list.uuid}
        key={list.uuid}
        leaveFor={leaveFor}
        onFocus={onFocus}
      >
        <div ref={randomRef}>
          <RandomTile
            item={t}
            list={list}
            key={list.uuid}
            inView={inView || process.env.NODE_ENV === "test"}
          />
        </div>
      </FocusableSection>
    </Root>
  );
};

export default RandomView;
