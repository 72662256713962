import { DeprecatedStorageKey, IStorageService, StorageKey } from "core";
import { UserToken } from "@blacknut/javascript-sdk/dist";
import electronService from "./ElectronService";

export class LocalStorageService implements IStorageService {
  public getItem<T>(key: StorageKey | DeprecatedStorageKey): Promise<T | null> {
    if (!localStorage || !localStorage.getItem) {
      return Promise.resolve(null);
    }
    const e = localStorage.getItem(key);
    if (e === null) {
      return Promise.resolve(null);
    }

    try {
      return Promise.resolve(JSON.parse(e));
    } catch (e) {
      return Promise.resolve(null);
    }
  }
  public setItem<T>(key: StorageKey | DeprecatedStorageKey, value: T): Promise<void> {
    if (value === undefined) {
      return this.removeItem(key);
    }
    if (!localStorage || !localStorage.setItem) {
      return Promise.resolve();
    }

    // retro compatibility with yoshi < 2
    if (
      (key === StorageKey.PLATFORM || key === StorageKey.VERSION) &&
      electronService.isAvailable()
    ) {
      const version = electronService.version;
      if (version && parseInt(version, 10) < 2) {
        localStorage.setItem(key, value as unknown as string);
        return Promise.resolve();
      }
    }

    if (key === StorageKey.TOKEN) {
      // Save token to local storage
      const token = { ...(value as unknown as UserToken) };
      localStorage.setItem(key, JSON.stringify(value));
      // But save also on session storage : when pressing F5 the currently selected profile should still be active
      if (sessionStorage && sessionStorage.setItem) {
        sessionStorage.setItem(key, JSON.stringify({ userToken: token.userToken }));
      }
    } else {
      localStorage.setItem(key, JSON.stringify(value));
    }

    return Promise.resolve();
  }
  public removeItem(key: StorageKey | DeprecatedStorageKey): Promise<void> {
    if (!localStorage || !localStorage.setItem) {
      return Promise.resolve();
    }
    localStorage.removeItem(key);
    // If token, save also on session storage
    if (key === StorageKey.TOKEN && sessionStorage && sessionStorage.removeItem) {
      sessionStorage.removeItem(key);
    }

    return Promise.resolve();
  }
}

export default new LocalStorageService();
